import React from 'react';
import ModalAnchor from 'src/anchors/ModalAnchor';
import WorkModalBody from 'src/work/WorkModalBody';
import { Name } from 'src/work/WorkFormatters';

export default function WorkAnchor (props) {
  const { work, showStatus, ...restOfProps } = props;
  return (
    <ModalAnchor
      {...restOfProps}
      title={<Name value={work} showStatus={showStatus} />}
      name={<>Jobb #<Name value={work} /></>}
    >
      <WorkModalBody id={work.id} />
    </ModalAnchor>
  );
}



import React from 'react';
import isObjectLike from 'lodash/isObjectLike';
import IdShort from 'src/formatters/IdShort';
import * as customerFilter from 'likvidum/shared/filter/customer';
import { CustomerRow } from 'likvidum/shared/types/customer';
import StatusBaseLabel, {StatusBaseLabelProps} from 'src/formatters/StatusBaseLabel';

interface NameProps {
  value: Pick<CustomerRow, 'name' | 'first_name' | 'last_name' | 'id'>;
}

export function Name (props: NameProps) {
  const { value:customer } = props;
  if (!isObjectLike(customer)) return null;

  let name = customer.name || '';
  if (!name) {
    if (customer.first_name && customer.last_name) {
      name = `${customer.first_name} ${customer.last_name}`;
    } else if (customer.id) {
      name = IdShort({value: customer.id.split('_')[0]});
    }
  }
  return (
    <>{name}</>
  );
}

interface StatusProps {
  value: CustomerRow['status'];
}

export function Status (props: StatusProps) {
  const { value } = props;
  return customerFilter.status(value);
}

interface StatusLabelProps extends StatusBaseLabelProps {
  value: CustomerRow['status'];
}

export function StatusLabel (props: StatusLabelProps) {
  const { value, ...restOfProps } = props;
  return (
    <StatusBaseLabel
      value={value}
      formatter={customerFilter.status}
      {...restOfProps}
    />
  );
}

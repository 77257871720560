

/**
 * WARNING: This file is used clientside
 */

const applicationFilter = require('shared/filter/application');
const format = require('shared/format');
const date = require('shared/filter/date');
const misc = require('shared/filter/misc');
const getter = require('shared/get');
const translations = require('shared/translations');

// filters column names for customer index v2
exports.indexColumnName = function (columnKey) {
  if (typeof columnKey !== 'string') return columnKey;

  switch (columnKey) {
    default: return columnKey;
    case 'id': return 'ID';
    case 'ssn': return 'Personnummer';
    case 'name': return 'Namn';
    case 'first_name': return 'Förnamn';
    case 'last_name': return 'Efternamn';
    case 'telephone_mobile': return 'Telefon (mobil)';
    case 'email': return 'E-post';
    case 'address_street': return 'Gatuadress';
    case 'address_city': return 'Stad';
    case 'address_postcode': return 'Postnummer';
    case 'address_co': return 'C/O';
    case 'portal_is_from': return 'Portalkund';
    case 'portal_allow_login': return 'Portalinloggning';
    case 'portal_last_login_at': return 'Senast portalinloggning';
    case 'portal_last_login_at_relative': return 'Senast portalinloggning';
    case 'contact_opt_in': return 'Kontakt godkänd';
    case 'contact_opt_in_at': return 'Kontakt godkänd';
    case 'contact_opt_in_at_relative': return 'Kontakt godkänd';
    case 'suspected_at': return 'Misstänkt';
    case 'created_at': return 'Skapad';
    case 'created_at_relative': return 'Skapad';
    case 'updated_at': return 'Uppdaterad';
    case 'updated_at_relative': return 'Uppdaterad';
    case 'status_updated_at': return 'Statusuppdatering';
    case 'last_uc_debt': return 'Senaste betalningsanmärkning';
    case 'last_uc_debt_at': return 'Senaste betalningsanmärkning';
    case 'last_uc_debt_at_relative': return 'Senaste betalningsanmärkning';
    case 'last_uc_risk': return 'Senaste riskprognos';

    case 'last_application_id': return 'Senaste ans.';
    case 'last_application_created': return 'Senaste ans. skapad?';
    case 'last_application_created_at': return 'Senaste ans. skapad';
    case 'last_application_created_at_relative': return 'Senaste ans. skapad';

    case 'last_application_disbursed_closed': return 'Senast ans. utb?';
    case 'last_application_disbursed_closed_at': return 'Senast utb. ans.';
    case 'last_application_disbursed_closed_at_relative': return 'Senast utb. ans.';
    case 'last_application_disbursed_bank_id': return 'Senast utb. långivare';
    case 'last_disbursed_offer_disbursed_amount': return 'Senast utb. belopp';
    case 'last_disbursed_offer_applied_amount': return 'Senaste utb. ans. belopp';
    case 'last_disbursed_offer_repayment_years': return 'Senast utb. återbetalningstid';
    case 'last_disbursed_offer_interest_rate_effective': return 'Senast utb. effektiva ränta';
    case 'cloudinsurance_synced_at': return 'Cloudinsurance synkdatum';
    case 'cloudinsurance_customer_id': return 'Cloudinsurance kund-ID';
    case 'has_cloudinsurance_customer_id': return 'Cloudinsurance kund-ID?';
    case 'cloudinsurance_created_by_portal': return 'Cloudinsurance skapad i portal';
    case 'suspected_terrorist': return 'Misstänkt för penningtvätt eller terrorism';
    case 'user_id': return 'Ägare';
    case 'risk': return 'Risk';
    case 'status': return 'Status';
    case 'preferred_language': return 'Föredraget språk';
    case 'age': return 'Ålder';
    case 'bank_clearing_number': return 'Clearingnummer';
    case 'bank_account_number': return 'Kontonummer';

    case 'LastApplication.status': return 'Senaste ans. status';
    case 'LastApplication.form.loan.amount.now': return 'Senaste ans. lösenbelopp';
    case 'LastApplication.form.loan.amount.new': return 'Senaste ans. nylån';
    case 'LastApplication.form.loan.repayment_years': return 'Senaste ans. återbetalningstid';
    case 'LastApplication.form.loan.purpose': return 'Senaste ans. lånesyfte';
    case 'LastApplication.form.main_applicant.address.type': return 'Senaste ans. boendeform';
    case 'LastApplication.form.main_applicant.status': return 'Senaste ans. civilstånd';
    case 'LastApplication.form.main_applicant.income': return 'Senaste ans. inkomst';
    case 'LastApplication.form.main_applicant.children': return 'Senaste ans. barn';
    case 'LastApplication.form.main_applicant.habitation_cost': return 'Senaste ans. boendekostnad';
    case 'LastApplication.form.main_applicant.employment_type': return 'Senaste ans. anställningsform';
  }
};

// filters customers to values by column key for customer index v2
exports.indexField = function (customer, columnKey) {
  const get = (...args) => getter(customer, ...args);

  switch (columnKey) {
    default: return get(columnKey);

    case 'ssn': return format.ssn('long-dash', get('ssn'));

    case 'last_application_id': return get('LastApplication.id');

    case 'last_application_created_at': return get('LastApplication.created_at') ? date.dayTime(get('LastApplication.created_at')) : '-';

    case 'last_uc_risk':
      return misc.pctWhenNumber(format.numberRound(get(columnKey) * 100, 2));

    case 'age': 
      return format.ssn('age', get('ssn'));

    case 'preferred_language':
      return misc.language(get(columnKey));

    case 'contact_opt_in_at':
    case 'created_at':
    case 'last_application_disbursed_closed_at':
    case 'last_uc_debt_at':
    case 'portal_bids_last_seen':
    case 'portal_last_login_at':
    case 'suspected_at':
    case 'updated_at':
      return get(columnKey) ? date.dayTime(get(columnKey)) : '-';

    case 'user_id': return get('User.name') || get('User.id') || get('user_id') || '';

    case 'contact_opt_in':
    case 'portal_is_from':
    case 'portal_allow_login':
    case 'suspected_terrorist':
    case 'cloudinsurance_created_by_portal':
      return misc.yesno(get(columnKey));

    case 'last_disbursed_offer_disbursed_amount': return misc.currencyWhenNumber(get('last_disbursed_offer.disbursed_amount'));
    case 'last_disbursed_offer_applied_amount': return misc.currencyWhenNumber(get('last_disbursed_offer.applied_amount'));
    case 'last_application_disbursed_bank_id': return get('DisbursedApplicationBank.name', get('DisbursedApplicationBank.id', '')) || '';
    case 'last_disbursed_offer_repayment_years': return misc.yearWhenNumber(get('last_disbursed_offer.repayment_years'));
    case 'last_disbursed_offer_interest_rate_effective': return misc.pctWhenNumber(format.numberRound(get('last_disbursed_offer.interest_rate_effective'), 2));
    case 'status': return exports.status(get(columnKey));
    case 'risk': return exports.risk(get(columnKey));

    case 'LastApplication.status': {
      const status = get(columnKey);
      const hasAcceptedProcess = !!get('LastApplication.accepted_process_id');
      return status ? applicationFilter.statusAccepted(status, hasAcceptedProcess) : '';
    }

    case 'LastApplication.form.main_applicant.employment_type': {
      const value = get(columnKey);
      return translations.application.employment_types(value);
    }

    case 'LastApplication.form.main_applicant.status': {
      const value = get(columnKey);
      return translations.application.applicant_statuses(value);
    }

    case 'LastApplication.form.main_applicant.address.type': {
      const value = get(columnKey);
      return translations.application.address_types(value);
    }

    case 'LastApplication.form.loan.purpose': {
      const value = get(columnKey);
      return translations.application.loan_purposes(value);
    }
  }
};

/**
 * Filter Customer Status
 * @param {String} status
 * @return {String}
 */
exports.status = function (status) {
  switch (status) {
    default: return status;
    case 'prospect': return 'Prospekt';
    case 'customer': return 'Kund';
    case 'no_contact': return 'Kontakta inte igen';
    case 'no_answer': return 'Svarar ej';
    case 'near_hope': return 'Närtidshopp (6 mån)';
    case 'midterm_hope': return 'Närtidshopp (12 mån)';
    case 'far_hope': return 'Långtidshopp';
    case 'bad_credit': return 'Betalningsanmärkning';
    case 'ignore': return 'Ignorera';
    case 'coapplicant': return 'Medsökande';
    case 'blocked': return 'Spärrad';
  }
};

/**
 * Filter Customer Risk
 * @param {String} risk
 * @return {String}
 */
exports.risk = function (risk) {
  switch (risk) {
    case 'low': return 'Låg risk';
    case 'medium': return 'Normal risk';
    case 'high': return 'Hög risk';
    case 'extreme': return 'Oacceptabel risk';
    default: return risk;
  }
};



const constants = require('shared/constants');

/**
 * WARNING: This file is used clientside
 */

exports.yesno = function (bool) {
  if (typeof bool === 'undefined' || bool === null) return bool;
  if (constants.query.isTruthy(bool)) return 'Ja';
  return 'Nej';
};

exports.appendWhenNotEmpty = function (val, str) {
  return !['', null, undefined].includes(val) ? val + str : val;
};

exports.currencyWhenNumber = function (val) {
  return exports.appendWhenNumber(val, ' kr');
};

exports.yearWhenNumber = function (val) {
  return exports.appendWhenNumber(val, ' år');
};

exports.pctWhenNumber = function (val) {
  return exports.appendWhenNumber(val, ' %');
};

exports.appendWhenNumber = function (val, str) {
  return typeof val === 'number' && isFinite(val) ? String(val) + str : '';
};

exports.logicalComparison = function (op) {
  switch (op) {
    default: return op;
    case 'eq': return 'Lika med';
    case 'gt': return 'Större än';
    case 'gte': return 'Lika/större';
    case 'lt': return 'Mindre än';
    case 'lte': return 'Lika/mindre';
  }
};

exports.logicalComparisonShort = function (op) {
  switch (op) {
    default: return op;
    case 'eq': return '=';
    case 'gt': return '>';
    case 'gte': return '≥';
    case 'lt': return '<';
    case 'lte': return '≤';
  }
};

exports.commonDateFields = function (name) {
  switch (name) {
    default: return name;
    case 'created_at': return 'Skapad';
    case 'closed_at': return 'Stängd';
    case 'accepted_at': return 'Accepterad';
    case 'status_updated_at': return 'Uppdaterad';
    case 'updated_at': return 'Uppdaterad';
  }
};

exports.language = function (language) {
  switch (language) {
    default: return language;
    case 'ar': return 'Arabiska';
    case 'sr': return 'Serbiska';
    case 'sv': return 'Svenska';
    case 'en': return 'Engelska';
    case 'es': return 'Spanska';
  }
};

exports.statusIsActive = function (isActive) {
  return isActive ? 'Aktiv' : 'Inaktiv';
};

exports.statusIsActiveValue = function (isActive) {
  return isActive ? 'active' : 'inactive';
};

// takes the "data" jsonb property of most types of logs and returns the error as a multiline string
exports.errorLogDiagnose = function (data = {}) {
  let errorObject;
  if (data.error) errorObject = data.error;
  else if (data.diagnose) errorObject = data.diagnose;
  else errorObject = data;
  if (typeof errorObject === 'string') return 'Fel: ' + errorObject;
  const { statusCode, stack } = errorObject;
  const message = errorObject.message || '(Okänt fel)';
  return [
    'Fel' + (statusCode ? ' ' + statusCode + ': ' : ': ') + message,
    stack,
  ].filter(v => v).join('\n');
};



/**
 * WARNING: This file is used clientside
 */

const getter = require('shared/get');

exports.priority = function (priority, length = 'long') {
  switch (length) {
    default: return priority;
    case 'long':
      switch (priority) {
        default: return priority;
        case 'low': return 'Låg (Kundtjänst)';
        case 'medium': return 'Mellan (Klagomålsansvarig)';
        case 'high': return 'Hög (Styrelse)';
      }
    case 'short':
      switch (priority) {
        default: return priority;
        case 'low': return 'Låg';
        case 'medium': return 'Mellan';
        case 'high': return 'Hög';
      }
  }
};

exports.status = function (status) {
  switch (status) {
    default: return status;
    case 'open': return 'Öppen';
    case 'closed': return 'Avslutad';
  }
};

exports.indexColumnName = function (columnName) {
  switch (columnName) {
    default: return columnName;
    case 'actions': return 'Åtgärder';
    case 'created_at': return 'Skapad';
    case 'created_at_relative': return 'Skapad';
    case 'customer_id': return 'Kund';
    case 'notes': return 'Anteckningar';
    case 'notes_length': return 'Notat';
    case 'id': return 'ID';
    case 'priority': return 'Prioritet';
    case 'status': return 'Status';
    case 'updated_at': return 'Uppdaterad';
    case 'updated_at_relative': return 'Uppdaterad';
  }
};

exports.indexField = function (complaint, columnName, options = {}) {
  const get = (...args) => getter(complaint, ...args);
  switch (columnName) {
    default: return get(columnName);
    case 'status': return exports.status(get('status'));
    case 'notes_length': return get('notes.length') || 0;
    case 'priority': return exports.priority(get('priority'), 'short');
    case 'customer_id':
      return get('Customer.name') || get('Customer.id') || get('customer_id') || '';
  }
};

import React from 'react';
import { useQuery } from '@tanstack/react-query';
import * as api from 'src/api';

interface IGroupSelectOptions {
  extendQueryWith?: {[key: string]: string};
}

export default function GroupSelectOptions (props: IGroupSelectOptions) {
  const { extendQueryWith = {} } = props;
  const query = useQuery({
    queryKey: ['groupSelectOptions', extendQueryWith],
    queryFn: () => api.request({
      url: '/group/selectOptions',
      params: {is_active: true, ...extendQueryWith},
    }).then(groups => {
      const options = groups.map(group => ({value: group.id, label: group.name ?? group.id}));
      return options;
    }),
  });
  if (!Array.isArray(query.data)) return null;
  return (
    <>
      {query.data.map(option => (
        <option key={option.value} value={option.value}>{option.label}</option>
      ))}
    </>
  );
}

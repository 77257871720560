import React from 'react';
import ModalAnchor from 'src/anchors/ModalAnchor';
import TemplateModalBody from 'src/template/TemplateModalBody';
import { Name } from 'src/template/TemplateFormatters';
import { VirtualTemplateRow } from 'shared/types/template';

interface TemplateAnchorProps {
  template: Pick<VirtualTemplateRow, 'id'>;
}

export default function TemplateAnchor (props: TemplateAnchorProps) {
  const { template, ...restOfProps } = props;
  return (
    <ModalAnchor
      {...restOfProps}
      title={<Name value={template} />}
      name="Mall"
    >
      <TemplateModalBody id={template.id} />
    </ModalAnchor>
  );
}

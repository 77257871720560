

/**
 * WARNING: This file is used clientside
 */

const moment = require('moment');

/**
 * Formats a Date (as a date)
 * @param {Date} date
 * @return {String}
 */
exports.day = function (date, timezone) {
  let m = moment(date);
  if (timezone) m = m.tz(timezone);
  if (!m.isValid()) return '';
  return m.format('YYYY-MM-DD');
};

/**
 * Formats a Date (as date + time)
 * @param {Date} date
 * @return {String}
 */
exports.dayTime = function (date, timezone) {
  let m = moment(date);
  if (timezone) m = m.tz(timezone);
  if (!m.isValid()) return '';
  return m.format('YYYY-MM-DD HH:mm:ss');
};

exports.dateRelative = function (relativeName) {
  switch (relativeName) {
    default: return relativeName;
    case 'today': return 'Idag';
    case 'yesterday': return 'Igår';
    case 'thisweek': return 'Denna vecka';
    case 'lastweek': return 'Föregående vecka';
    case 'thismonth': return 'Denna månad';
    case 'lastmonth': return 'Föregående månad';
    case 'thisquarter': return 'Detta kvartal';
    case 'lastquarter': return 'Föregående kvartal';
    case 'thisyear': return 'Detta år';
    case 'lastyear': return 'Föregående år';
  }
};

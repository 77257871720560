import React from 'react';
import {
  FormikFormGroupControl,
  FormikFormGroupSelect,
} from 'src/form/FormikFormControls';
import * as miscFilters from 'shared/filter/misc';
import * as constants from 'shared/constants';
import * as customerFilters from 'shared/filter/customer';

export function FirstName () {
  return (
    <FormikFormGroupControl
      label="Förnamn"
      name="first_name"
      minLength={0}
      maxLength={255}
    />
  );
}

export function LastName () {
  return (
    <FormikFormGroupControl
      label="Efternamn"
      name="last_name"
      minLength={0}
      maxLength={255}
    />
  );
}

export function TelephoneMobile () {
  return (
    <FormikFormGroupControl
      label="Mobiltelefon"
      name="telephone_mobile"
      minLength={0}
      maxLength={255}
    />
  );
}

export function Email () {
  return (
    <FormikFormGroupControl
      label="E-postadress"
      type="email"
      name="email"
      minLength={0}
      maxLength={255}
    />
  );
}

export function PreferredLanguage () {
  return (
    <FormikFormGroupSelect
      label="Föredraget språk"
      name="preferred_language"
      required
    >
      {constants.languages.map(language => (
        <option key={language} value={language}>{miscFilters.language(language)}</option>
      ))}
    </FormikFormGroupSelect>
  );
}

export function Risk () {
  return (
    <FormikFormGroupSelect
      label="Risk"
      name="risk"
      required
    >
      {constants.customer.risk.map(risk => (
        <option key={risk} value={risk}>{customerFilters.risk(risk)}</option>
      ))}
    </FormikFormGroupSelect>
  );
}

export function AddressStreet () {
  return (
    <FormikFormGroupControl
      label="Efternamn"
      name="last_name"
      minLength={0}
      maxLength={255}
    />
  );
}

export function AddressPostcode () {
  return (
    <FormikFormGroupControl
      label="Postnummer"
      name="address_postcode"
      minLength={5}
      maxLength={5}
      pattern="^\d{5}$"
    />
  );
}


export function AddressCo () {
  return (
    <FormikFormGroupControl
      label="C/O"
      name="address_co"
      minLength={0}
      maxLength={64}
    />
  );
}


export function AddressCity () {
  return (
    <FormikFormGroupControl
      label="Stad"
      name="address_city"
      minLength={0}
      maxLength={64}
    />
  );
}


export function BankClearingNumber () {
  return (
    <FormikFormGroupControl
      label="Clearingnummer"
      name="bank_clearing_number"
      minLength={0}
      maxLength={10}
    />
  );
}


export function BankAccountNumber () {
  return (
    <FormikFormGroupControl
      label="Kontonummer"
      name="bank_account_number"
      minLength={0}
      maxLength={20}
    />
  );
}



/**
 * WARNING: This file is used clientside
 */

const getter = require('shared/get');

exports.columnName = function (columnName) {
  switch (columnName) {
    default: return columnName;
    case 'id': return 'ID';
    case 'task': return 'Rutin';
    case 'msg': return 'Parametrar';
    case 'notify': return 'Underrätta';
    case 'retry_remaining': return 'Omtag kvar';
    case 'retry_delay': return 'Omtagsfördröjning';
    case 'prev_work_id': return 'Föregående';
    case 'first_work_id': return 'Första';
    case 'created_at': return 'Skapad';
    case 'created_at_relative': return 'Skapad';
    case 'updated_at': return 'Uppdaterad';
    case 'updated_at_relative': return 'Uppdaterad';
    case 'scheduled_at': return 'Schemalagd';
    case 'scheduled_at_relative': return 'Schemalagd';
    case 'actions': return 'Åtgärder';
    case 'match': return 'Matcha';
  }
};

exports.field = function (work, columnName) {
  const get = (...args) => getter(work, ...args);
  switch (columnName) {
    default: return get(columnName);
  }
};

import React from 'react';
import { Helmet } from 'react-helmet-async';
import Content from 'src/ui/Content';
import Main from 'src/ui/Main';
import Navbar from 'src/navbar/Navbar';
import { CardTitleIcon } from 'src/cards/CardHelpers';
import { RefreshObjectButton } from 'src/buttons/IconButtons';
import Pluralizer from 'src/formatters/Pluralizer';
import * as api from 'src/api';
import * as commonFilterDefs from 'src/tables/commonFilterDefinitions';
import { Activity, Flag } from 'react-feather';
import ExternalLinkAnchor from 'src/anchors/ExternalLinkAnchor';
import { Container, Card } from 'react-bootstrap';
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { MetabaseProperties, MetabaseTimelineWithEvents, MetabaseEvent } from 'shared/types/metabase';
import { IColumnDefinition, IFilterDefinition, TStateFilterMap } from 'src/tables/Table';
import { TSelectedRow, IStateOrder  } from 'src/tables/types';
import * as commonColumnDefs from 'src/tables/commonColumnDefinitions';
import CardBodyTable from 'src/tables/CardBodyTable';
import { ErrorAlertCardBody } from 'src/cards/CardHelpers';
import { metabaseApiUrlToBaseUrl } from 'src/misc';
import { sortBy } from 'lodash';

interface MetabaseTimelineAndEventsPageFilter extends TStateFilterMap {
  archived?: boolean;
}

const defaultFilter = {
  archived: false,
};

const filterDefinitions: IFilterDefinition[] = [
  commonFilterDefs.boolean({
    id: 'archived',
    title: 'Arkiverad',
  }),
];

const timelineColumnDefinitions: IColumnDefinition[] = [
  commonColumnDefs.selectOne(),
  commonColumnDefs.basic({
    id: 'id',
    title: 'Tidslinje',
    Cell: ({row, params}) => (
      <ExternalLinkAnchor href={`${params.metabaseUrl}/collection/${row.collection.id}/timelines`}>
        {row.name || row.id}
      </ExternalLinkAnchor>
    ),
  }),
  commonColumnDefs.basic({
    id: 'collection',
    title: 'Kollektion',
    Cell: ({row, params}) => (
      <ExternalLinkAnchor href={`${params.metabaseUrl}/collection/${row.collection.id}`}>
        {row.collection.name}
      </ExternalLinkAnchor>
    ),
  }),
  commonColumnDefs.basic({
    id: 'description',
    title: 'Beskrivning',
    Cell: ({row}) => <>{row.description ?? '-'}</>,
  }),
  commonColumnDefs.basic({
    id: 'events',
    title: 'Händelser',
    Cell: ({row}) => (
      <Pluralizer
        count={row.events.length}
        zero="Inga händelser"
        one="1 händelse"
        otherwise="%% händelser"
      />
    ),
  }),
];

export default function MetabaseTimelineAndEventsPage () {
  const [filter, setFilter] = React.useState<TStateFilterMap>(defaultFilter);
  const [order, setOrder] = React.useState<IStateOrder>({id: 'asc'});

  const propsQuery = useQuery<MetabaseProperties, Error>({
    queryKey: ['MetabaseProperties'],
    placeholderData: keepPreviousData,
    queryFn: () => api.request({url: '/metabase/properties'}),
  });
  const metabaseUrl = metabaseApiUrlToBaseUrl(propsQuery?.data?.apiUrl ?? '');

  const timelineQuery = useQuery<MetabaseTimelineWithEvents[], Error>({
    queryKey: ['MetabaseTimelineAndEventsPageTimelines', filter, order],
    placeholderData: keepPreviousData,
    queryFn: props => {
      const params = {...(props.queryKey[1] as MetabaseTimelineAndEventsPageFilter)};
      return api.request({
        url:  '/metabase/timeline',
        params: {...params, include: 'events'},
      });
    },
  });

  const [selectedTimelineId, setSelectedTimelineId] = React.useState<TSelectedRow>(null);

  const selectedTimeline = React.useMemo(() => {
    if (!selectedTimelineId || !Array.isArray(timelineQuery.data)) return null;
    const timeline = timelineQuery.data.find(timeline => String(timeline.id) === selectedTimelineId);
    return timeline;
  }, [selectedTimelineId, timelineQuery]);

  return (
    <Main>
      <Navbar />
      <Content>
        <Helmet title="Tidslinjer" />
        <Container fluid className="p-0">
          <Card className="border mb-1">
            <Card.Header>
              <CardTitleIcon
                title="Tidslinjer"
                Icon={<Flag size={16} />}
                spinning={timelineQuery.isLoading || timelineQuery.isRefetching}
              >
                <RefreshObjectButton
                  disabled={timelineQuery.isRefetching}
                  onClick={() => timelineQuery.refetch()}
                />
              </CardTitleIcon>
              <Card.Subtitle className="mt-1">
                <small>
                  <Pluralizer
                    count={timelineQuery.data?.length ?? 0}
                    zero="Inga tidslinjer"
                    one="Visar 1 tidslinje"
                    otherwise="Visar %% tidslinjer"
                  />
                </small>
              </Card.Subtitle>
            </Card.Header>
            <ErrorAlertCardBody error={timelineQuery.error} className="border-top p-3" />
            <CardBodyTable
              filter={filter}
              order={order}
              filterDefinitions={filterDefinitions}
              columnDefinitions={timelineColumnDefinitions}
              setFilter={setFilter}
              setOrder={setOrder}
              rows={timelineQuery?.data || []}
              isFetched={timelineQuery.isFetched}
              params={{metabaseUrl}}
              selectedRow={selectedTimelineId}
              setSelectedRow={setSelectedTimelineId}
            />
          </Card>
          {selectedTimeline && (
            <EventsTableCard
              metabaseUrl={metabaseUrl}
              timeline={selectedTimeline}
            />
          )}
        </Container>
      </Content>
    </Main>
  );
}

const eventColumnDefinitions: IColumnDefinition[] = [
  commonColumnDefs.basic({
    id: 'id',
    title: 'Händelse',
    Cell: ({row, params}) => <>{row.name}</>,
  }),
  commonColumnDefs.datetime({
    id: 'timestamp',
    title: 'Tid',
    cellProps: {format: 'YYYY-MM-DD'},
  }),
  commonColumnDefs.basic({
    id: 'description',
    title: 'Beskrivning',
    Cell: ({row}) => <>{row.description ?? '-'}</>,
  }),
  commonColumnDefs.basic({
    id: 'edit',
    title: 'Redigera',
    Cell: ({row, params}) => {
      const event = row as MetabaseEvent;
      const { timeline_id, id } = event;
      const { timelineUrl } = params;
      const editUrl = `${timelineUrl}/${timeline_id}/events/${id}/edit`;
      return (
        <ExternalLinkAnchor href={editUrl}>
          Redigera
        </ExternalLinkAnchor>
      );
    },
  }),
];

interface IEventsTableCard {
  metabaseUrl: string;
  timeline: MetabaseTimelineWithEvents;
}

function EventsTableCard (props: IEventsTableCard) {
  const { metabaseUrl, timeline } = props;
  const { events } = timeline;
  const timelineUrl = `${metabaseUrl}/collection/${timeline.collection_id}/timelines`;
  const sortedEvents = sortBy(events, 'timestamp').reverse();
  return (
    <Card className="border mt-4">
      <Card.Header>
        <CardTitleIcon
          title={
            <>
              Händelser under{' '}
              <ExternalLinkAnchor href={timelineUrl}>
                {timeline.name}
              </ExternalLinkAnchor>
            </>
          }
          Icon={<Activity size={16} />}
          spinning={false}
        >
        </CardTitleIcon>
        <Card.Subtitle className="mt-1">
          <small>
            <Pluralizer
              count={events.length}
              zero="Inga händelser"
              one="Visar 1 händelse"
              otherwise="Visar %% händelser"
            />
          </small>
        </Card.Subtitle>
      </Card.Header>
      <CardBodyTable
        columnDefinitions={eventColumnDefinitions}
        rows={sortedEvents}
        params={{metabaseUrl, timelineUrl}}
        isFetched={true}
      />
    </Card>
  );
}

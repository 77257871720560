/**
 * WARNING: This file is used clientside
 */

const getter = require('shared/get');
const date = require('shared/filter/date');

/**
 * Filter User Field
 * @param {String} fieldName
 * @param {Object} user
 * @return {String}
 */
exports.field = function (fieldName, user, extraData) {
  switch (fieldName) {
    default: return user[fieldName] || '';
    case 'id': return user.id;
    case 'name': return user.name || '(Inget namn)';
    case 'password': return '';
    case 'photo': return user[fieldName] || '(Ingen bild)';
    case 'bank': return user.bank_id || '';
    case 'status': return user.is_active ? 'Aktiv' : 'Inaktiv';
    case 'ip_last_login': return user.ip_last_login || '';
    case 'ip_whitelist': return user.ip_whitelist || [];
    case 'role': return exports.role(user.role) || '(Inget namn)';
    case 'statistics': return [
      // extraData should be userStatistics
      getter(extraData, [user.id, 'applications_count'], 0),
      getter(extraData, [user.id, 'customers_count'], 0),
      getter(extraData, [user.id, 'tasks_count'], 0),
    ].join('/');
  }
};

exports.role = function (role) {
  switch (role) {
    default: return role;
    case 'salesman': return 'Handläggare';
    case 'advisor': return 'Rådgivare';
    case 'bank': return 'Långivare';
    case 'administrator': return 'Administratör';
  }
};

exports.loginMethod = function (loginMethod) {
  switch (loginMethod) {
    default: return loginMethod;
    case 'password': return 'Lösenord';
    case 'bankid': return 'BankID';
    case 'password_app_totp': return 'Lösenord med TOTP via app';
    case 'password_email_totp': return 'Lösenord med TOTP via e-post';
  }
};

/**
 * Filter Customer Index Columns
 * @param {String} columnName
 * @return {String}
 */
exports.indexColumnName = function (columnName) {
  switch (columnName) {
    default: return columnName;
    case 'select': return 'Välj';
    case 'id': return 'ID/Inloggning';
    case 'photo': return 'Bild';
    case 'functions': return 'Funktioner';
    case 'name': return 'Namn';
    case 'first_name': return 'Förnamn';
    case 'last_name': return 'Efternamn';
    case 'email': return 'E-post';
    case 'telephone_number': return 'Telefon';
    case 'role': return 'Roll';
    case 'group_id': return 'Gruppmedlemskap';
    case 'bank_id': return 'Tillhörande långivare';
    case 'last_login_at': return 'Senaste inloggning';
    case 'last_login_at_relative': return 'Senaste inloggning';
    case 'ip_last_login': return 'Senaste IP';
    case 'ip_whitelist': return 'Tillåtna IP-adresser';
    case 'is_active': return 'Aktiv';
    case 'status': return 'Status';
    case 'login_method_bankid_personal_number': return 'Personnummer för BankID';
    case 'last_password_change_at': return 'Senaste lösenordsbyte';
    case 'password_strength': return 'Lösenordsstyrka';
    case 'login_method': return 'Inloggningsmetod';
    case 'actions': return 'Åtgärder';
    case 'created_at': return 'Skapad';
    case 'created_at_relative': return 'Skapad';
    case 'updated_at': return 'Uppdaterad';
    case 'updated_at_relative': return 'Uppdaterad';
  }
};

exports.status = function (isActive) {
  return isActive ? 'Aktiv' : 'Inaktiv';
};

exports.statusValue = function (isActive) {
  return isActive ? 'active' : 'inactive';
};

/**
 * Filter User Index Field
 * @param {Object} customer
 * @param {String} columnName
 * @return {String}
 */
exports.indexField = function (user, columnName, options = {}) {
  const { forExport = false } = options;
  const get = (...args) => getter(user, ...args);
  switch (columnName) {
    default: return get(columnName);
    case 'news_updated_at_read':
    case 'created_at':
    case 'updated_at':
    case 'last_login_at':
    case 'login_method': return exports.loginMethod(user[columnName]);
    case 'last_password_change_at':
      return forExport ? date.dayTime(get(columnName)) : get(columnName);
    case 'role': return exports.role(get('role'));
    case 'is_active': return exports.status(get('is_active'));
    case 'status': return exports.statusValue(get('is_active'));
    case 'bank_id': return get('Bank.name') || get('bank_id') || '';
    case 'ip_whitelist': {
      const list = get('ip_whitelist');
      return Array.isArray(list) ? list.join(',') : '';
    }
    case 'group_id': {
      const list = get('Groups');
      if (!Array.isArray(list)) return '-';
      return list.map(g => g.name || g.id).join(', ');
    }
    case 'functions': {
      const fns = get('functions');
      return Array.isArray(fns) ? fns.map(exports.function).join(', ') : '-';
    }
  }
};

exports.function = function (fn) {
  switch (fn) {
    default: return fn;
    case 'centralt_funktionsansvarig': return 'Centralt Funktionsansvarig';
    case 'klagomalsansvarig': return 'Klagomålsansvarig';
    case 'errors': return 'Felande objekt';
    case 'schemabokning': return 'Schemabokning';
    case 'app': return 'Åtkomst till app (nya CRM)';
  }
};

exports.passwordStrength = function (strength) {
  switch (String(strength)) {
    default: return '?';
    case '0': return 'Usel';
    case '1': return 'Dålig';
    case '2': return 'Sådär';
    case '3': return 'Bra';
    case '4': return 'Utmärkt';
  }
};

import React from 'react';

interface CustomerConsentModalBodyProps {
  id: string;
}

export default function CustomerConsentModalBody (props: CustomerConsentModalBodyProps) {
  return (
    <div>consent modal body here</div>
  );
}

import React from 'react';
import Anchor from  'src/anchors/Anchor';
import { Name } from 'src/letterTemplate/LetterTemplateFormatters';
import { LetterTemplateRow } from 'shared/types/letter_template';

interface LetterTemplateAnchorProps {
  letterTemplate: LetterTemplateRow;
}

export default function LetterTemplateAnchor (props: LetterTemplateAnchorProps) {
  const { letterTemplate, ...restOfProps } = props;
  return (
    <Anchor
      {...restOfProps}
      url={`/letter_template/${letterTemplate.id}`}
      name="Brevmallen"
    >
      <Name value={letterTemplate} />
    </Anchor>
  );
}



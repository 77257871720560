import useAuth from 'src/hooks/useAuth';
import constants from 'shared/constants';

const DEFAULT_TIMEZONE = constants.timezone;

export default function useTimezone () {
  const auth = useAuth();
  const timezone = auth.user?.settings?.displayTimezone;
  return timezone || DEFAULT_TIMEZONE;
}

import React from 'react';
import BlockSpinner from 'src/spinners/BlockSpinner';
import { StatusLabel } from  'src/bankProcess/BankProcessFormatters';
import StatusLabelContainer from 'src/components/StatusLabelContainer';
import ErrorAlert from 'src/alerts/ErrorAlert';
import { BankProcessRow } from 'shared/types/bank_process';
import WidgetList from 'src/widgets/WidgetList';
import { allWidgets, widgetGroups } from 'src/bankProcess/BankProcessWidgets';
import useWidgetList from 'src/hooks/useWidgetList';
import { useQuery } from '@tanstack/react-query';

interface BankProcessQuicklookProps {
  id: string;
}

const BankProcessQuicklook: React.FC<BankProcessQuicklookProps> = React.memo(function BankProcessQuicklook (props: BankProcessQuicklookProps) {
  const { id } = props;

  const query = useQuery<BankProcessRow, Error>({
    queryKey: [`/bank_process/${id}`],
  });

  const [state, dispatch] = useWidgetList('bankProcessQuicklook', [
    'notes',
    'log',
  ]);

  const bankProcess = query?.data;

  return (
    <div className="position-relative">
      <ErrorAlert error={query.error} className="mx-3" />
      <BlockSpinner className="mx-3" center isLoading={query.isLoading} />
      {bankProcess && (
        <>
          <StatusLabelContainer>
            <StatusLabel value={bankProcess.status} />
          </StatusLabelContainer>
          <WidgetList
            allWidgets={allWidgets}
            widgetGroups={widgetGroups}
            state={state}
            dispatch={dispatch}
            widgetProps={{bankProcess}}
          />
        </>
      )}
    </div>
  );
});
export default BankProcessQuicklook;

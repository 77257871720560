

/**
 * WARNING: This file is used clientside
 */

const applicationFilter = require('shared/filter/application');
const format = require('shared/format');
const date = require('shared/filter/date');
const misc = require('shared/filter/misc');
const getter = require('shared/get');

exports.fieldName = function (fieldName) {
  return applicationFilter.indexColumnName(fieldName);
};

/**
 * Filter BankProcess Status
 * @param {String} status
 * @return {String}
 */
exports.status = function (status) {
  switch (status) {
    default: return status;
    case 'new': return 'Nyskapad';
    case 'open': return 'Hos långivare';
    case 'closed': return 'Stängd';
    case 'waiting': return 'Bud finns';
    case 'error': return 'Fel';
    case 'accepted': return 'Accepterad';
    case 'disbursed': return 'Utbetald';
  }
};

exports.subStatus = function (subStatus) {
  switch (subStatus) {
    default: return subStatus;
    case 'pending': return 'Pröv';
    case 'change': return 'Förändring begärd';
    case 'change-reject': return 'Förändring begärd';
    case 'change-submit': return 'Förändring begärd';
    case 'pending,change': return 'Pröv eller Förändring';
    case 'offer': return 'Erbjudande finns';
    case 'complement': return 'Komplettering begärd';
    case 'rejected': return 'Erbjudande nekat';
    case 'denied': return 'Avslag';
    case 'credit_processing': return 'Ansökan behandlas';
    case 'accept_processing': return 'Accept behandlas';
    case 'accepted':
    case 'approve':
    case 'accept': return 'Accepterat';
  }
};

/**
 * Filter BankProcess closed_reason
 * @param {String} status
 * @return {String}
 */
exports.closedReason = function (closedReason) {
  switch (closedReason) {
    default: return closedReason;
    case null: return 'Ingen/annan orsak';
    case 'user_rejected': return 'Stängd av användare/kund';
    case 'bank_rejected': return 'Avslag från långivare';
    case 'bank_rejected_post_accept': return 'Avslag från långivare efter accept';
    case 'expired': return 'Utgången';
  }
};

/**
 * Filter BankProcess Index Columns
 * @param {String} columnName
 * @return {String}
 */
exports.indexColumnName = function (columnName) {
  switch (columnName) {
    default: return columnName;
    case 'select': return 'Markera';
    case 'actions': return 'Åtgärder';
    case 'bank_adapter': return 'Adapter';
    case 'bank_id': return 'Långivare';
    case 'bank_user_id': return 'Långivare';
    case 'date': return 'Datum';
    case 'month': return 'Månad';
    case 'updated_at': return 'Uppdaterad';
    case 'updated_at_relative': return 'Uppdaterad';
    case 'accepted_at': return 'Accepterad';
    case 'accepted_at_relative': return 'Accepterad';
    case 'closed_at': return 'Stängd';
    case 'closed_at_relative': return 'Stängd';
    case 'closed_reason': return 'Stängningsorsak';
    case 'created_at': return 'Skapad';
    case 'created_at_relative': return 'Skapad';
    case 'customer_id': return 'Kund';
    case 'disbursed': return 'Summa';
    case 'portal_is_from': return 'Portalansökan';
    case 'id': return 'ID';
    case 'ssn': return 'Huvudsökande (PNR)';
    case 'ssn_co': return 'Medsökande (PNR)';
    case 'status': return 'Status';
    case 'sub_status': return 'Sub-Status';
    case 'sub_bank': return 'Sub-långivare';
    case 'application_id': return 'Ansökan';
    case 'their_id': return 'Långivarens referens';
    case 'our_id': return 'Likvidums referens';
    case 'flag_status_ever_waiting': return 'Budad';
    case 'flag_status_ever_accepted': return 'Skuldebrev';
    case 'customer_co_excluded': return 'Medsökande exkluderad';
  }
};

exports.indexField = function (process, fieldName, options = {}) {
  const { forExport = false } = options;
  const get = (...args) => getter(process, ...args);

  switch (fieldName) {
    default: return get(fieldName);
    case 'closed_at':
    case 'updated_at':
    case 'created_at': return forExport ? date.dayTime(get(fieldName)) : get(fieldName);
    case 'their_id':
      return get('their_id');
    case 'our_id':
      return get('our_id');
    case 'closed_reason':
      return exports.closedReason(get('closed_reason'));
    case 'disbursed':
      return misc.appendWhenNotEmpty(get('disbursed'), ' kr') || '';
    case 'ssn':
      return format.ssn('long', get('ssn'));
    case 'ssn_co': {
      const hasCo = get('form.include_co_applicant');
      return hasCo ? format.ssn('long', get('form.co_applicant.ssn')) : '';
    }
    case 'status':
      return exports.status(get('status')) || '';
    case 'sub_status':
      return exports.subStatus(get('sub_status')) || '';
    case 'sub_bank':
      return get('data.sub_bank') || '';
    case 'bank_id':
      return get('Bank.name') || get('bank_id') || '-';

    case 'customer_co_excluded':
    case 'flag_status_ever_accepted':
    case 'flag_status_ever_waiting':
    case 'portal_is_from':
      return misc.yesno(get(fieldName));
  }
};

import React from 'react';
import customerFilter from 'shared/filter/customer';
import * as TableCells from 'src/tables/TableCells';
import * as commonFilterDefs from 'src/tables/commonFilterDefinitions';
import * as commonColumnDefs from 'src/tables/commonColumnDefinitions';
import * as TableCellActions from 'src/tables/TableCellActions';
import { IColumnDefinition, IFilterDefinition } from 'src/tables/Table';
import TablePage from 'src/tables/TablePage';

export default function CustomersTablePage () {
  return (
    <TablePage
      title="Kunder"
      pageTitle="Kundtabell"
      columnDefinitions={columnDefinitions}
      filterDefinitions={filterDefinitions}
      routeUrl="/customers"
      baseQueryUrl="/customer/table"
      baseQueryKey="CustomersTable"
      queryResultRowsKey="customers"
    />
  );
}

const filterDefinitions: IFilterDefinition[] = [
  commonFilterDefs.customerStatus({id: 'status'}),
  commonFilterDefs.uuid({id: 'id'}),
  commonFilterDefs.ssn({id: 'ssn'}),
  commonFilterDefs.string({
    id: 'name',
  }),
  commonFilterDefs.string({
    id: 'email',
  }),
  commonFilterDefs.string({
    id: 'telephone_mobile',
  }),
  commonFilterDefs.string({
    id: 'cloudinsurance_customer_id',
    permission: 'customer_read_insurance',
  }),
  commonFilterDefs.dateRange({id: 'created_at'}),
  commonFilterDefs.dateRelative({id: 'created_at_relative'}),
  commonFilterDefs.dateRange({
    id: 'contact_opt_in_at',
    permission: 'customer_search:portal',
  }),
  commonFilterDefs.dateRelative({
    id: 'contact_opt_in_at_relative',
    permission: 'customer_search:portal',
  }),
  commonFilterDefs.dateRange({
    id: 'last_application_created_at',
  }),
  commonFilterDefs.dateRelative({
    id: 'last_application_created_at_relative',
  }),
  commonFilterDefs.dateRange({
    id: 'last_uc_debt_at',
  }),
  commonFilterDefs.dateRelative({
    id: 'last_uc_debt_at_relative',
  }),
  commonFilterDefs.dateRange({
    id: 'last_application_disbursed_closed_at',
  }),
  commonFilterDefs.dateRelative({
    id: 'last_application_disbursed_closed_at_relative',
  }),
  commonFilterDefs.intOp({
    id: 'age',
    filterProps: {fieldOps: ['lt', 'gt']},
  }),
  commonFilterDefs.boolean({
    permission: {function: 'centralt_funktionsansvarig'},
    id: 'suspected_terrorist',
  }),
  commonFilterDefs.boolean({
    permission: 'customer_search:portal',
    id: 'portal_is_from',
  }),
  commonFilterDefs.boolean({
    permission: 'customer_search:portal',
    id: 'portal_allow_login',
  }),
  commonFilterDefs.boolean({
    permission: 'customer_search:portal',
    id: 'contact_opt_in',
  }),
  commonFilterDefs.boolean({
    id: 'last_uc_debt',
  }),
  commonFilterDefs.boolean({
    id: 'last_application_disbursed_closed',
  }),
  commonFilterDefs.userId({
    id: 'user_id',
    permission: 'customer:read_others',
  }),
  commonFilterDefs.bankId({
    id: 'last_application_disbursed_bank_id',
  }),
  commonFilterDefs.booleanNull({
    id: 'has_cloudinsurance_customer_id',
    permission: 'customer_read_insurance',
  }),
  commonFilterDefs.booleanNull({
    id: 'cloudinsurance_created_by_portal',
    permission: 'customer_read_insurance',
  }),
  commonFilterDefs.language({
    id: 'preferred_language',
  }),
  commonFilterDefs.boolean({
    id: 'last_application_created',
  }),
].map(filterDefinition => ({
  title: customerFilter.indexColumnName(filterDefinition.id),
  ...filterDefinition,
}));

const columnDefinitions: IColumnDefinition[] = [
  commonColumnDefs.select(),
  ...([
    commonColumnDefs.customerId({id: 'id', cellProps: {rowAsObject: true}}),
    commonColumnDefs.basic({id: 'ssn', sortable: true}),
    commonColumnDefs.basic({id: 'name'}),
    commonColumnDefs.basic({id: 'first_name'}),
    commonColumnDefs.basic({id: 'last_name'}),
    commonColumnDefs.basic({id: 'telephone_mobile'}),
    commonColumnDefs.basic({id: 'email'}),
    commonColumnDefs.basic({id: 'address_street'}),
    commonColumnDefs.basic({id: 'address_postcode'}),
    commonColumnDefs.basic({id: 'address_city'}),
    commonColumnDefs.basic({id: 'address_co'}),
    commonColumnDefs.datetime({
      id: 'contact_opt_in_at',
      sortable: true,
      groups: ['dates', 'sortable', 'portal'],
    }),
    commonColumnDefs.datetime({
      id: 'created_at',
      sortable: true,
      groups: ['dates', 'sortable'],
    }),
    commonColumnDefs.datetime({
      id: 'last_application_disbursed_closed_at',
      sortable: true,
      groups: ['dates', 'sortable', 'last_disbursed_application'],
    }),
    commonColumnDefs.datetime({
      id: 'portal_last_login_at',
      sortable: true,
      groups: ['dates', 'sortable', 'portal'],
      permission: 'application_search:portal',
    }),
    commonColumnDefs.datetime({
      id: 'status_updated_at',
      sortable: true,
      groups: ['dates', 'sortable'],
    }),
    commonColumnDefs.datetime({
      id: 'suspected_at',
      sortable: true,
      groups: ['dates', 'sortable'],
      permission: {function: 'centralt_funktionsansvarig'},
    }),
    commonColumnDefs.datetime({
      id: 'updated_at',
      sortable: true,
      groups: ['dates', 'sortable'],
    }),

    commonColumnDefs.boolean({
      id: 'portal_is_from',
      permission: 'application_search:portal',
      groups: ['portal'],
    }),

    commonColumnDefs.boolean({
      id: 'portal_allow_login',
      permission: 'application_search:portal',
      groups: ['portal'],
    }),

    commonColumnDefs.datetime({
      id: 'last_uc_debt_at',
      sortable: true,
      groups: ['dates', 'sortable', 'uc'],
    }),

    commonColumnDefs.basic({
      id: 'last_uc_risk',
      groups: ['uc'],
    }),

    commonColumnDefs.datetime({
      id: 'cloudinsurance_synced_at',
      sortable: true,
      groups: ['dates', 'sortable', 'cloudinsurance'],
      permission: 'customer_read_insurance',
    }),

    commonColumnDefs.basic({
      id: 'cloudinsurance_customer_id',
      groups: ['cloudinsurance'],
      permission: 'customer_read_insurance',
    }),

    commonColumnDefs.boolean({
      id: 'cloudinsurance_created_by_portal',
      permission: 'customer_read_insurance',
      cellProps: {strict: true},
      groups: ['portal'],
    }),

    commonColumnDefs.bankId({
      id: 'last_application_disbursed_bank_id',
      cellProps: {idKey: 'last_application_disbursed_bank_id'},
      groups: ['last_disbursed_application'],
    }),
    commonColumnDefs.sumCurrency({
      id: 'last_disbursed_offer_disbursed_amount',
      rowKey: 'last_disbursed_offer.disbursed_amount',
      groups: ['last_disbursed_application'],
    }),
    commonColumnDefs.sumCurrency({
      id: 'last_disbursed_offer_applied_amount',
      rowKey: 'last_disbursed_offer.applied_amount',
      groups: ['last_disbursed_application'],
    }),
    commonColumnDefs.basic({
      id: 'last_disbursed_offer_repayment_years',
      groups: ['last_disbursed_application'],
    }),
    commonColumnDefs.basic({
      id: 'last_disbursed_offer_interest_rate_effective',
      groups: ['last_disbursed_application'],
    }),

    commonColumnDefs.applicationId({
      id: 'last_application_id',
      cellProps: {objKey: 'LastApplication', idKey: 'last_application_id'},
      groups: ['last_application'],
    }),

    // these are concrete Customer columns related to the last application
    commonColumnDefs.datetime({
      id: 'last_application_created_at',
      sortable: true,
      groups: ['dates', 'sortable', 'last_application'],
    }),

    // these are included (last) Application columns i.e. from another table
    commonColumnDefs.basic({
      id: 'LastApplication.status',
      Cell: TableCells.ApplicationStatusTableCell,
      cellProps: {
        rowKey: 'LastApplication.status',
        rowKeyAcceptedProcessId: 'LastApplication.accepted_process_id',
      },
      groups: ['last_application'],
    }),

    commonColumnDefs.basic({
      id: 'LastApplication.form.main_applicant.status',
      groups: ['last_application'],
    }),

    commonColumnDefs.basic({
      id: 'LastApplication.form.loan.amount.now',
      groups: ['last_application'],
    }),

    commonColumnDefs.basic({
      id: 'LastApplication.form.loan.amount.new',
      groups: ['last_application'],
    }),

    commonColumnDefs.basic({
      id: 'LastApplication.form.loan.repayment_years',
      groups: ['last_application'],
    }),

    commonColumnDefs.basic({
      id: 'LastApplication.form.loan.purpose',
      groups: ['last_application'],
    }),

    commonColumnDefs.basic({
      id: 'LastApplication.form.main_applicant.employment_type',
      groups: ['last_application'],
    }),

    commonColumnDefs.basic({
      id: 'LastApplication.form.main_applicant.address.type',
      groups: ['last_application'],
    }),

    commonColumnDefs.basic({
      id: 'LastApplication.form.main_applicant.income',
      groups: ['last_application'],
    }),

    commonColumnDefs.basic({
      id: 'LastApplication.form.main_applicant.children',
      groups: ['last_application'],
    }),

    commonColumnDefs.basic({
      id: 'risk',
    }),

    commonColumnDefs.basic({
      id: 'preferred_language',
    }),

    commonColumnDefs.userId({id: 'user_id'}),

    commonColumnDefs.basic({
      id: 'status',
      Cell: TableCells.CustomerStatusTableCell,
      groups: ['status'],
    }),
  ].map(columnDefinition => {
    const { id } = columnDefinition;
    const header = customerFilter.indexColumnName(id);
    const cellValue = customer => customerFilter.indexField(customer, id);
    return {
      Cell: ({value}) => <>{value}</>,
      ...columnDefinition,
      cellValue,
      Header: ({value}) => <>{value}</>,
      title: header,
    };
  })),
  commonColumnDefs.actions({}, [
    TableCellActions.InspectRowTableCellAction,
  ]),
];

import React from 'react';
import isObjectLike from 'lodash/isObjectLike';
import StatusBaseLabel, {StatusBaseLabelProps} from 'src/formatters/StatusBaseLabel';
import { BankRow } from 'shared/types/bank';
import * as bankFilter from 'shared/filter/bank';

interface NameProps {
  value: Pick<BankRow, 'id' | 'name'>;
}

export function Name (props: NameProps) {
  const { value:bank } = props;
  if (!isObjectLike(bank)) return null;
  const { id = '', name = '' } = bank;
  return (
    <>
      {name || id}
    </>
  );
}

interface StatusLabelProps extends StatusBaseLabelProps {
  value: BankRow['is_active'];
}

export function StatusLabel (props: StatusLabelProps) {
  const { value, ...restOfProps } = props;
  return (
    <StatusBaseLabel
      value={value}
      formatter={bankFilter.status}
      {...restOfProps}
    />
  );
}

import React from 'react';
import { useMutation } from '@tanstack/react-query';
import ButtonSpinner, { ButtonSpinnerProps } from 'src/spinners/ButtonSpinner';
import { ExternalLink } from 'react-feather';
import useAuth from 'src/hooks/useAuth';
import useNotyf from 'src/hooks/useNotyf';
import { errorToMessage } from 'src/utils/error';

interface PassportButtonProps extends ButtonSpinnerProps {

}

export default function PassportButton (props: PassportButtonProps) {
  const { ...restOfProps } = props;

  const auth = useAuth();
  const notyf = useNotyf();

  const createTokenMutation = useMutation({
    mutationFn: async () => await auth.passport('crm'),
    onError: err => {
      notyf.error(errorToMessage(err));
    },
  });

  return (
    <ButtonSpinner
      {...restOfProps}
      onClick={() => createTokenMutation.mutate()}
      isLoading={createTokenMutation.isPending}
      disabled={createTokenMutation.isPending}
    >
      <ExternalLink size={18} className="me-1" />
      Gamla CRM
    </ButtonSpinner>
  );
}

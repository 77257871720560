import React from 'react';
import ucFilter from 'shared/filter/uc';
import * as TableCells from 'src/tables/TableCells';
import * as commonFilterDefs from 'src/tables/commonFilterDefinitions';
import * as commonColumnDefs from 'src/tables/commonColumnDefinitions';
import * as TableCellActions from 'src/tables/TableCellActions';
import { IColumnDefinition, IFilterDefinition } from 'src/tables/Table';
import TablePage from 'src/tables/TablePage';

export default function UcTablePage () {
  return (
    <TablePage
      title="Kreditupplysningar"
      pageTitle="Kreditupplysningstabell"
      columnDefinitions={columnDefinitions}
      filterDefinitions={filterDefinitions}
      routeUrl="/uc"
      baseQueryUrl="/uc/table"
      baseQueryKey="UcTable"
      queryResultRowsKey="uc"
    />
  );
}

const filterDefinitions: IFilterDefinition[] = [
  commonFilterDefs.uuid({id: 'id'}),
  commonFilterDefs.dateRange({id: 'created_at'}),
  commonFilterDefs.dateRelative({id: 'created_at_relative'}),
  commonFilterDefs.dateRange({id: 'response_at'}),
  commonFilterDefs.dateRelative({id: 'response_at_relative'}),
  commonFilterDefs.ssn({id: 'ssn_main'}),
  commonFilterDefs.ssn({id: 'ssn_co'}),
  commonFilterDefs.userId({id: 'created_by_user_id'}),
  commonFilterDefs.userId({id: 'submitted_by_user_id'}),
  commonFilterDefs.boolean({id: 'response'}),
  commonFilterDefs.ucResponseCode({id: 'response_codes'}),
].map(filterDefinition => ({
  title: ucFilter.indexColumnName(filterDefinition.id),
  ...filterDefinition,
}));

const columnDefinitions: IColumnDefinition[] = [
  commonColumnDefs.select(),
  ...([
    commonColumnDefs.ucId({id: 'id', cellProps: {rowAsObject: true}}),
    commonColumnDefs.customerId({
      id: 'customer_id',
      cellProps: {objKey: 'Customer', idKey: 'customer_id'},
    }),
    commonColumnDefs.applicationId({
      id: 'application_id',
      cellProps: {objKey: 'Application', idKey: 'application_id'},
    }),
    commonColumnDefs.basic({id: 'ssn_main'}),
    commonColumnDefs.basic({id: 'ssn_co'}),
    commonColumnDefs.userId({
      id: 'created_by_user_id',
      cellProps: {objKey: 'Creator', idKey: 'created_by_user_id'},
    }),
    commonColumnDefs.userId({
      id: 'submitted_by_user_id',
      cellProps: {objKey: 'Submitter', idKey: 'submitted_by_user_id'},
    }),
    commonColumnDefs.datetime({
      id: 'created_at',
      sortable: true,
      groups: ['dates', 'sortable'],
    }),
    commonColumnDefs.datetime({
      id: 'updated_at',
      sortable: true,
      groups: ['dates', 'sortable'],
    }),
    commonColumnDefs.datetime({
      id: 'response_at',
      sortable: true,
      groups: ['dates', 'sortable'],
    }),
    commonColumnDefs.basic({id: 'risk_main'}),
    commonColumnDefs.basic({id: 'risk_co'}),
    commonColumnDefs.datetime({id: 'last_debt_at_main', groups: ['dates']}),
    commonColumnDefs.datetime({id: 'last_debt_at_co', groups: ['dates']}),
    commonColumnDefs.basic({id: 'count_uc_last_12_months_main'}),
    commonColumnDefs.basic({id: 'count_uc_last_12_months_co'}),
    commonColumnDefs.boolean({
      id: 'is_approved_main',
      cellProps: {strict: true},
      cellOriginal: uc => uc?.response_main?.is_approved,
    }),
    commonColumnDefs.boolean({
      id: 'is_approved_co',
      cellProps: {strict: true},
      cellOriginal: uc => uc?.response_co?.is_approved,
    }),
    commonColumnDefs.basic({
      id: 'status',
      Cell: TableCells.UcStatusTableCell,
    }),
    commonColumnDefs.basic({id: 'response_codes'}),
  ].map(columnDefinition => {
    const { id } = columnDefinition;
    const header = ucFilter.indexColumnName(id);
    const cellValue = prospect => ucFilter.indexField(prospect, id);
    return {
      Cell: ({value}) => <>{value}</>,
      ...columnDefinition,
      cellValue,
      Header: ({value}) => <>{value}</>,
      title: header,
    };
  })),
  commonColumnDefs.actions({}, [
    TableCellActions.InspectRowTableCellAction,
  ]),
];

import React from 'react';
import QuicklookAnchor from 'src/anchors/QuicklookAnchor';
import ProspectQuicklook from 'src/prospect/ProspectQuicklook';
import { Name } from 'src/prospect/ProspectFormatters';

export default function ProspectAnchor (props) {
  const { prospect, ...restOfProps } = props;
  return (
    <QuicklookAnchor
      {...restOfProps}
      url={`/prospect/${prospect.id}/overview`}
      title={<Name value={prospect} />}
      name="Prospekt"
      objectId={prospect.id}
    >
      <ProspectQuicklook id={prospect.id} />
    </QuicklookAnchor>
  );
}


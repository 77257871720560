

/**
 * WARNING: This file is used clientside
 */
exports.application = {};

/**
 * @returns {Array}
 */
exports.application.offer_verifications = function (verifications) {
  if (!verifications) return [];
  if (typeof verifications === 'string') {
    verifications = [verifications];
  }
  return verifications.map(verification => {
    switch (verification) {
      default: return verification;

        // brocc
      case 'require_one_payslip': return 'Kopia på senaste lönespecifikationen';
      case 'require_three_payslips': return 'Kopia på de 3 senaste lönespecifikationerna';
      case 'require_employment_agreement': return 'Kopia på ett anställningsavtal';
      case 'require_company_tax_return': return 'Kopia på senaste självdeklarationen';
      case 'require_certificate_of_employment': return 'Kopia på ett arbetsgivarintyg';
      case 'require_existing_loan_redemption': return 'Uppgifter om befintliga lån som skall lösas' ;
      case 'require.data.bank_transaction_history': return 'Digitalt kontoutdrag';

        // collector (some shared with other banks)
      case 'incomedeclaration': return 'Inkomstdeklaration';
      case 'identification': return 'ID';
      case 'accountstatement': return 'Kontoutdrag';
      case 'other': return 'Övrigt';

        // lendify and collector (overlapping keys)
      case 'payslip': return 'Lönespecifikation';

        // lendify
      case 'employment_contract': return 'Anställningsavtal';
      case 'balance_sheet_and_income_statement': return 'Resultat- och balansräkning';
      case 'employers_certificate': return 'Arbetsgivarintyg';
      case 'pension': return 'Pension';
      case 'sickness_benefit': return 'Sjukpension';
      case 'account_history': return 'Kontoutdrag';
      case 'mandatory_consolidation': return 'Lösenkrav';

        // nystartfinans and sevenday (rest) (overlapping keys)
      case 'co_id_copy': return 'Legitimation (medsökande)';
      case 'main_id_copy': return 'Legitimation (huvudsökande)';
      case 'co_payslips': return 'Lönespecifikation (medsökande)';
      case 'main_payslips': return 'Lönespecifikation (huvudsökande)';
      case 'co_income_proof': return 'Inkomstbevis (medsökande)';
      case 'main_income_proof': return 'Inkomstbevis (huvudsökande)';
      case 'co_pension_detail': return 'Pensionsspecifikation (medsökande)';
      case 'main_pension_detail': return 'Pensionsspecifikation (huvudsökande)';
      case 'co_bank_statement': return 'Kontoutdrag (medsökande)';
      case 'main_bank_statement': return 'Kontoutdrag (huvudsökande)';
      case 'co_company_balance': return 'Balans- och resultaträkning (medsökande)';
      case 'main_company_balance': return 'Balans- och resultaträkning (huvudsökande)';

        // ikano
      case 'ikano_bank_scraping': return 'Digitalt kontoutdrag';
      case 'ikano_main_bank_scraping': return 'Digitalt kontoutdrag (huvudsökande)';
      case 'ikano_co_bank_scraping': return 'Digitalt kontoutdrag (medsökande)';
      case 'ikano_id_copy': return 'Legitimation';
      case 'ikano_co_id_copy': return 'Legitimation (medsökande)';
      case 'ikano_main_id_copy': return 'Legitimation (huvudsökande)';

        // norwegian
      case 'norwegian_main_TaxForm': return 'Inkomstdeklaration (huvudsökande)';
      case 'norwegian_co_TaxForm': return 'Inkomstdeklaration (medsökande)';
      case 'norwegian_main_SalarySpecification': return 'Lönespecifikation (huvudsökande)';
      case 'norwegian_co_SalarySpecification': return 'Lönespecifikation (medsökande)';
      case 'norwegian_main_SignLoanPapers': return 'Signerat skuldebrev (huvudsökande)';
      case 'norwegian_co_SignLoanPapers': return 'Signerat skuldebrev (medsökande)';
      case 'norwegian_main_RefinancingDetails': return 'Lista över lån att lösa (huvudsökande)';
      case 'norwegian_co_RefinancingDetails': return 'Lista över lån att lösa (medsökande)';
      case 'norwegian_main_PayoutAccountNumber': return 'Kontonummer för utbetalning (huvudsökande)';
      case 'norwegian_co_PayoutAccountNumber': return 'Kontonummer för utbetalning (medsökande)';
      case 'norwegian_main_Kyc': return 'KYC (huvudsökande)';
      case 'norwegian_co_Kyc': return 'KYC (medsökande)';

        // resurs
      case 'ID': return 'Legitimation';
      case 'PAYMENT_SLIP': return 'Inkomstunderlag för anställd';
      case 'RETIREMENT_INFO': return 'Pensionsbesked';
      case 'FARMER_INFO': return 'Lantbrukarinfo';
      case 'SELF_EMPLOYED_INFO': return 'Inkomstunderlag för företagare';
      case 'TAX_AUTHORITY_INFO': return 'Skattedeklaration';
      case 'INSURANCE_CERTIFICATE': return 'Utbetalningsunderlag från Försäkringskassan';
      case 'AGREEMENT_COPY': return 'Avtalskopia';
      case 'CONSOLIDATION_INVOICE': return 'Fullmakt för lösen av lån';
      case 'CONSOLIDATION_AVI': return 'Fullmakt för lösen av lån Avi';
      case 'OTHER': return 'Annat';
      case 'CO_APPLICANT_ID': return 'Medsökande: legitimation';
      case 'CO_APPLICANT_PAYMENT_SLIP': return 'Medsökande: Inkomstunderlag för anställd';
      case 'CO_APPLICANT_RETIREMENT_INFO': return 'Medsökande: Pensionsbesked';
      case 'CO_APPLICANT_FARMER_INFO': return 'Medsökande: Lantbrukarinfo';
      case 'CO_APPLICANT_SELF_EMPLOYED_INFO': return 'Medsökande: Inkomstunderlag för företagare';
      case 'CO_APPLICANT_TAX_AUTHORITY_INFO': return 'Medsökande: Skattedeklaration';
      case 'CO_APPLICANT_INSURANCE_CERTIFICATE': return 'Medsökande: Utbetalningsunderlag från Försäkringskassan';
      case 'CO_APPLICANT_AGREEMENT_COPY': return 'Medsökande: Avtalskopia';
      case 'CO_APPLICANT_CONSOLIDATION_INVOICE': return 'Medsökande: Fullmakt för lösen av lån';
      case 'CO_APPLICANT_CONSOLIDATION_AVI': return 'Medsökande: Fullmakt för lösen av lån Avi';
      case 'CO_APPLICANT_OTHER': return 'Medsökande: Annat';

      // wastgota
      case 'incomeCheck': return 'Inkomstunderlag';
      case 'accountCheck': return 'Kontoverifiering';
      case 'identificationCheck': return 'Legitimation';
      case 'addressCheck': return 'Adressbevis';
    }
  });
};

exports.application.offer_amortization_type = function (value) {
  switch (value) {
    default: return value;
    case 'annuity': return 'Annuitetslån';
    case 'straight': return 'Rak amortering';
    case 'none': return 'Annan';
  }
};

exports.application.offer_type = function (type) {
  switch (type) {
    default: return type;
    case 'avida_existing_customer': return 'Avida: befintlig kund'; // 2022-06-01 deprecated
    case 'after_no_repay_monthly_cost': return 'Månadskostnad efter amorteringsfri tid';
    case 'alt_amount': return 'Högsta belopp med samma återbetalningstid';
    case 'alt_repayment_years': return 'Kortaste återbetalningstid med samma belopp';
    case 'amortization_type': return 'Amorteringstyp';
    case 'amount': return 'Beviljat belopp';
    case 'application_fee': return 'Uppläggningsavgift';
    case 'applied_amount': return 'Ansökt belopp';
    case 'autogiro_fee': return 'Autogiroavgift';
    case 'bank_id': return 'Långivarens ID';
    case 'credento_insurance_monthly_cost': return 'Credento: månadskostnad för försäkring';
    case 'credento_is_ppi_mandatory': return 'Credento: försäkring krävs';
    case 'credento_is_preapproved': return 'Credento: lån förgodkänt';
    case 'credento_is_top_up': return 'Credento: kunden har tidigare lån';
    case 'credit_class': return 'Kreditklass';
    case 'co_applicant': return 'Medsökande';
    case 'existing_loan_redemption_value': return 'Lån att lösa'; // deprecated
    case 'ikano_limit_left': return 'Ikano: återstående limit';
    case 'interest_rate': return 'Nominell ränta';
    case 'interest_rate_effective': return 'Effektiv ränta';
    case 'loan_type': return 'Lånetyp';
    case 'norwegian_flex_loan': return 'Norwegian: Flexlån';
    case 'maximum_amount': return 'Högsta belopp';
    case 'minimum_amount': return 'Lägsta belopp';
    case 'maximum_repayment_years': return 'Högsta återbetalningstid';
    case 'minimum_repayment_years': return 'Lägsta återbetalningstid';
    case 'maximum_repayment_months': return 'Högsta återbetalningstid';
    case 'minimum_repayment_months': return 'Lägsta återbetalningstid';
    case 'minimum_amount_to_resolve': return 'Minsta lånesumma att lösa';
    case 'minimum_amount_to_resolve_ext_bank': return 'Minsta lånesumma att lösa (annan långivare)';
    case 'minimum_amount_to_resolve_same_bank': return 'Minsta lånesumma att lösa (samma långivare)';
    case 'monthly_cost': return 'Månadskostnad';
    case 'monthly_cost_last': return 'Månadskostnad (sista)';
    case 'no_repay_monthly_cost': return 'Amorteringsfri månadskostnad';
    case 'no_repay_months': return 'Amorteringsfria månader';
    case 'nordax_topup_amount': return 'Nordax: Beviljat tilläggslånebelopp';
    case 'northmill_product_type': return 'Northmill: produkt';
    case 'northmill_withdrawal_fee': return 'Northmill: uttagsavgift';
    case 'note': return 'Notat';
    case 'invoice_fee':
    case 'notice_fee': return 'Aviavgift';
    case 'fees_combined': return 'Avgifter';
    case 'repayments': return 'Inbetalningar';
    case 'repayments_combined': return 'Återbetalningstid';
    case 'repayment_time': return 'Återbetalningstid';
    case 'repayment_months': return 'Återbetalningstid i månader';
    case 'repayment_years': return 'Återbetalningstid';
    case 'preapproved_mc': return 'Beviljat Master Card'; // 2022-06-01 deprecated
    case 'resolve_required': return 'Lösen av lån krävs'; // 2022-06-01 deprecated
    case 'signing_link': return 'Länk för underskrift';
    case 'signing_link_co': return 'Länk för underskrift (medsökande)';
    case 'signing_link_main': return 'Länk för underskrift (huvudsökande)';
    case 'status': return 'Status';
    case 'their_id': return 'Erbjudandenummer';
    case 'total_cost': return 'Totalkostnad';
    case 'verifications': return 'Verifikationer som krävs';
    case 'existing_loan': return 'Befintligt lån';
    case 'existing_loan_topup': return 'Tilläggslånebelopp';
    case 'existing_loan_balance': return 'Befintlig lånesumma';
  }
};

exports.application.northmill_product_type = function (value) {
  switch (value) {
    default: return value;
    case 'kontokrediten': return 'Kontokredit';
    case 'privatlanet': return 'Privatlån';
  }
};

exports.application.loan_purposes = function (value) {
  switch (value) {
    default: return 'Ogiltig: ' + value;
    case 'motorfordon': return 'Motorfordon/Båt';
    case 'renovering': return 'Renovering/Köpa möbler';
    case 'rekreation': return 'Rekreation/vårdsyfte';
    case 'kreditlosning': return 'Lösa lån/krediter';
    case 'ovrigt': return 'Övrigt';
  }
};

exports.application.address_types = function (value) {
  switch (value) {
    default: return 'Ogiltig: ' + value;
    case 'villa': return 'Villa eller radhus';
    case 'bostadsratt': return 'Bostadsrätt';
    case 'hyresratt': return 'Hyresrätt';
    case 'inneboende': return 'Inneboende';
    // case 'annat': return 'Ogiltig: Annat';
  }
};

exports.application.employment_types = function (value) {
  switch (value) {
    default: return 'Ogiltig: ' + value;
    case 'fast_anstalld': return 'Fast anställd';
    case 'egen_foretagare': return 'Egen företagare';
    case 'tidsbegransad_anstallning': return 'Tidsbegränsad anställning';
    case 'timanstalld': return 'Timanställd/Extraarbete';
    case 'pensionar': return 'Pensionär';
    case 'sjukpensionar': return 'Sjukpensionär';
    case 'provanstalld': return 'Provanställd';
    // case 'vikariat': return 'Ogiltig: Vikariat';
    // case 'projektanstalld': return 'Ogiltig: Projektanställd';
    // case 'annat': return 'Ogiltig: Annat';
    // case 'arbetslos': return 'Ogiltig: Arbetslös';
    // case 'studerande': return 'Ogiltig: Studerande';
  }
};

exports.application.applicant_statuses = function (value) {
  switch (value) {
    default: return 'Ogiltig: ' + value;
    case 'ensam': return 'Ensamstående';
    case 'gift': return 'Gift';
    case 'sambo': return 'Sambo';
    case 'anka': return 'Änka/Änkling';
    case 'skild': return 'Skild';
    // case 'partner': return 'Ogiltig: Registrerat partnerskap';
    // case 'annat': return 'Ogiltig: Annat';
  }
};

exports.customer = {};

exports.customer.pep_myself_title = function (value) {
  switch (value) {
    default: return 'Ogiltig: ' + value;
    case 'm0': return 'Stats- eller regeringschef';
    case 'm1': return 'Minister, vice eller biträdande minister';
    case 'm2': return 'Parlamentsledamot';
    case 'm3': return 'Domare i högsta domstolen, konstitutionell domstol eller andra rättsliga organ på hög nivå';
    case 'm4': return 'Högre tjänsteman vid revisionsmyndighet';
    case 'm5': return 'Ledamot i centralbankers styrande organ';
    case 'm6': return 'Ambassadör eller beskickningschef';
    case 'm7': return 'Hög officer i försvarsmakten';
    case 'm8': return 'Person som ingår i statsägda företags förvaltnings- lednings- eller kontrollorgan';
    case 'm9': return 'Person som har en funktion i ledningen i en internationell organisation';
  }
};

exports.customer.pep_knows_title = function (value) {
  switch (value) {
    default: return 'Ogiltig: ' + value;
    case 'k0': return 'Kunden';
    case 'k1': return 'Verklig huvudman';
    case 'k2': return 'Maka eller make, registrerad partner eller sambo';
    case 'k3': return 'Barn';
    case 'k4': return 'Barns maka eller make, registrerade partner eller sambo';
    case 'k5': return 'Förälder';
    case 'k6': return 'Medarbetare';
    case 'k7': return 'Annan förbindelse';
  }
};

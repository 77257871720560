import React from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Form } from 'react-bootstrap';
import ErrorModal from 'src/modals/ErrorModal';
import * as api from 'src/api';

interface ISubscribeToLotteryButton {

}

interface SubscribedQueryData {
  subscribed: boolean;
}


export default function SubscribeToLotteryButton (props: ISubscribeToLotteryButton) {
  const queryClient = useQueryClient();

  const subscribedQuery = useQuery<SubscribedQueryData, Error>({
    queryKey: ['SubscribeToLotteryButton'],
    queryFn: () => api.request({
      method: 'get',
      url: '/task_lottery/self',
    }),
  });

  const subscribeMutation = useMutation<unknown, Error, SubscribedQueryData>({
    mutationFn: vars => api.request({
      method: 'post',
      data: {},
      url: vars.subscribed ? '/task_lottery/subscribe' : '/task_lottery/unsubscribe',
    }),
    onSuccess: (data, vars) => {
      queryClient.setQueryData(['SubscribeToLotteryButton'], vars);
    },
  });

  const onChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const vars = {subscribed: ev.target.checked};
    subscribeMutation.mutate(vars);
  };

  const id = React.useId();

  return (
    <div>
      <ErrorModal title="Ansökningslotteriet" error={subscribedQuery.error} />
      <Form.Switch
        id={id}
        label="Delta i lotteriet"
        disabled={subscribedQuery.isLoading || subscribeMutation.isLoading}
        checked={Boolean(subscribedQuery.data?.subscribed)}
        onChange={onChange}
      />
    </div>
  );
}


import React from 'react';

interface ModalStateContextInterface {
  onHide: () => void;
}

const defaultValue = {
  onHide: () => {},
};

const ModalStateContext = React.createContext<ModalStateContextInterface>(defaultValue);

export default ModalStateContext;

import React from 'react';
import Anchor from  'src/anchors/Anchor';
import { Name } from 'src/smsTemplate/SmsTemplateFormatters';
import { SmsTemplateRow } from 'shared/types/sms_template';

interface SmsTemplateAnchorProps {
  smsTemplate: SmsTemplateRow;
}

export default function SmsTemplateAnchor (props: SmsTemplateAnchorProps) {
  const { smsTemplate, ...restOfProps } = props;
  return (
    <Anchor
      {...restOfProps}
      url={`/sms_template/${smsTemplate.id}`}
      name="E-postmallen"
    >
      <Name value={smsTemplate} />
    </Anchor>
  );
}



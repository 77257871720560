import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import classNames from 'classnames';
import { IFilterDefinition } from 'src/tables/Table';
import { TStateFilterValue } from 'src/tables/types';

export type TOnChangeFilterValue = (rawValue: any) => void;

interface ITableFilterFormGroup {
  id: string;
  filterDefinition: IFilterDefinition;
  visible?: boolean;
  value: TStateFilterValue;
  onToggleVisible?: (id: string) => void;
  onChangeFilterValue: (id: string, value?: TStateFilterValue) => void;
}

const TableFilterFormGroup: React.FC<ITableFilterFormGroup> = React.memo(function TableFilterFormGroup (props: ITableFilterFormGroup) {
  const {
    id,
    filterDefinition,
    onToggleVisible,
    onChangeFilterValue,
    visible = true,
    value,
  } = props;

  const htmlId = React.useId();

  if (!filterDefinition?.Filter) {
    return null;
  }

  const valueIsNotDefined = getValueIsNotDefined(value);

  const handleClickReset = (ev: React.MouseEvent<HTMLLabelElement, MouseEvent>) => {
    if (valueIsNotDefined || !ev.altKey) return;
    ev.preventDefault();
    onChangeFilterValue(id);
  };

  const handleChangeValue: TOnChangeFilterValue = rawValue => {
    const value = getValueIsNotDefined(rawValue) ? undefined : rawValue;
    onChangeFilterValue(id, value);
  };

  const { Filter, title, Title, filterProps = {} } = filterDefinition;
  return (
    <Form.Group className="me-3 mb-3">
      <InputGroup>
        <InputGroup.Text
          className={classNames({'bg-secondary bg-gradient border-secondary text-white': !valueIsNotDefined})}
          as="label"
          htmlFor={htmlId}
          onClick={handleClickReset}
        >
          {Title ? (
            <Title>{title || id}</Title>
          ) : (
            <>{title || id}</>
          )}
        </InputGroup.Text>
        <Filter
          {...filterProps}
          id={htmlId}
          name={id}
          value={value}
          onChangeValue={handleChangeValue}
        />
        {onToggleVisible && (
          <InputGroup.Checkbox
            name="visible"
            className="mt-0"
            checked={visible}
            onChange={ev => onToggleVisible(id)}
            title="Filtret synligt utanför inställningar"
          />
        )}
      </InputGroup>
    </Form.Group>
  );
});

export default TableFilterFormGroup;

function getValueIsNotDefined (value: any) {
  return typeof value === 'undefined' || value === '';
}

import React from 'react';
import { Badge, Offcanvas } from 'react-bootstrap';
import classNames from 'classnames';
import { Sidebar } from 'react-feather';
import Anchor from 'src/anchors/Anchor';
import { Link } from 'react-router-dom';
import CopyToClipboardButton from 'src/buttons/CopyToClipboardButton';

interface IQuicklookAnchor {
  title: React.ReactNode;
  name: string;
  url: string;
  objectId?: string;
  children: React.ReactNode;
  favorite?: boolean | React.ReactNode;
  className?: string;
}

const QuicklookAnchor: React.FC<IQuicklookAnchor> = React.memo(function QuicklookAnchor (props: IQuicklookAnchor) {
  const {
    title,
    name,
    favorite,
    url,
    children,
    className,
    objectId,
    ...restOfProps
  } = props;

  const [show, setShow] = React.useState(false);
  const [mounted, setMounted] = React.useState(false);

  const handleClose = () => setShow(false);

  const handleShow = () => {
    setMounted(true);
    setShow(true);
  };

  const handleExited = () => {
    setMounted(false);
  };

  return (
    <Badge
      bg="white"
      className={classNames(className, 'd-inline-flex align-items-center border p-0')}
      {...restOfProps}
    >
      <span className="p-1 d-inline-block">
        <Link to={url} title={`Öppna ${name}`}>
          {title}
        </Link>
      </span>
      <button
        type="button"
        onClick={handleShow}
        className="btn py-0 px-1 border-0 border-start btn-link d-inline-block"
        title={`Förhandsgranska ${name}`} style={{ marginTop: '-1px' }}
      >
        <Sidebar className="feather" style={{ height: '12px', width: '12px', transform: 'scaleX(-1)' }} />
      </button>
      {mounted && (
        <Offcanvas show={show} onHide={handleClose} onExited={handleExited} placement="end" size="lg">
          <Offcanvas.Header>
            <div className="border rounded p-2 d-flex justify-content-between align-items-center w-100">
              <div>
                <strong className="d-flex align-items-center">
                  {favorite}
                  <span>{name}</span>
                </strong>
                <div className="d-flex gap-1 align-items-center">
                  <Anchor url={url} name={name}>{title}</Anchor>
                  {objectId && (
                    <CopyToClipboardButton
                      size="sm"
                      className="px-1 py-0 rounded"
                      variant="outline-primary"
                      copy={objectId}
                      onCopyMessage="ID kopierat"
                    />
                  )}
                </div>
              </div>
              <button type="button" className="btn-close" onClick={handleClose}></button>
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body className="p-0">
            {children}
          </Offcanvas.Body>
        </Offcanvas>
      )}
    </Badge>
  );
});
export default QuicklookAnchor;

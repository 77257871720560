

/**
 * WARNING: This file is used clientside
 */

const { fieldChange } = require('shared/filter/log');
const { fieldName } = require('shared/filter/bank');

exports.description = function (log) {
  const { data = {} } = log;
  switch (data.type) {
    default: return JSON.stringify(data);
    case 'field_changed': return fieldChange(data, fieldName);
    case 'created': return 'Skapad';
  }
};

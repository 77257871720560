import React from 'react';
import useQueryClientUtils from 'src/hooks/useQueryClientUtils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import * as api from 'src/api';
import {
  IBatchActionMutationData,
  IBatchDefinition,
  ISelectedRows,
  IUpdateRowMutationData,
  TableQueryKey,
} from 'src/tables/types';
import useNotyf from 'src/hooks/useNotyf';

interface UseTableUpdateRowMutationOptions {
  queryKey: TableQueryKey;
  subKey?: string; // in the query result cache, the (optional) sub key where the list of rows is found
}

// returns a mutation to execute an update of a row
// TODO this might be outdated/deprecated?
export function useTableUpdateRowMutation (options: UseTableUpdateRowMutationOptions) {
  const { queryKey, subKey } = options;
  const [queryKeyBase] = queryKey;

  const notyf = useNotyf();
  const queryClient = useQueryClient();
  const queryClientUtils = useQueryClientUtils();

  const updateRowMutation = useMutation({
    mutationFn: ({method, url, data}) => api.request({method, data, url}),
    onMutate: async (mutationData: IUpdateRowMutationData) => {
      const { rowId, data, optimistic, deletion } = mutationData;
      if (optimistic) {
        if (deletion) {
          queryClientUtils.applyDeletionsById({queryKey: [queryKeyBase]}, [rowId], {subKey});
        } else {
          const newRow = {...data, id: rowId};
          queryClientUtils.applyUpdates({queryKey: [queryKeyBase]}, [newRow], {subKey});
        }
      }
      await queryClient.cancelQueries({queryKey});
    },
    onSuccess: (newRow, mutationData: IUpdateRowMutationData) => {
      const { rowId, deletion, onSuccessNotyf } = mutationData;
      if (deletion) {
        queryClientUtils.applyDeletionsById({queryKey: [queryKeyBase]}, [rowId], {subKey});
      } else {
        queryClientUtils.applyUpdates({queryKey: [queryKeyBase]}, [newRow], {subKey});
      }
      if (onSuccessNotyf) {
        notyf.open(onSuccessNotyf);
      }
    },
  });

  return updateRowMutation;
}

interface UseTableBatchActionOptions {
  queryKey: TableQueryKey;
  batchDefinition?: IBatchDefinition;
  selectedRows: ISelectedRows;
  setSelectedRows: React.Dispatch<React.SetStateAction<ISelectedRows>>;
  subKey?: string;
  refetch: () => void;
}

// returns a mutation to execute a batch action on the selected rows
export function useTableBatchActionMutation (options: UseTableBatchActionOptions) {
  const {
    queryKey,
    batchDefinition,
    selectedRows,
    setSelectedRows,
    subKey,
    refetch,
  } = options;

  const notyf = useNotyf();
  const queryClientUtils = useQueryClientUtils();

  const batchActionMutation = useMutation({
    mutationFn: async (vars: IBatchActionMutationData) => {
      if (!batchDefinition) return;
      const { formToData = defaultBatchActionToData, url } = batchDefinition;
      const ids = Object.keys(selectedRows).filter(id => selectedRows[id]);
      const { action } = vars;
      const data = formToData(action, ids, vars);
      return api.request({url, method: 'post', data});
    },
    onSuccess: (newRows, vars: IBatchActionMutationData) => {
      const { action, onSuccessNotyf } = vars;
      if (action === 'delete') {
        refetch();
      } else {
        queryClientUtils.applyUpdates({queryKey: [queryKey[0]]}, newRows, {subKey});
      }
      setSelectedRows({});
      if (onSuccessNotyf) {
        notyf.open(onSuccessNotyf);
      }
    },
  });

  return batchActionMutation;
}

function defaultBatchActionToData (action: string, ids: string[]): IBatchActionMutationData {
  return {action, ids};
}

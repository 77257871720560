import React from 'react';

interface ProspectTriggerTableProps {
  prospectId: string;
}

export default function ProspectTriggerTable (props: ProspectTriggerTableProps) {
  const { prospectId } = props;
  return (
    <>prospekt trigger table {prospectId}</>
  );
}

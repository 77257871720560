import React from 'react';
import { Badge, Modal } from 'react-bootstrap';
import ModalStateContext from 'src/contexts/ModalStateContext';
import classNames from 'classnames';

export interface ModalAnchorProps extends React.PropsWithChildren {
  title: React.ReactNode;
  name: React.ReactNode;
  className?: string;
  size?: 'sm' | 'lg' | 'xl';
  modalBodyClassName?: string;
}

const ModalAnchor: React.FC<ModalAnchorProps> = React.memo(function ModalAnchor (props: ModalAnchorProps) {
  const {
    title,
    name,
    children,
    className,
    size,
    modalBodyClassName = 'p-2',
    ...restOfProps
  } = props;

  const [show, setShow] = React.useState(false);
  const [mounted, setMounted] = React.useState(false);

  const onHide = () => setShow(false);

  const onClickShow = () => {
    setMounted(true);
    setShow(true);
  };

  const onExited = () => {
    setMounted(false);
  };

  return (
    <Badge
      bg="white"
      className={classNames(className, 'd-inline-flex align-items-center border p-0')}
      {...restOfProps}
    >
      <span className="p-0 d-inline-block">
        <button
          type="button"
          onClick={onClickShow}
          className="btn px-1 py-0 border-0 btn-link d-inline-block"
          title={`Öppna ${name}`} style={{ marginTop: '-1px' }}
        >
          {title}
        </button>
      </span>
      {mounted && (
        <Modal show={show} onHide={onHide} onExited={onExited} placement="end" size={size}>
          <Modal.Header>
            <div className="d-flex justify-content-between align-items-center w-100">
              <strong className="d-block">
                {name}
              </strong>
              <button type="button" className="btn-close" onClick={onHide}></button>
            </div>
          </Modal.Header>
          <Modal.Body className={modalBodyClassName}>
            <ModalStateContext.Provider value={{onHide}}>
              {children}
            </ModalStateContext.Provider>
          </Modal.Body>
        </Modal>
      )}
    </Badge>
  );
});
export default ModalAnchor;

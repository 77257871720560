import React from 'react';
import { Form } from 'react-bootstrap';

export default function PostponeTaskForm (props) {
  return (
    <Form>
      Formulär för att skjuta upp en återkomst
    </Form>
  );
}

import { isFiniteNumber } from 'src/numbers';
import {
  TableRow,
  TStateFilterMap,
  TStateFilterList,
  ISelectedRows,
  TStateColumns,
  IState,
} from 'src/tables/types';
import qs from 'qs';

export function canLastPage (tableState: IState, count: number | null): boolean {
  if (!isFiniteNumber(count)) return false;
  const { page, limit } = tableState;
  if (!isFiniteNumber(page) || !isFiniteNumber(limit)) return false;
  const pages = totalNumberOfPages(count, limit);
  return page < pages;
}

export function canNextPage (tableState: IState, count: number | null): boolean {
  if (!isFiniteNumber(count)) return true;
  const { page, limit } = tableState;
  if (!isFiniteNumber(page) || !isFiniteNumber(limit)) return false;
  const pages = totalNumberOfPages(count, limit);
  return page < pages;
}

export function canPrevPage (tableState: IState): boolean {
  const { page } = tableState;
  return isFiniteNumber(page) ? page > 1 : false;
}

export function canFirstPage (tableState: IState): boolean {
  const { page } = tableState;
  return isFiniteNumber(page) ? page > 1 : false;
}

export function totalNumberOfPages (count: number | null, limit: number): number {
  if (count === null) return NaN;
  return Math.ceil(count / limit);
}

export function cycleSorted (sorted: any): ('asc' | 'desc') {
  if (sorted === 'asc') return 'desc';
  if (sorted === 'desc') return 'asc';
  return 'desc';
}

export function columnIsVisible (stateColumns: TStateColumns, columnId: string): boolean {
  return stateColumns.includes(columnId);
}

export function selectedRowsToArray (selectedRows: ISelectedRows): string[] {
  return Object.keys(selectedRows).filter(key => selectedRows[key]);
}

export function selectAllRows (rows: TableRow[], selectedRows: ISelectedRows = {}, shouldBeSelected = false): ISelectedRows {
  if (!shouldBeSelected) {
    return {};
  }
  return rows.reduce((map, row) => {
    map[row.id] = true;
    return map;
  }, {...selectedRows});
}

export function anyRowsSelected (rows: TableRow[], selectedRows: ISelectedRows = {}): boolean {
  if (!rows) return false;
  return rows.some(row => selectedRows[row.id]);
}

export function allRowsSelected (rows: TableRow[], selectedRows: ISelectedRows = {}): boolean {
  if (!rows) return false;
  return rows.length > 0 && rows.every(row => selectedRows[row.id]);
}

export function addColumnInStableOrder (columnId, columns, allColumnsOrdered) {
  const newColumns = [...columns, columnId];
  return allColumnsOrdered.filter(column => {
    return newColumns.includes(column);
  });
}

export function stateFilterToAutoTitle (stateFilter, filterDefinitions) {
  const tokens = stateFilter.reduce((tokens, filter) => {
    const { value, key } = filter;
    if (value === null || value === '' || typeof value === 'undefined') {
      return tokens;
    }

    const definition = filterDefinitions.find(definition => definition.id === key);
    if (definition) {
      const { title, filterValue } = definition;

      // if (definition.valid && !definition.valid(value)) {
      //   return tokens;
      // }

      if (filterValue) {
        return [...tokens, `${title}: ${filterValue(value)}`];
      }

      return [...tokens, `${title}: ${value}`];
    }
    return [...tokens, `${key}: ${value}`];
  }, []);
  return tokens.length > 0 ? tokens.join(', ') : '(Tom)';
}

export function stateFilterMapToList (map: TStateFilterMap): TStateFilterList {
  return Object.keys(map).map(key => {
    return {key, value: map[key]};
  });
}

export function getLinkToFilteredTable (path: string, baseFilter: TStateFilterMap, rowFilter: TStateFilterList = []): string {
  const tableFilter = [
    ...stateFilterMapToList(baseFilter),
    ...rowFilter,
  ];

  const overrideSearch = {
    filterVisible: tableFilter.map(t => t.key).filter(v => v),
    filter: tableFilter,
  };

  const query = qs.stringify({
    overrideSearch: JSON.stringify(overrideSearch),
  }, {
    addQueryPrefix: true,
    strictNullHandling: true,
  });

  return path + query;
}

// TODO should be part of the table settings
export function groupLabel (group: string): string {
  switch (group) {
    default: return group;
    case '': return 'Allt';
    case 'status': return 'Status';
    case 'user': return 'Användare';
    case 'portal': return 'Portal';
    case 'pep': return 'PEP';
    case 'flags': return 'Flaggor';
    case 'customer': return 'Kund';
    case 'form': return 'Formulär';
    case 'date': return 'Datum';
    case 'bank': return 'Långivare';
    case 'cloudinsurance': return 'Försäkring';
    case 'sortable': return 'Sorterbart';
    case 'dates': return 'Datum';
    case 'uc': return 'UC';
    case 'last_application': return 'Senaste ansökan';
    case 'last_disbursed_application': return 'Senaste utbetalda ansökan';
    case 'tracking': return 'Spårning';
    case 'other': return 'Övrigt';
  }
}

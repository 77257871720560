import React from 'react';
import { useLocation, matchPath } from 'react-router-dom';
// import reduceChildRoutes from 'src/sidebar/reduceChildRoutes';
import SidebarNavListItem, { SidebarNavListItemProps } from 'src/sidebar/SidebarNavListItem';

export interface ISidebarNavList {
  pages: (SidebarNavListItemProps | ISidebarNavList)[];
  depth: number;
}

export default function SidebarNavList (props: ISidebarNavList) {
  const { pages, depth } = props;
  const router = useLocation();
  const currentRoute = router.pathname;

  const childRoutes = pages.map<React.ReactNode[]>(page => {

    if (page.children) {
      const childHrefs: string[] = page.children.map(c => c.href ?? '').filter(v => v);
      const open = childHrefs.some(path => {
        return matchPath({path, end: false}, currentRoute) !== null;
      });
      return (
        <SidebarNavListItem
          {...page}
          open={open}
          key={page.title}
          depth={depth}
        >
          <SidebarNavList depth={depth + 1} pages={page.children} />
        </SidebarNavListItem>
      );
    }

    return (
      <SidebarNavListItem
        depth={depth}
        key={page.title}
        {...page}
      />
    );
  });
  return <>{childRoutes}</>;
}

import React from 'react';
import Main from 'src/ui/Main';
import Navbar from 'src/navbar/Navbar';
import Content from 'src/ui/Content';
import { Helmet } from 'react-helmet-async';
import { useParams, NavLink, Outlet } from 'react-router-dom';
import BlockSpinner from 'src/spinners/BlockSpinner';
import * as IconButtons from 'src/buttons/IconButtons';
import {useQuery} from '@tanstack/react-query';
import {ApplicationRow} from 'shared/types/application';
import Breadcrumbs from 'src/components/Breadcrumbs';
import { ErrorAlertCardBody, CardTitleIconStatus  } from 'src/cards/CardHelpers';
import * as ApplicationFormatters from 'src/application/ApplicationFormatters';
import {Card, Col, Nav, Row, Tab} from 'react-bootstrap';
import {Activity, Box, Edit2, Repeat} from 'react-feather';
import {GuardPermission} from 'src/guards/AuthGuards';
import ApplicationAssociatedObjectList from 'src/application/ApplicationAssociatedObjectList';
import ApplicationLogCard from 'src/application/ApplicationLogCard';

interface ApplicationPageParams {
  applicationId: string;
  tab?: string;
}

const ApplicationPage: React.FC = React.memo(function ApplicationPage () {
  const { applicationId, tab = 'form' } = useParams() as unknown as ApplicationPageParams;

  const readQuery = useQuery<ApplicationRow, Error>({
    queryKey: [`/application/${applicationId}`],
  });

  const onClickDelete = () => {
    // return deleteMutation.mutateAsync();
  };

  const application = readQuery.data;

  return (
    <Main>
      <Navbar />
      <Content>
        <Helmet title="Ansökan" />
        <h1>Ansökan</h1>
        <Breadcrumbs
          crumbs={[
            ['/applications/all', 'Ansökningar'],
            [`/application/${applicationId}/form`, application ? <ApplicationFormatters.Title value={application} /> : applicationId],
          ]}
        />
        <BlockSpinner isLoading={readQuery.isLoading} />
        <ErrorAlertCardBody error={readQuery.error} className="mx-0 mt-0 mb-3" />
        {application && (
          <Card className="border">
            <Card.Header className="border-bottom bg-light">
              <CardTitleIconStatus
                as="h2"
                title={<ApplicationFormatters.Title value={application} />}
                Status={<ApplicationFormatters.StatusLabel value={application.status} className="px-3" />}
                Icon={<Box size={16} />}
                spinning={readQuery.isRefetching}
              >
                <GuardPermission permission="application_delete">
                  <IconButtons.ConfirmModalDeleteButton
                    onConfirm={onClickDelete}
                    message="Är du säker på att du vill radera den här ansökan?"
                  />
                </GuardPermission>
                <IconButtons.FavoriteButton type="application" id={application.id} />
                <IconButtons.InspectObjectModalButton object={application} />
                <IconButtons.CopyObjectIdButton id={application.id} />
                <IconButtons.CopyCurrentUrlButton />
                <IconButtons.RefreshObjectButton
                  disabled={readQuery.isRefetching}
                  onClick={() => readQuery.refetch()}
                />
              </CardTitleIconStatus>
            </Card.Header>
            {application && (
              <ApplicationAssociatedObjectList application={application} />
            )}
            <Tab.Container
              activeKey={tab}
              transition={false}
              unmountOnExit={true}
              mountOnEnter={true}
            >
              <Nav className="nav-tabs pt-3 px-3">
                <Nav.Item>
                  <NavLink className="nav-link" to={`/application/${applicationId}/form`}>
                    <span className="d-inline-flex gap-1 align-items-center">
                      <Edit2 size={12} />{' '}
                      Formulär
                    </span>
                  </NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink className="nav-link" to={`/application/${applicationId}/bankProcess`}>
                    <span className="d-inline-flex gap-1 align-items-center">
                      <Repeat size={12} />{' '}
                      Processer
                    </span>
                  </NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink className="nav-link" to={`/application/${applicationId}/log`}>
                    <span className="d-inline-flex gap-1 align-items-center">
                      <Activity size={12} />{' '}
                      Logg
                    </span>
                  </NavLink>
                </Nav.Item>
              </Nav>
              <Tab.Content>

                <Outlet />

              </Tab.Content>
            </Tab.Container>
          </Card>
        )}
      </Content>
    </Main>
  );
});
export default ApplicationPage;


// most tabs are exported here temporarily

export function ApplicationPageTabLog () {
  const { applicationId } = useParams() as unknown as ApplicationPageParams;
  return (
    <div>
      <ApplicationLogCard applicationId={applicationId} className="m-4" />
    </div>
  );
}

export function ApplicationPageTabForm () {
  const { applicationId } = useParams() as unknown as ApplicationPageParams;

  const readQuery = useQuery<ApplicationRow, Error>({
    queryKey: [`/application/${applicationId}`],
  });

  const application = readQuery.data;

  return (
    <Card.Body className="pb-0">
      <Row>
        <Col xl={6}>
          {application && (
            <Card className="border mb-4">
              <Card.Header className="border-bottom bg-light">
                <Card.Title className="mb-0">Ansökan</Card.Title>
              </Card.Header>
              <Card.Body>Det finns ingenting här än.</Card.Body>
            </Card>
          )}
        </Col>
      </Row>
    </Card.Body>
  );
}

import React from 'react';
import { Spinner } from 'react-bootstrap';

const containerStyles: React.CSSProperties = {
  position: 'absolute',
  right: 7,
  top: '50%',
  bottom: '50%',
  background: 'rgba(255, 255, 255, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export default function FormControlSpinner (props) {
  const { isLoading } = props;
  if (!isLoading) return null;
  return (
    <div style={containerStyles}>
      <Spinner animation="border" size="sm" variant="default" />
    </div>
  );
}

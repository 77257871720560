import React from 'react';
import classNames from 'classnames';

export interface StatusBaseLabelProps extends React.HTMLAttributes<HTMLDivElement> {
  value: any;
  short?: boolean;
  narrow?: boolean;
  formatter?: (str: string) => string;
  labelClassName?: string;
}

export default function StatusBaseLabel (props: StatusBaseLabelProps) {
  const {
    value,
    className,
    short,
    narrow,
    labelClassName,
    formatter = v => v,
    children, // when set, will show this instead of running formatter
    ...restOfProps
  } = props;

  const classes = classNames(className, 'text-uppercase status-label', {
    'py-1': !short,
    'px-1': !narrow,
  }, labelClassName ?? `status-label-${value}`);

  return (
    <div
      className={classes}
      {...restOfProps}
    >
      {children ? children : formatter(value)}
    </div>
  );
}

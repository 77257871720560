

exports.isFiniteNumber = function (num) {
  return typeof num === 'number' && isFinite(num);
};

exports.precision = function (num, precision) {
  return exports.isFiniteNumber(num) ? num.toFixed(precision) : num;
};

exports.percentage = function (num) {
  return exports.isFiniteNumber(num) ? num * 100 : num;
};

exports.percent = function (num, precision) {
  return exports.isFiniteNumber(num) ? exports.precision(num * 100, precision) : num;
};

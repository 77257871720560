import React from 'react';
import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import { Download } from 'react-feather';
import { isFiniteNumber } from 'src/numbers';
import ConfirmActionModal from 'src/modals/ConfirmActionModal';
import { IState } from 'src/tables/types';
import qs from 'qs';

interface TableExportProps {
  className?: string;
  state: IState;
  updateState: (newState: IState) => void;
  count: number | null;
  exportUrl?: string;
}

export default function TableExport (props: TableExportProps) {
  const { className, state, updateState, count, exportUrl } = props;
  const { skipCount } = state;

  const [showConfirmExportModal, setShowConfirmExportModal] = React.useState(false);

  const exportDisabled = skipCount || !isFiniteNumber(count);

  const handleClickCount = () => {
    updateState({skipCount: !skipCount});
  };

  const handleClickExport = () => {
    if (isFiniteNumber(count) && count >= 1000) {
      setShowConfirmExportModal(true);
    } else {
      performExport();
    }
  };

  const performExport = () => {
    const finalExportUrl = `${exportUrl}?${qs.stringify(state, {strictNullHandling: true})}`;
    window.location.assign(finalExportUrl);
    setShowConfirmExportModal(false);
  };

  return (
    <div className={classNames(className)}>
      <Button
        className="d-inline-flex align-items-center"
        variant={skipCount ? 'outline-primary' : 'primary'}
        onClick={handleClickCount}
        title="Räkna antalet rader i hela tabellen"
      >
        Räkna
        {!exportUrl && (
          <>
            {' '}
            (<RowCount count={skipCount ? null : count} />)
          </>
        )}
      </Button>
      {exportUrl && (
        <Button
          className="ms-2 px-2 d-inline-flex align-items-center"
          variant="outline-primary"
          onClick={handleClickExport}
          disabled={exportDisabled}
          title="Ladda hem data som CSV"
        >
          <Download className="me-1" size={16} />
          Exportera <RowCount count={exportDisabled ? null : count} />
        </Button>
      )}
      <ConfirmActionModal
        show={showConfirmExportModal}
        message={(
          <p>
            <strong>Tabellen innehåller fler än 1 000 rader.</strong><br />
            Är du säker på att du vill exportera den?
          </p>
        )}
        confirmMessage="Ja, exportera tabellen"
        onDecline={() => setShowConfirmExportModal(false)}
        onConfirm={() => performExport()}
      />
    </div>
  );
}


function RowCount (props) {
  const { count } = props;
  if (!isFiniteNumber(count)) return null;
  return <>{count} {count === 1 ? 'rad' : 'rader'}</>;
}

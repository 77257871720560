import React from 'react';
import useAuth from 'src/hooks/useAuth';
import { PermissionDefinition } from 'src/contexts/AuthContext';

// requires user to be authenticated
export function GuardAuthed (props: React.PropsWithChildren) {
  const { children } = props;
  const { isAuthenticated, isInitialized } = useAuth();
  if (isInitialized && !isAuthenticated) {
    return null;
  }
  return <>{children}</>;
}

interface GuardRoleAnyProps extends React.PropsWithChildren {
  role: PermissionDefinition;
}

// requires a user to have ANY of the roles given
export function GuardRoleAny (props: GuardRoleAnyProps) {
  const { children, role } = props;
  const { hasAnyRole } = useAuth();
  return hasAnyRole(role) ? <>{children}</> : null;
}

interface GuardFunctionProps extends React.PropsWithChildren {
  fn: PermissionDefinition;
}

// requires a user to have ALL functions given
export function GuardFunction (props: GuardFunctionProps) {
  const { children, fn } = props;
  const { hasAllFunctions } = useAuth();
  return hasAllFunctions(fn) ? <>{children}</> : null;
}

interface GuardPermissionProps extends React.PropsWithChildren {
  permission: PermissionDefinition;
}

// requires a user to have ALL permissions given
export function GuardPermission (props: GuardPermissionProps) {
  const { children, permission } = props;
  const { hasAllPermissions } = useAuth();
  return hasAllPermissions(permission) ? <>{children}</> : null;
}

import React from 'react';
import { Form, Card, Row, Col } from 'react-bootstrap';
import { Formik, FormikHelpers } from 'formik';
import { BankRow } from 'shared/types/bank';
import ButtonSpinner from 'src/spinners/ButtonSpinner';
import ErrorAlert from 'src/alerts/ErrorAlert';
import InspectObjectModalButton from 'src/buttons/InspectObjectModalButton';
import * as BankFormFields from 'src/bank/BankFormFields';

export type BankCreateFormValues = Pick<BankRow, 'id'>;

interface BankCreateFormProps {
  initialValues: BankCreateFormValues;
  onSubmit: (values: BankCreateFormValues, helpers: FormikHelpers<BankCreateFormValues>) => Promise<any>;
  submitError?: Error | null;
}

const BankCreateForm: React.FC<BankCreateFormProps> = React.memo(function BankCreateForm (props: BankCreateFormProps) {
  const { initialValues, submitError, onSubmit } = props;
  const colProps = {md: 6, lg: 4};
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {formProps => (
        <Form onSubmit={formProps.handleSubmit}>
          <Card.Body className="pt-2">
            <Row>

              <Col {...colProps}>
                <BankFormFields.Id />
              </Col>

            </Row>
          </Card.Body>
          <Card.Footer className="border-top">
            <ErrorAlert error={submitError} />
            <div className="d-flex gap-2 flex-wrap">
              <InspectObjectModalButton object={formProps} />
              <ButtonSpinner
                type="submit"
                className="rounded"
                variant="success"
                isLoading={formProps.isSubmitting}
                disabled={!formProps.isValid || formProps.isSubmitting || !formProps.dirty}
              >
                Spara
              </ButtonSpinner>
            </div>
          </Card.Footer>
        </Form>
      )}
    </Formik>
  );
});
export default BankCreateForm;

import React from 'react';
import BlockSpinner from 'src/spinners/BlockSpinner';
import ErrorAlert from 'src/alerts/ErrorAlert';
import { StatusLabel } from  'src/customer/CustomerFormatters';
import StatusLabelContainer from 'src/components/StatusLabelContainer';
import WidgetList from 'src/widgets/WidgetList';
import { allWidgets, widgetGroups } from 'src/customer/CustomerWidgets';
import useWidgetList from 'src/hooks/useWidgetList';
import { useQuery } from '@tanstack/react-query';
import { CustomerRow } from 'shared/types/customer';

interface CustomerRowQuicklook {
  id: string;
}

const CustomerQuicklook: React.FC<CustomerRowQuicklook> = React.memo(function CustomerQuicklook (props: CustomerRowQuicklook) {
  const { id } = props;
  const query = useQuery<CustomerRow, Error>({
    queryKey: [`/customer/${id}`],
  });

  const [state, dispatch] = useWidgetList('customerQuicklook', [
    'notes',
    'log',
  ]);

  const customer = query?.data;

  return (
    <div className="position-relative">
      <ErrorAlert error={query.error} className="mx-3 my-0" />
      <BlockSpinner isLoading={query.isLoading} className="mx-3" />
      {customer && (
        <>
          <StatusLabelContainer>
            <StatusLabel value={customer.status} />
          </StatusLabelContainer>
          <WidgetList
            allWidgets={allWidgets}
            widgetGroups={widgetGroups}
            state={state}
            dispatch={dispatch}
            widgetProps={{customer}}
          />
        </>
      )}
    </div>
  );
});
export default CustomerQuicklook;

import React from 'react';
import { WebSocketContext } from 'src/contexts/WebSocketContext';
import { methods } from 'src/utils/websocket';
import { ReadyState } from 'react-use-websocket';

export type TModelIds = string[];

export default function useWebsocketModel (modelName: string) {
  const websocket = React.useContext(WebSocketContext);
  const [ids, setIds] = React.useState<TModelIds>([]);
  const isConnected = websocket.readyState === ReadyState.OPEN;

  React.useEffect(() => {
    let cleanupIds: TModelIds = [];
    if (isConnected && ids.length > 0) {
      // console.log('subscribing to', modelName, ids); // eslint-disable-line no-console
      const msg = methods.subscribeToModel(modelName, ids);
      websocket.callMethod('subscribeToModel', msg).then(() => {
        // console.log('subscribed to', modelName, ids); // eslint-disable-line no-console
        cleanupIds = [...ids];
      }).catch(err => {
        console.warn(err); // eslint-disable-line no-console
      });
    }
    return () => {
      if (isConnected && cleanupIds.length > 0) {
        // console.log('unsubscribing from', modelName, cleanupIds); // eslint-disable-line no-console
        const msg = methods.unsubscribeToModel(modelName, cleanupIds);
        websocket.callMethod('unsubscribeToModel', msg).then(() => {
          // console.log('unsubscribed from', modelName, cleanupIds); // eslint-disable-line no-console
        }).catch(err => {
          console.warn(err); // eslint-disable-line no-console
        });
      }
    };
  }, [ids, isConnected, modelName]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    websocket,
    ids,
    setIds: (models: TModelIds) => {
      setIds(models);
    },
  };
}

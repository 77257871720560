import React from 'react';
import { Form } from 'react-bootstrap';

export default function TaskForm (props) {
  return (
    <Form>
      Formulär för en återkomst
    </Form>
  );
}


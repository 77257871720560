import React from 'react'; // eslint-disable-line

interface IPluralizer {
  count: number;
  zero?: string;
  one?: string;
  otherwise?: string;
}

export default function Pluralizer (props: IPluralizer) {
  const { count, zero, one, otherwise } = props;
  if (zero && count === 0) return <>{zero}</>;
  if (one && count === 1) return <>{one}</>;
  if (otherwise) return <>{otherwise.replace(/%%/, String(count))}</>;
  return null;
}

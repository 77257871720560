import React from 'react';
import Anchor from  'src/anchors/Anchor';
import QuicklookAnchor from 'src/anchors/QuicklookAnchor';
import CustomerCloudInsuranceQuicklook from 'src/cloudInsurance/CloudInsuranceQuicklook';
import { Name } from 'src/cloudInsurance/CloudInsuranceFormatters';

interface CustomerCloudInsuranceAnchorProps {
  insurance: any; // TODO when/if there will be a type for cloud insurance
}

export default function CustomerCloudInsuranceAnchor (props: CustomerCloudInsuranceAnchorProps) {
  const { insurance, ...restOfProps } = props;
  return (
    <QuicklookAnchor
      {...restOfProps}
      url={`/customer_cloud_insurance/${insurance.id}`}
      title={<Name value={insurance} />}
      name="Försäkring (Cloud)"
      objectId={insurance.id}
    >
      <CustomerCloudInsuranceQuicklook id={insurance.id} />
    </QuicklookAnchor>
  );
}

export function CustomerCloudInsurancePlainAnchor (props: CustomerCloudInsuranceAnchorProps) {
  const { insurance, ...restOfProps } = props;
  return (
    <Anchor
      {...restOfProps}
      url={`/insurance/${insurance.id}`}
      name="Försäkringen (Cloud)"
    >
      <Name value={insurance} />
    </Anchor>
  );
}

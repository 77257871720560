import React from 'react';
import ErrorAlert from 'src/alerts/ErrorAlert';
import * as api from 'src/api';
import { useMutation, useQuery } from '@tanstack/react-query';
import { TextFragment } from 'shared/types/text_fragments';
import { Card, Form } from 'react-bootstrap';
import { numberOfLines } from 'src/utils/form';
import ButtonSpinner from 'src/spinners/ButtonSpinner';
import useNotyf from 'src/hooks/useNotyf';

interface BankTextFragmentCardProps {
  bankId: string;
}

export default function BankTextFragmentCard (props: BankTextFragmentCardProps) {
  const { bankId } = props;

  const query = useQuery<TextFragment[], Error>({
    queryKey: ['BankTextFragmentCard', {bankId}],
    queryFn: () => api.request({
      url: `/text_fragment/bank/${bankId}`,
    }),
  });

  const data = query.data ?? [];

  return (
    <>
      <ErrorAlert error={query.error} className="mx-3 mt-3 mb-2" />
      {data.map(text => (
        <BankTextFragmentCardForm
          key={text.id}
          text={text}
        />
      ))}
    </>
  );
}

interface TextFragmentUpdateVars {
  markdown: string;
}

interface BankTextFragmentCardFormProps {
  text: TextFragment;
}

function BankTextFragmentCardForm (props: BankTextFragmentCardFormProps) {
  const { text } = props;

  const notyf = useNotyf();

  const [dirty, setDirty] = React.useState<boolean>(false);
  const [markdown, setMarkdown] = React.useState<string>(text.markdown ?? '');

  const updateMutation = useMutation<TextFragment, Error, TextFragmentUpdateVars>({
    mutationFn: vars => api.request({
      url: `/text_fragment/${text.id}`,
      method: 'PUT',
      data: {...vars, id: text.id, renderer: text.renderer},
    }),
    onSuccess: () => {
      notyf.success({message: 'Texten uppdaterades'});
      setDirty(false);
    },
  });

  const onSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    if (!ev.currentTarget.reportValidity()) return;
    updateMutation.mutateAsync({markdown});
  };

  const onChangeMarkdown = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMarkdown(ev.target.value);
    setDirty(true);
  };

  return (
    <Form onSubmit={onSubmit}>
      <Card className="flex-fill w-100 border mb-3" key={text.id}>
        <Card.Header className="d-flex justify-content-between align-items-center py-2 px-3 border-bottom">
          <Card.Title className="mb-0">
            {text.id}
            {text.renderer === 'inline' ? <> <span className="small">(Inline)</span></> : ''}
          </Card.Title>
          <ButtonSpinner
            isLoading={updateMutation.isLoading}
            size="sm"
            variant="success"
            type="submit"
            disabled={updateMutation.isLoading || !dirty}
          >
            Spara
          </ButtonSpinner>
        </Card.Header>
        <Card.Body className="p-2">
          <Form.Control
            as="textarea"
            value={markdown}
            onChange={onChangeMarkdown}
            rows={Math.max(4, numberOfLines(text.markdown ?? '') + 1)}
          />
          {text.description && (
            <Form.Text>
              {text.description}
            </Form.Text>
          )}
        </Card.Body>
      </Card>
    </Form>
  );
}

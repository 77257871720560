

const moment = require('moment');

/**
 * WARNING: This file is used clientside
 */

/**
 * @desc Formats an UC response into an customer update
 * @param {Object} uc
 * @param {Boolean} [useCoApplicant=false] When true, the update will be meant for the co applicant user
 * @return {null|Object}
 */
exports.toCustomerUpdate = function (uc, useCoApplicant = false) {
  const ucObject = useCoApplicant ? uc.response_co : uc.response_main;
  if (!ucObject) return null;
  const result = {};
  const { first_name, last_name } = ucObject;
  if (first_name) result.first_name = first_name;
  if (last_name) result.last_name = last_name;
  if (ucObject.street) result.address_street = ucObject.street;
  if (ucObject.postcode) result.address_postcode = ucObject.postcode;
  if (ucObject.co) result.address_co = ucObject.co;
  if (ucObject.city) result.address_city = ucObject.city;
  return Object.keys(result).length > 0 ? result : null;
};

/**
 * @desc Formats an UC response into an application update
 * @param {Object} uc
 * @param {Boolean} [hasCoApplicantAttached=false] When true the co applicant is attached to the application and the address update will take place on the co customer object
 * @return {Object}
 */
exports.toApplicationUpdate = function (uc, hasCoApplicantAttached = false) {
  const main = uc.response_main;
  const co = uc.response_co;

  const applicationUpdate = {};

  if (main) {
    applicationUpdate['form.main_applicant.address.since'] = getLastAddressChange(main.last_address_change_at);
  }

  if (co) {
    if (co.first_name) applicationUpdate['form.co_applicant.first_name'] = co.first_name;
    if (co.last_name) applicationUpdate['form.co_applicant.last_name'] = co.last_name;
    applicationUpdate['form.co_applicant.address.since'] = getLastAddressChange(co.last_address_change_at);
    if (!hasCoApplicantAttached) {
      if (co.city) applicationUpdate['form.co_applicant.address.city'] = co.city;
      if (co.street) applicationUpdate['form.co_applicant.address.street'] = co.street;
      if (co.postcode) applicationUpdate['form.co_applicant.address.postcode'] = co.postcode;
      if (co.co) applicationUpdate['form.co_applicant.address.co'] = co.co;
    }
  }

  function getLastAddressChange (value) {
    if (value) return value;
    return moment(uc.status_updated_at).subtract(3, 'years').startOf('day').toDate();
  }

  return applicationUpdate;
};

import React from 'react';
import Main from 'src/ui/Main';
import { Helmet } from 'react-helmet-async';
import Navbar from 'src/navbar/Navbar';
import { Alert } from 'react-bootstrap';
import { AlertOctagon } from 'react-feather';

export function NotFoundAuthedPage () {
  return (
    <Main flush className="position-relative">
      <Navbar />
      <Helmet title="Sidan hittades inte" />
      <div>
        <Alert className="d-flex gap-1 m-3 p-2 align-self-start align-items-center text-danger" variant="danger">
          <AlertOctagon />
          Den här sidan finns inte.
        </Alert>
      </div>
    </Main>
  );
}

export function NotFoundPage () {
  return (
    <>
      <Helmet title="Sidan hittades inte" />
      <div>
        <Alert className="d-flex gap-2 m-3 p-2 align-self-center align-items-center text-danger" variant="danger">
          <AlertOctagon size={24} />
          Den här sidan finns inte.
        </Alert>
      </div>
    </>
  );
}

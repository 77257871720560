import React, {useState} from 'react';
import { Modal } from 'react-bootstrap';
import ButtonPromiseSpinner from 'src/spinners/ButtonPromiseSpinner';
import { ErrorAlertCentered } from 'src/alerts/ErrorAlert';

interface ConfirmActionModalProps {
  confirmMessage?: React.ReactNode;
  declineMessage?: React.ReactNode;
  onConfirm: () => (void | Promise<any>);
  onDecline?: () => (void | Promise<any>);
  onHide?: () => void;
  message: React.ReactNode;
  show: boolean;
}

export default function ConfirmActionModal (props: ConfirmActionModalProps) {
  const {
    show,
    confirmMessage = 'Ja',
    declineMessage = 'Nej',
    onConfirm,
    onDecline,
    onHide,
    message,
  } = props;


  const [error, setError] = useState<Error | undefined>();

  const onError = err => {
    setError(err);
  };

  const onClickConfirm = () => {
    const result = onConfirm();
    if (result instanceof Promise) return result.catch(onError);
    return Promise.resolve();
  };

  const onClickDecline = () => {
    if (!onDecline && onHide) {
      onHide();
    } else if (onDecline) {
      const result = onDecline();
      if (result instanceof Promise) return result.catch(onError);
    }
    return Promise.resolve();
  };

  return (
    <Modal show={show} onHide={onHide || onDecline}>
      <Modal.Body className="text-center m-2">
        {typeof message === 'string' ? (
          <p>
            <strong>
              {message}
            </strong>
          </p>
        ) : message}
        <ErrorAlertCentered error={error} />
        <div className="d-flex justify-content-center gap-2">
          <ButtonPromiseSpinner variant="success" onClick={onClickConfirm}>
            {confirmMessage}
          </ButtonPromiseSpinner>{' '}
          <ButtonPromiseSpinner variant="danger" onClick={onClickDecline}>
            {declineMessage}
          </ButtonPromiseSpinner>
        </div>
      </Modal.Body>
    </Modal>
  );
}

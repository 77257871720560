import React from 'react';
import { WidgetListCard } from 'src/cards/CardHelpers';
import { WidgetGroups, WidgetList } from 'src/widgets/types';
import { useQuery } from '@tanstack/react-query';
import { WidgetLoader, WidgetTimeline } from 'src/widgets/CommonWidgets';
import UserAnchor from 'src/user/UserAnchor';
import { UcRow } from 'shared/types/uc';
import { UcLogRowWithRelations } from 'shared/types/uc_log';
import * as ucLogFilters from 'shared/filter/uc_log';
import { Link } from 'react-router-dom';

interface UcWidgetProps {
  uc: UcRow;
}

export const widgetGroups: WidgetGroups = [
  {id: 'properties', title: 'Egenskaper'},
];

export const allWidgets: WidgetList = {
  log: {
    id: 'log',
    Component: Log,
    title: 'Logg',
    group: 'properties',
  },
};

export function Log (props: UcWidgetProps) {
  const { uc } = props;

  const query = useQuery<UcLogRowWithRelations[], Error>({
    queryKey: [`/uc/${uc.id}/log`, {params: {limit: 5}}],
  });

  return (
    <WidgetListCard {...props}>
      <WidgetLoader isLoading={query.isLoading} error={query.error}>
        {query.isSuccess && (
          <WidgetTimeline
            className="m-3"
            list={query.data}
            description={ucLogFilters.description}
            by={item => item.user_id ? <UserAnchor user={item.User} /> : 'System'}
          />
        )}
        <div className="d-flex justify-content-center mb-2 pt-2 border-top">
          <Link className="btn btn-outline-secondary btn-sm" to={`/uc/${uc.id}/log`}>
            Visa hela loggen
          </Link>
        </div>
      </WidgetLoader>
    </WidgetListCard>
  );
}

import React from 'react';
import classNames from 'classnames';
import { NumberFormatBase } from 'react-number-format';

// TODO
// 1. Context for setting currency etc?

export default function CurrencyInput (props) {
  const format = getFormatCurrency();
  return (
    <NumberFormatBase
      {...props}
      format={format}
    />
  );
}

export function CurrencyFormControl (props) {
  const { className, ...otherProps } = props;
  return (
    <CurrencyInput
      className={classNames('form-control', className)}
      {...otherProps}
    />
  );
}

function getFormatCurrency () {
  return numStr => {
    if (numStr === '') return '';
    return new Intl.NumberFormat('sv-SE', {
      style: 'currency',
      currency: 'SEK',
      maximumFractionDigits: 0,
      useGrouping: true,
    }).format(numStr);
  };
}

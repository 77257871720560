import React from 'react';
import { useFormikContext } from 'formik';
import ButtonSpinner, { ButtonSpinnerProps } from 'src/spinners/ButtonSpinner';

interface FormikSubmitButtonProps extends ButtonSpinnerProps {

}

export default function FormikSubmitButton (props: FormikSubmitButtonProps) {
  const { ...restOfProps } = props;
  const formProps = useFormikContext();
  return (
    <ButtonSpinner
      type="submit"
      className="rounded"
      variant="success"
      {...restOfProps}
      isLoading={formProps.isSubmitting}
      disabled={!formProps.isValid || formProps.isSubmitting || !formProps.dirty}
    >
      Spara
    </ButtonSpinner>
  );
}

import React from 'react';
import ModalAnchor, {ModalAnchorProps} from 'src/anchors/ModalAnchor';
import WorkTriggerTemplateEditForm from 'src/workTriggerTemplate/WorkTriggerTemplateEditForm';
import { Name, StatusLabel } from 'src/workTriggerTemplate/WorkTriggerTemplateFormatters';
import { WorkTriggerTemplateRow } from 'shared/types/work_trigger_template';

interface WorkTriggerTemplateAnchorProps extends ModalAnchorProps {
  workTriggerTemplate: WorkTriggerTemplateRow;
  onlyId?: boolean;
}

export default function WorkTriggerTemplateAnchor (props: WorkTriggerTemplateAnchorProps) {
  const { workTriggerTemplate, onlyId, ...restOfProps } = props;
  return (
    <ModalAnchor
      {...restOfProps}
      title={<Name onlyId={onlyId} value={workTriggerTemplate} />}
      name={(
        <span className="d-flex gap-2 align-items-center">
          <span>Triggermall</span>
          <span className="small">
            <Name idShort={false} onlyId={onlyId} value={workTriggerTemplate} />
          </span>
          <StatusLabel value={workTriggerTemplate.is_active} short />
        </span>
      )}
      modalBodyClassName="p-0"
    >
      <WorkTriggerTemplateEditForm workTriggerTemplateId={workTriggerTemplate.id} />
    </ModalAnchor>
  );
}

import React from 'react';
import EventEmitterContext from 'src/contexts/EventEmitterContext';

export default function useEventSubscribe <EventType> (eventName: string, callback: (ev: EventType) => void) {
  const eventEmitter = React.useContext(EventEmitterContext);

  React.useEffect(() => {
    eventEmitter.on(eventName, callback);
    return () => {
      eventEmitter.off(eventName, callback);
    };
  }, [eventName, eventEmitter, callback]);
}

import React from 'react';
import classNames from 'classnames';
import { ButtonVariant } from 'react-bootstrap/esm/types';
import { Link, LinkProps } from 'react-router-dom';

interface LinkButtonProps extends LinkProps {
  variant?: ButtonVariant;
  size?: 'sm' | 'lg';
}

export default function LinkButton (props: LinkButtonProps) {
  const {
    variant = 'primary',
    size,
    className:outerClassName,
    ...linkProps
  } = props;
  const className = classNames(outerClassName, 'btn', `btn-${variant}`, size ? `btn-${size}` : '');
  return (
    <Link {...linkProps} className={className} />
  );
}

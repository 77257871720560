import React from 'react';
import { Spinner } from 'react-bootstrap';

const containerStyles: React.CSSProperties = {
  position: 'absolute',
  left: '0',
  right: '0',
  top: '0',
  bottom: '0',
  background: 'rgba(255, 255, 255, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px',
  zIndex: '10',
};

export default function OverlaySpinner (props) {
  const { isLoading, ...restOfProps } = props;
  if (!isLoading) return null;
  return (
    <div {...restOfProps} style={containerStyles}>
      <Spinner animation="border" variant="default" />
    </div>
  );
}

export function OverlaySpinnerContainer (props: React.PropsWithChildren) {
  const { children } = props;
  return (
    <div className="position-relative overlay-spinner-container">
      {children}
    </div>
  );
}

import React from 'react';
import { Spinner } from 'react-bootstrap';
import classNames from 'classnames';

interface BlockSpinnerProps {
  isLoading: boolean;
  center?: boolean;
  className?: string;
}

export default function BlockSpinner (props: BlockSpinnerProps) {
  const { isLoading, center, className } = props;
  if (!isLoading) return null;
  return (
    <div className={classNames(className, 'd-flex align-items-center', {'justify-content-center': center})}>
      <Spinner className="me-2" animation="border" variant="default" size="sm" />
      <span className="text-muted">Laddar...</span>
    </div>
  );
}


/**
 * WARNING: This file is used clientside
 */

const { fieldChange } = require('shared/filter/log');
const { status } = require('shared/filter/application');
const { indexColumnName, indexField } = require('shared/filter/application');

exports.description = function (log) {
  const { data = {} } = log;
  switch (data.type) {
    default: return JSON.stringify(data);
    case 'status_changed': return `Status ändrad till "${status(data.to_status)}"`;
    case 'created': return 'Ansökan skapad';
    case 'restarted':return 'Ansökan återstartad';
    case 'field_changed':
      return fieldChange(data, indexColumnName, fieldValueFilter);
    case 'adservice_callback_highestBid':
      return 'Återkoppling till Adservice med högsta bud';
    case 'adtraction_callback_highestBid':
      return 'Återkoppling till Adtraction med högsta bud';
    case 'adtraction_callback_newApplication':
      return 'Återkoppling till Adtraction med ny ansökan';
    case 'google_analyticsCallback_highestBid':
      return 'Återkoppling till Google Analytics med högsta bud';
    case 'google_applicationDisbursedCallback':
      return 'Återkoppling till GA med ersättning';
    case 'google_applicationBidCallback':
      return 'Återkoppling till GA med högsta bud';
    case 'tryggsam_submitLead_result': {
      const { tryggsam_code, tryggsam_reason } = data;
      return `Lead skickad till Tryggsam med resultat ${tryggsam_code} / "${tryggsam_reason}"`;
    }
    case 'accepted_process_disconnected': {
      const msg = 'Accepterad process bortkopplad';
      if (data.bank_id) return `${msg} (${data.bank_id})`;
      return msg;
    }
  }
};

function fieldValueFilter (field, value) {
  const mockApplication = {[field]: value};
  return indexField(mockApplication, field);
}

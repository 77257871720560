/**
 * WARNING: This file is used clientside
 */
const getter = require('shared/get');

exports.description = function (log) {
  const data = getter(log, 'data', {}) || {};
  switch (getter(log, 'data.type')) {
    default: return JSON.stringify(data);
    case 'created': return 'Kreditupplysning skapad';
  }
};

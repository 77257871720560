import React from 'react';
import OverlaySpinner from 'src/spinners/OverlaySpinner';
import ErrorAlert from 'src/alerts/ErrorAlert';
import { CardTitleIcon } from 'src/cards/CardHelpers';
import {RefreshObjectButton} from 'src/buttons/IconButtons';
import LinkButton from 'src/buttons/LinkButton';
import { useQuery } from '@tanstack/react-query';
import { CustomerConsentRow } from 'shared/types/customer_consent';
import { Card, Table } from 'react-bootstrap';
import { Edit3, Printer } from 'react-feather';
import InspectObjectModalButton from 'src/buttons/InspectObjectModalButton';
import CustomerConsentAnchor from 'src/customerConsent/CustomerConsentAnchor';
import UserAnchor from 'src/user/UserAnchor';
import * as customerConsentFilter from 'shared/filter/customer_consent';
import DateTime from 'src/formatters/DateTime';
import classNames from 'classnames';

interface CustomerConsentListCardProps {
  searchParams: Record<string, any>;
  className?: string;
}

export default function CustomerConsentListCard (props: CustomerConsentListCardProps) {
  const { searchParams, className } = props;

  const query = useQuery<CustomerConsentRow[], Error>({
    queryKey: ['/customer_consent/search', {params: searchParams}],
  });

  const data = query.data ?? [];

  return (
    <Card className={classNames('border', className)}>
      <Card.Header className="border-bottom bg-light">
        <CardTitleIcon
          title="Samtycken"
          Icon={<Edit3 size={18} />}
          spinning={query.isRefetching}
        >
          <RefreshObjectButton
            disabled={query.isRefetching}
            onClick={() => query.refetch()}
          />
        </CardTitleIcon>
      </Card.Header>
      <div className="position-relative table-responsive">
        <ErrorAlert error={query.error} className="mx-3 mt-3 mb-2" />
        <OverlaySpinner isLoading={query.isLoading} />
        <Table striped className="my-0 border-bottom">
          <thead>
            <tr>
              <td>ID</td>
              <th>Användare</th>
              <th>Ämne</th>
              <th>Metod</th>
              <th>Skapad</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {query.isSuccess && data.map(item => (
              <tr key={item.id}>
                <td>
                  <CustomerConsentAnchor consent={item} />
                </td>
                <td>
                  {item.user_id && (
                    <UserAnchor user={{id: item.user_id}} />
                  )}
                </td>
                <td>{item.subject}</td>
                <td>{customerConsentFilter.method(item.method)}</td>
                <td><DateTime value={item.created_at} /></td>
                <td>
                  <div className="d-flex justify-content-end align-items-center gap-1">
                    <LinkButton
                      to={`/customer_consent/${item.id}/print`}
                      size="sm"
                      variant="outline-primary"
                      className="px-1 py-0"
                      target="_blank"
                      title="Öppna utskriftsvänlig sida"
                    >
                      <Printer size={14} />
                    </LinkButton>
                    <InspectObjectModalButton
                      size="sm"
                      object={item}
                    />
                  </div>
                </td>
              </tr>
            ))}
            {!query.data?.length && (
              <tr>
                <td colSpan={6} className="text-center">Det finns inga registrerade samtycken</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </Card>
  );
}

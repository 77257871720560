import React from 'react';
import WidgetList from 'src/widgets/WidgetList';
import { StatusLabel } from  'src/uc/UcFormatters';
import StatusLabelContainer from 'src/components/StatusLabelContainer';
import useWidgetList from 'src/hooks/useWidgetList';
import { allWidgets, widgetGroups } from 'src/uc/UcWidgets';
import BlockSpinner from 'src/spinners/BlockSpinner';
import ErrorAlert from 'src/alerts/ErrorAlert';
import { useQuery } from '@tanstack/react-query';
import { UcRow } from 'shared/types/uc';

// TODO finish

interface UcQuicklookProps {
  id: string;
}

const UcQuicklook: React.FC<UcQuicklookProps> = React.memo(function UcQuicklook (props: UcQuicklookProps) {
  const { id } = props;
  const query = useQuery<UcRow, Error>({
    queryKey: [`/uc/${id}`],
  });

  const [state, dispatch] = useWidgetList('ucQuicklook', [
    'log',
  ]);

  const uc = query?.data;

  return (
    <div className="position-relative">
      <ErrorAlert error={query.error} className="mx-3 my-0" />
      <BlockSpinner isLoading={query.isLoading} className="mx-3" />
      {uc && (
        <>
          <StatusLabelContainer>
            <StatusLabel status={uc.status} />
          </StatusLabelContainer>
          <WidgetList
            allWidgets={allWidgets}
            widgetGroups={widgetGroups}
            state={state}
            dispatch={dispatch}
            widgetProps={{uc}}
          />
        </>
      )}
    </div>
  );
});
export default UcQuicklook;

import React from 'react';
import BlockSpinner from 'src/spinners/BlockSpinner';
import ErrorAlert from 'src/alerts/ErrorAlert';
import { useQuery } from '@tanstack/react-query';
import { StatusLabel } from  'src/user/UserFormatters';
import StatusLabelContainer from 'src/components/StatusLabelContainer';
import WidgetList from 'src/widgets/WidgetList';
import { allWidgets, widgetGroups } from 'src/user/UserWidgets';
import useWidgetList from 'src/hooks/useWidgetList';
import { UserRow } from 'shared/types/user';

interface UserQuicklookProps {
  id: string;
}

const UserQuicklook: React.FC<UserQuicklookProps> = React.memo(function UserQuicklook (props: UserQuicklookProps) {
  const { id } = props;
  const query = useQuery<UserRow, Error>({
    queryKey: [`/user/${id}`],
  });

  const [state, dispatch] = useWidgetList('userQuicklook', [
    'log',
  ]);

  const user = query?.data;

  return (
    <div className="position-relative">
      <ErrorAlert error={query.error} className="mx-3 my-0" />
      <BlockSpinner isLoading={query.isLoading} className="mx-3" center />
      {user && (
        <>
          <StatusLabelContainer>
            <StatusLabel value={user.is_active} />
          </StatusLabelContainer>
          <WidgetList
            allWidgets={allWidgets}
            widgetGroups={widgetGroups}
            state={state}
            dispatch={dispatch}
            widgetProps={{user}}
          />
        </>
      )}
    </div>
  );
});
export default UserQuicklook;

import React from 'react';

interface IPepQueryModalBody {
  id: string;
}

export default function PepQueryModalBody (props: IPepQueryModalBody) {
  return (
    <div>pep query modal body here</div>
  );
}

import React from 'react';
import { useField } from 'formik';
import {
  Form,
  FormCheckProps,
  FormControlProps,
  FormSelectProps,
  InputGroup,
} from 'react-bootstrap';
import { FormCheckType } from 'react-bootstrap/esm/FormCheck';
import { inputPropsToFormikValidate, selectPropsToFormikValidate } from 'src/utils/form';

interface FormikFormGroupControlProps extends FormControlProps {
  label?: React.ReactNode;
  className?: string;
  name: string;
  type?: string;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  step?: number | string;
  min?: number | string;
  max?: number | string;
  rows?: number | string;
  helpText?: React.ReactNode;
}

// combines react-bootstrap Form.Group, Form.Feedback, Form.Control and formik Field
// must not always be used but can be useful for setting up a generic form with error validation
// think CAREFULLY before extending this component, it might be better to create a custom one
export const FormikFormGroupControl: React.FC<FormikFormGroupControlProps> = React.memo(function FormikFormGroupControl (props: FormikFormGroupControlProps) {
  const { name, type, className, label, helpText, ...restOfProps } = props;

  const [field, meta] = useField({
    name,
    type,
    validate: inputPropsToFormikValidate(props),
  });

  const id = React.useId();

  return (
    <Form.Group className={className}>
      {label && (
        <Form.Label className="mb-1 mt-3" htmlFor={id}>
          {label}
        </Form.Label>
      )}
      <Form.Control
        {...restOfProps}
        {...field}
        id={id}
        name={name}
        type={type}
        isInvalid={Boolean(meta.error)}
      />
      <Form.Control.Feedback type="invalid">
        {meta.error}
      </Form.Control.Feedback>
      {helpText && (
        <Form.Text>{helpText}</Form.Text>
      )}
    </Form.Group>
  );
});

interface FormikFormGroupSelectProps extends FormSelectProps, React.PropsWithChildren {
  label?: React.ReactNode;
  className?: string;
  name: string;
  required?: boolean;
}
// see note on FormikFormGroupControl
export const FormikFormGroupSelect: React.FC<FormikFormGroupSelectProps> = React.memo(function FormikFormGroupSelect (props: FormikFormGroupSelectProps) {
  const { name, children, className, label, ...restOfProps } = props;

  const [field, meta] = useField({
    name,
    validate: selectPropsToFormikValidate(props),
  });

  const id = React.useId();

  return (
    <Form.Group className={className}>
      {label && (
        <Form.Label className="mb-1 mt-3" htmlFor={id}>
          {label}
        </Form.Label>
      )}
      <Form.Select
        {...restOfProps}
        {...field}
        id={id}
        name={name}
        isInvalid={Boolean(meta.error)}
      >
        {children}
      </Form.Select>
      <Form.Control.Feedback type="invalid">
        {meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
});

interface FormikFormGroupCheckProps extends FormCheckProps {
  name: string;
  value?: string;
  type?: FormCheckType;
  className?: string;
}
// see note on FormikFormGroupControl
export const FormikFormGroupCheck: React.FC<FormikFormGroupCheckProps> = React.memo(function FormikFormGroupCheck (props: FormikFormGroupCheckProps) {
  const { name, value, type, className, ...restOfProps } = props;

  const [field, meta] = useField({
    name,
    value,
    type,
  });

  const id = React.useId();

  return (
    <Form.Group className={className}>
      <Form.Check
        {...restOfProps}
        {...field}
        type={type}
        id={id}
        name={name}
        isInvalid={Boolean(meta.error)}
      />
      <Form.Control.Feedback type="invalid">
        {meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
});

interface FormikFormInputGroupControlProps extends FormikFormGroupControlProps {
  before?: React.ReactNode;
  after?: React.ReactNode;
  className?: string;
}
// see note on FormikFormGroupControl
export const FormikFormInputGroupControl: React.FC<FormikFormInputGroupControlProps> = React.memo(function FormikFormInputGroupControl (props: FormikFormInputGroupControlProps) {
  const { name, label, className, type, before, after, ...restOfProps } = props;

  const [field, meta] = useField({
    name,
    type,
    validate: inputPropsToFormikValidate(props),
  });

  const id = React.useId();

  return (
    <Form.Group className={className}>
      {label && (
        <Form.Label className="mb-1 mt-3" htmlFor={id}>
          {label}
        </Form.Label>
      )}
      <InputGroup hasValidation>
        {before}
        <Form.Control
          {...restOfProps}
          {...field}
          type={type}
          id={id}
          name={name}
          isInvalid={Boolean(meta.error)}
        />
        {after}
        <Form.Control.Feedback type="invalid">
          {meta.error}
        </Form.Control.Feedback>
      </InputGroup>
    </Form.Group>
  );
});

import React from 'react';
import ButtonSpinner, { ButtonSpinnerProps } from 'src/spinners/ButtonSpinner';
import { useMutation } from '@tanstack/react-query';
import useNotyf from 'src/hooks/useNotyf';
import * as api from 'src/api';
import { errorToMessage } from 'src/utils/error';
import { AxiosRequestConfig } from 'axios';

export interface ExecuteButtonProps extends ButtonSpinnerProps {
  url: string;
  vars?: Record<string, any>;
  method?: AxiosRequestConfig['method'];
  onSuccess?: (result: unknown) => void;
}

export default function ExecuteButton (props: ExecuteButtonProps) {
  const {
    url,
    vars = {},
    children,
    disabled,
    onSuccess,
    method = 'POST',
    ...restOfProps
  } = props;

  const notyf = useNotyf();

  const mutation = useMutation<unknown, Error, Record<string, any>>({
    mutationFn: vars => api.request({
      url,
      method,
      data: vars,
    }),
    onSuccess,
    onError: err => {
      notyf.error(errorToMessage(err));
    },
  });

  const onClick = () => {
    mutation.mutateAsync(vars);
  };

  return (
    <ButtonSpinner
      {...restOfProps}
      isLoading={mutation.isPending}
      disabled={disabled || mutation.isPending}
      onClick={onClick}
    >
      {children}
    </ButtonSpinner>
  );
}

import React from 'react';
import ModalAnchor from 'src/anchors/ModalAnchor';
import EmailModalBody from 'src/modals/EmailModalBody';
import { Name } from 'src/email/EmailFormatters';

export default function EmailAnchor (props) {
  const { email, ...restOfProps } = props;
  return (
    <ModalAnchor
      {...restOfProps}
      title={<Name value={email} />}
      name="E-post"
    >
      <EmailModalBody id={email.id} />
    </ModalAnchor>
  );
}

import React from 'react';
import { Modal } from 'react-bootstrap';
import TaskForm from 'src/task/TaskForm';

interface IEditTaskModal {
  taskId: string;
  show: boolean;
  onHide: () => void;
}

const EditTaskModal: React.FC<IEditTaskModal> = React.memo(function EditTaskModal (props: IEditTaskModal) {
  const {
    taskId,
    show,
    onHide,
  } = props;

  return (
    <Modal show={show} onHide={onHide} >
      <Modal.Header>
        <Modal.Title>
          Redigera återkomst {taskId}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="m-2">
        <TaskForm />
      </Modal.Body>
    </Modal>
  );
});
export default EditTaskModal;

const getter = require('shared/get');
const date = require('shared/filter/date');
const applicationFilter = require('shared/filter/application');
const translations = require('shared/translations');

/**
 * WARNING: This file is used clientside
 */

// filters column names for prospects table
exports.indexColumnName = function (columnKey) {
  switch (columnKey) {
    default: return columnKey;
    case 'created_at': return 'Skapad';
    case 'created_at_relative': return 'Skapad';
    case 'created_by_channel': return 'Kanal';
    case 'email': return 'E-postadress';
    case 'email_blocked': return 'E-postblockering';
    case 'id': return 'ID';
    case 'loan_new': return 'Nylån';
    case 'loan_now': return 'Lån att lösa';
    case 'loan_purpose': return 'Lånesyfte';
    case 'submitter_ip_address': return 'IP-adress';
    case 'tracking_code_adtraction': return 'Adtraction-kod';
    case 'tracking_code_adservice': return 'Adservice-kod';
    case 'tracking_code_ga_client_id': return 'Analytics-kod';
    case 'tracking_code_gclid': return 'Adwordskod';
    case 'repayment_years': return 'Återbetalningstid';
    case 'result': return 'Avslutskod';
    case 'ssn': return 'Personnummer';
    case 'telephone': return 'Telefonnummer';
    case 'telephone_blocked': return 'Telefonnummerblockering';
    case 'total_loan_amount': return 'Total lånesumma';
    case 'last_application_id': return 'Senaste ans. ID';
    case 'last_application_status': return 'Senaste ans. status';
    case 'last_application_created_at': return 'Senaste ans. skapad';
    case 'actions': return 'Åtgärder';
  }
};

// filters prospects to values by column key for prospect table
exports.indexField = function (prospect, fieldName, options = {}) {
  const { forExport = false } = options;
  const get = (...args) => getter(prospect, ...args);
  switch (fieldName) {
    default: return get(fieldName);
    case 'created_at':
    case 'updated_at':
      return forExport ? date.dayTime(get(fieldName)) : get(fieldName);
    case 'loan_new': return (get('data.loan.amount.new') || 0) + ' kr';
    case 'loan_now': return (get('data.loan.amount.now') || 0) + ' kr';
    case 'loan_purpose': return translations.application.loan_purposes(get('data.loan.purpose'));
    case 'submitter_ip_address': return get('metadata.submitter_ip_address');
    case 'tracking_code_adtraction': return get('metadata.tracking_code_adtraction');
    case 'tracking_code_adservice': return get('metadata.tracking_code_adservice');
    case 'tracking_code_ga_client_id': return get('metadata.tracking_code_ga_client_id');
    case 'tracking_code_gclid': return get('metadata.tracking_code_gclid');
    case 'repayment_years': return (get('data.loan.repayment_years') || 0) + ' år';
    case 'ssn': return get('ssn');
    case 'result': return exports.result(get('result'));
    case 'last_application_id': return get('Customer.LastApplication.id') || '';
    case 'last_application_status': {
      const field = get('Customer.LastApplication.status');
      const hasAcceptedProcess = !!get('Customer.LastApplication.accepted_process_id');
      return field ? applicationFilter.statusAccepted(field, hasAcceptedProcess) : '';
    }
    case 'last_application_created_at': {
      const field = get('Customer.LastApplication.created_at');
      return field ? date.dayTime(field) : '';
    }
    case 'total_loan_amount': {
      return ((get('data.loan.amount.new') || 0) + (get('data.loan.amount.now') || 0)) + ' kr';
    }
  }
};

exports.result = function (result) {
  switch (result) {
    default: return result;
    case 'not_submitted_yet': return 'Ej inskickad';
    case 'halted_active_application': return 'Pågående ansökan';
    case 'halted_timeout_application': return 'För nära i tid';
    case 'application_created': return 'Ansökan skapad';
    case 'halted_blocked': return 'Kund spärrad';
    case 'halted_flagged': return 'Kund flaggad';
    case 'halted_other': return 'Blockerad av annan orsak';
  }
};

exports.resultLabelClassName = function (result) {
  switch (result) {
    default: return 'status-label-grey';
    case 'not_submitted_yet': return 'status-label-green';
    case 'halted_active_application': return 'status-label-yellow';
    case 'halted_timeout_application': return 'status-label-yellow';
    case 'application_created': return 'status-label-blue';
    case 'halted_blocked': return 'status-label-red';
    case 'halted_flagged': return 'status-label-red';
    case 'halted_other': return 'status-label-red';
  }
};

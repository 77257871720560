

/**
 * WARNING: This file is used clientside
 */

const getter = require('shared/get');

exports.fieldName = function (fieldName) {
  return exports.indexColumnName(fieldName);
};

exports.indexColumnName = function (columnName) {
  switch (columnName) {
    default: return columnName;
    case '_select': return 'Markera';
    case 'actions': return 'Åtgärder';
    case 'application_id': return 'Ansökan';
    case 'customer_cloud_insurance_id': return 'Försäkring';
    case 'body': return 'Brödtext';
    case 'created_at': return 'Skapad';
    case 'created_at_relative': return 'Skapad';
    case 'foreign_id': return 'Tillhörande objekt';
    case 'gsm': return 'GSM';
    case 'id': return 'ID';
    case 'pdf': return 'PDF';
    case 'status': return 'Status';
    case 'status_at': return 'Statustid';
    case 'status_at_relative': return 'Statustid';
    case 'template_id': return 'Mall';
    case 'updated_at': return 'Uppdaterad';
    case 'updated_at_relative': return 'Uppdaterad';
    case 'user_id': return 'Ägare';
  }
};

exports.status = function (status) {
  switch (status) {
    default: return status;
    case 'new': return 'Nyskapad';
    case 'error': return 'Fel';
    case 'queued-send': return 'Köad för skickning';
    case 'sent': return 'Skickad';
    case 'delivered': return 'Levererat';
  }
};

exports.indexField = function (sms, columnName, options = {}) {
  const get = (...args) => getter(sms, ...args);
  switch (columnName) {
    default: return get(columnName);
    case 'status': return exports.status(get('status'));
  }
};

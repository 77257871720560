import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ErrorType, errorToMessage } from 'src/utils/error';

interface IErrorModal {
  error?: ErrorType | null;
  title?: string;
}

export default function ErrorModal (props: IErrorModal) {
  const { error, title } = props;
  const [show, setShow] = React.useState(true);
  if (!error) return null;
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
    >
      {title && (
        <Modal.Header className="justify-content-center">
          <h3 className="mb-0">
            {title}
          </h3>
        </Modal.Header>
      )}
      <Modal.Body className="text-center m-3">
        <p className="mb-0">
          {errorToMessage(error)}
        </p>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <Button variant="outline-danger" onClick={() => setShow(false)}>
          Stäng
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

import React from 'react';
import { useDebounce } from 'use-debounce'; // TODO should be replaced, this hook makes the component rerender with the interval
import * as api from 'src/api';
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import useAuth from 'src/hooks/useAuth';
import { PermissionCheckFunction } from 'src/contexts/AuthContext';
import {
  StatusLabel as ApplicationStatusLabel,
  Ordinal,
} from 'src/application/ApplicationFormatters';
import {
  StatusLabel as BankProcessStatusLabel,
  Title as BankProcessTitle,
} from 'src/bankProcess/BankProcessFormatters';
import { Link, useNavigate } from 'react-router-dom';
import {
  Button,
  Form,
  InputGroup,
  Spinner,
} from 'react-bootstrap';
import { X } from 'react-feather';
import classNames from 'classnames';
import { preventDefaultSubmit } from 'src/misc';

interface INavbarQuickSearch {
  className?: string;
}

interface ApplicationRowResult {
  id: string;
  created_at: string;
  ordinal: number;
  status: string;
  has_accepted_process: boolean;
}

interface BankProcessRowResult {
  id: string;
  created_at: string;
  status: string;
  application_id: string;
  bank_id: string;
}

interface IQuickSearchDataItem {
  id: string;
  name: string;
  BankProcesses?: BankProcessRowResult[];
  Applications?: ApplicationRowResult[];
  ApplicationCos?: ApplicationRowResult[];
}

type TQuickSearchData = IQuickSearchDataItem[];

interface IQuickSearchQueryParams  {
  field: string;
  query: string;
}

const NavbarQuickSearch: React.FC<INavbarQuickSearch> = React.memo(function NavbarQuickSearch (props: INavbarQuickSearch) {
  const { className } = props;

  const { hasAllPermissions } = useAuth();

  const [query, setQuery] = React.useState<string>('');
  const [field, setField] = React.useState<string>('name');
  const [showResults, setShowResults] = React.useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = React.useState<number>(0);

  const [inputWidth, setInputWidth] = React.useState<number>(0);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const onChangeQuery = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = ev.target;
    setQuery(value);
    setField(deriveField(value, hasAllPermissions));
    if (inputWidth === 0 && value) {
      const width = inputRef.current?.offsetWidth ?? 0;
      setInputWidth(width);
    } else if (!value) {
      setInputWidth(0);
    }
  };

  const resetQuery = () => {
    setQuery('');
    setShowResults(false);
    setSelectedIndex(0);
    setInputWidth(0);
  };

  const navigate = useNavigate();

  const onKeyDownInput = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    switch (ev.key) {
      default: return;
      case 'Escape':
        resetQuery();
        break;
      case 'Enter': {
        const chosenResult = results[selectedIndex];
        if (!chosenResult) return;
        const { id:customerId } = chosenResult;
        navigate(`/customer/${customerId}`);
      } break;
      case 'ArrowUp':
        setSelectedIndex(Math.max(0, selectedIndex - 1));
        break;
      case 'ArrowDown':
        setSelectedIndex(Math.min(queryObject?.data?.length ?? 0, selectedIndex + 1));
        break;
    }
  };

  const onClickReset = () => {
    resetQuery();
  };


  const [queryParamsDebounced] = useDebounce<IQuickSearchQueryParams>({query, field}, 500);
  const queryObject = useQuery<TQuickSearchData>({
    placeholderData: keepPreviousData,
    queryKey: ['NavbarQuickSearch', queryParamsDebounced],
    queryFn: ({signal}) => {
      const { query, field } = queryParamsDebounced;
      if (!query || !field) return [];
      setShowResults(true);
      const filteredQuery = filterQuery(field, query);
      return api.request({
        signal,
        method: 'get',
        params: {field, query: filteredQuery},
        url: '/misc/quicksearch',
      });
    },
  });

  const hasQuery = query.length > 0;
  const results = queryObject?.data ?? [];

  const theClassName = classNames(
    className,
    'quicksearch',
    {
      'show-results': showResults,
      'has-query': hasQuery,
    },
  );

  return (
    <Form className={theClassName} onSubmit={preventDefaultSubmit}>
      <InputGroup className="input">
        <InputGroup.Text className="bg-white">
          {formatField(field)}
        </InputGroup.Text>
        <Form.Control
          name="query"
          placeholder="Snabbsök"
          onChange={onChangeQuery}
          onKeyDown={onKeyDownInput}
          value={query}
          ref={inputRef}
          style={{width: inputWidth > 0 ? `calc(${inputWidth}px - 27px)` : undefined}}
        />
        {hasQuery && (
          <Button variant="danger" className="p-1" onClick={onClickReset}>
            <X size={19} />
          </Button>
        )}
      </InputGroup>
      {showResults ? (
        <div className="results border-start border-end border-bottom px-2 pt-2 bg-white">
          {queryObject.isFetching && (
            <div className="d-flex justify-content-center align-items-center mb-2">
              <Spinner size="sm" />
            </div>
          )}
          {(results.length ?? 0) > 0 && (
            <ol className="list-unstyled m-0 p-0">
              {results.map((item, index) => (
                <QuerySearchItem
                  className="mb-2"
                  key={item.id}
                  item={item}
                  selected={selectedIndex === index}
                />
              ))}
            </ol>
          )}
          {!results.length && !queryObject.isFetching && (
            <div className="text-center mb-2">
              Inga sökresultat
            </div>
          )}
        </div>
      ) : null}
    </Form>
  );
});
export default NavbarQuickSearch;

interface IQuerySearchItem {
  className?: string;
  item: IQuickSearchDataItem;
  selected: boolean;
}

function QuerySearchItem (props: IQuerySearchItem) {
  const bankProcess = props.item.BankProcesses?.[0];
  if (bankProcess) {
    return (
      <QuerySearchItemBankProcess
        {...props}
        bankProcess={bankProcess}
      />
    );
  }

  const applicationToShow = getApplicationToShow(props.item);
  if (applicationToShow) {
    return (
      <QuerySearchItemApplication
        {...props}
        application={applicationToShow.application}
        isCoApplicant={applicationToShow.isCo}
      />
    );
  }

  const { selected, className, item } = props;
  const { name:customerName, id:customerId } = item;
  const classes = classNames(className, 'border rounded', {'bg-light': selected});
  return (
    <li className={classes}>
      <Link
        to={`/customer/${customerId}`}
        className={classNames('customer-link p-1 text-decoration-none text-dark', {'bg-light': selected})}
      >
        {customerName}
      </Link>
    </li>
  );
}

interface IQuerySearchItemBankProcess extends IQuerySearchItem {
  bankProcess: BankProcessRowResult;
}

function QuerySearchItemBankProcess (props: IQuerySearchItemBankProcess) {
  const { selected, className, item, bankProcess } = props;
  const { id:customerId, name:customerName } = item;
  const { id:bankProcessId, application_id:applicationId, status } = bankProcess;
  return (
    <li className={classNames(className)}>
      <Link
        to={`/customer/${customerId}`}
        className={classNames('customer-link border rounded-start p-1 text-decoration-none text-dark', {'bg-light': selected})}
      >
        {customerName} / <BankProcessTitle value={bankProcess} />
      </Link>
      <Link
        className="d-flex align-items-stretch text-decoration-none bank-process-link"
        to={`/application/${applicationId}/bank_process/${bankProcessId}`}
      >
        <BankProcessStatusLabel
          className="d-flex align-items-center justify-content-center"
          value={status}
        />
      </Link>
    </li>
  );
}

interface IQuerySearchItemApplication extends IQuerySearchItem {
  application: ApplicationRowResult;
  isCoApplicant: boolean;
}

function QuerySearchItemApplication (props: IQuerySearchItemApplication) {
  const { selected, className, item, application } = props;
  const { id:customerId, name:customerName } = item;
  const { id:applicationId, ordinal, status, has_accepted_process } = application;
  return (
    <li className={classNames(className)}>
      <Link
        to={`/customer/${customerId}`}
        className={classNames('customer-link border rounded-start p-1 text-decoration-none text-dark', {'bg-light': selected})}
      >
        {customerName} / <Ordinal value={ordinal} />
      </Link>
      <Link
        className="d-flex align-items-stretch text-decoration-none application-link"
        to={`/application/${applicationId}`}
      >
        <ApplicationStatusLabel
          className="d-flex align-items-center justify-content-center"
          value={status}
          hasAcceptedProcess={has_accepted_process}
        />
      </Link>
    </li>
  );
}

type TGetApplicationToShow = null | {application: ApplicationRowResult; isCo: boolean; };

function getApplicationToShow (item: IQuickSearchDataItem): TGetApplicationToShow {
  const main = item.Applications?.[0];
  const co = item.Applications?.[1];
  if (main && co) {
    const mainDate = new Date(main.created_at);
    const coDate = new Date(co.created_at);
    if (coDate > mainDate) return {application: co, isCo: true};
    return {application: main, isCo: false};
  }
  if (main) return {application: main, isCo: false};
  if (co) return {application: co, isCo: true};
  return null;
}

// establishes which field user wants to search for using their query
function deriveField (query: string, hasAllPermissions: PermissionCheckFunction): string {
  if (!query) return 'name';
  if (hasAllPermissions('misc_quicksearch_process_id')) {
    if (query.toLowerCase().startsWith('pexid:')) return 'process_external_id';
    if (query.toLowerCase().startsWith('pid:')) return 'process_id';
  }
  if (/@/.test(query)) return 'email';
  if (query.length >= 36) return 'application_id';
  if (query.startsWith('0')) return 'telephone';
  if (/^[1-9]/.test(query)) return 'ssn';
  return 'name';
}

function formatField (field: string): string {
  switch (field) {
    default: return 'Namn';
    case 'name': return 'Namn';
    case 'application_id': return 'ID';
    case 'email': return 'E-post';
    case 'telephone': return 'Telefon';
    case 'process_id': return 'Proc. ID';
    case 'process_external_id': return 'Proc. Ex-ID';
    case 'ssn': return 'PNR';
  }
}

function filterQuery (field: string, query: string): string {
  if (['process_id', 'process_external_id'].includes(field)) {
    const match = query.match(/^.+?:(.+)$/);
    return (match[1] || '').trim();
  }
  return (query || '').trim();
}

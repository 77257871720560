

/**
 * WARNING: This file is used clientside
 */

const { day } = require('shared/filter/date');

exports.fieldChange = function (data, fieldNameFilter, fieldValueFilter = defaultFieldValueFilter) {
  const { changes, prev, curr, field } = data;

  if (Array.isArray(changes)) {
    // DEPRECATED: old style with all changes in an array (backwards compatible)
    if (changes.length < 1) return '(Tom uppdatering)';
    return changes.map(change => {
      const { field } = change;
      const fieldName = fieldNameFilter ? fieldNameFilter(field) : field;
      const oldv = fieldValueFilter(field, change.old);
      const newv = fieldValueFilter(field, change.new);
      return `Ändrade "${fieldName}" från "${oldv}" till "${newv}"`;
    }).join('\n');
  }

  const fieldName = fieldNameFilter ? fieldNameFilter(field) : field;
  const hasPrev = 'prev' in data;
  if (!hasPrev) return `${fieldName} ändrat till "${fieldValueFilter(field, curr)}"`;
  return `${fieldName} ändrat från "${fieldValueFilter(field, prev)}" till "${fieldValueFilter(field, curr)}"`;
};

// naturalises the "by" object
exports.by = function (by) {
  if (!by) return 'System';
  const { type, bank_adapter, bank_id, user_id } = by;

  return [
    getType(),
    bank_id && !bank_adapter ? `(${bank_id})` : '',
    bank_adapter ? `(${bank_adapter})` : '',
  ].filter(v => v).join(' ');

  function getType () {
    const ttype = type || user_id || 'system'; // sometimes the type is stored in user_id
    switch (ttype) {
      default: return ttype;
      case 'system': return 'System';
      case 'portal': return 'Portal';
      case 'worker': return 'Worker';
    }
  }
};

// TODO flesh this out
function defaultFieldValueFilter (field, value) {
  switch (field) {
    default: return value || '';
    case 'is_active': return value ? 'Aktiv' : 'Inaktiv';
    case 'created_at':
    case 'closed_at': return day(value);
  }
}


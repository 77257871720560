import React from 'react';
import CustomerAnchor from 'src/customer/CustomerAnchor';
import ApplicationAnchor from 'src/application/ApplicationAnchor';
import UserAnchor from 'src/user/UserAnchor';
import { CustomerRow } from 'shared/types/customer';
import { UserRow } from 'shared/types/user';
import { ApplicationRow } from 'shared/types/application';

export type ObjectTypeAnchorProps = {
  type: 'customer';
  value: null | CustomerRow;
} | {
  type: 'user';
  value: null | UserRow;
} | {
  type: 'application';
  value: null | ApplicationRow;
};

function ObjectTypeAnchor (props: ObjectTypeAnchorProps) {
  const { type, value } = props;
  if (!value) return null;
  if (type === 'customer') return <CustomerAnchor customer={value} />;
  if (type === 'application') return <ApplicationAnchor application={value} />;
  if (type === 'user') return <UserAnchor user={value} />;
  return null;
}
export default React.memo(ObjectTypeAnchor);

import React from 'react';
import classNames from 'classnames';
import { NumberFormatBase } from 'react-number-format';

export default function SuffixNumberInput (props) {
  const { suffix = '', ...otherProps } = props;
  const format = getFormatSuffix(suffix);
  return (
    <NumberFormatBase
      {...otherProps}
      format={format}
    />
  );
}

export function SuffixNumberFormControl (props) {
  const { className, ...otherProps } = props;
  return (
    <SuffixNumberInput
      className={classNames('form-control', className)}
      {...otherProps}
    />
  );
}

function getFormatSuffix (suffix) {
  return numStr => {
    if (numStr === '') return '';
    return `${numStr}${suffix}`;
  };
}

import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Container } from 'react-bootstrap';
import Content from 'src/ui/Content';
import Main from 'src/ui/Main';
import Navbar from 'src/navbar/Navbar';

export default function SettingsPage () {
  return (
    <Main>
      <Navbar />
      <Content>
        <Container fluid className="p-0">
          <Helmet title="Systeminställningar" />
          System settings page
        </Container>
      </Content>
    </Main>
  );
}

import React from 'react';

interface FileRowModalBodyProps {
  id: string;
}

export default function FileModalBody (props: FileRowModalBodyProps) {
  return (
    <div>file modal body here</div>
  );
}

/**
 * WARNING: This file is used clientside
 */

const { fieldChange } = require('shared/filter/log');
const { status } = require('shared/filter/customer');
const { indexColumnName, indexField } = require('shared/filter/customer');
const getter = require('shared/get');

exports.description = function (log) {
  const data = getter(log, 'data', {}) || {};
  switch (getter(log, 'data.type')) {
    default: return JSON.stringify(data);
    case 'created': return 'Kund skapad';
    case 'fieldchange': // <- deprecated name
    case 'field_changed': return fieldChange(data, indexColumnName, fieldValueFilter);
      // pre 2021-12-27 logging:
    case 'user_id_changed': {
      const from = getter(data, 'prev_user_id') || '';
      const to = getter(data, 'curr_user_id') || '';
      return `Ägare ändrad från "${from}" till "${to}"`;
    }
    // pre 2021-12-27 logging:
    case 'status_changed': {
      const from = status(getter(data, 'prev_status'));
      const to = status(getter(data, 'curr_status'));
      return `Status ändrad från "${from}" till "${to}"`;
    }
    case 'crm_portal_login': return 'Inloggning till portal via CRM påbörjad';
    case 'portal_login': return 'Inloggad i portal';
    case 'portal_login_bankid': return 'Inloggad i portal med BankID';
    case 'portal_logout': return 'Utloggad från portal';
    case 'cloudinsurance_created_by_portal': return `Kunden har tecknat försäkring (Cloud) via portalen med kund-ID "${data.cloudinsurance_customer_id}"`;
    case 'cloudinsurance_sync': return `Kund skapad i försäkringssystem (Cloud) med ID "${data.cloudinsurance_customer_id}"`;
    case 'insurance_jaycom_sync': return `Kund skapad i Jaycom försäkringssystem med ID "${data.insurance_jaycom_customer_id}"`;
    case 'email_removed': {
      const email = data.webhook && data.webhook.email ? data.webhook.email : '(okänd)';
      return `E-postadress "${email}" borttagen efter inkommen bounce-callback`;
    }
  }
};

function fieldValueFilter (field, value) {
  const mockCustomer = {[field]: value};
  return indexField(mockCustomer, field);
}

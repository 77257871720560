

/**
 * WARNING: This file is used clientside
 */

exports.reason = function (reasons) {
  if (!reasons || !Array.isArray(reasons)) return '';
  return reasons.map(function (reason) {
    switch (reason) {
      default: return reason;
      case 'required': return 'Värde krävs';
      case 'empty': return 'Värde saknas';
      case 'typeError':
      case 'type': return 'Fel sort';
      case 'min': return 'Värde för litet';
      case 'max': return 'Värde för stort';
      case 'finite': return 'Obundet tal';
      case 'date_outside': return 'Datum utanför intervall';
      case 'date_inside': return 'Datum innanför intervall';
      case 'values': return 'Värde ej i godkänd lista';
      case 'format': return 'Värde har fel format';
      case 'value': return 'Felaktigt värde';
      case 'contains': return 'Värde inte i lista';
      case 'min_length': return 'Värde för kort';
      case 'max_length': return 'Värde för långt';
    }
  }).join(', ');
};

import React from 'react';
import * as api from 'src/api';
import BlockSpinner from 'src/spinners/BlockSpinner';
import ErrorAlert from 'src/alerts/ErrorAlert';
import { useQuery } from '@tanstack/react-query';

// TODO finish

interface IProspectQuicklook {
  id: string;
}

const ProspectQuicklook: React.FC<IProspectQuicklook> = React.memo(function ProspectQuicklook (props: IProspectQuicklook) {
  const { id } = props;
  const query = useQuery({
    queryKey: ['prospect', {id}],
    queryFn: () => api.request({
      url: `/prospect/${id}`,
    }),
  });

  const prospect = query?.data;

  return (
    <div className="position-relative">
      <ErrorAlert error={query.error} className="mx-3 my-0" />
      <BlockSpinner isLoading={query.isLoading} className="mx-3" center />
      {prospect && (
        <>
          <pre className="border m-3 p-3">
            {JSON.stringify(prospect, null, 2)}
          </pre>
        </>
      )}
    </div>
  );
});
export default ProspectQuicklook;

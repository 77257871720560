

/**
 * WARNING: This file is used clientside
 */

const getter = require('shared/get');

exports.fieldName = function (fieldName) {
  return exports.indexColumnName(fieldName);
};

exports.indexColumnName = function (columnName) {
  switch (columnName) {
    default: return columnName;
    case '_select': return 'Markera';
    case 'actions': return 'Åtgärder';
    case 'address': return 'Adress';
    case 'application_id': return 'Ansökan';
    case 'body': return 'Brevtext';
    case 'city': return 'Stad';
    case 'co': return 'C/O';
    case 'created_at': return 'Skapad';
    case 'created_at_relative': return 'Skapad';
    case 'crediflow_series': return 'Crediflow-serie';
    case 'first_name': return 'Förnamn';
    case 'foreign_id': return 'Tillhörande objekt';
    case 'id': return 'ID';
    case 'last_name': return 'Efternamn';
    case 'name': return 'Mottagare';
    case 'pdf': return 'PDF';
    case 'postcode': return 'Postnummer';
    case 'status': return 'Status';
    case 'status_at': return 'Statustid';
    case 'status_at_relative': return 'Statustid';
    case 'street': return 'Stad';
    case 'template': return 'Renderingsmall';
    case 'template_id': return 'Mall';
    case 'updated_at': return 'Uppdaterad';
    case 'updated_at_relative': return 'Uppdaterad';
    case 'user_id': return 'Ägare';
  }
};

exports.status = function (status) {
  switch (status) {
    default: return status;
    case 'new': return 'Nyskapad';
    case 'error': return 'Fel';
    case 'queued-render': return 'Köad för rendering';
    case 'queued-send': return 'Köad för skickning';
    case 'rendered': return 'Renderad';
    case 'sent': return 'Skickad';
  }
};

exports.indexField = function (letter, columnName, options = {}) {
  const get = (...args) => getter(letter, ...args);
  switch (columnName) {
    default: return get(columnName);
    case 'status': return exports.status(get('status'));
    case 'address': {
      const { city, postcode, street, co } = letter || {};
      return [co, street, postcode, city].filter(v => v).join(', ');
    }
    case 'name': {
      const { first_name, last_name } = letter || {};
      return [first_name, last_name].filter(v => v).join(' ');
    }
  }
};

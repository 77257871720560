import React from 'react';
import { IColumnDefinitionMap } from 'src/tables/Table';
import {
  IUpdateRowMutationData,
  ISelectedRows,
  TStateColumns,
  TableRow, 
} from 'src/tables/types';
import { UseMutateAsyncFunction } from '@tanstack/react-query';
import TableTd from 'src/tables/TableTd';

interface ITableBody {
  rows: TableRow[];
  selectedRows: ISelectedRows;
  updateRowMutateAsync?: UseMutateAsyncFunction<unknown, unknown, IUpdateRowMutationData>;
  setSelectedRows?: React.Dispatch<React.SetStateAction<ISelectedRows>>;
  columnDefinitionsById: IColumnDefinitionMap;
  columns?: TStateColumns;
}
const TableBody: React.FC<ITableBody> = React.memo(function TableBody (props: ITableBody) {
  const {
    rows,
    selectedRows,
    setSelectedRows,
    updateRowMutateAsync,
    columnDefinitionsById,
    columns = [],
  } = props;

  const handleChangeSelected = React.useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
    if (!setSelectedRows) return;
    const { name, checked } = ev.target;
    setSelectedRows(prevRows => ({...prevRows, [name]: checked}));
  }, [setSelectedRows]);

  return (
    <tbody>
      {rows && rows.length > 0 ? rows.map(row => (
        <tr key={row.id} className="border-bottom">
          {columns.map(columnId => (
            <TableTd
              key={columnId}
              columnDefinition={columnDefinitionsById[columnId]}
              row={row}
              selected={selectedRows[row.id] || false}
              onChangeSelected={handleChangeSelected}
              updateRowMutateAsync={updateRowMutateAsync}
            />
          ))}
        </tr>
      )) : (
        <tr>
          <td colSpan={columns?.length ?? 1}>Det finns ingenting här</td>
        </tr>
      )}
    </tbody>
  );
});
export default TableBody;

import React from 'react';
import ModalAnchor from 'src/anchors/ModalAnchor';
import TaskModalBody from 'src/task/TaskModalBody';
import { Name } from 'src/task/TaskFormatters';

export default function TaskAnchor (props) {
  const { task, ...restOfProps } = props;
  return (
    <ModalAnchor
      {...restOfProps}
      title={<Name value={task} />}
      name="Återkomst"
    >
      <TaskModalBody id={task.id} />
    </ModalAnchor>
  );
}


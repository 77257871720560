

/**
 * WARNING: This file is used clientside
 */

const getter = require('shared/get');
const format = require('shared/format');
const date = require('shared/filter/date');
const misc = require('shared/filter/misc');
const translations = require('shared/translations');

/**
 * Filter Application Status
 * @param {String} status
 * @return {String}
 */
exports.status = function (status) {
  switch (status) {
    default: return status;
    case 'new': return 'Nyskapad';
    case 'open': return 'Hos långivare';
    case 'closed': return 'Stängd';
    case 'waiting': return 'Bud finns';
    case 'error': return 'Fel';
    case 'accepted': return 'Accepterad';
    case 'disbursed': return 'Utbetald';
  }
};

exports.statusAccepted = function (status, hasAcceptedProcess = false) {
  const value = exports.status(status);
  if (!hasAcceptedProcess || ['new', 'accepted', 'disbursed'].includes(status)) return value;
  return 'Acp/' + value;
};

/**
 * @summary Filter Application Closed Reason
 * @param {String} reason
 * @return {String}
 */
exports.closedReason = function (reason) {
  switch (reason) {
    default: return reason;
    case 'bad_credit': return 'Betalningsanmärkning';
    case 'sanction_list_match': return 'Kund på sanktionslista';
    case 'pn_expired': return 'Skuldebrev har utgått';
    case 'offers_expired': return 'Erbjudanden har utgått';
    case 'offers_rejected:near_hope': return 'Erbjudande nekat (närtidshopp 6 mån)';
    case 'offers_rejected:midterm_hope': return 'Erbjudande nekat (närtidshopp 12 mån)';
    case 'offers_rejected:far_hope': return 'Erbjudande nekat (långtidshopp)';
    case 'application_rejected': return 'Avslag från långivare';
    case 'application_rejected_post_accept': return 'Avslag efter accept';
    case 'no_contact': return 'Kund vill ej bli kontaktad mer';
    case 'no_submission': return 'Ej inskickad';
  }
};

exports.indexColumnName = function (columnKey) {
  switch (columnKey) {
    default: return columnKey;
    case 'id': return 'ID';
    case 'ordinal': return 'Ansöknings-nr';
    case 'customer_id_co': return 'Kund (medsökande)';
    case 'customer_id': return 'Kund';
    case 'Customer.telephone_mobile': return 'Kunds telefon (mobil)';
    case 'Customer.email': return 'Kunds e-postadress';
    case 'Customer.address_street': return 'Kunds gatuadress';
    case 'Customer.address_city': return 'Kunds stad';
    case 'Customer.address_postcode': return 'Kunds postnummer';
    case 'Customer.address_co': return 'Kunds c/o';
    case 'portal_is_from': return 'Portalansökan';
    case 'portal_submitter_ip_address': return 'IP-adress (portal)';
    case 'portal_tracking_code_adtraction': return 'Adtraction-kod (portal)';
    case 'portal_tracking_code_adservice': return 'Adservice-kod (portal)';
    case 'portal_tracking_code_gclid': return 'Adwords-kod (portal)';
    case 'portal_tracking_code_ga_id': return 'Analytics-kod (portal)';
    case 'date': return 'Datum';
    case 'created_by_user_id': return 'Skapare';
    case 'created_by_role': return 'Roll (skapare)';
    case 'created_by_user_groups': return 'Skapargrupp';
    case 'created_by_channel': return 'Kanal';
    case 'created_at': return 'Skapad';
    case 'created_at_relative': return 'Skapad';
    case 'closed_at': return 'Stängd';
    case 'closed_at_relative': return 'Stängd';
    case 'accepted_at': return 'Accepterad';
    case 'accepted_at_relative': return 'Accepterad';
    case 'status_updated_at': return 'Uppdaterad';
    case 'status_updated_at_relative': return 'Uppdaterad';
    case 'repayment_years': return 'Återbetalningstid';
    case 'loan_new': return 'Summa nylån';
    case 'loan_now': return 'Summa lösen';
    case 'total_loan_amount': return 'Lånesumma';
    case 'role': return 'Intressent';
    case 'role_advisor': return 'Rådgivare';
    case 'ssn': return 'Huvudsökande (PNR)';
    case 'ssn_co': return 'Medsökande (PNR)';
    case 'ssn_co_exists': return 'Har medsökande';
    case 'closed_reason': return 'Stängningsorsak';
    case 'disbursed': return 'Summa';
    case 'accepted_bank_id': return 'Accepterad långivare';
    case 'note': return 'Notat';
    case 'process_counters': return 'Process';
    case 'accepted_process_id': return 'Accepterad process';
    case 'has_accepted_process_id': return 'Har accepterad process';
    case 'flag_loan_only_new': return 'Endast nylån';
    case 'flag_has_waiting_process': return 'Har "Bud finns" processer';
    case 'flag_status_ever_accepted': return 'Accepterad (någonsin)';
    case 'flag_status_ever_waiting': return 'Beviljad (någonsin)';
    case 'flag_accepted_via_portal': return 'Portalaccept';
    case 'flag_advisor_is_done': return 'Markerad som avklarad av användare';
    case 'flag_customer_is_done': return 'Markerad som ointressant av kund';
    case 'flag_last_uc_is_approved': return 'Senaste UC godkänd';
    case 'flag_customer_prev_disbursed': return 'Kund tidigare utb. ans.';
    case 'flag_wants_tryggsam': return 'Vill ha Tryggsam ID-skydd';
    case 'flag_restarted': return 'Återstartad';
    case 'flag_high_value_bids': return 'Höga bud';
    case 'flag_zensum_qualified': return 'Kvalificerad för Zensum';
    case 'pep_answers.pep_myself': return 'Kunden är PEP';
    case 'pep_answers.pep_knows': return 'Kunden känner en PEP';
    case 'status': return 'Status';
  }
};

/**
 * @param {Application|Object} app
 * @param {String} fieldName
 * @param {Boolean} [options.forExport=false] When true will format dates to a user-friendly format
 * @return {*}
 */
exports.indexField = function (app, fieldName, options = {}) {
  const { forExport = false } = options;
  const get = (...args) => getter(app, ...args);

  switch (fieldName) {
    default: return app[fieldName];

    case 'ordinal':
      return format.numberPad(get('ordinal'), 3);

    case 'created_at':
    case 'closed_at':
    case 'accepted_at':
    case 'status_updated_at':
      return forExport ? date.dayTime(app[fieldName]) : app[fieldName];

    case 'loan_new':
      return (get('form.loan.amount.new') || 0) + ' kr';

    case 'loan_now':
      return (get('form.loan.amount.now') || 0) + ' kr';

    case 'total_loan_amount': {
      const amount = get('form.loan.amount') || {};
      return (amount.new || 0) + (amount.now || 0) + ' kr';
    }

    case 'closed_reason':
      return exports.closedReason(get('closed_reason'));

    case 'disbursed':
      return misc.appendWhenNotEmpty(get('disbursed'), ' kr') || '';

    case 'repayment_years':
      return misc.appendWhenNotEmpty(get('form.loan.repayment_years') || 0, ' år');

    case 'process_counters': {
      const total = get('process_total') || 0;
      const answered = get('process_answered') || 0;
      const waiting = get('process_waiting') || 0;
      return `${waiting}/${answered}/${total}`;
    }

    case 'ssn':
      return format.ssn('long', get('ssn'));

    case 'ssn_co': {
      const hasCo = get('form.include_co_applicant');
      return hasCo ? format.ssn('long', get('form.co_applicant.ssn')) : '';
    }

    case 'Customer.telephone_mobile': return get('Customer.telephone_mobile') || '-';
    case 'Customer.email': return get('Customer.email') || '-';
    case 'Customer.address_street': return get('Customer.address_street') || '-';
    case 'Customer.address_city': return get('Customer.address_city') || '-';
    case 'Customer.address_postcode': return get('Customer.address_postcode') || '-';
    case 'Customer.address_co': return get('Customer.address_co') || '-';

    case 'customer':
    case 'customer_id':
      return get('Customer.name') || get('Customer.id') || get('customer_id') || '-';

    case 'customer_co':
    case 'customer_id_co':
      return get('CustomerCo.name') || get('CustomerCo.id') || get('customer_id_co') || '-';

    case 'accepted_bank_id':
      return get('AcceptedBank.name') || get('accepted_bank_id') || '';

    case 'created_by_user_id':
      return get('Creator.name') || get('created_by_user_id') || '-';

    case 'created_by_role':
      return exports.createdByRole(get('created_by_role')) || '-';

    case 'created_by_user_groups':
      return (get('created_by_user_groups') || []).join(', ');

    case 'role_advisor':
      return get('Advisor.name') || get('role_advisor') || '-';

    case 'has_accepted_process_id':
      return misc.yesno(get('accepted_process_id'));

    case 'flag_zensum_qualified':
    case 'flag_has_waiting_process':
    case 'flag_loan_only_new':
    case 'flag_status_ever_accepted':
    case 'flag_status_ever_waiting':
    case 'flag_accepted_via_portal':
    case 'flag_advisor_is_done':
    case 'flag_customer_is_done':
    case 'flag_last_uc_is_approved':
    case 'flag_customer_prev_disbursed':
    case 'flag_wants_tryggsam':
    case 'flag_restarted':
    case 'flag_high_value_bids':
    case 'portal_is_from':
      return misc.yesno(get(fieldName));

    case 'pep_answers.pep_myself': {
      const v = get(fieldName);
      if (typeof v === 'undefined' || v === null) return '-';
      if (v === false) return 'Nej';
      return translations.customer.pep_myself_title(v);
    }

    case 'pep_answers.pep_knows': {
      const v = get(fieldName);
      if (typeof v === 'undefined' || v === null) return '-';
      if (v === false) return 'Nej';
      return translations.customer.pep_knows_title(v);
    }

    case 'status':
      return exports.statusAccepted(get('status'), Boolean(get('accepted_process_id'))) || '';

  }
};

exports.createdByRole = role => {
  switch (role) {
    default: return role;
    case 'salesman': return 'Handläggare';
    case 'advisor': return 'Rådgivare';
    case 'bank': return 'Långivare';
    case 'administrator': return 'Administratör';
    case 'portal': return 'Portal';
  }
};

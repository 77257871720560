import React from 'react';
import SidebarNavSection from 'src/sidebar/SidebarNavSection';
import { SidebarNavSectionProps } from 'src/sidebar/SidebarNavSection';

interface SidebarNavProps {
  items: SidebarNavSectionProps[];
}

const SidebarNav: React.FC<SidebarNavProps> = React.memo(function SidebarNav (props: SidebarNavProps) {
  const { items } = props;
  return (
    <ul className="sidebar-nav">
      {items && items.map((item, index) => (
        <SidebarNavSection
          key={item.title || index}
          pages={item.pages}
          title={item.title}
        />
      ))}
    </ul>
  );
});

export default SidebarNav;

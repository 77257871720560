import React from 'react';
import { omit } from 'lodash';
import BlockSpinner from 'src/spinners/BlockSpinner';
import ErrorAlert from 'src/alerts/ErrorAlert';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { WorkTriggerTemplateRow, WorkTriggerTemplateRowForm } from 'shared/types/work_trigger_template';
import * as api from 'src/api';
import useNotyf from 'src/hooks/useNotyf';
import WorkTriggerTemplateForm, {formValuesToUpdate, workTriggerTemplateToFormValues} from 'src/workTriggerTemplate/WorkTriggerTemplateForm';
import { useEventModelEmitCreates, useEventModelEmitDeletes, useEventModelEmitUpdates } from 'src/hooks/useEventModels';
import useModalStateContext from 'src/hooks/useModalStateContext';
import * as formUtils from 'src/utils/form';

interface WorkTriggerTemplateEditFormProps {
  workTriggerTemplateId: string;
}

const WorkTriggerTemplateEditForm: React.FC<WorkTriggerTemplateEditFormProps> = React.memo(function WorkTriggerTemplateEditForm (props: WorkTriggerTemplateEditFormProps) {
  const { workTriggerTemplateId:outerWorkTriggerTemplateId } = props;

  // this intermediate step is because we need to load in a new work trigger template when cloning
  const [workTriggerTemplateId, setWorkTriggerTemplateId] = React.useState(outerWorkTriggerTemplateId);

  const modalStateContext = useModalStateContext();

  const notyf = useNotyf();

  const queryKey = [`/work_trigger_template/${workTriggerTemplateId}`];
  const query = useQuery<WorkTriggerTemplateRow | null, Error>({queryKey});

  const queryClient = useQueryClient();

  const emitUpdate = useEventModelEmitUpdates<WorkTriggerTemplateRow>('work_trigger_template');
  const emitCreate = useEventModelEmitCreates<WorkTriggerTemplateRow>('work_trigger_template');
  const emitDelete = useEventModelEmitDeletes<WorkTriggerTemplateRow>('work_trigger_template');

  const updateMutation = useMutation<WorkTriggerTemplateRow, Error, Partial<WorkTriggerTemplateRow>>({
    mutationFn: vars => api.request({
      url: `/work_trigger_template/${workTriggerTemplateId}`,
      method: 'PATCH',
      data: vars,
    }),
    onSuccess: obj => {
      notyf.success({message: 'Triggermallen uppdaterades'});
      emitUpdate(obj);
      modalStateContext.onHide();
      queryClient.setQueryData(queryKey, obj);
    },
  });

  const cloneMutation = useMutation<WorkTriggerTemplateRow, Error, Omit<WorkTriggerTemplateRow, 'id'>>({
    mutationFn: vars => api.request({
      url: '/work_trigger_template',
      method: 'POST',
      data: vars,
    }),
    onSuccess: obj => {
      notyf.success({message: 'Triggermallen klonades'});
      emitCreate(obj);
      setWorkTriggerTemplateId(obj.id);
    },
  });

  const deleteMutation = useMutation<void, Error>({
    mutationFn: () => api.request({
      url: `/work_trigger_template/${workTriggerTemplateId}`,
      method: 'DELETE',
    }),
    onSuccess: () => {
      notyf.success({message: 'Triggermallen raderades'});
      if (query.data) emitDelete(query.data);
      modalStateContext.onHide();
    },
  });

  const onClone = async (values: WorkTriggerTemplateRowForm) => {
    const clone = omit({
      ...values,
      is_active_from: null,
      is_active_to: null,
      description: values.description + ' (klon)',
      is_active: false,
    }, 'id', 'created_at', 'updated_at');
    return cloneMutation.mutateAsync(clone);
  };

  const onDelete = async () => {
    return deleteMutation.mutateAsync();
  };

  const workTriggerTemplate = query.data;

  const initialValues = workTriggerTemplate ? workTriggerTemplateToFormValues(workTriggerTemplate) : {};

  const formCycleHelpers = formUtils.getFormikFormCycleHelpers<WorkTriggerTemplateRow, WorkTriggerTemplateRowForm, Partial<WorkTriggerTemplateRow>>({
    queryDataToFormValues: workTriggerTemplateToFormValues,
    formValuesToMutationVars: values => formValuesToUpdate(initialValues, values),
    mutateAsync: updateMutation.mutateAsync,
  });

  return (
    <div>
      <ErrorAlert error={query.error} className="m-3" />
      <BlockSpinner isLoading={query.isLoading || query.isRefetching || cloneMutation.isPending} />
      {workTriggerTemplate && (
        <>
          {!query.isRefetching && (
            <WorkTriggerTemplateForm
              initialValues={initialValues}
              onSubmit={formCycleHelpers.onSubmit}
              submitError={updateMutation.error}
              onClone={onClone}
              onDelete={onDelete}
            />
          )}
          <ErrorAlert error={cloneMutation.error} className="m-3" />
          <ErrorAlert error={deleteMutation.error} className="m-3" />
        </>
      )}
    </div>
  );
});

export default WorkTriggerTemplateEditForm;

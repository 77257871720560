import React from 'react';
import OverlaySpinner from 'src/spinners/OverlaySpinner';
import ErrorAlert from 'src/alerts/ErrorAlert';
import { CardTitleIcon } from 'src/cards/CardHelpers';
import {RefreshObjectButton} from 'src/buttons/IconButtons';
import { useQuery } from '@tanstack/react-query';
import { ComplaintRow } from 'shared/types/complaint';
import { Card, Table } from 'react-bootstrap';
import { LifeBuoy } from 'react-feather';
import InspectObjectModalButton from 'src/buttons/InspectObjectModalButton';
import ComplaintAnchor from 'src/complaint/ComplaintAnchor';
import * as ComplaintFormatters from 'src/complaint/ComplaintFormatters';
import * as complaintFilter from 'shared/filter/complaint';
import DateTime from 'src/formatters/DateTime';
import classNames from 'classnames';

interface ComplaintListCardProps {
  searchParams: Record<string, any>;
  className?: string;
}

export default function ComplaintListCard (props: ComplaintListCardProps) {
  const { searchParams, className } = props;

  const query = useQuery<ComplaintRow[], Error>({
    queryKey: ['/complaint/search', {params: searchParams}],
  });

  const data = query.data ?? [];

  return (
    <Card className={classNames('border', className)}>
      <Card.Header className="border-bottom bg-light">
        <CardTitleIcon
          title="Klagomål"
          Icon={<LifeBuoy size={18} />}
          spinning={query.isRefetching}
        >
          <RefreshObjectButton
            disabled={query.isRefetching}
            onClick={() => query.refetch()}
          />
        </CardTitleIcon>
      </Card.Header>
      <div className="position-relative table-responsive">
        <ErrorAlert error={query.error} className="mx-3 mt-3 mb-2" />
        <OverlaySpinner isLoading={query.isLoading} />
        <Table striped className="my-0 border-bottom">
          <thead>
            <tr>
              <td>Nr</td>
              <th>Skapad</th>
              <th>Prioritet</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {query.isSuccess && data.map(item => (
              <tr key={item.id}>
                <td>
                  <ComplaintAnchor complaint={item} />
                </td>
                <td><DateTime value={item.created_at} /></td>
                <td>{complaintFilter.priority(item.priority)}</td>
                <td><ComplaintFormatters.StatusLabel value={item.status} /></td>
                <td>
                  <div className="d-flex justify-content-end align-items-center gap-1">
                    <InspectObjectModalButton
                      size="sm"
                      object={item}
                    />
                  </div>
                </td>
              </tr>
            ))}
            {!query.data?.length && (
              <tr>
                <td colSpan={5} className="text-center">
                  Det finns inga klagomål här
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </Card>
  );
}

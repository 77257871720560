import React, {useState} from 'react';
import {useQuery} from '@tanstack/react-query';
import {useParams} from 'react-router';
import BlockSpinner from 'src/spinners/BlockSpinner';
import { NavLink } from 'react-router-dom';
import { ApplicationRow } from 'shared/types/application';
import { BankProcessRowWithRelations } from 'shared/types/bank_process';
import { BankRow } from 'shared/types/bank';
import classNames from 'classnames';
import {Card, Nav, Tab, Form, Button} from 'react-bootstrap';
import {Activity, Edit2, RefreshCw} from 'react-feather';
import BankProcessLogCard from 'src/bankProcess/BankProcessLogCard';
import * as BankProcessFormatters from 'src/bankProcess/BankProcessFormatters';
import * as IconButtons from 'src/buttons/IconButtons';
import DateTime from 'src/formatters/DateTime';
import { sortBy } from 'lodash';
import IdProvider from 'src/components/IdProvider';

interface ApplicationPageTabProcessProps {
  className?: string;
}

interface ApplicationPageTabProcessPageParams {
  applicationId: string;
  bankProcessId: string;
  bankProcessTab: string;
}

interface ApplicationPageTabProcessResponse {
  application: ApplicationRow;
  banks: BankRow[];
  banksUncreated: BankRow[];
  processes: BankProcessRowWithRelations[];
}

type OrderBy = 'relevance' | 'bank_id' | 'status_updated_at';

export default function ApplicationPageTabProcess (props: ApplicationPageTabProcessProps) {
  const { className } = props;
  const {
    applicationId,
    bankProcessId:activeBankProcessId,
    bankProcessTab:activeBankProcessTab = 'form',
  } = useParams() as unknown as ApplicationPageTabProcessPageParams;

  const query = useQuery<ApplicationPageTabProcessResponse>({
    queryKey: [`/application/${applicationId}/process`],
  });

  const [orderBy, setOrderBy] = useState<OrderBy>('relevance');

  const bankProcesses = sortBy(query.data?.processes || [], bankProcess => {
    if (orderBy === 'relevance') {
      const priority = ['new', 'error', 'open', 'waiting', 'accepted', 'disbursed'];
      let { status } = bankProcess;
      if (query.data?.application?.accepted_process_id === bankProcess.id) {
        // the accepted process is considered "accepted" no matter the actual status
        status = 'accepted';
      }
      return -1 * priority.indexOf(status);
    }
    return bankProcess[orderBy];
  });

  const { banksUncreated = [] } = query.data || {};

  return (
    <div className={classNames(className, 'position-relative')}>
      <BlockSpinner isLoading={query.isLoading} />
      <BankProcessSortCard
        orderBy={orderBy}
        setOrderBy={setOrderBy}
      />
      {query.isSuccess && (
        <div className="m-4">
          {bankProcesses.map(bankProcess => (
            <BankProcessCard
              key={bankProcess.id}
              applicationId={applicationId}
              bankProcess={bankProcess}
              activeBankProcessId={activeBankProcessId}
              activeBankProcessTab={activeBankProcessTab}
            />
          ))}
          {banksUncreated.map(bank => (
            <BankUncreatedCard
              key={bank.id}
              bank={bank}
            />
          ))}
        </div>
      )}
    </div>
  );
}

interface BankProcessCardProps {
  applicationId: string;
  bankProcess: BankProcessRowWithRelations;
  activeBankProcessId: string;
  activeBankProcessTab: string;
}

function BankProcessCard (props: BankProcessCardProps) {
  const { applicationId, bankProcess, activeBankProcessId, activeBankProcessTab } = props;

  return (
    <Card className="border rounded">
      <BankProcessNavLink
        applicationId={applicationId}
        bankProcess={bankProcess}
        activeBankProcessId={activeBankProcessId}
        activeBankProcessTab={activeBankProcessTab}
      />
      {activeBankProcessId === bankProcess.id && (
        <div className="border-top">
          <Tab.Container
            activeKey={activeBankProcessTab}
            transition={false}
            unmountOnExit={true}
            mountOnEnter={true}
          >
            <Nav className="nav-tabs pt-3 px-3">
              <Nav.Item>
                <NavLink className="nav-link" to={`/application/${applicationId}/bankProcess/${bankProcess.id}/form`}>
                  <span className="d-inline-flex gap-1 align-items-center">
                    <Edit2 size={12} />{' '}
                    Formulär
                  </span>
                </NavLink>
              </Nav.Item>
              <Nav.Item>
                <NavLink className="nav-link" to={`/application/${applicationId}/bankProcess/${bankProcess.id}/log`}>
                  <span className="d-inline-flex gap-1 align-items-center">
                    <Activity size={12} />{' '}
                    Logg
                  </span>
                </NavLink>
              </Nav.Item>
            </Nav>
            <Tab.Content>

              <Tab.Pane eventKey="form">

                <Card className="border m-4">
                  <Card.Header className="border-bottom bg-light">
                    <Card.Title className="mb-0">Formulär</Card.Title>
                  </Card.Header>
                  <Card.Body>Det finns ingenting här än.</Card.Body>
                </Card>

              </Tab.Pane>

              <Tab.Pane eventKey="log">
                <BankProcessLogCard className="m-4" bankProcessId={bankProcess.id} />
              </Tab.Pane>

            </Tab.Content>

          </Tab.Container>
        </div>
      )}
    </Card>
  );
}

interface BankUncreatedCardProps {
  bank: BankRow;
}

function BankUncreatedCard (props: BankUncreatedCardProps) {
  const { bank } = props;
  return (
    <Card className="border rounded">
      <div className="bg-light card-header d-flex justify-content-between">
        <div className="card-title mb-0 flex-grow">
          <span className="d-flex align-items-center gap-2 text-secondary">
            <RefreshCw size={16} />
            {bank.name || bank.id}
          </span>
        </div>
        <div className="d-flex justify-content-end gap-1 align-items-center">
          <Button size="sm">
            Validera
          </Button>
          <Button variant="success" size="sm">
            Skapa
          </Button>
        </div>
      </div>
    </Card>
  );
}

interface BankProcessNavLinkProps {
  applicationId: string;
  bankProcess: BankProcessRowWithRelations;
  activeBankProcessId?: string;
  activeBankProcessTab?: string;
}

function BankProcessNavLink (props: BankProcessNavLinkProps) {
  const { applicationId, activeBankProcessId, activeBankProcessTab, bankProcess } = props;

  const activeBankProcessIsThis = bankProcess.id === activeBankProcessId;
  const to = activeBankProcessIsThis ? `/application/${applicationId}/bankProcess` : `/application/${applicationId}/bankProcess/${bankProcess.id}/${activeBankProcessTab || 'form'}`;

  return (
    <div className="bg-light card-header d-flex justify-content-between">
      <NavLink className="text-reset text-decoration-none flex-grow-1" to={to}>
        <div className="card-title mb-0">
          <span className="d-flex align-items-center gap-2">
            <RefreshCw size={16} />
            {bankProcess.Bank.name ?? bankProcess.bank_id}
          </span>
        </div>
      </NavLink>
      <div className="d-flex justify-content-end gap-3 align-items-center">
        <div className="d-flex align-items-center">
          <DateTime
            value={bankProcess.status_updated_at}
            className="small"
            style={{top: '1px', position: 'relative'}}
          />
          <IconButtons.FavoriteButton type="bankProcess" id={bankProcess.id} />
          <IconButtons.InspectObjectModalButton object={bankProcess} />
          <IconButtons.CopyObjectIdButton id={bankProcess.id} />
        </div>
        <BankProcessFormatters.StatusLabel style={{minWidth: '150px'}} value={bankProcess.status} short />
      </div>
    </div>
  );
}

interface BankProcessSortCardProps {
  orderBy: OrderBy;
  setOrderBy: React.Dispatch<React.SetStateAction<OrderBy>>;
}

function BankProcessSortCard (props: BankProcessSortCardProps) {
  const { orderBy, setOrderBy } = props;

  const onChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setOrderBy(ev.target.value);
  };

  return (
    <Card className="m-4 border rounded">
      <Card.Body className="py-2 px-3 d-flex align-items-center gap-3">
        <span>Sortera efter:{' '}</span>
        <IdProvider>
          {id => (
            <Form.Check
              className="me-0 mb-0"
              id={id}
              type="radio"
              label="Relevans"
              value="relevance"
              onChange={onChange}
              checked={orderBy === 'relevance'}
            />
          )}
        </IdProvider>
        <IdProvider>
          {id => (
            <Form.Check
              className="me-0 mb-0"
              id={id}
              type="radio"
              label="Långivarnamn"
              value="bank_id"
              onChange={onChange}
              checked={orderBy === 'bank_id'}
            />
          )}
        </IdProvider>
        <IdProvider>
          {id => (
            <Form.Check
              className="me-0 mb-0"
              id={id}
              type="radio"
              label="Statusuppdatering"
              value="status_updated_at"
              onChange={onChange}
              checked={orderBy === 'status_updated_at'}
            />
          )}
        </IdProvider>
      </Card.Body>
    </Card>
  );
}

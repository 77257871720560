import React from 'react';
import complaintFilter from 'shared/filter/complaint';
import * as TableCells from 'src/tables/TableCells';
import * as commonFilterDefs from 'src/tables/commonFilterDefinitions';
import * as commonColumnDefs from 'src/tables/commonColumnDefinitions';
import * as TableCellActions from 'src/tables/TableCellActions';
import TablePage from 'src/tables/TablePage';

export default function ComplaintsTablePage () {
  return (
    <TablePage
      title="Klagomål"
      pageTitle="Klagomålstabell"
      columnDefinitions={columnDefinitions}
      filterDefinitions={filterDefinitions}
      routeUrl="/complaints"
      baseQueryUrl="/complaint/table"
      baseQueryKey="ComplaintTable"
      queryResultRowsKey="complaints"
    />
  );
}

const filterDefinitions = [
  commonFilterDefs.string({id: 'id'}),
  commonFilterDefs.complaintStatus({id: 'status'}),
  commonFilterDefs.dateRange({id: 'created_at'}),
  commonFilterDefs.dateRelative({id: 'created_at_relative'}),
  commonFilterDefs.dateRange({id: 'updated_at'}),
  commonFilterDefs.dateRelative({id: 'updated_at_relative'}),
].map(filterDefinition => ({
  title: complaintFilter.indexColumnName(filterDefinition.id),
  ...filterDefinition,
}));

const columnDefinitions = [
  commonColumnDefs.select(),
  ...([
    commonColumnDefs.complaintId({id: 'id', cellProps: {rowAsObject: true}}),
    commonColumnDefs.customerId({
      id: 'customer_id',
      cellProps: {objKey: 'Customer', idKey: 'customer_id'},
    }),
    commonColumnDefs.datetime({
      id: 'created_at',
      sortable: true,
      groups: ['dates', 'sortable'],
    }),
    commonColumnDefs.datetime({
      id: 'updated_at',
      sortable: true,
      groups: ['dates', 'sortable'],
    }),
    commonColumnDefs.basic({id: 'priority'}),
    commonColumnDefs.basic({id: 'notes_length'}),
    commonColumnDefs.basic({
      Cell: TableCells.ComplaintStatusTableCell,
      id: 'status',
    }),
  ].map(columnDefinition => {
    const { id } = columnDefinition;
    const header = complaintFilter.indexColumnName(id);
    const cellValue = complaint => complaintFilter.indexField(complaint, id);
    return {
      Cell: ({value}) => <>{value}</>,
      ...columnDefinition,
      cellValue,
      Header: ({value}) => <>{value}</>,
      title: header,
    };
  })),
  commonColumnDefs.actions({}, [
    TableCellActions.InspectRowTableCellAction,
  ]),
];

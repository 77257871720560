import React from 'react';
import isObjectLike from 'lodash/isObjectLike';

interface NameProps {
  value: {id: number};
}

export function Name (props: NameProps) {
  const { value:workScheduled } = props;
  if (!isObjectLike(workScheduled)) return null;
  const { id } = workScheduled;
  return (
    <>{id}</>
  );
}

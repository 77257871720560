import React, { HTMLAttributes } from 'react';
import isObjectLike from 'lodash/isObjectLike';
import StatusBaseLabel from 'src/formatters/StatusBaseLabel';
import * as userFilter from 'likvidum/shared/filter/user';
import avatarGeneric from 'src/assets/img/avatar_generic.png';
import classNames from 'classnames';

export function Name (props) {
  const { value:user } = props;
  if (!isObjectLike(user)) return null;

  let name = user.name || '';
  if (!name) {
    if (user.id) {
      name = user.id;
    }
  }

  return (
    <>{name}</>
  );
}

export function Status (props) {
  const { value } = props;
  return userFilter.status(value);
}

export function StatusLabel (props) {
  const { value:isActive, ...restOfProps } = props;
  return (
    <StatusBaseLabel
      value={isActive}
      formatter={isActive => userFilter.status(isActive)}
      {...restOfProps}
    />
  );
}

interface IPhotoImg extends HTMLAttributes<HTMLImageElement> {
  user?: {
    photo?: string;
    name?: string;
  } | null;
}

export function AvatarImg (props: IPhotoImg) {
  const { user, className, ...restOfProps } = props;
  const { photo, name } = user || {};
  const [errorLoading, setErrorLoading] = React.useState(false);
  const src = photo && !errorLoading ? `/public/images/photos/${photo}` : avatarGeneric;
  return (
    <img
      className={classNames(className, 'img-fluid', 'rounded-circle')}
      {...restOfProps}
      src={src}
      alt={name || ''}
      onError={() => setErrorLoading(true)}
    />
  );
}

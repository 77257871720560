import React from 'react';
import { CustomerJaycomInsurance } from 'shared/types/customer_jaycom_insurance';
import AssociatedObjectList from 'src/components/AssociatedObjectList';
import {oldCrmUrl} from 'src/misc';

const JAYCOM_URL = process.env.REACT_APP_JAYCOM_URL || 'http://localhost';

interface JaycomInsuranceAssociatedObjectListProps {
  insurance: CustomerJaycomInsurance;
}

function JaycomInsuranceAssociatedObjectList (props: JaycomInsuranceAssociatedObjectListProps) {
  const { insurance } = props;

  const associatedObjects = [
    insurance.customer_id ? {type: 'customer', value: {id: insurance.customer_id}, label: 'Kund'} : null,
    insurance.application_id ? {type: 'application', value: {id: insurance.application_id}, label: 'Ansökan'} : null,
    insurance.created_by_user_id ? {type: 'user', value: {id: insurance.created_by_user_id}, label: 'Skapad av'} : null,
    insurance.updated_by_user_id ? {type: 'user', value: {id: insurance.updated_by_user_id}, label: 'Uppdaterad av'} : null,
  ].filter(v => v);

  const { jaycom_insurance_id, jaycom_customer_id } = insurance;

  const externalLinks = [
    {url: oldCrmUrl(`/customer_jaycom_insurance/${insurance.id}/form`), label: 'Gamla CRM'},
    jaycom_customer_id ? {url: `${JAYCOM_URL}/?page=crm.kund.visa&id=${jaycom_customer_id}`, label: 'Jaycom kundkort'} : null,
    jaycom_insurance_id && jaycom_insurance_id ? {url: `${JAYCOM_URL}/?page=forsakring.kundmoment.visa&kund=${jaycom_customer_id}&id=${jaycom_insurance_id}`, label: 'Jaycom försäkringskort'} : null,
  ].filter(v => v);

  return (
    <AssociatedObjectList
      associatedObjects={associatedObjects}
      externalLinks={externalLinks}
    />
  );
}
export default React.memo(JaycomInsuranceAssociatedObjectList);

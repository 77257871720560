import React from 'react';
import Anchor from  'src/anchors/Anchor';
import { Name } from 'src/emailTemplate/EmailTemplateFormatters';
import { EmailTemplateRow } from 'shared/types/email_template';

interface EmailTemplateAnchorProps {
  emailTemplate: EmailTemplateRow;
}

export default function EmailTemplateAnchor (props: EmailTemplateAnchorProps) {
  const { emailTemplate, ...restOfProps } = props;
  return (
    <Anchor
      {...restOfProps}
      url={`/email_template/${emailTemplate.id}`}
      name="E-postmallen"
    >
      <Name value={emailTemplate} />
    </Anchor>
  );
}


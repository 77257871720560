

/**
 * WARNING: This file is used clientside
 */

/**
 * @desc Basic lodash get() clone
 * @param {Object|Array} obj
 * @param {String|[]String} path Supports dots
 * @param {*} defaultValue If path undefined
 */ 
module.exports = function (obj, path, defaultValue) {
  if (typeof path === 'string') {
    path = path.split('.');
  }
  if (!Array.isArray(path)) {
    return defaultValue;
  }

  for (let i = 0; i < path.length; i++) {
    if (typeof obj === 'undefined' || obj === null) return defaultValue;
    obj = obj[path[i]];
  }

  return typeof obj === 'undefined' ? defaultValue : obj;
};

import React from 'react';
import ConfirmActionModal from 'src/modals/ConfirmActionModal';
import { Button, ButtonProps } from 'react-bootstrap';

export interface ConfirmActionModalButtonProps extends ButtonProps {
  children: React.ReactNode;
  confirmMessage?: React.ReactNode;
  declineMessage?: React.ReactNode;
  onConfirm: () => void;
  onDecline?: () => void;
  message: React.ReactNode;
}

const ConfirmActionModalButton: React.FC<ConfirmActionModalButtonProps> = React.memo(function ConfirmActionModalButton (props: ConfirmActionModalButtonProps) {
  const {
    children,
    confirmMessage = 'Ja',
    declineMessage = 'Nej',
    onConfirm,
    onDecline,
    message,
    ...buttonProps
  } = props;

  const [showModal, setShowModal] = React.useState(false);

  const onHide = () => {
    setShowModal(false);
  };

  const onClick = () => {
    setShowModal(true);
  };

  return (
    <>
      <Button {...buttonProps} onClick={onClick}>
        {children}
      </Button>
      <ConfirmActionModal
        message={message}
        confirmMessage={confirmMessage}
        declineMessage={declineMessage}
        onConfirm={onConfirm}
        onDecline={onDecline}
        show={showModal}
        onHide={onHide}
      />
    </>
  );
});
export default ConfirmActionModalButton;

import React from 'react';
import {Table} from 'react-bootstrap';
import { CustomerJaycomInsurance } from 'shared/types/customer_jaycom_insurance';
import * as JaycomInsuranceFormatters from 'src/jaycomInsurance/JaycomInsuranceFormatters';

interface JaycomInsurancePropertyListProps {
  insurance: CustomerJaycomInsurance;
}

function JaycomInsurancePropertyList (props: JaycomInsurancePropertyListProps) {
  const { insurance } = props;

  return (
    <Table>
      <tbody>
        <tr>
          <th>Jaycom kund-ID</th>
          <td><strong>{insurance.jaycom_customer_id || '-'}</strong></td>
        </tr>
        <tr>
          <th>Jaycom försäkrings-ID</th>
          <td><strong>{insurance.jaycom_insurance_id || '-'}</strong></td>
        </tr>
        {insurance.cancelled_reason && (
          <tr>
            <th>Annullationsorsak</th>
            <td>{insurance.cancelled_reason}</td>
          </tr>
        )}
        {insurance.error && (
          <tr>
            <th>Felorsak</th>
            <td>
              <pre className="mb-0">{JSON.stringify(insurance.error, null, 2)}</pre>
            </td>
          </tr>
        )}
        <tr>
          <th>Försäkrat månadsbelopp</th>
          <td><JaycomInsuranceFormatters.InsuredAmount value={insurance.insured_amount} /></td>
        </tr>
        <tr>
          <th className="border-bottom-0">Skapad av</th>
          <td>
            {insurance.created_by_user_id ? 'Användare' : 'Kund via portal'}
          </td>
        </tr>
      </tbody>
    </Table>
  );
}
export default React.memo(JaycomInsurancePropertyList);

import React from 'react';
import {
  Button,
  Navbar as BsNavbar,
  Nav,
} from 'react-bootstrap';
import { Plus, RefreshCw } from 'react-feather';
import PassportButton from 'src/buttons/PassportButton';
import useSidebar from 'src/hooks/useSidebar';
import NavbarUser from 'src/navbar/NavbarUser';
import NavbarLogDropdown from 'src/navbar/NavbarLogDropdown';
import NavbarTaskDropdown from 'src/navbar/NavbarTaskDropdown';
import NavbarQuickSearch from 'src/navbar/NavbarQuickSearch';
import {useIsFetching, useQueryClient} from '@tanstack/react-query';

const Navbar = React.memo(function Navbar () {
  const { isOpen, setIsOpen } = useSidebar();

  const queryClient = useQueryClient();
  const isFetching = useIsFetching();

  const onClick = () => {
    setIsOpen(!isOpen);
  };

  const onClickReload = () => {
    queryClient.refetchQueries();
  };

  return (
    <BsNavbar variant="light" expand className="navbar-bg sticky-top border-bottom">
      <span
        role="switch"
        aria-checked={isOpen}
        tabIndex={0}
        onKeyPress={onClick}
        onClick={onClick}
        className="sidebar-toggle d-flex"
      >
        <i className="hamburger align-self-center" />
      </span>

      <NavbarQuickSearch
        className="d-none d-sm-inline-block"
      />

      <Button className="d-none d-sm-inline-flex gap-1 align-items-center ms-sm-2 bg-gradient" variant="success" size="sm">
        <Plus size={18} />
        Ny ansökan
      </Button>

      <Button
        className="d-none d-sm-inline-flex py-1 gap-1 align-items-center ms-sm-2"
        size="sm"
        variant="secondary"
        title="Ladda om allt på sidan"
        onClick={onClickReload}
        disabled={isFetching > 0}
      >
        <RefreshCw size={14}></RefreshCw>
      </Button>

      <PassportButton
        className="d-none d-sm-inline-flex align-items-center ms-sm-2 bg-gradient"
        variant="outline-secondary"
        size="sm"
      />

      <BsNavbar.Collapse>
        <Nav className="navbar-align">
          <NavbarTaskDropdown />
          <NavbarLogDropdown />
          <NavbarUser />
        </Nav>
      </BsNavbar.Collapse>

    </BsNavbar>
  );
});

export default Navbar;

import React from 'react';
import { Button, ButtonProps } from 'react-bootstrap';

export interface ModalOpeningButtonModalProps {
  show: boolean;
  onHide: () => void;
  [key: string]: any;
}

interface ModalOpeningButtonProps extends ButtonProps {
  children: React.ReactNode;
  Modal: React.FC<ModalOpeningButtonModalProps>;
  modalProps: Record<string, any>;
}

const ModalOpeningButton: React.FC<ModalOpeningButtonProps> = React.memo(function ModalOpeningButton (props: ModalOpeningButtonProps) {
  const {
    children,
    Modal,
    modalProps = {},
    ...buttonProps
  } = props;

  const [showModal, setShowModal] = React.useState(false);

  const onHide = () => {
    setShowModal(false);
  };

  const onShow = () => {
    setShowModal(true);
  };

  return (
    <>
      <Button {...buttonProps} onClick={onShow}>
        {children}
      </Button>
      <Modal
        {...modalProps}
        show={showModal}
        onHide={onHide}
      />
    </>
  );
});
export default ModalOpeningButton;

import React from 'react';

interface ISmsModalBody {
  id: string;
}

export default function SmsModalBody (props: ISmsModalBody) {
  return (
    <div>sms modal body here</div>
  );
}

import React from 'react';
import userFilter from 'shared/filter/user';
import * as TableCells from 'src/tables/TableCells';
import * as commonFilterDefs from 'src/tables/commonFilterDefinitions';
import * as commonColumnDefs from 'src/tables/commonColumnDefinitions';
import * as TableCellActions from 'src/tables/TableCellActions';
import CountryFlag from 'src/formatters/CountryFlag';
import GroupSelectOptions from 'src/userGroup/GroupSelectOptions';
import { AvatarImg } from 'src/user/UserFormatters';
import { Form, ProgressBar, Button } from 'react-bootstrap';
import { IColumnCellProps, IColumnDefinition, IFilterDefinition } from 'src/tables/Table';
import { IBatchDefinition, IBatchActionSubForm } from 'src/tables/types';
import { useIsFetching } from '@tanstack/react-query';
import TablePage from 'src/tables/TablePage';
import constants from 'shared/constants';

export default function UserTablePage () {
  return (
    <TablePage
      title="Användare"
      pageTitle="Användartabell"
      columnDefinitions={columnDefinitions}
      filterDefinitions={filterDefinitions}
      routeUrl="/user"
      baseQueryUrl="/user/table"
      baseQueryKey="UserTable"
      queryResultRowsKey="users"
      batchDefinition={batchDefinition}
    />
  );
}

function batchActionToData (action: string, ids: string[], formData) {
  const { group_id } = formData;
  if (group_id) return {action, ids, group_id};
  return {action, ids};
}

const batchDefinition: IBatchDefinition = {
  SubForm: UserTableBatchActionSubForm,
  permission: 'user_batch',
  url: '/user/batch',
  formToData: batchActionToData,
  actions: [
    {
      value: 'group_add',
      label: 'Lägg till i grupp',
    },
    {
      value: 'group_remove',
      label: 'Ta bort från grupp',
    },
    {
      value: 'group_set',
      label: 'Ange alla gruppmedlemmar',
    },
  ],
};

function UserTableBatchActionSubForm (props: IBatchActionSubForm) {
  const { action } = props;

  const selectId = React.useId();

  const [group, setGroup] = React.useState('');

  const onChange = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    setGroup(ev.currentTarget.value);
  };

  const isLoadingOptions: boolean = useIsFetching({
    queryKey: ['groupSelectOptions'],
  }) > 0;

  if (!['group_add', 'group_remove', 'group_set'].includes(action)) {
    return null;
  }

  return (
    <Form.Group className="me-2">
      <Form.Select
        id={selectId}
        disabled={isLoadingOptions}
        required
        name="group_id"
        value={group}
        onChange={onChange}
      >
        <option value="">Välj grupp</option>
        <GroupSelectOptions />
      </Form.Select>
    </Form.Group>
  );
}

const loginMethodFilterOptions = constants.user.login_methods.map((value: string) => ({
  value,
  label: userFilter.loginMethod(value),
}));

const passwordStrengthFilterOptions = [0, 1, 2, 3, 4].map(value => ({
  value: String(value),
  label: value + ' - ' + userFilter.passwordStrength(value),
}));

const userRoleFilterOptions = constants.user.roles.map((value: string) => ({
  value,
  label: userFilter.role(value),
}));

const filterDefinitions: IFilterDefinition[] = [
  commonFilterDefs.string({id: 'id'}),
  commonFilterDefs.string({id: 'name'}),
  commonFilterDefs.string({id: 'email'}),
  commonFilterDefs.string({id: 'telephone_number'}),
  commonFilterDefs.boolean({id: 'is_active'}),
  commonFilterDefs.groupId({id: 'group_id'}),
  commonFilterDefs.bankId({id: 'bank_id'}),
  commonFilterDefs.select({id: 'login_method'}, loginMethodFilterOptions),
  commonFilterDefs.select({id: 'password_strength'}, passwordStrengthFilterOptions),
  commonFilterDefs.select({id: 'role'}, userRoleFilterOptions),
  commonFilterDefs.dateRange({id: 'created_at'}),
  commonFilterDefs.dateRelative({id: 'created_at_relative'}),
  commonFilterDefs.dateRange({id: 'updated_at'}),
  commonFilterDefs.dateRelative({id: 'updated_at_relative'}),
].map(filterDefinition => ({
  title: userFilter.indexColumnName(filterDefinition.id),
  ...filterDefinition,
}));

const columnDefinitions: IColumnDefinition[] = [
  commonColumnDefs.select(),
  ...([
    commonColumnDefs.cell('photo', UserPhotoTableCell),
    commonColumnDefs.userId({id: 'id', cellProps: {rowAsObject: true}}),
    commonColumnDefs.basic({id: 'name', sortable: true}),
    commonColumnDefs.cell('email'),
    commonColumnDefs.cell('functions'),
    commonColumnDefs.cell('role'),
    commonColumnDefs.cell('password_strength', UserPasswordStrengthTableCell),
    commonColumnDefs.cell('login_method'),
    commonColumnDefs.bankId({id: 'bank_id', cellProps: {idKey: 'bank_id'}}),
    commonColumnDefs.cell('group_id'),
    commonColumnDefs.cell('ip_last_login', UserIpLastLoginTableCell),
    commonColumnDefs.inputMutator({
      id: 'ip_whitelist',
      cellProps: {
        rowValueKey: 'ip_whitelist',
        getUrl: row => `/user/${row.id}`,
        getData: (rowValueKey, rawValue: string) => {
          const value = rawValue.split(',');
          return {[rowValueKey]: value};
        },
        mutateProps: {method: 'patch', optimistic: true},
        inputProps: {name: 'ip_whitelist'},
      },
    }),
    commonColumnDefs.cell('login_method_bankid_personal_number'),
    commonColumnDefs.cell('telephone_number'),
    commonColumnDefs.cell('status', TableCells.UserStatusTableCell),
    commonColumnDefs.datetime({
      id: 'last_login_at',
      sortable: true,
      groups: ['dates', 'sortable'],
    }),
    commonColumnDefs.datetime({
      id: 'last_password_change_at',
      sortable: true,
      groups: ['dates', 'sortable'],
    }),
    commonColumnDefs.datetime({
      id: 'created_at',
      sortable: true,
      groups: ['dates', 'sortable'],
    }),
    commonColumnDefs.datetime({
      id: 'updated_at',
      sortable: true,
      groups: ['dates', 'sortable'],
    }),
  ].map(columnDefinition => {
    const { id } = columnDefinition;
    const title = userFilter.indexColumnName(id);
    const cellValue = user => userFilter.indexField(user, id);
    return {
      ...columnDefinition,
      cellValue,
      title,
    };
  })),
  commonColumnDefs.actions({}, [
    UserSwitchUserButton,
    TableCellActions.InspectRowTableCellAction,
  ]),
];

function UserSwitchUserButton (props: IColumnCellProps) {
  const { row } = props;
  if (row.role === 'admin') return null;
  return (
    <Button size="sm" className="py-0 me-2">
      Logga in
    </Button>
  );
}

function UserPhotoTableCell (props: IColumnCellProps) {
  const { row } = props;
  return <AvatarImg user={row} style={{maxHeight: '30px'}} />;
}

function UserIpLastLoginTableCell (props: IColumnCellProps) {
  const { row } = props;
  const { ip_last_login, ip_last_login_country } = row;
  if (!ip_last_login) return null;
  return (
    <>
      {ip_last_login_country ? <CountryFlag countryCode={ip_last_login_country} /> : null}
      {' '}{ip_last_login}
    </>
  );
}

function UserPasswordStrengthTableCell (props: IColumnCellProps) {
  const { row } = props;
  const passwordStrength = row.password_strength;
  if (passwordStrength === null) return <></>;
  const label = userFilter.passwordStrength(passwordStrength);
  const variant = getPasswordStrengthColor(passwordStrength);
  return (
    <ProgressBar
      variant={variant}
      label={label}
      min={0}
      now={passwordStrength}
      max={4}
      style={{minWidth: '30%'}}
    />
  );
}

function getPasswordStrengthColor (strength: number) {
  switch (strength) {
    default: return 'danger';
    case 0: return 'danger';
    case 1: return 'warning';
    case 2: return 'warning';
    case 3: return 'success';
    case 4: return 'success';
  }
}

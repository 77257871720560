import React from 'react';
import { Form } from 'react-bootstrap';

export default function IndeterminateCheckbox (props) {
  const { indeterminate, checked, ...rest } = props;
  const ref = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (typeof indeterminate === 'boolean' && ref.current) {
      ref.current.indeterminate = !checked && indeterminate;
    }
  }, [checked, ref, indeterminate]);

  return (
    <Form.Check
      ref={ref}
      checked={checked}
      {...rest}
    />
  );
}

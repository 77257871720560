import React from 'react';
import BlockSpinner from 'src/spinners/BlockSpinner';
import ErrorAlert from 'src/alerts/ErrorAlert';
import StatusLabelContainer from  'src/components/StatusLabelContainer';
import { allWidgets, widgetGroups } from 'src/bank/BankWidgets';
import WidgetList from 'src/widgets/WidgetList';
import { StatusLabel } from  'src/bank/BankFormatters';
import useWidgetList from 'src/hooks/useWidgetList';
import { useQuery } from '@tanstack/react-query';
import { BankRow } from 'shared/types/bank';

interface BankQuicklookProps {
  id: string;
}

const BankQuicklook: React.FC<BankQuicklookProps> = React.memo(function BankQuicklook (props: BankQuicklookProps) {
  const { id } = props;
  const query = useQuery<BankRow, Error>({
    queryKey: [`/bank/${id}`],
  });

  const [state, dispatch] = useWidgetList('bankQuicklook', ['log']);

  const bank = query?.data;

  return (
    <div className="position-relative">
      <ErrorAlert error={query.error} className="mx-3 my-0" />
      <BlockSpinner isLoading={query.isLoading} className="mx-3" center />
      {bank && (
        <>
          <StatusLabelContainer>
            <StatusLabel value={bank.is_active} />
          </StatusLabelContainer>
          <WidgetList
            allWidgets={allWidgets}
            widgetGroups={widgetGroups}
            state={state}
            dispatch={dispatch}
            widgetProps={{bank}}
          />
        </>
      )}
    </div>
  );
});
export default BankQuicklook;

import React, { ChangeEvent } from 'react';
import { BasicCell } from 'src/tables/TableCells';
import { TTableParams, IColumnDefinition } from 'src/tables/Table';
import { IUpdateRowMutationData, TableAlign, TableRow } from 'src/tables/types';
import classNames from 'classnames';

interface ITableTd {
  row: TableRow;
  columnDefinition: IColumnDefinition;
  selected?: boolean;
  highlighted?: boolean;
  onChangeSelected?: (ev: ChangeEvent<HTMLInputElement>) => void;
  updateRowMutateAsync?: (data: IUpdateRowMutationData) => Promise<any>;
  params?: TTableParams;
  align?: TableAlign;
}

const TableTd: React.FC<ITableTd> = React.memo(function TableTd (props: ITableTd) {
  const {
    align,
    row,
    columnDefinition,
    selected = false,
    highlighted = false,
    onChangeSelected = () => {},
    updateRowMutateAsync = async () => {},
    params,
  } = props;
  if (!columnDefinition) return null;
  const {
    Cell = BasicCell,
    cellProps,
    cellValue,
    cellOriginal,
  } = columnDefinition;

  const value = typeof cellValue === 'function' ? cellValue(row) : cellValue;
  const original = typeof cellOriginal === 'function' ? cellOriginal(row) : undefined;

  // note: highlight doesn't work on striped tables for the striped row
  const className = classNames(align ? `align-${align}` : null, highlighted ? 'bg-success-subtle' : null);

  return (
    <td className={className}>
      <Cell
        value={value}
        original={original}
        row={row}
        selected={selected}
        onChangeSelected={onChangeSelected}
        updateRowMutateAsync={updateRowMutateAsync}
        params={params}
        {...cellProps}
      />
    </td>
  );
});
export default TableTd;

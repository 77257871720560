import React from 'react';
import isObjectLike from 'lodash/isObjectLike';
import * as CustomerFormatters from  'src/customer/CustomerFormatters';
import * as applicationFilter from 'likvidum/shared/filter/application';
import StatusBaseLabel, {StatusBaseLabelProps} from 'src/formatters/StatusBaseLabel';
import {ApplicationRow, ApplicationRowWithRelations} from 'shared/types/application';

interface OrdinalProps {
  value: number | string;
}

export function Ordinal (props: OrdinalProps) {
  let ordinal = props.value;
  if (typeof ordinal === 'number') ordinal = String(ordinal);
  if (typeof ordinal !== 'string') return '';
  return ordinal.padStart(3, '0');
}

interface TitleProps {
  value: ApplicationRow | ApplicationRowWithRelations;
}

export function Title (props: TitleProps) {
  const { value:application } = props;
  if (!isObjectLike(application)) return null;

  let ordinal = application.ordinal;
  if (!ordinal) {
    if (application.application_id) {
      ordinal = application.application_id.split('_')[1];
      return <>{ordinal}</>;
    } else if (application.id) {
      ordinal = application.id.split('_')[1];
      return <>{ordinal}</>;
    }
  }

  // "application" might be a special type of log entry
  const { customer_name, form } = application;
  let { first_name, last_name } = application;
  if (form && (!first_name || !last_name)) {
    // check the form if we can find the names
    first_name = application.form?.main_applicant?.first_name;
    last_name = application.form?.main_applicant?.last_name;
  }
  if (customer_name || first_name || last_name) {
    const name = customer_name || [first_name || '', last_name || ''].filter(v => v).join(' ');
    return <>{name} #{ordinal}</>;
  }

  return (
    <>
      <CustomerFormatters.Name value={application.Customer} />
      {' '}#{ordinal}
    </>
  );
}

interface AcceptedProcessTitleProps {
  value: ApplicationRow;
}

export function AcceptedProcessTitle (props: AcceptedProcessTitleProps) {
  const { value:application } = props;
  if (!isObjectLike(application)) return null;
  const { accepted_bank_id, accepted_process_id } = application;
  if (!accepted_process_id) return null;
  return (
    <>
      <Title value={application} />
      {' '}({accepted_bank_id})
    </>
  );
}

interface StatusProps {
  value: ApplicationRow['status'];
}

export function Status (props: StatusProps) {
  const { value } = props;
  return applicationFilter.status(value);
}

interface StatusLabelProps extends StatusBaseLabelProps {
  value: ApplicationRow['status'];
  hasAcceptedProcess?: boolean;
}

export function StatusLabel (props: StatusLabelProps) {
  const { value, hasAcceptedProcess = false, ...restOfProps } = props;
  return (
    <StatusBaseLabel
      {...restOfProps}
      value={value}
      formatter={v => applicationFilter.statusAccepted(v, hasAcceptedProcess)}
    />
  );
}



/**
 * WARNING: This file is used clientside
 */

const { role:userRole } = require('shared/filter/user');
const numbers = require('shared/numbers');

exports.status = function (isActive) {
  return isActive ? 'Aktiv' : 'Inaktiv';
};

exports.statusValue = function (isActive) {
  return isActive ? 'active' : 'inactive';
};

exports.createProcessRoles = function (roles = {}) {
  if (roles.everyone) return 'Samtliga';
  return Object.keys(roles).map(role => {
    const value = roles[role];
    return value ? exports.createProcessRole(role) : null;
  }).filter(v => v).join(', ');
};

exports.createProcessRole = function (role) {
  switch (role) {
    default: return userRole(role);
    case 'portal': return 'Portalansökan';
    case 'everyone': return 'För samtliga';
  }
};

exports.expireProcessDays = function (bankSettings = {}) {
  const expire = bankSettings.expire_process_days;
  if (!numbers.isFiniteNumber(expire)) return '';
  return `Efter ${expire} ${expire === 1 ? 'dag' : 'dagar'}`;
};

exports.submitDelay = function (bankSettings = {}) {
  const active = bankSettings.submit_delay_active === true;
  const interest = bankSettings.submit_delay_on_minimum_interest_rate;
  const amount = bankSettings.submit_delay_unless_amount_bid;
  const minutes = bankSettings.submit_delay_minutes;
  if (!active || !numbers.isFiniteNumber(minutes)) {
    return '';
  }

  const start = `Efter ${minutes} ${minutes === 1 ? 'minut' : 'minuter'} `;
  const tokens = [
    numbers.isFiniteNumber(interest) ? `om ränta > ${numbers.precision(numbers.percentage(interest), 2)} %` : null,
    numbers.isFiniteNumber(amount) ? `om belopp < ${amount} kr` : null,
  ].filter(v => v !== null);
  return start + tokens.join(' och ');
};

exports.fieldName = function (field) {
  switch (field) {
    default: return field;
    case 'is_active': return 'Status';
  }
};

import React from 'react';
import { CustomerRow } from 'shared/types/customer';
import AssociatedObjectList from 'src/components/AssociatedObjectList';
import {oldCrmUrl} from 'src/misc';

interface CustomerAssociatedObjectListProps {
  customer: CustomerRow;
}

function CustomerAssociatedObjectList (props: CustomerAssociatedObjectListProps) {
  const { customer } = props;

  const externalLinks = [
    {url: oldCrmUrl(`/customer/${customer.id}/`), label: 'Gamla CRM'},
  ].filter(v => v);

  return (
    <AssociatedObjectList
      associatedObjects={[]}
      externalLinks={externalLinks}
    />
  );
}
export default React.memo(CustomerAssociatedObjectList);

import React, { HTMLAttributes } from 'react';
import { Spinner } from 'react-bootstrap';

interface IBasicSpinner extends HTMLAttributes<HTMLDivElement> {
  isLoading: boolean;
  spinnerProps?: ({[key: string]: any});
}

export default function BasicSpinner (props: IBasicSpinner) {
  const { className = 'me-2', isLoading, spinnerProps = {}, ...otherProps } = props;
  if (!isLoading) return null;
  return (
    <div className={className} {...otherProps}>
      <Spinner
        animation="border"
        variant="default"
        size="sm"
        {...spinnerProps}
      />
    </div>
  );
}



import React from 'react';
import ModalAnchor from 'src/anchors/ModalAnchor';
import CustomerConsentModalBody from 'src/customerConsent/CustomerConsentModalBody';
import { Name } from 'src/customerConsent/CustomerConsentFormatters';
import { CustomerConsentRow } from 'shared/types/customer_consent';

interface CustomerConsentAnchorProps {
  consent: CustomerConsentRow;
}

export default function CustomerConsentAnchor (props: CustomerConsentAnchorProps) {
  const { consent, ...restOfProps } = props;
  return (
    <ModalAnchor
      {...restOfProps}
      title={<Name value={consent} />}
      name="Samtycke"
    >
      <CustomerConsentModalBody id={consent.id} />
    </ModalAnchor>
  );
}

import React from 'react';
import { useField, useFormikContext } from 'formik';
import { isFiniteNumber } from 'src/numbers';
import {
  Form,
  FormControlProps,
  InputGroup,
} from 'react-bootstrap';

interface FormikTotpProps {
  baseName: string;
  className?: string;
  label?: string;
  disabled?: boolean;
}
const FormikTotp: React.FC<FormikTotpProps> = React.memo(function FormikTotp (props: FormikTotpProps) {
  const { label, baseName, className, disabled = false } = props;

  const formik = useFormikContext();

  const id0 = React.useId();
  const ref = React.useRef<null | HTMLDivElement>(null);

  const getInputByTotpIndex = (totpIndex: number): HTMLInputElement | undefined => {
    if (!ref.current) return;
    const input = ref.current.querySelector(`input[data-totp-index='${totpIndex}']`) as HTMLInputElement;
    return input;
  };

  React.useEffect(() => {
    if (!ref.current) return;
    getInputByTotpIndex(0)?.focus();
  }, [ref]);

  const onPaste = (ev: React.ClipboardEvent<HTMLDivElement>) => {
    ev.preventDefault();
    const arr = ev.clipboardData.getData('text').replace(/\D/g, '').split('').slice(0, 6);

    const update = arr.reduce((update, char, totpIndex) => {
      const digit = parseInt(char, 10);
      if (!isFiniteNumber(digit) || digit < 0 || digit > 9) return update;
      update[`${baseName}${totpIndex}`] = String(digit);
      return update;
    }, {});

    formik.setValues(prevValues => {
      return {...prevValues, ...update};
    });
  };

  return (
    <Form.Group className={className}>
      {label && (
        <Form.Label className="mb-1 mt-3" htmlFor={id0}>
          {label}
        </Form.Label>
      )}
      <InputGroup hasValidation ref={ref} onPaste={onPaste}>
        <TotpFormControl
          name={`${baseName}0`}
          autoComplete="one-time-code"
          totpIndex={0}
          getInputByTotpIndex={getInputByTotpIndex}
          id={id0}
          disabled={disabled}
        />
        <TotpFormControl
          name={`${baseName}1`}
          totpIndex={1}
          getInputByTotpIndex={getInputByTotpIndex}
          disabled={disabled}
        />
        <TotpFormControl
          name={`${baseName}2`}
          totpIndex={2}
          getInputByTotpIndex={getInputByTotpIndex}
          disabled={disabled}
        />
        <TotpFormControl
          name={`${baseName}3`}
          totpIndex={3}
          getInputByTotpIndex={getInputByTotpIndex}
          disabled={disabled}
        />
        <TotpFormControl
          name={`${baseName}4`}
          totpIndex={4}
          getInputByTotpIndex={getInputByTotpIndex}
          className="rounded-0 text-center"
          disabled={disabled}
        />
        <TotpFormControl
          name={`${baseName}5`}
          totpIndex={5}
          getInputByTotpIndex={getInputByTotpIndex}
          disabled={disabled}
        />
      </InputGroup>
    </Form.Group>
  );
});
export default FormikTotp;

interface TotpFormControlProps extends FormControlProps { 
  name: string;
  totpIndex: number;
  getInputByTotpIndex: (index: number) => HTMLInputElement | undefined;
}

function TotpFormControl (props: TotpFormControlProps) {
  const { name, totpIndex, getInputByTotpIndex, ...restOfProps } = props;

  const [field, meta] = useField({
    name,
  });

  const onKeyUp = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    switch (ev.key) {
      default: return;
      case 'Backspace': {
        if (ev.target.selectionStart > 0) return;
        const prev = getInputByTotpIndex(totpIndex - 1);
        if (prev) prev.focus();
      }
    }
  };

  const onChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = ev.target.value;

    if (newValue.length > 1) return;
    if (newValue.length > 0 && !isFiniteNumber(parseInt(newValue, 10))) return;

    const oldValue = field.value ?? '';
    field.onChange(ev);

    if (newValue.length > 0 && oldValue.length <= 0) {
      // digit added, focus next
      const next = getInputByTotpIndex(totpIndex + 1);
      if (next) next.focus();
    } else if (newValue.length <= 0 && oldValue.length > 0) {
      // digit removed, focus prev
      const prev = getInputByTotpIndex(totpIndex - 1);
      if (prev) prev.focus();
    }
  };

  return (
    <Form.Control
      className="text-center"
      data-totp-index={totpIndex}
      {...restOfProps}
      {...field}
      type="text"
      name={name}
      pattern="\d{1}"
      isInvalid={Boolean(meta.error)}
      style={{fontSize: '240%'}}
      onKeyUp={onKeyUp}
      onChange={onChange}
    />
  );
}

import axios, { AxiosRequestConfig } from 'axios';

interface RequestProps extends AxiosRequestConfig {
  returnData?: boolean;
}

export async function request (props: RequestProps) {
  const { returnData = true, ...otherProps } = props;
  return axios({
    method: 'get',
    timeout: 60000,
    baseURL: '/api',
    ...otherProps,
    headers: {
      'Accept': 'application/json',
      ...(otherProps.headers || {}),
    },
  }).then(response => {
    if (returnData) return response.data;
    return response;
  });
}

// see crmServer/routes/auth "limitedInfoErrorMiddleware"
export type ErrorLimited = {
  error: string;
  ok: false;
};



const user = require('shared/filter/user');

exports.role = function (role) {
  switch (role) {
    default: return role;
    case 'employee': return 'Anställd';
    case 'administrator':
    case 'salesman':
    case 'advisor':
      return user.role(role);
  }
};

exports.size = function (size, precision = 2) {
  if (typeof size !== 'number' || !isFinite(size)) return '';
  size /= 1024;
  return size.toFixed(precision);
};

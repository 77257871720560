import React from 'react';
import * as api from 'src/api';
import { useQuery } from '@tanstack/react-query';
import { getLinkToFilteredTable } from 'src/tables/helpers';
import CounterCard from 'src/cards/CounterCard';
import {
  Row,
  Col,
} from 'react-bootstrap';

interface QueryResponse {
  workNewCount: number;
  workScheduledCount: number;
  bankProcessErrorCount: number;
  smsErrorCount: number;
  emailErrorCount: number;
  letterErrorCount: number;
}

const workScheduledUrl = getLinkToFilteredTable('/work_scheduled', [], [
].filter(v => v));

const workNewUrl = getLinkToFilteredTable('/work', [], [
  {key: 'status', value: 'new'},
].filter(v => v));

const bankProcessErrorUrl = getLinkToFilteredTable('/bank_processes', [], [
  {key: 'status', value: 'error'},
].filter(v => v));

const smsErrorUrl = getLinkToFilteredTable('/sms', [], [
  {key: 'status', value: 'error'},
].filter(v => v));

const letterErrorUrl = getLinkToFilteredTable('/letter', [], [
  {key: 'status', value: 'error'},
].filter(v => v));

const emailErrorUrl = getLinkToFilteredTable('/email', [], [
  {key: 'status', value: 'error'},
].filter(v => v));

export default function DashboardStatusCounterCard (props) {
  const query = useQuery<QueryResponse>({
    queryKey: ['DashboardStatusCounterCard'],
    queryFn: () => api.request({url: '/dashboard/status', params: {}}),
  });

  const baseProps = {
    isLoading: query.isLoading || query.isRefetching,
    onRefetch: query.refetch,
  };

  const data = query?.data;

  return (
    <Row>
      <Col xxl="2" lg="4" xs="6">
        <CounterCard
          {...baseProps}
          title="Schemalagda jobb"
          count={data?.workScheduledCount}
          url={workScheduledUrl}
        />
      </Col>
      <Col xxl="2" lg="4" xs="6">
        <CounterCard
          {...baseProps}
          title="Köade jobb"
          highMark={10}
          count={data?.workNewCount}
          url={workNewUrl}
        />
      </Col>
      <Col xxl="2" lg="4" xs="6">
        <CounterCard
          {...baseProps}
          title="Felande processer"
          highMark={50}
          count={data?.bankProcessErrorCount}
          url={bankProcessErrorUrl}
        />
      </Col>
      <Col xxl="2" lg="4" xs="6">
        <CounterCard
          {...baseProps}
          title="Felande brev"
          highMark={1}
          count={data?.letterErrorCount}
          url={letterErrorUrl}
        />
      </Col>
      <Col xxl="2" lg="4" xs="6">
        <CounterCard
          {...baseProps}
          title="Felande SMS"
          highMark={1}
          count={data?.smsErrorCount}
          url={smsErrorUrl}
        />
      </Col>
      <Col xxl="2" lg="4" xs="6">
        <CounterCard
          {...baseProps}
          title="Felande e-post"
          highMark={1}
          count={data?.emailErrorCount}
          url={emailErrorUrl}
        />
      </Col>
    </Row>
  );
}

import React from 'react';

interface BooleanProps extends React.HTMLAttributes<HTMLSpanElement> {
  value?: boolean;
  canBeIndetermined?: boolean;
}

export default function Boolean (props: BooleanProps) {
  const { value, canBeIndetermined = false, ...spanProps } = props;
  if (canBeIndetermined && typeof value !== 'boolean') return null;
  if (value) return <span {...spanProps}>Ja</span>;
  return <span {...spanProps}>Nej</span>;
}

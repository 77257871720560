

/**
 * WARNING: This file is used clientside
 */

const getter = require('shared/get');

exports.fieldName = function (fieldName) {
  return exports.indexColumnName(fieldName);
};

exports.indexColumnName = function (columnName) {
  switch (columnName) {
    default: return columnName;
    case '_select': return 'Markera';
    case 'actions': return 'Åtgärder';
    case 'application_id': return 'Ansökan';
    case 'body': return 'Meddelandetext';
    case 'complaint_id': return 'Klagomål';
    case 'customer_cloud_insurance_id': return 'Försäkring';
    case 'created_at': return 'Skapad';
    case 'created_at_relative': return 'Skapad';
    case 'foreign_id': return 'Tillhörande objekt';
    case 'from': return 'Avsändare';
    case 'from_address': return 'Avsändaradress';
    case 'id': return 'ID';
    case 'status': return 'Status';
    case 'status_at': return 'Statustid';
    case 'status_at_relative': return 'Statustid';
    case 'subject': return 'Ämne';
    case 'template_id': return 'Mall';
    case 'to': return 'Mottagare';
    case 'to_address': return 'Mottagaradress';
    case 'to_name': return 'Mottagarnamn';
    case 'updated_at': return 'Uppdaterad';
    case 'updated_at_relative': return 'Uppdaterad';
    case 'user_id': return 'Ägare';
  }
};

exports.status = function (status) {
  switch (status) {
    default: return status;
    case 'new': return 'Nyskapad';
    case 'error': return 'Fel';
    case 'queued': return 'Köad';
    case 'sent': return 'Skickad';
  }
};

exports.indexField = function (email, columnName, options = {}) {
  const get = (...args) => getter(email, ...args);
  switch (columnName) {
    default: return get(columnName);
    case 'status': return exports.status(get('status'));
    case 'from':
    case 'to': {
      const { name, address } = get(columnName) || {};
      return [name, address ? `<${address}>` : ''].filter(v => v).join(' ');
    }
  }
};

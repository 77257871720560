import React, { useState, useMemo, useCallback, useId } from 'react';
import { Col, Row, Form, InputGroup, Button } from 'react-bootstrap';
import useAuth from 'src/hooks/useAuth';
import { stateFilterToAutoTitle, groupLabel } from 'src/tables/helpers';
import union from 'lodash/union';
import sortBy from 'lodash/sortBy';
import { preventDefaultSubmit } from 'src/misc';
import { IState, IStateFilter, TStateFilterValue } from 'src/tables/types';
import { IFilterDefinition } from 'src/tables/Table';
import TableFilterFormGroup from 'src/tables/TableFilterFormGroup';
import {X} from 'react-feather';

interface TableFilterViewOptions {
  searchText: string;
  showGroup: null | string;
}

interface TableFilterFormProps {
  onlyShowVisibleFilters: boolean;
  showSettings: boolean;
  filterDefinitions: IFilterDefinition[];
  stateFilter: IStateFilter[];
  stateFilterVisible: string[];
  stateTitleAuto: boolean;
  updateState: (newState: IState) => void;
}

const TableFilterForm: React.FC<TableFilterFormProps> = React.memo(function TableFilterForm (props: TableFilterFormProps) {
  const {
    onlyShowVisibleFilters = false,
    showSettings = false,
    filterDefinitions,
    stateFilter:filter,
    stateFilterVisible:filterVisible,
    stateTitleAuto:titleAuto,
    updateState,
  } = props;

  const auth = useAuth();

  const valueByFilterId = useMemo(() => filter.reduce((map, filter) => {
    map[filter.key] = filter.value;
    return map;
  }, {}), [filter]);

  const handleToggleVisible = useCallback((id: string) => {
    const isVisible = filterVisible.includes(id);
    const newFilterVisible = isVisible ? filterVisible.filter((iid: string) => iid !== id) : [...filterVisible, id];
    updateState({filterVisible: newFilterVisible});
  }, [filterVisible, updateState]);

  const handleChangeFilterValue = useCallback((id: string, value: TStateFilterValue) => {
    let newFilter;
    if (typeof value === 'undefined') {
      // remove the filter
      newFilter = filter.filter(existing => existing.key !== id);
    } else if (filter.some(existing => existing.key === id)) {
      // change existing filter
      newFilter = filter.map(f => {
        if (f.key !== id) return f;
        return {...f, value};
      });
    } else {
      // add new filter
      newFilter = [...filter, {key: id, value}];
    }

    if (newFilter) {
      const update: IState = {filter: newFilter};
      if (titleAuto) {
        update.title = stateFilterToAutoTitle(newFilter, filterDefinitions);
      }
      updateState(update);
    }
  }, [filter, updateState, filterDefinitions, titleAuto]);

  // TODO possibly this state should be elevated to <Table>
  const [viewOptions, setViewOptions] = useState<TableFilterViewOptions>({
    searchText: '',
    showGroup: null,
  });

  const eligibleFilterDefinitions = useMemo(() => {
    const result = filterDefinitions.filter(filterDefinition => {
      const { id, permission } = filterDefinition;
      const hasPermission = auth.permission(permission);
      const visibleByVisibility = (onlyShowVisibleFilters ? filterVisible.includes(id) : true);
      const visibleBySettings = (showSettings ? shouldShowBySettings(viewOptions, filterDefinition) : true);
      return hasPermission && visibleByVisibility && visibleBySettings;
    });
    return sortBy(result, 'title');
  }, [filterDefinitions, filterVisible, auth, viewOptions, onlyShowVisibleFilters, showSettings]);

  const filterGroups = useMemo(() => filterDefinitions.reduce<string[]>((groups, definition) => {
    return union(groups, definition.groups || []);
  }, []), [filterDefinitions]);

  if (onlyShowVisibleFilters && !filterVisible.length) {
    return null;
  }

  return (
    <div className="border-top">
      {showSettings && (
        <div className="border-bottom p-3">
          <TableFilterSettingsForm
            viewOptions={viewOptions}
            setViewOptions={setViewOptions}
            filterGroups={filterGroups}
          />
        </div>
      )}
      <Form onSubmit={preventDefaultSubmit} className="p-0">
        <div className="d-flex flex-wrap px-3 pt-3">
          {eligibleFilterDefinitions.map(filterDefinition => (
            <TableFilterFormGroup
              key={filterDefinition.id}
              id={filterDefinition.id}
              filterDefinition={filterDefinition}
              value={valueByFilterId[filterDefinition.id]}
              visible={filterVisible.includes(filterDefinition.id)}
              onToggleVisible={!onlyShowVisibleFilters ? handleToggleVisible : undefined}
              onChangeFilterValue={handleChangeFilterValue}
            />
          ))}
        </div>
      </Form>
    </div>
  );
});
export default TableFilterForm;

interface TableFilterSettingsFormProps {
  viewOptions: TableFilterViewOptions;
  setViewOptions: React.Dispatch<React.SetStateAction<TableFilterViewOptions>>;
  filterGroups: string[];
}

function TableFilterSettingsForm (props: TableFilterSettingsFormProps) {
  const { viewOptions, setViewOptions, filterGroups } = props;

  const changeHandler = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
    const newSettings = {...viewOptions, [ev.target.name]: ev.target.value};
    setViewOptions(newSettings);
  }, [viewOptions, setViewOptions]);

  const onClickReset = () => {
    setViewOptions(viewOptions => ({...viewOptions, searchText: ''}));
  };

  return (
    <Form onSubmit={preventDefaultSubmit}>
      <Row gap={3}>
        <Col>
          <Form.Group className="mb-3 mb-md-0">
            <InputGroup>
              <Form.Control
                name="searchText"
                value={viewOptions.searchText || ''}
                onChange={changeHandler}
                placeholder="Sök efter filter"
                style={{minWidth: '100px'}}
              />
              <Button
                variant={viewOptions.searchText ? 'danger' : 'outline-danger'}
                className="p-1"
                onClick={onClickReset}
                disabled={!viewOptions.searchText}
                title="Ta bort söktexten"
              >
                <X size={19} />
              </Button>
            </InputGroup>
          </Form.Group>
        </Col>
        <Col xs="auto">
          <div className="d-flex align-items-center border rounded ps-2 py-1 flex-wrap">
            {filterGroups.length > 0 ? ['', ...filterGroups].map(group => (
              <SettingsFilterGroupCheckbox
                key={group}
                group={group}
                onChange={changeHandler}
                value={viewOptions.showGroup || ''}
              />
            )) : undefined}
          </div>
        </Col>
      </Row>
    </Form>
  );
}

interface SettingsFilterGroupCheckboxProps {
  group: string;
  value: string;
  onChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
}

const SettingsFilterGroupCheckbox: React.FC<SettingsFilterGroupCheckboxProps> = React.memo(function SettingsFilterGroupCheckbox (props: SettingsFilterGroupCheckboxProps) {
  const { value, group, ...restOfProps } = props;
  const id = useId();
  return (
    <Form.Check
      className="mb-0 me-3"
      type="radio"
      name="showGroup"
      value={group}
      label={groupLabel(group)}
      checked={value === group}
      id={id}
      {...restOfProps}
    />
  );
});

function shouldShowBySettings (viewOptions, definition) {
  const { searchText, showGroup } = viewOptions;

  if (searchText && searchText.length > 0) {
    const regexp = new RegExp(searchText, 'gi');
    const { id, title } = definition;
    if (!regexp.test(id) && !regexp.test(title)) {
      return false;
    }
  }

  if (showGroup) {
    const groups = definition.groups || [];
    if (!groups.includes(showGroup)) {
      return false;
    }
  }

  return true;
}

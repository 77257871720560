import React from 'react';
import moment from 'moment';
import { TStateFilterMap } from 'src/tables/types';
import { Button } from 'react-bootstrap';

interface SetFilterButtonProps {
  setFilter: (newFilter: TStateFilterMap) => void;
  from: () => string;
  to: () => string;
  filter: TStateFilterMap;
  filterDateKey?: string;
  title: string;
}

export const SetFilterButton: React.FC<SetFilterButtonProps> = React.memo(function SetFilterButton (props: SetFilterButtonProps) {
  const {
    title,
    filter,
    filterDateKey = 'date',
    setFilter,
    from,
    to,
  } = props;

  const onClick = React.useCallback(() => {
    const newFilter = {
      ...filter,
      [filterDateKey]: {...(filter[filterDateKey] || {}), from: from(), to: to()},
    };
    setFilter(newFilter);
  }, [filter, setFilter, filterDateKey, from, to]);

  return (
    <Button
      className="ms-1 py-0 px-1"
      size="sm"
      variant="outline-primary"
      onClick={onClick}
    >
      {title}
    </Button>
  );
});
export default SetFilterButtonProps;

export const ThisYear = props => (
  <SetFilterButton
    {...props}
    title="I år"
    from={() => moment().startOf('year').format('YYYY-MM-DD')}
    to={() => moment().endOf('year').format('YYYY-MM-DD')}
  />
);

export const LastYear = props => (
  <SetFilterButton
    {...props}
    title="Föregående år"
    from={() => moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD')}
    to={() => moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD')}
  />
);

export const ThisMonth = props => (
  <SetFilterButton
    {...props}
    title="Denna månad"
    from={() => moment().startOf('month').format('YYYY-MM-DD')}
    to={() => moment().endOf('month').format('YYYY-MM-DD')}
  />
);

export const LastMonth = props => (
  <SetFilterButton
    {...props}
    title="Föregående månad"
    from={() => moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')}
    to={() => moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')}
  />
);

export const ThisWeek = props => (
  <SetFilterButton
    {...props}
    title="Denna vecka"
    from={() => moment().startOf('week').format('YYYY-MM-DD')}
    to={() => moment().endOf('week').format('YYYY-MM-DD')}
  />
);

export const LastWeek = props => (
  <SetFilterButton
    {...props}
    title="Föregående vecka"
    from={() => moment().subtract(1, 'week').startOf('week').format('YYYY-MM-DD')}
    to={() => moment().subtract(1, 'week').endOf('week').format('YYYY-MM-DD')}
  />
);

export const Today = props => (
  <SetFilterButton
    {...props}
    title="Idag"
    from={() => moment().startOf('day').format('YYYY-MM-DD')}
    to={() => moment().endOf('day').format('YYYY-MM-DD')}
  />
);

export const Yesterday = props => (
  <SetFilterButton
    {...props}
    title="Igår"
    from={() => moment().subtract(1, 'day').startOf('day').format('YYYY-MM-DD')}
    to={() => moment().subtract(1, 'day').endOf('day').format('YYYY-MM-DD')}
  />
);

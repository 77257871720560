import React from 'react';
import EventEmitterContext from 'src/contexts/EventEmitterContext';

export default function useEventEmit <EventType> (eventName: string) {
  const eventEmitter = React.useContext(EventEmitterContext);

  return (data: EventType) => {
    eventEmitter.emit(eventName, data);
  };
}

import React from 'react';
import { Modal } from 'react-bootstrap';
import { ObjectInspector, chromeLight } from 'react-inspector';

const theme: any = {
  ...chromeLight,
  BASE_BACKGROUND_COLOR: 'transparent',
};

export default function InspectObjectModal (props) {
  const {
    show,
    object,
    onHide,
  } = props;
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>
          Granska objekt
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="m-2">
        <ObjectInspector
          theme={theme}
          data={object}
          expandLevel={1}
        />
      </Modal.Body>
    </Modal>
  );
}

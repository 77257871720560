/**
 * WARNING: This file is used clientside
 */

const { fieldChange } = require('shared/filter/log');
const { indexField, indexColumnName } = require('shared/filter/user');
const getter = require('shared/get');

exports.description = function (log) {
  const data = getter(log, 'data', {}) || {};
  switch (getter(log, 'data.type')) {
    default:
      if (data.message) return data.message;
      return JSON.stringify(data);
    case 'field_changed': return fieldChange(data, indexColumnName, fieldValueFilter);
    case 'login_bankid': return 'Inloggad med BankID';
    case 'login_passport': return 'Passinloggad i andra CRM';
    case 'login': return 'Inloggad';
    case 'logout': return 'Utloggad';
    case 'created': return 'Skapad';
  }
};

function fieldValueFilter (field, value) {
  const mockUser = {[field]: value};
  return indexField(mockUser, field);
}

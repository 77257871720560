import React from 'react';
import isObjectLike from 'lodash/isObjectLike';
import StatusBaseLabel, {StatusBaseLabelProps} from 'src/formatters/StatusBaseLabel';
import * as bankProcessFilter from 'likvidum/shared/filter/bank_process';
import { BankProcessRow } from 'likvidum/shared/types/bank_process';
import { Title as ApplicationTitle } from 'src/application/ApplicationFormatters';

interface TitleProps {
  value: BankProcessRow & {bank_name?: string};
}

export function Title (props: TitleProps) {
  const { value:bankProcess } = props;
  if (!isObjectLike(bankProcess)) return null;
  const { bank_name = '', bank_id = '' } = bankProcess;
  return (
    <>
      <ApplicationTitle value={bankProcess} />
      {' '}({bank_name || bank_id})
    </>
  );
}

interface StatusProps {
  value: BankProcessRow['status'];
}

export function Status (props: StatusProps) {
  const { value } = props;
  return bankProcessFilter.status(value);
}

interface StatusLabelProps extends StatusBaseLabelProps {
  value: BankProcessRow['status'];
}

export function StatusLabel (props: StatusLabelProps) {
  const { value, ...restOfProps } = props;
  return (
    <StatusBaseLabel
      value={value}
      formatter={bankProcessFilter.status}
      {...restOfProps}
    />
  );
}

const numberFormatter = new Intl.NumberFormat('sv-SE', {
});

const currencyFormatter = new Intl.NumberFormat('sv-SE', {
  style: 'currency',
  currency: 'SEK',
  maximumFractionDigits: 0,
  useGrouping: true,
});

const percentageFormatter = new Intl.NumberFormat('sv-SE', {
  style: 'percent',
  maximumFractionDigits: 2,
});

export function numberfmt (numStr, format?): string {
  if (format) return (new Intl.NumberFormat('sv-SE', format)).format(numStr);
  return numberFormatter.format(numStr);
}

export function currency (numStr, format?): string {
  if (format) return (new Intl.NumberFormat('sv-SE', format)).format(numStr);
  return currencyFormatter.format(numStr);
}

export function percentage (numStr, format?): string {
  if (format) return (new Intl.NumberFormat('sv-SE', format)).format(numStr);
  return percentageFormatter.format(numStr);
}

// 500000 -> 500 tkr
export function sekThousandsScale (number): string {
  if (typeof number !== 'number' || !isFinite(number)) return '';
  return (Math.floor(number / 1000)) + ' tkr';
}

export function isFiniteNumber (num: any): num is number {
  return typeof num === 'number' && isFinite(num) && num >= 0;
}

import React from 'react';
import * as api from 'src/api';
import Main from 'src/ui/Main';
import Navbar from 'src/navbar/Navbar';
import Content from 'src/ui/Content';
import { Helmet } from 'react-helmet-async';
import PlaceholderCard from 'src/cards/PlaceholderCard';

// TODO finish

interface TaskRowLotteryPage {
}

const TaskLotteryPage: React.FC<TaskRowLotteryPage> = React.memo(function TaskLotteryPage (props: TaskRowLotteryPage) {
  const queryKey = ['task_lottery'];
  const queryFn = () => api.request({url: '/task_lottery/info'});
  return (
    <Main>
      <Navbar />
      <Content>
        <Helmet title="Lotteriet" />
        <PlaceholderCard
          queryKey={queryKey}
          queryFn={queryFn}
        />
      </Content>
    </Main>
  );
});
export default TaskLotteryPage;


import React from 'react';
import JaycomInsuranceListCard from 'src/jaycomInsurance/JaycomInsuranceListCard';
import { Card, Row, Col, Table } from 'react-bootstrap';
import { CustomerRow } from 'shared/types/customer';
import ExternalLinkAnchor from 'src/anchors/ExternalLinkAnchor';
import { CardTitleIcon } from 'src/cards/CardHelpers';
import {useQuery} from '@tanstack/react-query';
import { ErrorAlertCardBody  } from 'src/cards/CardHelpers';
import BlockSpinner from 'src/spinners/BlockSpinner';
import DateTime from 'src/formatters/DateTime';
import ExecuteButton from 'src/buttons/ExecuteButton';
import {LifeBuoy} from 'react-feather';
import * as IconButtons from 'src/buttons/IconButtons';
import useNotyf from 'src/hooks/useNotyf';

const JAYCOM_URL = process.env.REACT_APP_JAYCOM_URL || 'http://localhost';

interface CustomerPageInsuranceTabProps {
  customerId: string;
}

export default function CustomerPageInsuranceTab (props: CustomerPageInsuranceTabProps) {
  const { customerId } = props;

  return (
    <Row className="px-4 pt-4">
      <Col xl={5}>
        <CustomerPageInsuranceTabJaycomPropertyList customerId={customerId} />
      </Col>
      <Col xl={7}>
        <JaycomInsuranceListCard
          searchParams={{customer_id: customerId}}
          createParams={{customer_id: customerId}}
        />
      </Col>
    </Row>
  );
  
}

interface CustomerPageInsuranceTabJaycomPropertyListProps {
  customerId: string;
}

function CustomerPageInsuranceTabJaycomPropertyList (props: CustomerPageInsuranceTabJaycomPropertyListProps)  {
  const { customerId } = props;

  const notyf = useNotyf();

  const readQuery = useQuery<CustomerRow, Error>({
    queryKey: [`/customer/${customerId}`],
  });

  const onReload = () => {
    readQuery.refetch();
  };

  const onSuccessSync = () => {
    readQuery.refetch();
    notyf.success('Kundkortet synkades till Jaycom');
  };

  const customer = readQuery.data;

  return (
    <Card className="border">
      <Card.Header className="border-bottom bg-light">
        <CardTitleIcon
          title="Försäkringskund (Jaycom)"
          Icon={<LifeBuoy size={18} />}
          spinning={readQuery.isRefetching}
        >
          <IconButtons.RefreshObjectButton
            disabled={readQuery.isRefetching}
            onClick={onReload}
          />
        </CardTitleIcon>
      </Card.Header>
      <BlockSpinner isLoading={readQuery.isLoading} />
      <ErrorAlertCardBody error={readQuery.error} className="mx-0 mt-0 mb-3" />
      <Card.Body className="p-0">
        {customer && (
          <Table className="mb-0">
            <tbody>
              <tr>
                <th className="ps-3">Jaycom kund-ID</th>
                <td>
                  {customer.insurance_jaycom_customer_id ? (
                    <span className="d-inline-flex gap-1 align-items-baseline">
                      <strong>{customer.insurance_jaycom_customer_id}</strong>
                      <ExternalLinkAnchor href={`${JAYCOM_URL}/?page=crm.kund.visa&id=${customer.insurance_jaycom_customer_id}`} className="small">
                        Jaycom kundkort
                      </ExternalLinkAnchor>
                    </span>
                  ) : '-'}
                </td>
              </tr>
              <tr>
                <th className="ps-3">Jaycom synkdatum</th>
                <td>
                  {customer.insurance_jaycom_synced_at ? (
                    <DateTime value={customer.insurance_jaycom_synced_at} />
                  ) : '-'}
                </td>
              </tr>
            </tbody>
          </Table>
        )}
      </Card.Body>
      <Card.Footer className="p-3">
        <ExecuteButton
          url={`/customer/${customerId}/jaycom_insurance/sync`}
          onSuccess={onSuccessSync}
          variant={customer?.insurance_jaycom_customer_id ? 'primary' : 'success'}
          size="sm"
          disabled={readQuery.isLoading || readQuery.isRefetching}
        >
          Synka kundkort till Jaycom
        </ExecuteButton>
      </Card.Footer>
    </Card>
  );
}

import React from 'react';
import { Dropdown } from 'react-bootstrap';

export default function NavbarDropdown (props) {
  const {
    children,
    count,
    showBadge,
    icon: Icon,
    ...otherProps
  } = props;
  return (
    <Dropdown className="me-2 nav-item" align="end" {...otherProps}>
      <Dropdown.Toggle as="a" className="nav-link nav-icon dropdown-toggle">
        <div className="position-relative">
          <Icon className="align-middle" size={18} />
          {showBadge ? <span className="indicator">{count}</span> : null}
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu-lg py-0">
        {children}
      </Dropdown.Menu>
    </Dropdown>
  );
}

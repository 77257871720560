import React from 'react';
import useWebsocketModel, { TModelIds } from 'src/hooks/useWebsocketModel';

type CreateCallback <T> = (id: string, create: T) => void;

export default function useWebsocketModelCreates<T> (modelName: string, onCreateReceived: CreateCallback<T>) {
  const { websocket, setIds } = useWebsocketModel(modelName);

  React.useEffect(() => {
    websocket.methodEmitter.on('modelCreate', data => {
      if (!isApplicableCreate(modelName, data)) return;
      const { id, create } = data;
      onCreateReceived(id, create as T);
    });
    return () => {
      websocket.methodEmitter.off('modelCreate');
    };
  }, [websocket.methodEmitter, onCreateReceived, modelName]);

  return {
    setIds: (models: TModelIds) => {
      setIds(models);
    },
  };
}

interface IWebsocketModelCreate {
  id: string;
  modelName: string;
  create: object;
}

function isApplicableCreate (modelName: string, create: IWebsocketModelCreate) {
  if (modelName !== create.modelName) return false;
  return true;
}

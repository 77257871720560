import React from 'react';
import OverlaySpinner from 'src/spinners/OverlaySpinner';
import FormControlSpinner from 'src/spinners/FormControlSpinner';
import { RefreshObjectButton } from 'src/buttons/IconButtons';
import DateTime from 'src/formatters/DateTime';
import ApplicationAnchor from 'src/application/ApplicationAnchor';
import ErrorAlert from 'src/alerts/ErrorAlert';
import { CardTitleIcon } from 'src/cards/CardHelpers';
import { useQuery, useMutation } from '@tanstack/react-query';
import * as api from 'src/api';
import { StatusLabel } from 'src/application/ApplicationFormatters';
import {
  Card,
  Table,
  Form,
} from 'react-bootstrap';
import {
  AlertTriangle,
} from 'react-feather';
import { Formik, Field } from 'formik';

export default function ErroredApplicationsCard (props) {

  const query = useQuery({
    queryKey: ['ErroredApplicationsCard'],
    queryFn: () => api.request({
      url: '/dashboard/errors',
    }),
  });

  return (
    <Card className="flex-fill w-100 border">
      <Card.Header className="pb-1">
        <CardTitleIcon
          title="Felande ansökningar"
          Icon={<AlertTriangle size={18} />}
          spinning={query.isRefetching}
        >
          <RefreshObjectButton
            disabled={query.isRefetching}
            onClick={() => query.refetch()}
          />
        </CardTitleIcon>
      </Card.Header>
      <Card.Body className="p-0 position-relative">
        <ErrorAlert error={query.error} className="mx-3 mt-3 mb-2" />
        <OverlaySpinner isLoading={query.isLoading} />
        <div className="table-responsive">
          <Table striped className="my-0 border-bottom">
            <thead>
              <tr>
                <th>Länk</th>
                <th className="d-none d-lg-table-cell">Status</th>
                <th className="d-none d-lg-table-cell">Feltid</th>
                <th>Notat <small className="text-normal d-inline d-md-none">(syns av alla)</small></th>
              </tr>
            </thead>
            <tbody>
              {query.isSuccess && query.data.applications?.map(application => (
                <tr key={application.id}>
                  <td>
                    <ApplicationAnchor application={application} />
                  </td>
                  <td className="d-none d-lg-table-cell">
                    <StatusLabel short value={application.status} />
                  </td>
                  <td className="d-none d-lg-table-cell">
                    <DateTime value={application.created_at} />
                  </td>
                  <td>
                    <ApplicationNoteForm
                      id={application.id}
                      note={application.note}
                    />
                  </td>
                </tr>
              ))}
              {!query.data?.applications?.length && (
                <tr>
                  <td colSpan={4}>Det finns inga felande ansökningar</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Card.Body>
    </Card>
  );
}

function ApplicationNoteForm (props) {
  const { id, note } = props;

  const mutation = useMutation({
    mutationFn: data => api.request({
      method: 'patch',
      url: `/application/${id}`,
      data,
    }),
  });

  const initialValues = {note};

  const onSubmit = async (data, formProps) => {
    const result = await mutation.mutateAsync(data);
    formProps.resetForm({values: {note: result.note}});
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {formProps => (
        <Form onSubmit={formProps.handleSubmit}>
          <div className="position-relative">
            <Field name="note">
              {fieldProps => (
                <Form.Control
                  {...fieldProps.field}
                  maxLength="200"
                  style={{minWidth: '100px'}}
                  onBlur={ev => {
                    fieldProps.field.onBlur(ev);
                    if (formProps.dirty) {
                      formProps.submitForm();
                    }
                  }}
                />
              )}
            </Field>
            <FormControlSpinner isLoading={formProps.isSubmitting} />
          </div>
        </Form>
      )}
    </Formik>
  );
}

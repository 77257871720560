import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import ButtonSpinner from 'src/spinners/ButtonSpinner';
import * as api from 'src/api';
import ErrorAlert from 'src/alerts/ErrorAlert';
import { CustomerJaycomInsurance } from 'shared/types/customer_jaycom_insurance';
import InspectObjectModalButton from 'src/buttons/InspectObjectModalButton';
import * as JaycomInsuranceFormFields from 'src/jaycomInsurance/JaycomInsuranceFormFields';
import AccountNumberValidatorCard from 'src/cards/AccountNumberValidatorCard';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import * as formUtils from 'src/utils/form';
import useNotyf from 'src/hooks/useNotyf';

// note: this edits the "form" column of a jaycom insurance row, not the entire row

interface JaycomInsuranceFormEditFormCardProps {
  insurance: CustomerJaycomInsurance;
}

type ObjectType = CustomerJaycomInsurance['form'];

type FormValues = Required<Omit<ObjectType, 'monthly_costs'>>;

const JaycomInsuranceFormEditFormCard: React.FC<JaycomInsuranceFormEditFormCardProps> = React.memo(function JaycomInsuranceFormEditFormCard (props: JaycomInsuranceFormEditFormCardProps) {
  const { insurance } = props;

  const queryClient = useQueryClient();
  const notyf = useNotyf();

  const updateFormMutation = useMutation<ObjectType, Error, Partial<ObjectType>>({
    mutationFn: vars => api.request({
      url: `/customer_jaycom_insurance/${insurance.id}/form`,
      method: 'PATCH',
      data: vars,
    }).then(response => {
      return response.form;
    }),
    onSuccess: updatedJaycomInsuranceForm => {
      queryClient.setQueryData([`/customer_jaycom_insurance/${insurance.id}`], insurance => ({
        ...(insurance || {}),
        form: updatedJaycomInsuranceForm,
      }));
      notyf.success({message: 'Försäkringsformuläret uppdaterades'});
    },
  });

  const initialValues = insuranceToFormValues(insurance.form);

  const formCycleHelpers = formUtils.getFormikFormCycleHelpers<ObjectType, FormValues, ObjectType>({
    queryDataToFormValues: insuranceToFormValues,
    formValuesToMutationVars: values => {
      const { insured_amount } = values;
      return {
        ...values,
        insured_amount: typeof insured_amount === 'string' ? parseInt(insured_amount, 10) || 0 : insured_amount,
      };
    },
    mutateAsync: updateFormMutation.mutateAsync,
  });

  const colProps = {lg: 6};
  return (
    <Card className="border mb-1">
      <Card.Header className="border-bottom bg-light">
        <Card.Title className="mb-0">Försäkringsformulär</Card.Title>
      </Card.Header>
      <Formik initialValues={initialValues} onSubmit={formCycleHelpers.onSubmit} enableReinitialize>
        {formProps => (
          <Form>
            <Card.Body className="pt-0">

              <Row>

                <Col {...colProps}>
                  <JaycomInsuranceFormFields.FormInsuredAmout />
                </Col>

                <Col {...colProps}>
                  <JaycomInsuranceFormFields.FormPaymentMethod />
                </Col>

              </Row>

              {formProps.values.payment_method === 'autogiro' && (
                <div>
                  <h4 className="mt-4 mb-0">Konto för autogiro</h4>
                  <hr className="mt-1 mb-0" />

                  <Row>

                    <Col {...colProps}>
                      <JaycomInsuranceFormFields.FormClearingNo />
                    </Col>

                    <Col {...colProps}>
                      <JaycomInsuranceFormFields.FormAccountNo />
                    </Col>

                  </Row>

                  <AccountNumberValidatorCard
                    className="mt-3 mb-0"
                    clearingNumber={formProps.values.clearing_no}
                    accountNumber={formProps.values.account_no}
                  />

                </div>
              )}

            </Card.Body>
            <Card.Footer className="border-top">
              <ErrorAlert error={updateFormMutation.error} />
              <div className="d-flex gap-2 flex-wrap">
                <InspectObjectModalButton object={formProps} />
                <ButtonSpinner
                  type="submit"
                  className="rounded"
                  variant="success"
                  isLoading={formProps.isSubmitting}
                  disabled={!formProps.isValid || formProps.isSubmitting || !formProps.dirty}
                >
                  Spara
                </ButtonSpinner>
              </div>
            </Card.Footer>
          </Form>
        )}
      </Formik>
    </Card>
  );
});
export default JaycomInsuranceFormEditFormCard;

function insuranceToFormValues (insuranceForm: ObjectType): FormValues {
  const { payment_method, insured_amount, clearing_no, account_no } = insuranceForm;
  return {
    payment_method: payment_method || 'autogiro',
    insured_amount: insured_amount,
    clearing_no: clearing_no || '',
    account_no: account_no || '',
  };
}

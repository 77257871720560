import React from 'react';
import prospectFilter from 'shared/filter/prospect';
import * as TableCells from 'src/tables/TableCells';
import * as commonFilterDefs from 'src/tables/commonFilterDefinitions';
import * as commonColumnDefs from 'src/tables/commonColumnDefinitions';
import * as TableCellActions from 'src/tables/TableCellActions';
import { IColumnDefinition, IFilterDefinition } from 'src/tables/Table';
import TablePage from 'src/tables/TablePage';

export default function ProspectsTablePage () {
  return (
    <TablePage
      title="Prospekter"
      pageTitle="Prospekttabell"
      columnDefinitions={columnDefinitions}
      filterDefinitions={filterDefinitions}
      routeUrl="/prospects"
      baseQueryUrl="/prospect/table"
      baseQueryKey="ProspectTable"
      queryResultRowsKey="prospects"
    />
  );
}

const filterDefinitions: IFilterDefinition[] = [
  commonFilterDefs.uuid({id: 'id'}),
  commonFilterDefs.dateRange({id: 'created_at'}),
  commonFilterDefs.dateRelative({id: 'created_at_relative'}),
  commonFilterDefs.string({id: 'tracking_code_adtraction', groups: ['tracking']}),
  commonFilterDefs.string({id: 'tracking_code_adservice', groups: ['tracking']}),
  commonFilterDefs.string({id: 'tracking_code_gclid', groups: ['tracking']}),
  commonFilterDefs.string({id: 'tracking_code_ga_client_id', groups: ['tracking']}),
  commonFilterDefs.string({id: 'created_by_channel', groups: ['tracking']}),
  commonFilterDefs.ssn({id: 'ssn'}),
  commonFilterDefs.string({id: 'email'}),
  commonFilterDefs.boolean({id: 'email_blocked'}),
  commonFilterDefs.string({id: 'telephone'}),
  commonFilterDefs.boolean({id: 'telephone_blocked'}),
  commonFilterDefs.loanPurpose({id: 'loan_purpose'}),
  commonFilterDefs.prospectResult({id: 'result'}),
].map(filterDefinition => ({
  title: prospectFilter.indexColumnName(filterDefinition.id),
  ...filterDefinition,
}));

const columnDefinitions: IColumnDefinition[] = [
  // commonColumnDefs.select(),
  ...([
    commonColumnDefs.prospectId({id: 'id', cellProps: {rowAsObject: true}}),
    commonColumnDefs.datetime({
      id: 'created_at',
      sortable: true,
      groups: ['dates', 'sortable'],
    }),
    commonColumnDefs.basic({id: 'created_by_channel', groups: ['tracking']}),
    commonColumnDefs.basic({id: 'email', groups: ['form']}),
    commonColumnDefs.boolean({id: 'email_blocked', groups: ['form']}),
    commonColumnDefs.basic({id: 'loan_purpose', groups: ['form']}),
    commonColumnDefs.basic({id: 'submitter_ip_address'}),
    commonColumnDefs.basic({id: 'tracking_code_adtraction', groups: ['tracking']}),
    commonColumnDefs.basic({id: 'tracking_code_adservice', groups: ['tracking']}),
    commonColumnDefs.basic({id: 'tracking_code_ga_client_id', groups: ['tracking']}),
    commonColumnDefs.basic({id: 'tracking_code_gclid', groups: ['tracking']}),
    commonColumnDefs.basic({id: 'repayment_years', groups: ['form']}),
    commonColumnDefs.basic({id: 'ssn', groups: ['form']}),
    commonColumnDefs.basic({id: 'telephone', groups: ['form']}),
    commonColumnDefs.boolean({id: 'telephone_blocked', groups: ['form']}),
    commonColumnDefs.basic({id: 'total_loan_amount', groups: ['form']}),
    commonColumnDefs.basic({id: 'loan_new', groups: ['form']}),
    commonColumnDefs.basic({id: 'loan_now', groups: ['form']}),
    commonColumnDefs.basic({id: 'result'}),
    commonColumnDefs.applicationId({
      id: 'last_application_id',
      groups: ['last_application'],
      cellProps: {objKey: 'Customer.LastApplication'},
    }),
    commonColumnDefs.basic({
      id: 'last_application_status',
      Cell: TableCells.ApplicationStatusTableCell,
      cellProps: {
        rowKey: 'Customer.LastApplication.status',
        rowKeyAcceptedProcessId: 'Customer.LastApplication.accepted_process_id',
      },
      groups: ['last_application'],
    }),
    commonColumnDefs.datetime({
      id: 'last_application_created_at',
      groups: ['dates', 'last_application'],
    }),
  ].map(columnDefinition => {
    const { id } = columnDefinition;
    const header = prospectFilter.indexColumnName(id);
    const cellValue = prospect => prospectFilter.indexField(prospect, id);
    return {
      Cell: ({value}) => <>{value}</>,
      ...columnDefinition,
      cellValue,
      Header: ({value}) => <>{value}</>,
      title: header,
    };
  })),
  commonColumnDefs.actions({}, [
    TableCellActions.InspectRowTableCellAction,
  ]),
];

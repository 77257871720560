import React from 'react';
import ModalAnchor from 'src/anchors/ModalAnchor';
import FileModalBody from 'src/file/FileModalBody';
import { Name } from 'src/file/FileFormatters';

export default function FileAnchor (props) {
  const { file, ...restOfProps } = props;
  return (
    <ModalAnchor
      {...restOfProps}
      title={<Name value={file} />}
      name="Fil"
    >
      <FileModalBody id={file.id} />
    </ModalAnchor>
  );
}

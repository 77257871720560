import { PermissionCheckFunction } from 'src/contexts/AuthContext';
import { TaskRow } from 'shared/types/task';
import moment from 'moment';

export function isCompleted (task: TaskRow): boolean {
  return Boolean(task.is_done && task.completed_at);
}

export function isFailed (task: TaskRow): boolean {
  return Boolean(task.is_done && task.failed_at);
}

export function isDone (task: TaskRow): boolean {
  return isCompleted(task) || isFailed(task);
}

export function canUse (task: TaskRow, userId: string, permission: PermissionCheckFunction): boolean {
  return permission('task:read_others') || userId === task.user_id;
}

export function canComplete  (task: TaskRow, userId: string, permission: PermissionCheckFunction): boolean {
  if (!canUse(task, userId, permission)) return false;
  if (!permission('task_execute:complete')) return false;
  return !isDone(task);
}

export function canUncomplete (task: TaskRow, userId: string, permission: PermissionCheckFunction): boolean {
  if (!canUse(task, userId, permission)) return false;
  if (!permission('task_execute:uncomplete')) return false;
  return isDone(task);
}

export function canPostpone (task: TaskRow, userId: string, permission: PermissionCheckFunction): boolean {
  if (!canUse(task, userId, permission)) return false;
  if (!permission('task_execute:postpone')) return false;
  if (isDone(task)) return false;
  const { postponed_max:max, postponed_count:now } = task;
  if (max !== null && now < max) return false;
  return true;
}

export function canFail (task: TaskRow, userId: string, permission: PermissionCheckFunction): boolean {
  if (!canUse(task, userId, permission)) return false;
  if (!permission('task_execute:fail')) return false;
  if (isDone(task)) return false;
  const { postponed_max:max, postponed_count:now } = task;
  if (max !== null && max > now) return false;
  return true;
}

export function canEdit (task: TaskRow, userId: string, permission: PermissionCheckFunction): boolean {
  if (!canUse(task, userId, permission)) return false;
  if (!permission('task_update')) return false;
  return true;
}

export function canDelete (task: TaskRow, userId: string, permission: PermissionCheckFunction): boolean {
  if (!canUse(task, userId, permission)) return false;
  if (!permission('task_delete')) return false;
  return true;
}

// FIXME: this used to account for users display timezone, should be fixed
export function temporalStatusName (task: TaskRow, now: Date): string {
  if (task.failed_at) return 'failed';
  if (task.completed_at) return 'completed';
  const starts = task.time_starts ? new Date(task.time_starts) : null;
  const expires = task.time_expires ? new Date(task.time_expires) : null;
  if (expires && expires <= now) return 'missed'; // note: this was previously "delayed"
  const endOfToday = moment().endOf('day').toDate();
  if (starts && starts >= now && starts <= endOfToday) return 'upcoming-today';
  if (starts && starts <= now) return 'ongoing';
  if (starts && starts > endOfToday) return 'upcoming-future';
  return '';
}

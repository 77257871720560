import React from 'react';
import ModalAnchor from 'src/anchors/ModalAnchor';
import SmsModalBody from 'src/sms/SmsModalBody';
import { Name } from 'src/sms/SmsFormatters';

export default function SmsAnchor (props) {
  const { sms, ...restOfProps } = props;
  return (
    <ModalAnchor
      {...restOfProps}
      title={<Name value={sms} />}
      name="SMS"
    >
      <SmsModalBody id={sms.id} />
    </ModalAnchor>
  );
}

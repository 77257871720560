import React from 'react';
import InspectObjectModal from 'src/modals/InspectObjectModal';
import ModalOpeningButton from 'src/buttons/ModalOpeningButton';
import { Eye, Trash } from 'react-feather';
import ButtonReplacedSpinner from 'src/spinners/ButtonReplacedSpinner';
import ConfirmActionModal from 'src/modals/ConfirmActionModal';
import { IColumnCellProps } from 'src/tables/Table';
import { PermissionDefinition } from 'src/contexts/AuthContext';
import useAuth from 'src/hooks/useAuth';

// TODO replace with InspectObjectModalButton
export const InspectRowTableCellAction: React.FC<IColumnCellProps> = React.memo(function InspectRowTableCellAction (props: IColumnCellProps) {
  const { row } = props;
  return (
    <ModalOpeningButton
      Modal={InspectObjectModal}
      modalProps={{object: row}}
      variant="outline-secondary"
      className="px-1 py-0"
      title="Granska objektet"
    >
      <Eye size={14} />
    </ModalOpeningButton>
  );
});

interface ExecuteButtonTableCellActionProps extends IColumnCellProps {
  urlTemplate: string;
  variant?: string;
  title?: string;
  mutateProps?: Record<string, any>;
}

export const ExecuteButtonTableCellAction: React.FC<ExecuteButtonTableCellActionProps> = React.memo(function ExecuteButtonTableCellAction (props: ExecuteButtonTableCellActionProps) {
  const {
    row,
    updateRowMutateAsync,
    urlTemplate,
    mutateProps = {},
    variant = 'primary',
    title,
    children,
  } = props;

  const [isLoading, setIsLoading] = React.useState(false);

  const onClick = React.useCallback(() => {
    const url = urlTemplate.replace('%s', String(row.id));
    const mutateData = {
      data: {},
      method: 'POST',
      ...mutateProps,
      url,
      rowId: String(row.id),
      optimistic: false,
    };
    setIsLoading(true);
    return updateRowMutateAsync(mutateData).finally(() => {
      setIsLoading(false);
    });
  }, [urlTemplate, setIsLoading, updateRowMutateAsync, row, mutateProps]);

  return (
    <ButtonReplacedSpinner
      className="px-1 py-0 ms-1"
      isLoading={isLoading}
      onClick={onClick}
      variant={variant}
      title={title}
    >
      {children}
    </ButtonReplacedSpinner>
  );
});

interface DeleteRowTableCellActionProps extends IColumnCellProps {
  urlTemplate: string;
  objectName?: string;
  permission?: PermissionDefinition;
  variant?: string;
  mutateProps?: {[key: string]: any};
  successMessage?: string;
  confirmMessage?: string;
}

export const DeleteRowTableCellAction: React.FC<DeleteRowTableCellActionProps> = React.memo(function DeleteRowTableCellAction (props: DeleteRowTableCellActionProps) {
  const {
    row,
    urlTemplate,
    updateRowMutateAsync,
    variant = 'outline-danger',
    mutateProps = {},
    permission,
    successMessage = 'Raden raderades',
    confirmMessage = 'Är du säker på att du vill radera raden?',
  } = props;

  const [showModal, setShowModal] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const { hasAnyPermissions } = useAuth();

  const onClick = React.useCallback(() => {
    setShowModal(true);
  }, [setShowModal]);

  const onDecline = React.useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  const onConfirm = React.useCallback(() => {
    setShowModal(false);
    const url = urlTemplate.replace('%s', String(row.id));
    const mutateData = {
      onSuccessNotyf: {type: 'danger', message: successMessage},
      ...mutateProps,
      url,
      method: 'DELETE',
      rowId: String(row.id),
      deletion: true,
    };
    setIsLoading(true);
    return updateRowMutateAsync(mutateData).finally(() => {
      setIsLoading(false);
    });
  }, [urlTemplate, setIsLoading, updateRowMutateAsync, row, mutateProps, successMessage]);

  if (permission && !hasAnyPermissions(permission)) {
    return null;
  }

  return (
    <>
      <ButtonReplacedSpinner
        className="px-1 py-0 ms-1"
        isLoading={isLoading}
        onClick={onClick}
        variant={variant}
      >
        <Trash size={14} />
      </ButtonReplacedSpinner>
      <ConfirmActionModal
        show={showModal}
        onDecline={onDecline}
        onConfirm={onConfirm}
        message={confirmMessage}
      />
    </>
  );
});

/**
 * WARNING: This file is used clientside
 */

const getter = require('shared/get');

exports.indexColumnName = function (columnKey) {
  switch (columnKey) {
    default: return columnKey;
    case 'id': return 'ID';
    case 'customer_id': return 'Kund';
    case 'application_id': return 'Ansökan';
    case 'created_by_user_id': return 'Skapad av';
    case 'updated_by_user_id': return 'Uppdaterad av';
    case 'error': return 'Fel';
    case 'form': return 'Formulär';
    case 'form_status': return 'Formulärstatus';
    case 'status': return 'Status';
    case 'cloudinsurance_id': return 'Försäkrings-ID';
    case 'cloudinsurance_token': return 'Försäkrings-token';
    case 'cloudinsurance_url': return 'Försäkrings-URL';
    case 'cloudinsurance_quote_id': return 'Försäkringens offert-ID';
    case 'form.mainObject_datafields_510016': return 'Försäkrat månadsbelopp';
    case 'created_at': return 'Skapad';
    case 'created_at_relative': return 'Skapad';
    case 'updated_at': return 'Uppdaterad';
    case 'updated_at_relative': return 'Uppdaterad';
    case 'status_updated_at': return 'Statusuppdatering';
    case 'status_updated_at_relative': return 'Statusuppdatering';
    case 'actions': return 'Åtgärder';
  }
};

exports.indexField = function (customerCloudInsurance, columnKey) {
  const get = (...args) => getter(customerCloudInsurance, ...args);
  switch (columnKey) {
    default: return get(columnKey);
    case 'status': return exports.status(get('status'));
    case 'customer_id': return get('Customer.name') || get('Customer.id') || get('customer_id') || '';
    case 'created_by_user_id': return get('Creator.name') || get('Creator.id') || get('created_by_user_id') || '';
    case 'updated_by_user_id': return get('Updater.name') || get('Updater.id') || get('updated_by_user_id') || '';
    case 'form_status': return get('form_status') || '';
    case 'form.mainObject_datafields_510016': return exports.formDatafields510016ToInsuredSum(get(columnKey));
    case 'form': return get('form_status') || '';
  }
};

exports.formStatus = function (formStatus) {
  switch (formStatus) {
    default: return formStatus;
    case 'valid': return 'Ifyllt';
    case 'invalid': return 'Ej redo';
  }
};

exports.status = function (status) {
  switch (status) {
    default: return status;
    case 'new': return 'Nyskapad';
    case 'submitted': return 'Inskickad';
    case 'created': return 'Offert';
    case 'error': return 'Fel';
    case 'ci_pending': return 'Pågående';
    case 'ci_converted': return 'Konverterad';
    case 'ci_cancelled': return 'Avbruten';
    case 'ci_declined': return 'Nekad';
    case 'ci_expired': return 'Utgången';
  }
};

exports.formDatafields510016ToInsuredSum = function (value) {
  switch (String(value)) {
    default: return value ? `Okänt: ${value}` : '';
    case '550005': return '1500 kr';
    case '550008': return '2000 kr';
    case '550006': return '2500 kr';
    case '550003': return '3000 kr';
    case '550009': return '4000 kr';
    case '550010': return '5000 kr';
    case '550011': return '6000 kr';
    case '550012': return '7000 kr';
    case '550013': return '8000 kr';
    case '550014': return '9000 kr';
    case '550015': return '10000 kr';
  }
};

import React from 'react';
import DateTime from 'src/formatters/DateTime';
import classNames from 'classnames';
import OverlaySpinner from 'src/spinners/OverlaySpinner';
import ErrorAlert from 'src/alerts/ErrorAlert';
import { CardTitleIcon } from 'src/cards/CardHelpers';
import {RefreshObjectButton} from 'src/buttons/IconButtons';
import { useQuery } from '@tanstack/react-query';
import { CustomerLogRowWithRelations } from 'shared/types/customer_log';
import UserAnchor from 'src/user/UserAnchor';
import * as customerLogFilters from 'shared/filter/customer_log';
import { Card, Table } from 'react-bootstrap';
import { Activity } from 'react-feather';
import InspectObjectModalButton from 'src/buttons/InspectObjectModalButton';
import CountryFlag from 'src/formatters/CountryFlag';

interface CustomerLogCardProps {
  className?: string;
  customerId: string;
}

export default function CustomerLogCard (props: CustomerLogCardProps) {
  const { customerId, className } = props;

  const query = useQuery<CustomerLogRowWithRelations[], Error>({
    queryKey: [`/customer/${customerId}/log`],
  });

  const data = query.data ?? [];

  return (
    <Card className={classNames('border', className)}>
      <Card.Header className="border-bottom bg-light">
        <CardTitleIcon
          title="Kundlogg"
          Icon={<Activity size={18} />}
          spinning={query.isRefetching}
        >
          <RefreshObjectButton
            disabled={query.isRefetching}
            onClick={() => query.refetch()}
          />
        </CardTitleIcon>
      </Card.Header>
      <div className="position-relative table-responsive">
        <ErrorAlert error={query.error} className="mx-3 mt-3 mb-2" />
        <OverlaySpinner isLoading={query.isLoading} />
        <Table striped className="my-0 border-bottom">
          <thead>
            <tr>
              <th>Datum</th>
              <th>Utförare</th>
              <th>IP</th>
              <th>Beskrivning</th>
            </tr>
          </thead>
          <tbody>
            {query.isSuccess && data.map(item => (
              <tr key={item.id}>
                <td>
                  <DateTime value={item.date} />
                </td>
                <td>
                  {!item.User ? 'System' : (
                    <UserAnchor user={item.User} />
                  )}
                </td>
                <td>
                  {item.data.ip && (
                    <>
                      {item.data.ip_country && (
                        <CountryFlag countryCode={item.data.ip_country} />
                      )}
                      {item.data.ip}
                    </>
                  )}
                </td>
                <td>
                  {customerLogFilters.description(item)}
                </td>
                <td>
                  <div className="d-flex justify-content-end">
                    <InspectObjectModalButton
                      size="sm"
                      object={item}
                    />
                  </div>
                </td>
              </tr>
            ))}
            {!data.length && (
              <tr>
                <td colSpan={4} className="text-center">Det finns inga logginlägg</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </Card>
  );
}

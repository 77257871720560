import React from 'react';
import BlockSpinner from 'src/spinners/BlockSpinner';
import ErrorAlert from 'src/alerts/ErrorAlert';
import { StatusLabel } from  'src/application/ApplicationFormatters';
import StatusLabelContainer from 'src/components/StatusLabelContainer';
import { allWidgets, widgetGroups } from 'src/application/ApplicationWidgets';
import { useQuery } from '@tanstack/react-query';
import useWidgetList from 'src/hooks/useWidgetList';
import WidgetList from 'src/widgets/WidgetList';
import { ApplicationRow } from 'shared/types/application';

interface ApplicationQuicklookProps {
  id: string;
}
const ApplicationQuicklook: React.FC<ApplicationQuicklookProps> = React.memo(function ApplicationQuicklook (props: ApplicationQuicklookProps) {
  const { id } = props;

  const query = useQuery<ApplicationRow, Error>({
    queryKey: [`/application/${id}`],
  });

  const [state, dispatch] = useWidgetList('applicationQuicklook', [
    'parties',
    'applicants',
    'properties',
    'formLoan',
    'interestRateAndAmountChart',
  ]);

  const application = query?.data;

  return (
    <div className="position-relative">
      <ErrorAlert error={query.error} className="mx-3 my-0" />
      <BlockSpinner isLoading={query.isLoading} center className="mx-3" />
      {application && (
        <>
          <StatusLabelContainer>
            <StatusLabel value={application.status} />
          </StatusLabelContainer>
          <WidgetList
            allWidgets={allWidgets}
            widgetGroups={widgetGroups}
            state={state}
            dispatch={dispatch}
            widgetProps={{application}}
          />
        </>
      )}
    </div>
  );
});
export default ApplicationQuicklook;

import React from 'react';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import {
  Form,
  Button,
  InputGroup,
} from 'react-bootstrap';
import {
  Trash,
  Save,
  Edit,
} from 'react-feather';
import { stateFilterToAutoTitle } from 'src/tables/helpers';
import { IState } from 'src/tables/types';

export default function TableSavedStateForm (props) {
  const {
    defaultState,
    onDeleteState,
    onSaveNewState,
    onSwapState,
    savedStates,
    setState,
    setSavedStates,
    filterDefinitions,
    state,
  } = props;

  const [isEditing, setIsEditing] = React.useState(false);

  const swapStateHandler = ev => {
    const stateId = ev.target.value;
    if (stateId === 'default') {
      onSwapState({...defaultState, skipCount: true});
      return;
    }
    const newState = savedStates.find(state => state.id === stateId);
    if (newState) {
      onSwapState(newState);
    } else {
      onSwapState(omit(state, 'id'));
    }
  };


  const handleClickSaveNewState = () => onSaveNewState(state);

  const handleClickEditState = ev => {
    setIsEditingWrapped(true);
  };

  const handleClickDeleteState = () => {
    onDeleteState(state);
    setIsEditingWrapped(false);
  };

  const handleSaveTitles = values => {
    // since the title of the state is shown in the select dropdown we also
    // need to update the state object as it is in savedStates so there is no
    // flicker/hint of the old value when we are waiting for server confirmation
    const updatedState = {...state, ...values};
    if (values.titleAuto) values.title = null;
    const updatedSavedStates = savedStates.map(state => {
      if (state.id !== updatedState.id) return state;
      return updatedState;
    });
    setState(updatedState);
    setSavedStates(updatedSavedStates);
    setIsEditingWrapped(false);
  };

  const selectRef = React.useRef<HTMLSelectElement>(null);
  const [titleFormInputWidth, setTitleFormInputWidth] = React.useState(0);

  const setIsEditingWrapped = value => {
    if (value && selectRef.current) {
      // when beginning to edit the title of a saved state we want to match the
      // width of the select field with the input field, so we save the width here
      // and pass it on to StateTitleForm
      const width = selectRef.current.clientWidth;
      setTitleFormInputWidth(width);
    }
    setIsEditing(value);
  };

  return (
    <div className="d-flex flex-grow-1">
      {isEditing ? (
        <StateTitleForm
          initialValues={pick(state, 'title', 'titleAuto')}
          stateFilter={state.filter || []}
          onSave={handleSaveTitles}
          inputWidth={titleFormInputWidth}
          filterDefinitions={filterDefinitions}
        />
      ) : (
        <Form.Select
          name="state"
          value={state.id || ''}
          onChange={swapStateHandler}
          ref={selectRef}
        >
          <option value="">(Osparad sökning)</option>
          <option value="default">(Återställ sökning)</option>
          {(savedStates || []).map(state => (
            <option key={state.id} value={state.id}>
              {state.title || state.id}
            </option>
          ))}
        </Form.Select>
      )}
      {(state.id && !isEditing) && (
        <Button
          className="px-2 ms-2 d-inline-flex align-items-center"
          variant="outline-primary"
          title="Redigera sökning"
          onClick={handleClickEditState}
        >
          <Edit size={15} className="me-1"  /> Redigera
        </Button>
      )}
      {!state.id && (
        <Button
          className="px-2 ms-2 d-inline-flex align-items-center"
          variant="outline-primary"
          title="Spara sökning"
          onClick={handleClickSaveNewState}
        >
          <Save size={15} />
          <span className="d-none d-md-inline-block ms-1">Spara</span>
        </Button>
      )}
      <Button
        className="px-2 ms-2 d-inline-flex align-items-center"
        variant="outline-danger"
        title="Radera sparad sökning"
        onClick={handleClickDeleteState}
        disabled={!state.id}
      >
        <Trash size={15} />
        <span className="d-none d-md-inline-block ms-1">Radera</span>
      </Button>
    </div>
  );
}

function StateTitleForm (props) {
  const {
    onSave,
    initialValues,
    inputWidth,
    stateFilter,
    filterDefinitions,
  } = props;

  const [values, setValues] = React.useState(pick(initialValues, 'title', 'titleAuto'));
  const updateValue = (key, value) => setValues({...values, [key]: value});

  const handleChangeTitleAuto = ev => {
    const titleAuto = ev.target.checked;
    const update: IState = {titleAuto};
    if (titleAuto) {
      update.title = stateFilterToAutoTitle(stateFilter, filterDefinitions);
    }
    setValues({...values, ...update});
  };

  // focus input field when setting titleAuto to off
  const inputRef = React.useRef<HTMLInputElement>(null);
  React.useEffect(() => {
    if (inputRef.current && !values.titleAuto) inputRef.current.focus();
  }, [inputRef, values.titleAuto]);

  const handleSubmit = ev => {
    ev.preventDefault();
    onSave(values);
  };

  return (
    <Form onSubmit={handleSubmit} className="d-flex">
      <InputGroup className="flex-nowrap">
        <Form.Control
          name="title"
          value={values.title || ''}
          onChange={ev => updateValue(ev.target.name, ev.target.value)}
          placeholder="Ange titel"
          minLength={1}
          maxLength={255}
          disabled={values.titleAuto}
          ref={inputRef}
          required={!values.titleAuto}
          style={{width: inputWidth > 0 ? `calc(${inputWidth}px - 35px)` : undefined}}
        />
        <span
          className="input-group-text"
          style={{width: '37px'}}
        >
          <Form.Check
            name="titleAuto"
            className="mt-0"
            checked={values.titleAuto || false}
            onChange={handleChangeTitleAuto}
            title="Ange titel automatiskt"
          />
        </span>
      </InputGroup>
      <Button
        className="px-2 ms-2 d-inline-flex align-items-center"
        variant="outline-primary"
        title="Spara sökning"
        type="submit"
      >
        <Save size={15} className="me-1" /> Spara
      </Button>
    </Form>
  );
}

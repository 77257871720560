import React from 'react';

interface IdProviderProps  {
  children: (id: string) => React.ReactElement;
}

const IdProvider: React.FC<IdProviderProps> = React.memo(function IdProvider (props: IdProviderProps) {
  const { children } = props;
  const id = React.useId();
  return children(id);
});

export default IdProvider;

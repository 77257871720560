import React from 'react';
import { isEqual } from 'lodash';
import {FieldValidator, FormikErrors, FormikHelpers} from 'formik';
import { maybeAxiosErrorToErrorMap } from 'src/utils/error';
import {AxiosError} from 'axios';

export type TChangeValue<T = any, K = string> = (name: K, value: T) => void | Promise<any>;

export function preventDefaultSubmit (ev: React.FormEvent<HTMLFormElement>) {
  ev.preventDefault();
}

// returns all fields in obj that exist in a, but has a changed value in obj
export function changes <T> (base: T, obj: Partial<T>): Partial<T> {
  const changes = {} as Partial<T>;
  for (const baseKey in base) {
    if (baseKey in obj && !isEqual(base[baseKey], obj[baseKey])) {
      changes[baseKey] = obj[baseKey];
    }
  }
  return changes;
}

// returns the number of lines in the string
// may be used to set the "rows" attribute of a textarea to be adaptive
export function numberOfLines (str: string): number {
  return str.split('\n').length;
}

interface InputAttributes {
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  pattern?: string;
  // TODO fill in more, step, min, max
}

export function inputPropsToFormikValidate (attrs: InputAttributes): FieldValidator {
  const { required, minLength, maxLength, pattern } = attrs;
  return function (value) {
    if (required && !value) return 'Värdet måste anges';
    if (typeof minLength === 'number' && minLength > 0 && value?.length < minLength) return `Värdet måste vara minst ${minLength} tecken långt`;
    if (typeof maxLength === 'number' && maxLength > 0 && value?.length > maxLength) return `Värdet får vara max ${minLength} tecken långt`;
    if (typeof pattern === 'string' && !(new RegExp(pattern)).test(value)) return `Värdet måste matcha önskat format: ${pattern}`;
    return '';
  };
}

interface SelectAttributes {
  required?: boolean;
}

export function selectPropsToFormikValidate (attrs: SelectAttributes): FieldValidator {
  const { required } = attrs;
  return function (value) {
    if (required && !value) return 'Värdet måste anges';
    return '';
  };
}

interface CheckAttributes {
  required?: boolean;
}

export function checkPropsToFormikValidate (attrs: CheckAttributes): FieldValidator {
  const { required } = attrs;
  return function (value) {
    if (required && !value) return 'Värdet måste väljas';
    return '';
  };
}

// the basic form cycle works like this:
// 1. an object of type QueryData is read from the server (via useQuery or useMutation)
// 2. the object is converted to form values of type FormValues
// 3. the form values are converted to an update (mutationVars for a useMutation)
// 4. the useMutation is executed and it is expected to return an object of type QueryData
// 5. the cycle repeats at step 1
interface FormikFormCycleHelperOptions <QueryData, FormValues, MutationVars> {
  queryDataToFormValues: (data: QueryData) => FormValues;
  formValuesToMutationVars: (formValues: FormValues) => MutationVars;
  mutateAsync: (vars: MutationVars) => Promise<QueryData>;
}

export function getFormikFormCycleHelpers <QueryData = Record<string, any>, FormValues = Record<string, any>, MutationVars = Record<string, any>> (options: FormikFormCycleHelperOptions<QueryData, FormValues, MutationVars>) {
  const {
    queryDataToFormValues,
    formValuesToMutationVars,
    mutateAsync,
  } = options;

  const onSubmit = async function (newFormValues: FormValues, formHelpers: FormikHelpers<FormValues>) {
    const update = formValuesToMutationVars(newFormValues);
    try {
      const queryData = await mutateAsync(update);
      formHelpers.resetForm({values: queryDataToFormValues(queryData)});
    } catch (err) {
      formHelpers.setSubmitting(false);
      const errorMap = maybeAxiosErrorToErrorMap(err as AxiosError);
      if (errorMap) {
        formHelpers.setErrors(errorMap as FormikErrors<FormValues>);
        return;
      }
      throw err;
    }
  };

  return {
    onSubmit,
  };
}

import React from 'react';
import isObjectLike from 'lodash/isObjectLike';
import IdShort from 'src/formatters/IdShort';
import StatusBaseLabel from 'src/formatters/StatusBaseLabel';
import * as ucFilter from 'likvidum/shared/filter/uc';

export function Name (props) {
  const { value:uc } = props;
  if (!isObjectLike(uc)) return null;
  const id = uc.id;
  return (
    <IdShort value={id} />
  );
}

export function StatusLabel (props) {
  const { value, ...restOfProps } = props;
  return (
    <StatusBaseLabel
      value={value}
      formatter={ucFilter.status}
      {...restOfProps}
    />
  );
}

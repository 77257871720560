import React from 'react';
import Anchor from  'src/anchors/Anchor';
import QuicklookAnchor from 'src/anchors/QuicklookAnchor';
import JaycomInsuranceQuicklook from 'src/jaycomInsurance/JaycomInsuranceQuicklook';
import { Name } from 'src/jaycomInsurance/JaycomInsuranceFormatters';
import { CustomerJaycomInsurance } from 'shared/types/customer_jaycom_insurance';

interface CustomerJaycomInsuranceAnchorProps {
  insurance: CustomerJaycomInsurance;
}

export default function CustomerJaycomInsuranceAnchor (props: CustomerJaycomInsuranceAnchorProps) {
  const { insurance, ...restOfProps } = props;
  return (
    <QuicklookAnchor
      {...restOfProps}
      url={`/customer_jaycom_insurance/${insurance.id}/form`}
      title={<Name value={insurance} />}
      name="Försäkring (Jaycom)"
      objectId={insurance.id}
    >
      <JaycomInsuranceQuicklook id={insurance.id} />
    </QuicklookAnchor>
  );
}

export function CustomerJaycomInsurancePlainAnchor (props: CustomerJaycomInsuranceAnchorProps) {
  const { insurance, ...restOfProps } = props;
  return (
    <Anchor
      {...restOfProps}
      url={`/customer_jaycom_insurance/${insurance.id}/form`}
      name="Försäkringen (Jaycom)"
    >
      <Name value={insurance} />
    </Anchor>
  );
}

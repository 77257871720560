

/**
 * WARNING: This file is used clientside
 */

const moment = require('moment');
const validate = require('shared/validate');
const translations = require('shared/translations');
const { isFiniteNumber } = require('shared/numbers');

/**
 * @summary Formats a SSN
 * @param {String} format
 * @param {String} ssn
 * @return {String}
 * format = long -> YYYYMMDDXXXX
 * format = long-dash -> YYYYMMDD-XXXX
 * format = short -> YYMMDDXXXX
 * format = short-dash -> YYMMDD-XXXX
 * format = age -> N (age)
 * format = birthday -> YYYYMMDD
 * format = birthdate -> new Date(YYYY-MM-DD)
 * format = is-male -> Boolean
 */
exports.ssn = function (format, ssn) {
  if (typeof ssn !== 'string' || !validate.ssn(ssn)) {
    return '';
  }

  // format is "long-dash" or "short-dash"
  if (ssn.length === 13 || ssn.length === 11) {
    ssn = ssn.replace(/\D/g, '');
  }

  // format is "short"
  if (ssn.length === 10) {
    const year = parseInt(ssn.slice(0, 2), 10);
    const now = parseInt(String((new Date()).getFullYear()).slice(2), 10);
    const century = year > now ? '19' : '20';
    ssn =  century + ssn;
  }

  switch (format) {
    default: return '';
    case 'long': return ssn;
    case 'long-dash': return ssn.slice(0, 8) + '-' + ssn.slice(8);
    case 'short': return ssn.slice(2);
    case 'short-dash': return ssn.slice(2, 8) + '-' + ssn.slice(8);
    case 'age': return ageBySSN(ssn);
    case 'birthday': return ssn.slice(0, 8);
    case 'birthdate': return moment(ssn.slice(0, 8), 'YYYYMMDD').toDate();
    case 'is-male': return parseInt(ssn.slice(-2, -1), 10) % 2 > 0;
  }

  function ageBySSN (ssn) {
    const date = moment(ssn.slice(0, 8), 'YYYYMMDD');
    return Math.abs(date.diff(moment(), 'years'));
  }
};

/**
 * @summary Cap the amount of a loan to be within a set limit
 * @param {Object} loan
 * @param {Number} loan.repayment_years
 * @param {Object} loan.amount
 * @param {Number} loan.amount.now Size of current loan
 * @param {Number} loan.amount.new Size of new loan
 * @param {Number} [props.minTotal] Minimum loan size
 * @param {Number} [props.maxTotal] Maximum loan size
 * @param {Boolean} [props.maxTotalClamp=true] When false, will not clamp totals above max
 * @param {Boolean} [props.minTotalClamp=true] When false, will not clamp totals below max
 * @param {Number} [props.minYears] Minimum loan repayment years
 * @param {Number} [props.maxYears] Maximum loan repayment years
 * @return {Object}
 */
exports.clampLoan = function (loan, props = {}) {
  const {
    minTotal = 0,
    maxTotal = Infinity,
    minYears = 0,
    maxYears = Infinity,
    maxTotalClamp = true,
    minTotalClamp = true,
  } = props;
  const amount = loan.amount || {};
  const total = (amount.now || 0) + (amount.new || 0);

  const result = {
    ...loan,
    repayment_years: loan.repayment_years || 0,
    amount: {now: amount.now || 0, new: amount.new || 0},
  };

  if (total > maxTotal && maxTotalClamp) {
    const diff = total - maxTotal;
    if (amount.new < diff) {
      result.amount.now = amount.now + amount.new - diff || 0;
      result.amount.new = 0;
    } else {
      result.amount.now = amount.now || 0;
      result.amount.new = amount.new - diff || 0;
    }
  }

  if (total < minTotal && minTotalClamp) {
    const totalDiff = minTotal - total;
    result.amount.now = amount.now || 0;
    result.amount.new = amount.new + totalDiff || 0;
  }

  if (isFiniteNumber(loan.repayment_years)) {
    const years = loan.repayment_years;
    result.repayment_years = Math.max(minYears, Math.min(maxYears, years));
  }

  return result;
};

/**
 * Pads a number with {amount} zeroes
 * @param {Number} num Number to pad
 * @param {Number} amount Number of zeroes to pad with
 * @return {String}
 */
exports.numberPad = function (num, amount) {
  return String(num).padStart(amount, '0');
};

/*
 * Rounds number {num} to {precision} decimals
 */
exports.numberRound = function (num, precision) {
  if (!isFiniteNumber(num)) return NaN;
  if (!(precision >= 0)) return num;
  const fac = Math.pow(10, precision);
  num = Math.round(num * fac);
  return num / fac;
};

/**
 * @desc Groups a number and returns a string
 * @param {String|Number} num If string must be parseable as a float
 * @return {String}
 */
exports.numberGroup = function (num) {
  if (typeof num === 'string') num = parseFloat(num);
  if (!isFiniteNumber(num)) return '';
  return num.toLocaleString('sv-SE', {
    useGrouping: true,
  });
};

/**
 * Splits an application id into tokens
 * @param {String} id Application ID to split
 * @return {Object}
 */
exports.application_id = function (id) {
  if (typeof id !== 'string') {
    return {};
  }

  const tokens = id.split('_');

  return {
    customer_id: tokens[0],
    ordinal: parseInt(tokens[1], 10),
  };

};

/**
 * @summary Normalize a linefeed
 * @param {String} str
 * @return {String}
 */
exports.linefeed = function (str) {
  if (typeof str !== 'string') return '';
  const exp = /\r\n|\r|\n/g;
  return str.replace(exp, function () {
    return '\r\n';
  });
};

/**
 * @summary Convert CRLF to <br />
 * @param {String} str
 * @return {String}
 */
exports.nl2br = function (str) {
  if (typeof str !== 'string') return '';
  return exports.linefeed(str).replace(/\r\n/g, '<br />');
};

/**
 * @desc Converts a process and an offer to something that can be directly used in a contact template
 * @param {Bid} offer From BankProcess.prototype.getOffer
 * @return {Object}
 */
exports.processToContactTemplateObject = function (offer) {
  if (!offer) {
    return null;
  }

  const {
    bank_id,
    bank_name,
    bank_adapter,
    amount,
    monthly_cost,
    verifications,
    total_cost,
    bank_logo_file_name,
    amortization_type = 'annuity',
    unknown = false,
  } = offer;

  const initial_fee = offer.application_fee || 0;
  const repayment_time = getRepaymentTime(offer);
  const period_fee = (offer.autogiro_fee || 0) + (offer.notice_fee || 0);
  const result = {
    unknown,
    amortization_type: translations.application.offer_amortization_type(amortization_type),
    bank_id: bank_id || '',
    bank_name: bank_name || '',
    bank_adapter: bank_adapter || '',
    bank_logo_file_name,
    repayment_time,
    principal: exports.numberGroup(Math.round(amount || 0)),
    interest_rate: exports.numberGroup(exports.numberRound(offer.interest_rate, 2) || 0),
    interest_rate_effective: exports.numberGroup(exports.numberRound(offer.interest_rate_effective, 2) || 0),
    invoice_fee: exports.numberGroup(period_fee),
    initial_fee: exports.numberGroup(initial_fee),
    monthly_cost: exports.numberGroup(Math.round(monthly_cost || 0)),
    total_cost: exports.numberGroup(Math.round(total_cost || 0)),
    verifications: formatVerifications(verifications),
  };

  return result;
};

function getRepaymentTime (offer) {
  const { repayment_years, repayment_months } = offer;
  if (!repayment_years && !repayment_months) return '';
  const str = repayment_years ? repayment_years + ' år' : repayment_months + ' månader';
  return str + ` (${repayment_years ? repayment_years * 12 : repayment_months || 0} inbetalningar)`;
}

function formatVerifications (verifications) {
  if (!verifications || !Array.isArray(verifications) || verifications.length < 1) {
    return 'Nej';
  }
  const list = translations.application.offer_verifications(verifications.filter(v => v !== 'require.data.bank_transaction_history'));
  return list.length > 0 ? list.join(', ') : 'Nej';
}

/**
 * @summary Converts a loan to its constitutients
 * @param {Error|Object} err
 * @return {Object}
 */
exports.errorToObject = function (err) {
  if (!(err instanceof Error)) return err;
  const { message, stack, code } = err;
  return {message, stack, code};
};

/**
 * @summary Converts a csv string to a 2D array, discarding the header row
 * @param {String} text
 * @param {String} [delimiter=";"]
 * @param {Boolean} [removeHeader=true]
 * @return {Array.<Array.<String>>}
 */
exports.csvStrToArray = function (text, delimiter, removeHeader) {
  removeHeader = typeof removeHeader === 'boolean' ? removeHeader : true;
  delimiter = delimiter || ';';
  text = exports.linefeed(text);
  const csv = text.split('\r\n').map(function (line) {
    return line.split(delimiter);
  });
  if (removeHeader) csv.shift(); // remove header row
  return csv;
};

// fetched from wikipedia 2020-09-08
// @see https://sv.wikipedia.org/wiki/Lista_%C3%B6ver_svenska_riktnummer
// list must be in descending order
const swedishAreaCodes = [
  981, 980, 978, 977, 976, 975, 973, 971, 970, 969,
  961, 960, 954, 953, 952, 951, 950, 944, 943, 942,
  941, 940, 939, 935, 934, 933, 932, 930, 929, 928,
  927, 926, 925, 924, 923, 922, 921, 920, 918, 916,
  915, 914, 913, 912, 911, 910, 900, 749, 746, 741,
  740, 719, 710, 696, 695, 693, 692, 691, 690, 687,
  684, 682, 680, 672, 671, 670, 663, 662, 661, 660,
  657, 653, 652, 651, 650, 647, 645, 644, 643, 642,
  640, 624, 623, 622, 621, 620, 613, 612, 611, 591,
  590, 589, 587, 586, 585, 584, 583, 582, 581, 580,
  573, 571, 570, 565, 564, 563, 560, 555, 554, 553,
  552, 551, 550, 534, 533, 532, 531, 530, 528, 526,
  525, 524, 523, 522, 521, 520, 515, 514, 513, 512,
  511, 510, 506, 505, 504, 503, 502, 501, 500, 499,
  498, 496, 495, 494, 493, 492, 491, 490, 486, 485,
  481, 480, 479, 478, 477, 476, 474, 472, 471, 470,
  459, 457, 456, 455, 454, 451, 435, 433, 431, 430,
  418, 417, 416, 415, 414, 413, 411, 410, 393, 392,
  390, 383, 382, 381, 380, 378, 372, 371, 370, 346,
  345, 340, 325, 322, 321, 320, 304, 303, 302, 301,
  300, 297, 295, 294, 293, 292, 291, 290, 281, 280,
  278, 271, 270, 258, 253, 251, 250, 248, 247, 246,
  243, 241, 240, 227, 226, 225, 224, 223, 222, 221,
  220, 200, 176, 175, 174, 173, 171, 159, 158, 157,
  156, 155, 152, 151, 150, 144, 143, 142, 141, 140,
  125, 123, 122, 121, 120, 99, 90, 79, 78, 77,
  76, 75, 73, 72, 70, 63, 60, 54, 46, 44,
  42, 40, 36, 35, 33, 31, 26, 23, 21, 20,
  19, 18, 16, 13, 11, 10, 8,
];

/**
 * @desc Returns the area code of a Swedish telephone number
 * @param {String} telephoneNumber In format 0736709818
 * @return {String}
 */
exports.swedishAreaCode = tel => {
  if (typeof tel !== 'string') return '';
  if (tel.startsWith('0')) tel = tel.slice(1); // remove leading zero
  const match = swedishAreaCodes.find(num => tel.startsWith(String(num)));
  if (match) {
    return '0' + String(match);
  }
  return '';
};

/**
 * @desc Returns the local code (excluding area) of a Swedish telephone number
 * @param {String} telephoneNumber In format 0736709818
 * @return {String}
 */
exports.swedishLocalCode = tel => {
  if (typeof tel !== 'string') return '';
  const areaCode = exports.swedishAreaCode(tel);
  if (areaCode === '') return tel;
  return tel.slice(areaCode.length);
};

/**
 * @desc Returns the constituent parts of an account number
 * @param {String} str
 * @return {AccountNumberParts} Has clearing and account props
 */
exports.accountNumberParts = str => {
  if (typeof str !== 'string' || !str) return {};
  const clearing = str.charAt(0) === '8' ? str.slice(0, 5) : str.slice(0, 4);
  const account = str.charAt(0) === '8' ? str.slice(5) : str.slice(4);
  return {clearing, account};
};

/**
 * @desc Takes task options from an WorkTriggerTemplate and returns all associated ids
 * @param {Object} msg This is the "msg" cell in a WorkTriggerTemplate
 * @param {Object} result Results put in this object
 * @return {Object}
 */
exports.getWorkTriggerTemplateAssociatedIds = function (msg, result) {
  if (msg.email_template_id) {
    append('emailTemplate', msg.email_template_id);
  }
  if (msg.letter_template_id) {
    append('letterTemplate', msg.letter_template_id);
  }
  if (msg.sms_template_id) {
    append('smsTemplate', msg.sms_template_id);
  }
  if (msg.letter_template_id_by_bank_id) {
    const ids = Object.keys(msg.letter_template_id_by_bank_id).map(key => msg.letter_template_id_by_bank_id[key]);
    append('letterTemplate', ...ids);
  }
  if (msg.letter_template_id_default) {
    append('letterTemplate', msg.letter_template_id_default);
  }

  if (msg.language) {
    for (const subObjKey in msg.language) {
      const subObj = msg.language[subObjKey];
      if (subObj && typeof subObj === 'object') {
        exports.getWorkTriggerTemplateAssociatedIds(subObj, result);
      }
    }
  }

  return result;

  function append (key, ...ids) {
    if (result[key]) result[key] = [...result[key], ...ids];
    else result[key] = ids;
  }
};

/**
 * @desc Formats a loan object as a representative example (tested in db/models/Bank)
 * @param {Loan} loanObj
 * @param {String} [language='sv']
 * @return {String}
 */
exports.loanToRepresentativeExample = function (loanObj, language = 'sv') {
  const {
    amortization_type,
    repayment_periods,
    principal,
    interest_rate,
    interest_rate_effective,
    initial_fee,
    period_fee,
    period_cost,
    total_cost,
    current_at,
  } = loanObj;

  if ([
    repayment_periods,
    principal,
    interest_rate,
    interest_rate_effective,
    initial_fee,
    period_fee,
    period_cost,
    total_cost,
  ].some(v => !isFiniteNumber(v))) {
    return '';
  }

  const yearStr = language === 'en' ? 'year(s)' : 'år';
  const monthStr = language === 'en' ? 'month(s)' : 'mån';

  const timeYears = Math.floor(repayment_periods / 12);
  const timeMonths = repayment_periods % 12;
  let time = `${timeYears} ${yearStr}`;
  if (timeYears < 1) {
    time = `${timeMonths} ${monthStr}`;
  } else if (timeMonths > 0) {
    time += ` ${timeMonths} ${monthStr}`;
  }

  const principalFmt = roundfmt(principal, 0);
  const interestRateFmt = roundfmt(interest_rate * 100, 2);
  const interestRateEffectiveFmt = roundfmt(interest_rate_effective * 100, 2);
  const initialFeeFmt = roundfmt(initial_fee, 0);
  const periodFeeFmt = roundfmt(period_fee, 0);
  const totalCostFmt = roundfmt(total_cost, 0);
  const periodCostFmt = roundfmt(period_cost + period_fee, 0);

  const today = moment(current_at || Date.now()).format('YYYY-MM-DD');

  switch (amortization_type) {
    default: return '';

    case 'none': // assume "none" means annuity anyway
    case 'annuity': {
      if (language === 'en') {
        return `
Example: For an annuity loan of ${principalFmt} kr repaid over ${time}, adjustable interest rate ${interestRateFmt} %, ${initialFeeFmt} kr initial fee and ${periodFeeFmt} kr fee per invoice the effective interest rate becomes ${interestRateEffectiveFmt} %. Total cost: ${totalCostFmt} kr or ${periodCostFmt} kr/month divided over ${repayment_periods} repayments (${today}).
      `.trim();
      }
      return `
Exempel: För ett annuitetslån på ${principalFmt} kr över ${time}, rörlig ränta ${interestRateFmt} %, ${initialFeeFmt} kr i uppläggningsavgift och ${periodFeeFmt} kr i aviavgift blir den effektiva räntan ${interestRateEffectiveFmt} %. Totalkostnad: ${totalCostFmt} kr eller ${periodCostFmt} kr/mån fördelat på ${repayment_periods} inbetalningar (${today}).
      `.trim();
    }

    case 'straight': {
      const periodCostAvg = total_cost / repayment_periods;
      const periodCostAvgFmt = roundfmt(periodCostAvg, 0);
      if (language === 'en') {
        return `
Example: For a straight-line amortized loan of ${principalFmt} kr repaid over ${time}, adjustable interest rate ${interestRateFmt} %, ${initialFeeFmt} kr initial fee and ${periodFeeFmt} kr fee per invoice the effective interest rate becomes ${interestRateEffectiveFmt} %. Total cost: ${totalCostFmt} kr or an average of ${periodCostAvgFmt} kr/month divided over ${repayment_periods} repayments (${today}).
      `.trim();
      }
      return `
Exempel: För ett lån med rak amortering på ${principalFmt} kr över ${time}, rörlig ränta ${interestRateFmt} %, ${initialFeeFmt} kr i uppläggningsavgift och ${periodFeeFmt} kr i aviavgift blir den effektiva räntan ${interestRateEffectiveFmt} %. Totalkostnad: ${totalCostFmt} kr eller i genomsnitt ${periodCostAvgFmt} kr/mån fördelat på ${repayment_periods} inbetalningar (${today}).
      `.trim();
    }

  }

  function roundfmt (num, maximumFractionDigits = 0, extendOptions = {}) {
    return num.toLocaleString('sv-SE', {
      roundingType: 'fractionDigits',
      maximumFractionDigits,
      ...extendOptions,
    });
  }
};

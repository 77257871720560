import React from 'react';
import QuicklookAnchor from 'src/anchors/QuicklookAnchor';
import UcQuicklook from 'src/uc/UcQuicklook';
import { Name } from 'src/uc/UcFormatters';

export default function UcAnchor (props) {
  const { uc, ...restOfProps } = props;
  return (
    <QuicklookAnchor
      {...restOfProps}
      url={`/uc/${uc.id}`}
      title={<Name value={uc} />}
      name="UC"
      objectId={uc.id}
    >
      <UcQuicklook id={uc.id} />
    </QuicklookAnchor>
  );
}


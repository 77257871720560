import React from 'react';
import ModalAnchor from 'src/anchors/ModalAnchor';
import LetterModalBody from 'src/letter/LetterModalBody';
import { Name } from 'src/letter/LetterFormatters';

export default function LetterAnchor (props) {
  const { letter, ...restOfProps } = props;
  return (
    <ModalAnchor
      {...restOfProps}
      title={<Name value={letter} />}
      name="Brev"
    >
      <LetterModalBody id={letter.id} />
    </ModalAnchor>
  );
}




const getter = require('shared/get');
const misc = require('shared/filter/misc');
const date = require('shared/filter/date');
const ucUtils = require('shared/ucUtils');

/**
 * WARNING: This file is used clientside
 */

exports.fieldName = function (fieldName) {
  switch (fieldName) {
    default: return fieldName;
    case 'id': return 'ID';
    case 'created_at': return 'Skapad';
    case 'response': return 'Svar';
  }
};

exports.status = function (status) {
  switch (status) {
    default: return status;
    case 'new': return 'Ej inskickad';
    case 'approved': return 'Godkänd';
    case 'approved_old': return 'Godkänd (äldre)';
    case 'denied': return 'Ej godkänd';
    case 'error': return 'Fel';
  }
};

/**
 * @desc Converts a response code according to LIR to natural language
 * @param {String} responseCode
 * @param {Boolean} [includeCode=false] When true, the code will be prepended
 * @return {String}
 */
exports.responseCode = function (responseCode, includeCode = false) {
  const prefix = includeCode ? responseCode + ' ' : '';
  switch (responseCode) {
    default: return 'Okänd: ' + responseCode;
    case 'UNG': return prefix + 'Huvudsökande: Personen är under 18 år';
    case '001': return prefix + 'Medsökande: Personen är under 18 år';
    case 'FRV': return prefix + 'Huvudsökande: Personen har förvaltare utsedd';
    case '002': return prefix + 'Medsökande: Personen har förvaltare utsedd';
    case 'UTV': return prefix + 'Huvudsökande: Personen är utvandrad/obefintlighetsregistrerad';
    case '003': return prefix + 'Medsökande: Personen är utvandrad/obefintlighetsregistrerad';
    case 'ADR': return prefix + 'Huvudsökande: Personen saknar adress i UC:s register';
    case '004': return prefix + 'Medsökande: Personen saknar adress i UC:s register';
    case 'BOX': return prefix + 'Huvudsökande: Personen har box-adress i storstadsregionerna Stockholm, Göteborg eller Malmö';
    case '005': return prefix + 'Medsökande: Personen har box-adress i storstadsregionerna Stockholm, Göteborg eller Malmö';
    case 'FAC': return prefix + 'Huvudsökande: Personen har fack-adress';
    case '006': return prefix + 'Medsökande: Personen har fack-adress';
    case 'POS': return prefix + 'Huvudsökande: Personen har Poste Restante-adress';
    case '007': return prefix + 'Medsökande: Personen har Poste Restante-adress';
    case 'SKS': return prefix + 'Huvudsökande: Personen har notering om skuldsanering';
    case '009': return prefix + 'Medsökande: Personen har notering om skuldsanering';
    case 'SKY': return prefix + 'Huvudsökande: Personen har skyddade personuppgifter';
    case '010': return prefix + 'Medsökande: Personen har skyddade personuppgifter';
    case '012': return prefix + 'Huvudsökande: Personen har en eller fler betalningsanmärkning, av typen allmänna och enskilda mål, de senaste 36 månaderna';
    case '013': return prefix + 'Medsökande: Personen har en eller fler betalningsanmärkning, av typen allmänna och enskilda mål, de senaste 36 månaderna';
    case 'ASK': return prefix + 'Huvudsökande: Personen har aktuellt skuldsaldo hos Kronofogdemyndigheten';
    case '008': return prefix + 'Medsökande: Personen har aktuellt skuldsaldo hos Kronofogdemyndigheten';
    case 'IDH': return prefix + 'Huvudsökande: Personen har anmält förlorad id-handling till UC';
    case '011': return prefix + 'Medsökande: Personen har anmält förlorad id-handling till UC';
    case 'OKS': return prefix + 'Huvudsökande: Sökande saknar betalningsanmärkning och/ eller aktuellt skuldsaldo';
    case 'OKM': return prefix + 'Medsökande: Sökande saknar betalningsanmärkning och/ eller aktuellt skuldsaldo';
  }
};

exports.toApplicationUpdate = ucUtils.toApplicationUpdate;
exports.toCustomerUpdate = ucUtils.toCustomerUpdate;

// filters column names for uc table
exports.indexColumnName = function (columnKey) {
  switch (columnKey) {
    default: return columnKey;
    case 'id': return 'ID';
    case 'customer_id': return 'Kund';
    case 'application_id': return 'Ansökan';
    case 'ssn_main': return 'PNR (huvuds.)';
    case 'ssn_co': return 'PNR (meds.)';
    case 'created_by_user_id': return 'Skapare';
    case 'submitted_by_user_id': return 'Inskickare';
    case 'created_at': return 'Skapad';
    case 'created_at_relative': return 'Skapad';
    case 'updated_at': return 'Uppdaterad';
    case 'updated_at_relative': return 'Uppdaterad';
    case 'response': return 'Besvarad?';
    case 'response_at': return 'Besvarad';
    case 'response_at_relative': return 'Besvarad';
    case 'response_codes': return 'Svarskoder';
    case 'risk_main': return 'Risk (huvuds.)';
    case 'risk_co': return 'Risk (meds.)';
    case 'last_debt_at_main': return 'Senaste anmärkning (huvuds.)';
    case 'last_debt_at_co': return 'Senast anmärkning (meds.)';
    case 'count_uc_last_12_months_main': return 'UC senaste 12 mån (huvuds.)';
    case 'count_uc_last_12_months_co': return 'UC senaste 12 mån (meds.)';
    case 'is_approved_main': return 'Godkänd (huvuds.)';
    case 'is_approved_co': return 'Godkänd (meds.)';
    case 'status': return 'Status';
  }
};

// filters uc values by column name
exports.indexField = function (uc, columnKey, options = {}) {
  const { forExport = false } =  options;
  const get = (...args) => getter(uc, ...args);
  switch (columnKey) {
    default: return get(columnKey);
    case 'response_at':
    case 'created_at':
    case 'updated_at':
      return forExport ? date.dayTime(get(columnKey)) : get(columnKey);
    case 'ssn_main': return get('request.object') || '';
    case 'ssn_co': return get('request.coObject') || '';
    case 'risk_main': {
      const risk = get('response_main.risk_main');
      return typeof risk === 'number' ? Math.round(risk * 100) + ' %' : '';
    }
    case 'risk_co': {
      const risk = get('response_co.risk_main');
      return typeof risk === 'number' ? Math.round(risk * 100) + ' %' : '';
    }
    case 'created_by_user_id': return get('Creator.name') || get('created_by_user_id') || '';
    case 'submitted_by_user_id': return get('Submitter.name') || get('submitted_by_user_id') || '';
    case 'last_debt_at_main': return get('response_main.last_debt_at') || '';
    case 'last_debt_at_co': return get('response_co.last_debt_at') || '';
    case 'count_uc_last_12_months_main': return misc.appendWhenNumber(get('response_main.count_uc_last_12_months'), ' st') || '';
    case 'count_uc_last_12_months_co': return misc.appendWhenNumber(get('response_co.count_uc_last_12_months'), ' st') || '';
    case 'is_approved_main': {
      const is = get('response_main.is_approved');
      return typeof is === 'boolean' ? misc.yesno(is) : '';
    }
    case 'is_approved_co': {
      const is = get('response_co.is_approved');
      return typeof is === 'boolean' ? misc.yesno(is) : '';
    }
  }
};

import React from 'react';
import isObjectLike from 'lodash/isObjectLike';
import * as workFilter from 'likvidum/shared/filter/work';
import StatusBaseLabel from 'src/formatters/StatusBaseLabel';

interface NameProps {
  value: {id: number, status?: string};
  showStatus?: boolean;
}

export function Name (props: NameProps) {
  const { value:work, showStatus = false } = props;
  if (!isObjectLike(work)) return null;
  const { id, status } = work;
  return (
    <>{id}{showStatus && <> / <Status value={status} /></>}</>
  );
}

export function Status (props) {
  const { value } = props;
  return workFilter.status(value);
}

export function StatusLabel (props) {
  const { value, ...restOfProps } = props;
  return (
    <StatusBaseLabel
      value={value}
      formatter={workFilter.status}
      {...restOfProps}
    />
  );
}

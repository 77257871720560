import React from 'react';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { WidgetState, WidgetListState, WidgetListStateAction } from 'src/widgets/types';

type TChangeCallback = (state: WidgetListState) => void;

export default function useWidgetList (keyName: string, defaultWidgetList: string[]): [WidgetListState, React.Dispatch<WidgetListStateAction>] {
  const reducer = getWrappedReducer(state => {
    saveLocalStorage(state);
  });

  const [initialLocalStorage, saveLocalStorage] = useLocalStorage(keyName, getDefaultState(defaultWidgetList));

  const [state, dispatch] = React.useReducer<React.Reducer<WidgetListState, WidgetListStateAction>>(reducer, initialLocalStorage);

  return [state, dispatch];
}

export function getDefaultState (initialWidgetIds: string[]): WidgetListState {
  return {
    active: 0,
    current: initialWidgetIds.map(id => ({id})),
    0: null,
    1: [],
    2: [],
  };
}

function getWrappedReducer (onChangeCallback: TChangeCallback) {
  return (state: WidgetListState, action: WidgetListStateAction) => {
    const result: WidgetListState = reducer(state, action);
    onChangeCallback(result);
    return result;
  };
}

function reducer (state: WidgetListState, action: WidgetListStateAction): WidgetListState {
  const { type } = action;
  switch (type) {
    default: return state;
    case 'activate': return {
      ...state,
      active: action.index,
      current: state[action.index],
      [state.active]: state.current,
      [action.index]: null,
    };
    case 'add': return {
      ...state,
      current: [{id: action.id}, ...state.current],
    };
    case 'remove': return {
      ...state,
      current: state.current.filter(item => item.id !== action.id),
    };
    case 'swap': return {
      ...state,
      current: swap<WidgetState>(state.current, action.from, action.to),
    };
  }
}

function swap<T> (list: T[], startIndex: number, endIndex: number): T[] {
  if (startIndex === endIndex) return list;
  if (startIndex > list.length - 1 || startIndex < 0) return list;
  if (endIndex > list.length - 1 || endIndex < 0) return list;
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}

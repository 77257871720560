import React from 'react';
import ErrorAlert from 'src/alerts/ErrorAlert';
import * as api from 'src/api';
import { useQuery } from '@tanstack/react-query';
import { Card, Table } from 'react-bootstrap';
import { BankCommissionRate } from 'shared/types/bank_commission_rates';
import { BankCommissionBonusRate } from 'shared/types/bank_commission_bonus_rates';
import { SequelizeRange } from 'shared/types/misc';
import * as NumberFormatters from 'src/formatters/NumberFormatters';
import * as numberUtils from 'src/numbers';

interface BankCommissionRateQueryData {
  bank_commission_rates: BankCommissionRate[];
  bank_commission_bonus_rates: BankCommissionBonusRate[];
}

interface BankCommissionRateCardsProps {
  bankId: string;
}

export default function BankCommissionRateCards (props: BankCommissionRateCardsProps) {
  const { bankId } = props;

  const query = useQuery<BankCommissionRateQueryData, Error>({
    queryKey: ['BankCommissionRateCard', {bankId}],
    queryFn: () => api.request({
      url: `/bank_commission/rates/bank/${bankId}`,
    }),
  });

  const data = query.data;

  return (
    <>
      <ErrorAlert error={query.error} className="mx-3 mt-3 mb-2" />
      {data && (
        <>
          <BankCommissionRateCard
            bankCommissionRates={data.bank_commission_rates}
          />
          <BankCommissionBonusRateCard
            bankCommissionBonusRates={data?.bank_commission_bonus_rates}
          />
        </>
      )}
    </>
  );
}

interface BankCommissionRateCardProps {
  bankCommissionRates: BankCommissionRate[];
}
function BankCommissionRateCard (props: BankCommissionRateCardProps) {
  const { bankCommissionRates } = props;
  return (
    <Card className="border">
      <Card.Header className="border-bottom">
        <Card.Title className="mb-0">Grundersättning per ansökan</Card.Title>
      </Card.Header>
      <Table striped>
        <thead>
          <tr>
            <th>Datum</th>
            <th>Utbetald summa</th>
            <th>Procent</th>
            <th>Fast ersättning</th>
          </tr>
        </thead>
        <tbody>
          {bankCommissionRates.length > 0 ? (
            <>
              {bankCommissionRates.map(rate => (
                <tr key={rate.id}>
                  <td><Range range={rate.valid_date_range} /></td>
                  <td>
                    <Range
                      range={rate.valid_amount_range}
                      format={numberUtils.numberfmt}
                    />
                  </td>
                  <td>
                    {rate.commission_rate && (
                      <NumberFormatters.Percent value={rate.commission_rate} />
                    )}
                  </td>
                  <td>
                    {rate.commission_fixed && (
                      <NumberFormatters.Currency value={rate.commission_fixed} />
                    )}
                  </td>
                </tr>
              ))}
            </>
          ) : (
            <tr>
              <td colSpan={4}>Ingen grundersättning</td>
            </tr>
          )}
        </tbody>
      </Table>
    </Card>
  );
}

interface BankCommissionBonusRateCardProps {
  bankCommissionBonusRates: BankCommissionBonusRate[];
}

function BankCommissionBonusRateCard (props: BankCommissionBonusRateCardProps) {
  const { bankCommissionBonusRates } = props;
  return (
    <Card className="border mb-1">
      <Card.Header className="border-bottom">
        <Card.Title className="mb-0">Bonusersättning på månadsbasis</Card.Title>
      </Card.Header>
      <Table striped>
        <thead>
          <tr>
            <th>Datum</th>
            <th>Utbetald totalsumma</th>
            <th>Procent</th>
          </tr>
        </thead>
        <tbody>
          {bankCommissionBonusRates.length > 0 ? (
            <>
              {bankCommissionBonusRates.map(bonus => (
                <tr key={bonus.id}>
                  <td><Range range={bonus.valid_date_range} /></td>
                  <td>
                    <Range
                      range={bonus.valid_monthly_sum_range}
                      format={numberUtils.numberfmt}
                    />
                  </td>
                  <td>
                    {bonus.commission_rate && (
                      <NumberFormatters.Percent value={bonus.commission_rate} />
                    )}
                  </td>
                </tr>
              ))}
            </>
          ) : (
            <tr>
              <td colSpan={3}>Ingen bonusersättning</td>
            </tr>
          )}
        </tbody>
      </Table>
    </Card>
  );
}

interface RangeProps {
  range: SequelizeRange;
  format?: (value: any) => string;
}
function Range (props: RangeProps) {
  const { range, format = v => v } = props;
  const { value:fromValue, inclusive:fromInclusive } = range[0];
  const { value:toValue, inclusive:toInclusive } = range[1];
  if (fromValue === null && toValue === null) return <>∞</>;
  const fromChar = fromInclusive ? '[' : '(';
  const toChar = toInclusive ? ']' : ')';
  return (
    <>
      {fromChar}{fromValue === null ? '∞' : format(fromValue)}
      {' – '}
      {toValue === null ? '∞' : format(toValue)}{toChar}
    </>
  );
}



/**
 * WARNING: This file is used clientside
 */

const moment = require('moment');

/*
 * Validates a SSN
 * @return Boolean
 */
exports.ssn = function (ssn) {
  if (!ssn || typeof ssn !== 'string') {
    return false;
  }

  if (ssn.indexOf('-') === -1) {
    if (ssn.length === 10) {
      ssn = ssn.slice(0, 6) + '-' + ssn.slice(6);
    } else {
      ssn = ssn.slice(0, 8) + '-' + ssn.slice(8);
    }
  }

  // clean and shorten ssn
  ssn = ssn.replace('-', '');
  if (ssn.length === 12) {
    // validate nonsense centuries
    if (ssn.slice(0, 2) !== '19' && ssn.slice(0, 2) !== '20') {
      return false;
    }
    ssn = ssn.substring(2);
  }

  const date = moment(ssn, 'YYMMDD');
  if (!date.isValid()) {
    return false;
  }

  let sum = 0;
  const numdigits = ssn.length;
  const parity = numdigits % 2;
  let i;
  let digit;

  // Check luhn algorithm
  for (i = 0; i < numdigits; i = i + 1) {
    digit = parseInt(ssn.charAt(i));
    if (i % 2 === parity) digit *= 2;
    if (digit > 9) digit -= 9;
    sum += digit;
  }
  return (sum % 10) === 0;
};

/**
 * @desc Validates an UUID
 * @return Boolean
 */
exports.uuid = function (uuid) {
  if (typeof uuid !== 'string') {
    return false;
  }

  const regexp = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return regexp.test(uuid);
};

exports.swedishMobileTelephoneNumber = function (str) {
  return /^07[023679]\d{7}$/.test(str);
};

exports.bankAccountClearingNumber = function (clearingNo) {
  if (typeof clearingNo === 'string') {
    if (!/^\d{4,5}$/.test(String(clearingNo))) {
      return false;
    }
    clearingNo = parseInt(clearingNo, 10);
  }
  if (String(clearingNo).length > 4) {
    clearingNo = parseInt(String(clearingNo).slice(0, 4), 10);
  }
  return isFinite(clearingNo) && 1000 <= clearingNo && clearingNo <= 9999;
};

/**
 * @desc Validates an ApplicationId
 * @param {String} id
 * @return {Boolean}
 */
exports.application_id = function (id) {
  if (typeof id !== 'string') {
    return false;
  }

  const tokens = id.split('_');
  const customerId = tokens[0];
  const numeral = tokens[1];

  // customerId valid?
  if (!exports.uuid(customerId)) {
    return false;
  }

  // numeral valid?
  const regexp = /^\d{3}$/;
  if (!regexp.test(numeral)) {
    return false;
  }

  return true;
};

/**
 * Validate a Complaint id
 * @param {String} id
 * @return {Boolean}
 */
exports.complaint_id = function (id) {
  if (typeof id !== 'string') {
    return false;
  }

  const exp = /^\w-\d+$/g;

  return exp.test(id);
};

import React from 'react';

interface ITemplateModalBody {
  id: string;
}

export default function TemplateModalBody (props: ITemplateModalBody) {
  return (
    <div>template modal body here</div>
  );
}


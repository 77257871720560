import React from 'react';
import pepQueryFilter from 'shared/filter/pep_query';
import * as TableCells from 'src/tables/TableCells';
import * as commonFilterDefs from 'src/tables/commonFilterDefinitions';
import * as commonColumnDefs from 'src/tables/commonColumnDefinitions';
import * as TableCellActions from 'src/tables/TableCellActions';
import { IColumnDefinition, IFilterDefinition } from 'src/tables/Table';
import TablePage from 'src/tables/TablePage';

export default function PepQueryTablePage () {
  return (
    <TablePage
      title="PEP-omfrågor"
      pageTitle="PEP-omfrågetabell"
      columnDefinitions={columnDefinitions}
      filterDefinitions={filterDefinitions}
      routeUrl="/pep_query"
      baseQueryUrl="/pep/table"
      baseQueryKey="PepQueryTable"
    />
  );
}

const filterDefinitions: IFilterDefinition[] = [
  commonFilterDefs.pepQueryStatus({id: 'status'}),
  commonFilterDefs.uuid({id: 'id'}),
  commonFilterDefs.applicationId({id: 'application_id'}),
  commonFilterDefs.uuid({id: 'customer_id'}),
  commonFilterDefs.userId({id: 'created_by_user_id'}),
  commonFilterDefs.dateRange({id: 'created_at'}),
  commonFilterDefs.dateRelative({id: 'created_at_relative'}),
  commonFilterDefs.dateRange({id: 'trapets_doquery_result_at'}),
  commonFilterDefs.dateRelative({id: 'trapets_doquery_result_at_relative'}),
  commonFilterDefs.dateRange({id: 'updated_at'}),
  commonFilterDefs.dateRelative({id: 'updated_at_relative'}),
].map(filterDefinition => ({
  title: pepQueryFilter.indexColumnName(filterDefinition.id),
  ...filterDefinition,
}));

const columnDefinitions: IColumnDefinition[] = [
  ...([
    commonColumnDefs.pepQueryId({id: 'id', cellProps: {rowAsObject: true}}),
    commonColumnDefs.cell('application_id', TableCells.ApplicationTableCell, {idKey: 'application_id'}),
    commonColumnDefs.cell('customer_id', TableCells.CustomerTableCell, {
      idKey: 'customer_id',
      objKey: 'Customer',
    }),
    commonColumnDefs.cell('created_by_user_id', TableCells.UserTableCell, {idKey: 'created_by_user_id'}),
    commonColumnDefs.cell('status', TableCells.PepQueryStatusTableCell),
    commonColumnDefs.datetime({
      id: 'created_at',
      sortable: true,
      groups: ['dates', 'sortable'],
    }),
    commonColumnDefs.datetime({
      id: 'updated_at',
      sortable: true,
      groups: ['dates', 'sortable'],
    }),
    commonColumnDefs.datetime({
      id: 'trapets_doquery_result_at',
      sortable: true,
      groups: ['dates', 'sortable'],
    }),
  ].map(columnDefinition => {
    const { id } = columnDefinition;
    const header = pepQueryFilter.indexColumnName(id);
    const cellValue = pep => pepQueryFilter.indexField(pep, id);
    return {
      Cell: ({value}) => <>{value}</>,
      ...columnDefinition,
      cellValue,
      Header: ({value}) => <>{value}</>,
      title: header,
    };
  })),
  commonColumnDefs.actions({}, [
    TableCellActions.InspectRowTableCellAction,
  ]),
];

import React from 'react';
import isObjectLike from 'lodash/isObjectLike';
import IdShort from 'src/formatters/IdShort';
import * as insuranceFilter from 'likvidum/shared/filter/customer_jaycom_insurance';
import constants from 'shared/constants';
import StatusBaseLabel, { StatusBaseLabelProps } from 'src/formatters/StatusBaseLabel';
import { CustomerJaycomInsurance } from 'shared/types/customer_jaycom_insurance';

interface NameProps {
  value: Pick<CustomerJaycomInsurance, 'id'>;
}

export function Name (props: NameProps) {
  const { value:insurance } = props;
  if (!isObjectLike(insurance)) return null;
  const id = insurance.id;
  return (
    <IdShort value={id} />
  );
}

interface StatusProps {
  value: string;
}

export function Status (props: StatusProps) {
  const { value } = props;
  return insuranceFilter.status(value);
}

interface StatusLabelProps extends StatusBaseLabelProps {
  value: string;
}

export function StatusLabel (props: StatusLabelProps) {
  const { value, ...restOfProps } = props;
  return (
    <StatusBaseLabel
      {...restOfProps}
      value={value}
      formatter={insuranceFilter.status}
    />
  );
}

interface InsuredAmountProps {
  value: CustomerJaycomInsurance['insured_amount'];
}

export function InsuredAmount (props: InsuredAmountProps) {
  const { value } = props;
  const index = constants.customerJaycomInsurance.insuredAmounts.indexOf(value);
  if (index === -1) return <>Ogiltigt värde/index för summa ${value}</>;
  const price = constants.customerJaycomInsurance.insuredAmountPricePerMonth[index];
  return <>{value} kr ({price} kr/mån)</>;
}

import React from 'react';
import isObjectLike from 'lodash/isObjectLike';
import IdShort from 'src/formatters/IdShort';
import { VirtualTemplateRow } from 'shared/types/template';

interface NameProps {
  value: Pick<VirtualTemplateRow, 'id'>;
}

export function Name (props: NameProps) {
  const { value:template } = props;
  if (!isObjectLike(template)) return null;
  const id = template.id;
  return (
    <IdShort value={id} />
  );
}

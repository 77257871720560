import React from 'react';
import Anchor from  'src/anchors/Anchor';
import { Name } from 'src/bank/BankFormatters';
import QuicklookAnchor from 'src/anchors/QuicklookAnchor';
import BankQuicklook from 'src/bank/BankQuicklook';
import { BankRow } from 'shared/types/bank';

interface BankAnchorProps {
  bank: BankRow;
}

export default function BankAnchor (props: BankAnchorProps) {
  const { bank, ...restOfProps } = props;
  return (
    <QuicklookAnchor
      {...restOfProps}
      url={`/bank/${bank.id}/form`}
      title={<Name value={bank} />}
      name="Långivare"
      objectId={bank.id}
    >
      <BankQuicklook id={bank.id} />
    </QuicklookAnchor>
  );
}

export function BankPlainAnchor (props: BankAnchorProps) {
  const { bank, ...restOfProps } = props;
  return (
    <Anchor
      {...restOfProps}
      url={`/bank/${bank.id}`}
      name="Långivaren"
    >
      <Name value={bank} />
    </Anchor>
  );
}

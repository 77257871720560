import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import useSidebar from 'src/hooks/useSidebar';
import SidebarNav from 'src/sidebar/SidebarNav';
import { ReactComponent as LikvidumLogo } from 'src/assets/img/likvidum_logo.svg';
import sidebarNavItems from 'src/sidebar/sidebarNavItems';
import { Link } from 'react-router-dom';

export default function Sidebar () {
  const { isOpen } = useSidebar();

  return (
    <nav className={`sidebar ${!isOpen ? 'collapsed' : ''}`}>
      <div className="sidebar-content">
        <PerfectScrollbar className="border-end">
          <Link className="sidebar-brand" to="/">
            <LikvidumLogo />
          </Link>

          <SidebarNav items={sidebarNavItems} />
        </PerfectScrollbar>
      </div>
    </nav>
  );
}

import React from 'react';
import isObjectLike from 'lodash/isObjectLike';
import IdShort from 'src/formatters/IdShort';
import StatusBaseLabel, {StatusBaseLabelProps} from 'src/formatters/StatusBaseLabel';
import * as smsFilter from 'likvidum/shared/filter/sms';
import { SmsRow } from 'shared/types/sms';

interface NameProps {
  value: SmsRow;
}

export function Name (props: NameProps) {
  const { value:sms } = props;
  if (!isObjectLike(sms)) return null;
  const id = sms.id;
  return (
    <IdShort value={id} />
  );
}

interface StatusLabelProps extends StatusBaseLabelProps {
  value: SmsRow['status'];
}

export function StatusLabel (props: StatusLabelProps) {
  const { value, ...restOfProps } = props;
  return (
    <StatusBaseLabel
      value={value}
      formatter={smsFilter.status}
      {...restOfProps}
    />
  );
}



/**
 * WARNING: This file is used clientside
 */

const getter = require('shared/get');
const moment = require('moment-timezone');
const date = require('shared/filter/date');
const misc = require('shared/filter/misc');

/**
 * @summary Task Type
 */
exports.type = function (type) {
  switch (type) {
    case 'callback-day': return 'Dagsbokning';
    case 'callback-interval': return 'Intervallbokning';
    case 'callback-exact': return 'Mötesbokning';
    case 'other': return 'Övrigt';
    default: return type;
  }
};

/**
 * @summary Task Time (Starts + Expires)
 * @param {Date} starts
 * @param {Date} expires
 * @param {String} type
 * @param {String} [timezone]
 * @return {String}
 */
exports.time = function (starts, expires, type, timezone) {
  if (!(starts instanceof Date) && typeof starts === 'string' && starts.length > 0) {
    starts = new Date(starts);
    if (isNaN(starts)) starts = null;
  }

  if (!(expires instanceof Date) && typeof expires === 'string' && expires.length > 0) {
    expires = new Date(expires);
    if (isNaN(expires)) expires = null;
  }


  if (!(starts instanceof Date) || !(expires instanceof Date)) {
    return '';
  }

  let mexpires = moment(expires);
  let mstarts = moment(starts);

  if (timezone) {
    mexpires = mexpires.tz(timezone);
    mstarts = mstarts.tz(timezone);
  }

  const date = 'YYYY-MM-DD';
  const time = 'HH:mm';
  const full = date + ' ' + time;

  switch (type) {
    default:
    case 'other': return mstarts.format(full) + ' - ' + mexpires.format(full);
    case 'callback-day': return mexpires.format(date);
    case 'callback-exact': return mexpires.format(full);
    case 'callback-interval': return mexpires.format(date) + ' ' + mstarts.format(time) + '-' + mexpires.format(time);
  }
};

/**
 * @summary Task Postponed
 * @param {Number} count
 * @param {Number} max
 * @return {String}
 */
exports.postponed = function (count, max) {
  if (max === 0) return '';
  if (max === null) return count;
  return count + '/' + max;
};

/**
 * @desc Task Index Column Name
 * @param {String} columnName
 * @return {String}
 */
exports.indexColumnName = function (columnName) {
  switch (columnName) {
    default: return columnName;
    case 'select': return 'Välj';
    case 'temporal_status': return 'Status';
    case 'id': return 'ID';
    case 'type': return 'Typ';
    case 'date': return 'Datum';
    case 'application_id': return 'Ansökan';
    case 'created_at': return 'Skapad';
    case 'created_at_relative': return 'Skapad';
    case 'updated_at': return 'Uppdaterad';
    case 'updated_at_relative': return 'Uppdaterad';
    case 'distributed_at': return 'Utlottad';
    case 'distributed_at_relative': return 'Utlottad';
    case 'completed_at': return 'Avklarad';
    case 'completed_at_relative': return 'Avklarad';
    case 'failed_at': return 'Misslyckad (tid)';
    case 'failed_at_relative': return 'Misslyckad (tid)';
    case 'postponed_at': return 'Uppskjuten (tid)';
    case 'postponed_at_relative': return 'Uppskjuten (tid)';
    case 'time': return 'Börjar/Slutar';
    case 'time_starts': return 'Börjar';
    case 'time_starts_relative': return 'Börjar';
    case 'time_expires': return 'Slutar';
    case 'time_expires_relative': return 'Slutar';
    case 'user_id': return 'Ägare';
    case 'description': return 'Beskrivning';
    case 'postponed': return 'Uppskjuten';
    case 'is_done': return 'Avklarad?';
    case 'score': return 'Prio';
    case 'stage': return 'Steg';
    case 'flags': return 'Flaggor';
    case 'lottery_assign_application': return 'Lotteri: ange rådgivare';
    case 'actions': return 'Åtgärder';
  }
};

exports.indexField = function (task, fieldName) {
  const get = (...args) => getter(task, ...args);
  switch (fieldName) {
    case 'time': return exports.time(task.time_starts, task.time_expires, task.type);
    case 'type': return exports.type(task.type);
    case 'time_expires':
    case 'time_starts':
    case 'postponed_at':
    case 'created_at': 
    case 'completed_at':
    case 'distributed_at':
    case 'updated_at':
      return date.dayTime(get(fieldName));
    case 'lottery_assign_application':
      return misc.yesno(get(fieldName));
    case 'user_id':
      return get('User.name') || get('user_id') || '-';
    case 'application_id': {
      const applicationId = get('application_id');
      if (!applicationId) return '';
      const [, ordinal] = applicationId.split('_');
      const customerName = get('Application.Customer.name');
      if (customerName) return customerName + ' - ' + ordinal;
      return applicationId;
    }
    case 'postponed':
      return exports.postponed(task.postponed_count, task.postponed_max);
    case 'score': return typeof task.score === 'number' ? task.score : '';
    case 'stage': return typeof task.stage === 'number' ? '#' + task.stage : '';
    default: return get(fieldName);
  }
};

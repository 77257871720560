import React from 'react';
import ApplicationAnchor from 'src/application/ApplicationAnchor';
import BankProcessAnchor from 'src/bankProcess/BankProcessAnchor';

export default function UserLogDescription (props) {
  const { data } = props;
  if (!data?.type) return null;
  const { application, bank_process:bankProcess, type } = data;
  return (
    <>
      {type === 'application_created' && (
        <strong className="text-dark">Skapade ansökan</strong>
      )}
      {type === 'application_disbursed' && (
        <strong className="text-dark">Ansökan utbetald</strong>
      )}
      {type === 'application_assigned' && (
        <strong className="text-dark">Tilldelades ansökan</strong>
      )}
      {type === 'new_offer' && (
        <strong className="text-dark">Nytt erbjudande på ansökan</strong>
      )}
      {application && (
        <>
          <br />
          <ApplicationAnchor
            application={application}
          />
        </>
      )}
      {bankProcess && (
        <>
          <br />
          <BankProcessAnchor
            bankProcess={bankProcess}
          />
        </>
      )}
    </>
  );
}

import React from 'react';

interface IdProps {
  value: string;
}

export default function Id (props: IdProps) {
  const { value } = props;
  if (typeof value !== 'string') return null;
  return (
    <span className="font-monospace">
      {value}
    </span>
  );
}

import React from 'react';
import * as api from 'src/api';
import BlockSpinner from 'src/spinners/BlockSpinner';
import ErrorAlert from 'src/alerts/ErrorAlert';
import { useQuery } from '@tanstack/react-query';

interface WorkScheduledModalBodyProps {
  id: number;
}

export default function WorkScheduledModalBody (props: WorkScheduledModalBodyProps) {
  const { id } = props;

  const query = useQuery<any, Error>({
    queryKey: ['work_scheduled', {id}],
    queryFn: () => api.request({url: `/work_scheduled/${id}`}),
  });

  const workScheduled = query?.data;

  return (
    <div>
      <ErrorAlert error={query.error} className="m-3" />
      <BlockSpinner isLoading={query.isLoading} />
      {workScheduled && (
        <>
          <pre className="border m-3 p-3">
            {JSON.stringify(workScheduled, null, 2)}
          </pre>
        </>
      )}
    </div>
  );
}



import React from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { NavLink } from 'react-router-dom';

type BreadcrumbPart = [string, React.ReactNode];

interface BreadcrumbsProps {
  crumbs: BreadcrumbPart[]
}
const Breadcrumbs: React.FC<BreadcrumbsProps> = React.memo(function Breadcrumbs (props: BreadcrumbsProps) {
  const { crumbs } = props;
  return (
    <Breadcrumb>
      {crumbs.map((crumb, index) => (
        <NavLink key={index} className="breadcrumb-item" to={crumb[0]}>
          {crumb[1]}
        </NavLink>
      ))}
    </Breadcrumb>
  );
});
export default Breadcrumbs;

import React from 'react';

export type HTMLFormControls = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

interface IUseFocusControlOptions {
  undefinedValue?: any;
  getValue?: (target: HTMLFormControls, controlledValue: any) => any;
  getInvalid?: (target: HTMLFormControls, controlledValue: any) => boolean;
}

// useFocusControl can be used for a form field that can be controlled by the user
// it is focused, and set to a fixed value when it is not focused
export default function useFocusControl (uncontrolledValue, onChangeValue, options: IUseFocusControlOptions = {}) {
  const {
    undefinedValue = '',
    getValue = target => target.value,
    getInvalid = target => !target.checkValidity(),
  } = options;

  const defined = typeof uncontrolledValue !== 'undefined';

  const [isInvalid, setIsInvalid] = React.useState<boolean>(false);
  const [focused, setFocused] = React.useState<boolean>(false);
  const [controlledValue, setControlledValue] = React.useState(defined ? uncontrolledValue : undefinedValue);

  const onFocus = () => {
    if (!focused) {
      setFocused(true);
      setControlledValue(defined ? uncontrolledValue : undefinedValue);
    }
  };

  const onBlur = (ev: React.FocusEvent<HTMLFormControls>) => {
    if (isInvalid) {
      setFocused(false);
      setIsInvalid(false);
    } else {
      // onChangeValue can optionally return a promise, which means we will only give control
      // of the value back (focused=false) when the promise resolves
      const maybePromise = onChangeValue(controlledValue, ev.target);
      if (typeof maybePromise?.finally === 'function') {
        maybePromise.finally(() => {
          setFocused(false);
        });
      } else {
        setFocused(false);
      }
    }
  };

  const onChange = (ev: React.ChangeEvent<HTMLFormControls>) => {
    const value = getValue(ev.target, controlledValue);
    const invalid = getInvalid(ev.target, controlledValue);
    setControlledValue(value);
    setIsInvalid(invalid);
  };

  const value = focused ? controlledValue : (defined ? uncontrolledValue : undefinedValue);

  return {
    onFocus,
    onChange,
    onBlur,
    isInvalid,
    value,
  };
}

import React from 'react';
import isObjectLike from 'lodash/isObjectLike';
import IdShort from 'src/formatters/IdShort';
import { CustomerConsentRow } from 'shared/types/customer_consent';

interface ConsentFormatterProps {
  value: CustomerConsentRow;
}

export function Name (props: ConsentFormatterProps) {
  const { value:consent } = props;
  if (!isObjectLike(consent)) return null;
  const id = consent.id;
  return (
    <IdShort value={id} />
  );
}

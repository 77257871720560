

/**
 * WARNING: This file is used clientside
 */

exports.columnName = function (key) {
  switch (key) {
    default: return key;
    case 'month': return 'Månad';
    case 'created_by_user_groups': return 'Skapargrupp';
    case 'portal_is_from': return 'Portal';
    case 'portal_tracking_code_adtraction_set': return 'Adtraction';
    case 'portal_tracking_code_adservice_set': return 'Adservice';
    case 'portal_tracking_code_gclid_set': return 'Adwords';
    case 'portal_tracking_code_ga_id_set': return 'Analytics';
  }
};

import React from 'react';
import { ErrorAlertCentered } from 'src/alerts/ErrorAlert';
import { Helmet } from 'react-helmet-async';

export default function AppError (props) {
  const { error } = props;
  return (
    <div className="container mt-4">
      <Helmet title="Ett fel inträffade" />
      <ErrorAlertCentered
        title="Ett fel inträffade och sidan kan inte visas"
        error={error}
      />
    </div>
  );
}

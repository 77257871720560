import React, { useState, useMemo, useCallback } from 'react';
import { Col, Row, Form, InputGroup, Button } from 'react-bootstrap';
import * as tableHelpers from 'src/tables/helpers';
import useAuth from 'src/hooks/useAuth';
import union from 'lodash/union';
import sortBy from 'lodash/sortBy';
import { preventDefaultSubmit } from 'src/misc';
import { IColumnDefinition } from 'src/tables/Table';
import { groupLabel } from 'src/tables/helpers';
import { IState, TStateColumns } from 'src/tables/types';
import {X} from 'react-feather';
import IdProvider from 'src/components/IdProvider';

type TableColumnViewOptionsDisposition = 'list' | 'columns';

interface TableColumnViewOptions {
  searchText: string;
  disposition: TableColumnViewOptionsDisposition;
}

interface TableColumnFormProps {
  columnDefinitions: IColumnDefinition[];
  stateColumns: TStateColumns;
  updateState: (newState: IState) => void;
}

export default function TableColumnForm (props: TableColumnFormProps) {
  const { columnDefinitions, stateColumns:columns, updateState } = props;

  const auth = useAuth();

  const onToggleVisible = useCallback((columnId: string, visible: boolean) => {
    const allColumnsOrdered = columnDefinitions.map(c => c.id);
    if (visible) {
      const newColumns = tableHelpers.addColumnInStableOrder(columnId, columns, allColumnsOrdered);
      updateState({columns: newColumns});
    } else {
      const newColumns = columns.filter(id => id !== columnId);
      updateState({columns: newColumns});
    }
  }, [updateState, columns, columnDefinitions]);

  // TODO possibly this state should be elevated to <Table>
  const [viewOptions, setViewOptions] = useState<TableColumnViewOptions>({
    searchText: '',
    disposition: 'columns',
  });

  const eligibleColumnDefinitions = useMemo(() => {
    const result = columnDefinitions.filter(columnDefinition => {
      const { permission } = columnDefinition;
      const hasPermission = auth.permission(permission);
      return hasPermission && shouldShowByViewOptions(viewOptions, columnDefinition);
    });
    return sortBy(result, 'title');
  }, [columnDefinitions, viewOptions, auth]);

  const columnGroups = useMemo(() => columnDefinitions.reduce<string[]>((groups, definition) => {
    return union(groups, definition.groups || []);
  }, []), [columnDefinitions]);

  return (
    <div className="border-top">
      <div className="border-bottom p-3">
        <TableColumnViewOptionsForm
          viewOptions={viewOptions}
          setViewOptions={setViewOptions}
          columnGroups={columnGroups}
        />
      </div>
      <TableColumnSelectForm
        disposition={viewOptions.disposition}
        eligibleColumnDefinitions={eligibleColumnDefinitions}
        columns={columns}
        columnGroups={columnGroups}
        onToggleVisible={onToggleVisible}
      />
    </div>
  );
}

interface TableColumnSelectFormProps {
  disposition: TableColumnViewOptionsDisposition;
  eligibleColumnDefinitions: IColumnDefinition[];
  columns: TStateColumns;
  columnGroups: string[];
  onToggleVisible: (columnId: string, visible: boolean) => void;
}
function TableColumnSelectForm (props: TableColumnSelectFormProps) {
  const {
    disposition,
    columnGroups,
    eligibleColumnDefinitions,
    columns,
    onToggleVisible,
  } = props;

  const columnsByGroup = useMemo(() => {

    // columns without any group are put into their own bucket
    const otherColumns = eligibleColumnDefinitions.filter(c => {
      return !c.groups || c.groups.length < 1;
    });

    const columnsByGroup = columnGroups.reduce<Record<string, IColumnDefinition[]>>((map, group) => {
      const columns = eligibleColumnDefinitions.filter(column => {
        if (!column.groups) return false;
        return column.groups.includes(group);
      });

      if (!map[group]) map[group] = [];
      map[group] = columns;
      return map;
    }, {other: otherColumns});

    return columnsByGroup;
  }, [columnGroups, eligibleColumnDefinitions]);

  return (
    <Form onSubmit={preventDefaultSubmit}>
      {disposition === 'list' && (
        <div className="d-flex flex-wrap p-3">
          {eligibleColumnDefinitions.map(columnDefinition => (
            <TableColumn
              key={columnDefinition.id}
              columnDefinition={columnDefinition}
              visible={tableHelpers.columnIsVisible(columns, columnDefinition.id)}
              onToggleVisible={onToggleVisible}
            />
          ))}
        </div>
      )}
      {disposition === 'columns' && (
        <Row className="m-0 py-2 py-md-0 px-1">
          {Object.keys(columnsByGroup).map(group => (
            <Col key={group} lg={2} md={3} sm={4}>
              <div className="border my-2 my-sm-3 p-3 rounded">
                <h4>{groupLabel(group)}</h4>
                {columnsByGroup[group].map(columnDefinition => (
                  <TableColumn
                    key={columnDefinition.id}
                    columnDefinition={columnDefinition}
                    visible={tableHelpers.columnIsVisible(columns, columnDefinition.id)}
                    onToggleVisible={onToggleVisible}
                  />
                ))}
              </div>
            </Col>
          ))}
        </Row>
      )}
    </Form>
  );
}

interface TableColumnViewOptionsFormProps {
  viewOptions: TableColumnViewOptions;
  setViewOptions: React.Dispatch<React.SetStateAction<TableColumnViewOptions>>;
  columnGroups: string[];
}

function TableColumnViewOptionsForm (props: TableColumnViewOptionsFormProps) {
  const { viewOptions, setViewOptions, columnGroups } = props;

  const onChange = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
    const newSettings = {...viewOptions, [ev.target.name]: ev.target.value};
    setViewOptions(newSettings);
  }, [viewOptions, setViewOptions]);

  const onClickReset = () => {
    setViewOptions(viewOptions => ({...viewOptions, searchText: ''}));
  };

  return (
    <Form onSubmit={preventDefaultSubmit}>
      <Row gap={3}>
        <Col>
          <Form.Group className="mb-3 mb-md-0">
            <InputGroup>
              <Form.Control
                name="searchText"
                value={viewOptions.searchText || ''}
                onChange={onChange}
                placeholder="Sök efter kolumn"
                style={{minWidth: '100px'}}
              />
              <Button
                variant={viewOptions.searchText ? 'danger' : 'outline-danger'}
                className="p-1"
                onClick={onClickReset}
                disabled={!viewOptions.searchText}
                title="Ta bort söktexten"
              >
                <X size={19} />
              </Button>
            </InputGroup>
          </Form.Group>
        </Col>
        {columnGroups.length > 0 ? (
          <>
            <Col xs="auto">
              <div className="d-flex align-items-center border rounded gap-3 px-3 py-1 flex-wrap">
                <strong>Visa: </strong>
                <IdProvider>
                  {id => (
                    <Form.Check
                      className="mb-0"
                      type="radio"
                      name="disposition"
                      value="list"
                      label="Lista"
                      checked={viewOptions.disposition === 'list'}
                      onChange={onChange}
                      id={id}
                    />
                  )}
                </IdProvider>
                <IdProvider>
                  {id => (
                    <Form.Check
                      className="mb-0"
                      type="radio"
                      name="disposition"
                      value="columns"
                      label="Kolumner"
                      checked={viewOptions.disposition === 'columns'}
                      onChange={onChange}
                      id={id}
                    />
                  )}
                </IdProvider>
              </div>
            </Col>
          </>
        ) : null}
      </Row>
    </Form>
  );
}

interface TableColumnProps {
  columnDefinition: IColumnDefinition;
  visible: boolean;
  onToggleVisible: (id: string, visible: boolean) => void;
}

const TableColumn: React.FC<TableColumnProps> = React.memo(function TableColumn (props: TableColumnProps) {
  const { columnDefinition, onToggleVisible, visible } = props;
  const id = React.useId();
  return (
    <div className="px-1">
      <Form.Check
        type="checkbox"
        checked={visible}
        value={columnDefinition.id}
        onChange={() => onToggleVisible(columnDefinition.id, !visible)}
        label={columnDefinition.title}
        id={id}
      />
    </div>
  );
});

function shouldShowByViewOptions (viewOptions: TableColumnViewOptions, definition: IColumnDefinition) {
  const { searchText } = viewOptions;

  if (searchText && searchText.length > 0) {
    const regexp = new RegExp(searchText, 'gi');
    const { id, title } = definition;
    if (!regexp.test(id) && !regexp.test(title ?? '')) {
      return false;
    }
  }
  return true;
}

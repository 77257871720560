import React from 'react';
import { WidgetListCard } from 'src/cards/CardHelpers';
import { WidgetGroups, WidgetList } from 'src/widgets/types';
import { useQuery } from '@tanstack/react-query';
import { WidgetLoader, WidgetTimeline } from 'src/widgets/CommonWidgets';
import { UserRow } from 'shared/types/user';
import { UserLogRowWithRelations } from 'shared/types/user_log';
import * as userLogFilters from 'shared/filter/user_log';
import { Link } from 'react-router-dom';

interface UserWidgetProps {
  user: UserRow;
}

export const widgetGroups: WidgetGroups = [
  {id: 'properties', title: 'Egenskaper'},
];

export const allWidgets: WidgetList = {
  log: {
    id: 'log',
    Component: Log,
    title: 'Logg',
    group: 'properties',
  },
};

interface UserLogResponse {
  logs: UserLogRowWithRelations[];
}

export function Log (props: UserWidgetProps) {
  const { user } = props;

  const query = useQuery<UserLogResponse, Error>({
    queryKey: [`/user/${user.id}/log`, {params: {limit: 5}}],
  });

  return (
    <WidgetListCard {...props}>
      <WidgetLoader isLoading={query.isLoading} error={query.error}>
        {query.isSuccess && (
          <WidgetTimeline
            className="m-3"
            list={query.data.logs}
            description={userLogFilters.description}
            by={item => item.User ? item.User.name : 'System'}
          />
        )}
        <div className="d-flex justify-content-center mb-2 pt-2 border-top">
          <Link className="btn btn-outline-secondary btn-sm" to={`/user/${user.id}/log`}>
            Visa hela loggen
          </Link>
        </div>
      </WidgetLoader>
    </WidgetListCard>
  );
}

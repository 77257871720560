import React from 'react';
import letterFilter from 'shared/filter/letter';
import * as TableCells from 'src/tables/TableCells';
import * as commonFilterDefs from 'src/tables/commonFilterDefinitions';
import * as commonColumnDefs from 'src/tables/commonColumnDefinitions';
import * as TableCellActions from 'src/tables/TableCellActions';
import { IColumnCellProps, IColumnDefinition, IFilterDefinition } from 'src/tables/Table';
import TablePage from 'src/tables/TablePage';
import { Download } from 'react-feather';

export default function LetterTablePage () {
  return (
    <TablePage
      title="Brev"
      pageTitle="Brevtabell"
      columnDefinitions={columnDefinitions}
      filterDefinitions={filterDefinitions}
      routeUrl="/letter"
      baseQueryUrl="/letter/table"
      baseQueryKey="LetterTable"
      queryResultRowsKey="letters"
      batchDefinition={batchDefinition}
    />
  );
}

const batchDefinition = {
  url: '/letter/batch',
  permission: 'letter_batch',
  actions: [
    {value: 'generate_crediflow_series', label: 'Generera Crediflow-serie'},
    {value: 'set_status_sent', label: 'Markera som "Skickad"'},
    {value: 'send', label: 'Köa för skickning'},
    {value: 'render', label: 'Köa för rendering'},
    {value: 'delete', label: 'Radera'},
  ],
};

const filterDefinitions: IFilterDefinition[] = [
  commonFilterDefs.letterStatus({id: 'status'}),
  commonFilterDefs.uuid({id: 'id'}),
  // commonFilterDefs.string({id: 'from_address'}),
  // commonFilterDefs.string({id: 'to_address'}),
  commonFilterDefs.applicationId({id: 'application_id'}),
  // commonFilterDefs.uuid({id: 'customer_cloud_insurance_id'}),
  commonFilterDefs.uuid({id: 'template_id'}),
  commonFilterDefs.userId({id: 'user_id'}),
  commonFilterDefs.dateRange({id: 'created_at'}),
  commonFilterDefs.dateRelative({id: 'created_at_relative'}),
  commonFilterDefs.dateRange({id: 'status_at'}),
  commonFilterDefs.dateRelative({id: 'status_at_relative'}),
  commonFilterDefs.dateRange({id: 'updated_at'}),
  commonFilterDefs.dateRelative({id: 'updated_at_relative'}),
].map(filterDefinition => ({
  title: letterFilter.indexColumnName(filterDefinition.id),
  ...filterDefinition,
}));

const columnDefinitions: IColumnDefinition[] = [
  commonColumnDefs.select(),
  ...([
    commonColumnDefs.letterId({id: 'id', cellProps: {rowAsObject: true}}),
    commonColumnDefs.datetime({
      id: 'created_at',
      sortable: true,
      groups: ['dates', 'sortable'],
    }),
    commonColumnDefs.datetime({
      id: 'updated_at',
      sortable: true,
      groups: ['dates', 'sortable'],
    }),
    commonColumnDefs.datetime({
      id: 'status_at',
      sortable: true,
      groups: ['dates', 'sortable'],
    }),
    commonColumnDefs.cell('application_id', TableCells.ApplicationTableCell, {idKey: 'application_id'}),
    commonColumnDefs.cell('user_id', TableCells.UserTableCell, {idKey: 'user_id'}),
    commonColumnDefs.cell('template_id', TableCells.TemplateTableCell, {idKey: 'template_id'}),
    commonColumnDefs.foreignId('foreign_id', {
      application_id: {
        Cell: TableCells.ApplicationTableCell,
        cellProps: {idKey: 'application_id'},
      },
    }),
    commonColumnDefs.cell('template'),
    commonColumnDefs.cell('name'),
    commonColumnDefs.cell('address'),
    commonColumnDefs.cell('pdf', LetterDownloadPdfCell),
    commonColumnDefs.cell('crediflow_series'),
    commonColumnDefs.cell('status', TableCells.LetterStatusTableCell),
  ].map(columnDefinition => {
    const { id } = columnDefinition;
    const header = letterFilter.indexColumnName(id);
    const cellValue = letter => letterFilter.indexField(letter, id);
    return {
      Cell: ({value}) => <>{value}</>,
      ...columnDefinition,
      cellValue,
      Header: ({value}) => <>{value}</>,
      title: header,
    };
  })),
  commonColumnDefs.actions({}, [
    TableCellActions.InspectRowTableCellAction,
  ]),
];

function LetterDownloadPdfCell (props: IColumnCellProps) {
  const { row } = props;
  if (!row.pdf) return null;
  return (
    <a className="px-1 py-0 btn btn-outline-primary" href={`/api/letter/${row.id}/pdf`} target="_blank" rel="noopener noreferrer" title="Ladda hem renderad PDF">
      <Download size={16} />
    </a>
  );
}

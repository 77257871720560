import React from 'react';
import countries from 'shared/countries';

const UNICODE_OFFSET = 127397;

interface ICountryFlag {
  countryCode: string;
}

export default function CountryFlag (props: ICountryFlag) {
  const { countryCode } = props;
  if (!countryCode) return null;
  return (
    <abbr style={{textDecoration: 'none'}} title={countryCodeToName(countryCode)}>
      {countryCodeToFlag(countryCode)}
    </abbr>
  );
}

function countryCodeToFlag (countryCode: string): string {
  if (typeof countryCode !== 'string' || countryCode.length !== 2) {
    return '';
  }
  const codePoints: number[] = countryCode.split('').map((c: string) => {
    return c.codePointAt(0) + UNICODE_OFFSET;
  }).filter(v => v);
  return String.fromCodePoint(...codePoints);
}

function countryCodeToName (countryCode: string): string {
  if (Array.isArray(countryCode)) return countryCode.map(c => countries[c]).join(', ');
  return countries[countryCode];
}

import React from 'react';
import TableControlPanelHead from 'src/tables/TableControlPanelHead';
import TableControlPanelFoot from 'src/tables/TableControlPanelFoot';
import TableHead from 'src/tables/TableHead';
import TableBody from 'src/tables/TableBody';
import TableFoot from 'src/tables/TableFoot';
import { UseMutateAsyncFunction } from '@tanstack/react-query';
import {
  IState,
  IUpdateRowMutationData,
  ISelectedRows,
  IBatchDefinition,
  IBatchActionMutationData,
  IStateOrder,
  TableRowId,
} from 'src/tables/types';
import { Card, Table as BsTable } from 'react-bootstrap';
import { PermissionDefinition } from 'src/contexts/AuthContext';

export interface TableRow {
  id: TableRowId;
  [key: string]: any;
}

// just some custom parameters that can be passed to the table
export type TTableParams = ({[key: string]: any});

export interface IColumnHeaderProps {
  value: string;
  allRowsSelected: boolean;
  anyRowsSelected: boolean;
  onChangeAllSelected: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  [key: string]: any;
}

export interface IColumnCellProps {
  value: any;
  original?: any;
  row: TableRow;
  selected: boolean;
  onChangeSelected: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  updateRowMutateAsync: (data: IUpdateRowMutationData) => Promise<any>;
  [key: string]: any;
}

export interface IColumnFooterProps {
  selectedRows: ISelectedRows;
  rows: TableRow[];
  allRowsSelected: boolean;
  anyRowsSelected: boolean;
  [key: string]: any;
}

export interface IColumnDefinition {
  id: string;
  title?: string;
  cellValue?: any;
  cellOriginal?: any | ((row: TableRow) => any);
  cellProps?: ({[key: string]: any});
  headerProps?: ({[key: string]: any});
  sortable?: boolean;
  Header?: React.ComponentType<IColumnHeaderProps>;
  Cell?: React.ComponentType<IColumnCellProps>;
  Footer?: React.ComponentType<IColumnFooterProps>;
  permission?: PermissionDefinition;
  groups?: string[];
}

export interface IColumnDefinitionMap {
  [key: string]: IColumnDefinition;
}

export interface IFilterFilterProps {
  id: string;
  name: string;
  value: any;
  onChangeValue: (value: any) => void;
  [key: string]: any;
}

export interface IFilterTitleProps {
}

export interface IFilterDefinition {
  id: string;
  filterValue?: (value: any) => React.ReactNode;
  Filter: React.ComponentType<IFilterFilterProps>;
  Title?: React.ComponentType<IFilterTitleProps>;
  title?: string;
  filterProps?: {[key: string]: any};
  groups?: string[];
  permission?: PermissionDefinition;
}

export interface IFilterDefinitionMap {
  [key: string]: IFilterDefinition;
}

export interface ITable {
  id: string;
  title: string;
  isLoading: boolean;
  error: unknown;
  rows: TableRow[];
  count?: number;
  refetch: () => Promise<any>;
  state: IState;
  setState: React.Dispatch<React.SetStateAction<IState>>;
  savedStates: IState[];
  setSavedStates: React.Dispatch<React.SetStateAction<IState[]>>;
  defaultState: IState;
  exportUrl?: string;
  routeUrl: string;
  columnDefinitions: IColumnDefinition[];
  filterDefinitions: IFilterDefinition[];
  selectedRows: ISelectedRows;
  setSelectedRows: React.Dispatch<React.SetStateAction<ISelectedRows>>;
  onSwapState: (state: IState) => void;
  onSaveNewState: (state: IState) => Promise<any>;
  onDeleteState: (state: IState) => Promise<any>;
  updateRowMutateAsync: UseMutateAsyncFunction<unknown, Error, IUpdateRowMutationData>;
  batchDefinition?: IBatchDefinition;
  batchActionMutateAsync: UseMutateAsyncFunction<unknown, Error, IBatchActionMutationData>;
}

const Table: React.FC<ITable> = React.memo(function Table (props: ITable) {
  const {
    id,
    title,
    isLoading,
    error,
    rows,
    count,
    refetch,
    state,
    setState,
    savedStates,
    setSavedStates,
    defaultState,
    columnDefinitions,
    filterDefinitions,
    selectedRows,
    setSelectedRows,
    onSwapState,
    onSaveNewState,
    onDeleteState,
    exportUrl,
    routeUrl,
    updateRowMutateAsync,
    batchDefinition,
    batchActionMutateAsync,
  } = props;

  const updateState = React.useCallback((extendWith = {}) => setState({
    ...state,
    ...extendWith,
  }), [state, setState]);

  const updateStateOrder = React.useCallback((newOrder: IStateOrder) => {
    updateState({order: newOrder});
  }, [updateState]);

  const columnDefinitionsById: IColumnDefinitionMap = React.useMemo(() => columnDefinitions.reduce((map, def) => {
    map[def.id] = def;
    return map;
  }, {}), [columnDefinitions]);

  return (
    <>
      <Card className="mb-0 rounded-0 overflow-hidden flex-grow-1">
        <TableControlPanelHead
          title={title}
          isLoading={isLoading}
          error={error}
          state={state}
          refetch={refetch}
          setState={setState}
          updateState={updateState}
          savedStates={savedStates}
          setSavedStates={setSavedStates}
          defaultState={defaultState}
          columnDefinitions={columnDefinitions}
          filterDefinitions={filterDefinitions}
          onSwapState={onSwapState}
          onSaveNewState={onSaveNewState}
          onDeleteState={onDeleteState}
          routeUrl={routeUrl}
        />
        <Card.Body className="p-0 border-top" style={{overflowY: 'auto', overflowX: 'scroll'}}>
          <BsTable
            id={id}
            className="mb-0 border-bottom table-sticky-thead"
            striped
            borderless
            size={state.size === 'sm' ? 'sm' : undefined}
          >
            <TableHead
              columnDefinitionsById={columnDefinitionsById}
              columns={state.columns}
              order={state.order}
              setOrder={updateStateOrder}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              rows={rows}
            />
            <TableBody
              columnDefinitionsById={columnDefinitionsById}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              updateRowMutateAsync={updateRowMutateAsync}
              rows={rows}
              columns={state.columns}
            />
            <TableFoot
              columnDefinitionsById={columnDefinitionsById}
              selectedRows={selectedRows}
              rows={rows}
              columns={state.columns}
            />
          </BsTable>
        </Card.Body>
      </Card>
      <TableControlPanelFoot
        state={state}
        updateState={updateState}
        count={count ?? null}
        selectedRows={selectedRows}
        exportUrl={exportUrl}
        batchDefinition={batchDefinition}
        batchActionMutateAsync={batchActionMutateAsync}
      />
    </>
  );
});
export default Table;

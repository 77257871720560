import React from 'react';
import { ThumbsUp, ThumbsDown } from 'react-feather';
import classNames from 'classnames';

export default function BooleanIcon (props) {
  const { value, canBeIndetermined = false, className, ...otherProps } = props;
  const classes = classNames(className, 'status-label p-1 text-white', value ? 'status-label-true' : 'status-label-false');
  if (canBeIndetermined && typeof value !== 'boolean') return null;
  if (value) {
    return (
      <ThumbsUp className={classes} {...otherProps} />
    );
  }
  return (
    <ThumbsDown className={classes} {...otherProps} />
  );
}

import React from 'react';
import classNames from 'classnames';
import { Form } from 'react-bootstrap';
import { preventDefaultSubmit } from 'src/misc';

export default function TablePageSizeForm (props) {
  const { className, state, updateState } = props;
  const htmlPageLimitId = React.useId();
  const { limit } = state;
  return (
    <Form className={classNames(className)} onSubmit={preventDefaultSubmit}>
      <label htmlFor={htmlPageLimitId} className="me-2 small">
        Antal per sida:
      </label>
      <Form.Select
        id={htmlPageLimitId}
        className="d-inline-block w-auto"
        value={limit}
        onChange={ev => updateState({limit: parseInt(ev.target.value, 10) || 10})}
      >
        {[10, 20, 50, 100].map(pageSize => (
          <option key={pageSize} value={pageSize}>
            {pageSize}
          </option>
        ))}
      </Form.Select>
    </Form>
  );
}

import React from 'react';
import { Button, ButtonProps } from 'react-bootstrap';
import classNames from 'classnames';

interface IconButtonProps extends ButtonProps {
  Icon?: React.ReactNode;
}

export default function IconButton (props: IconButtonProps) {
  const {
    Icon,
    size,
    className,
    ...buttonProps
  } = props;

  return (
    <Button
      className={classNames(className, 'btn-icon')}
      size={size}
      {...buttonProps}
    >
      {Icon}
    </Button>
  );
}

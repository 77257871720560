import React from 'react';
import * as api from 'src/api';
import Main from 'src/ui/Main';
import Navbar from 'src/navbar/Navbar';
import Content from 'src/ui/Content';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import PlaceholderCard from 'src/cards/PlaceholderCard';

// TODO finish

interface CustomerCloudInsurancePageProps {
}

const CustomerCloudInsurancePage: React.FC<CustomerCloudInsurancePageProps> = React.memo(function CustomerCloudInsurancePage (props: CustomerCloudInsurancePageProps) {
  const { insuranceId } = useParams();
  const queryKey = ['insurance', {insuranceId}];
  const queryFn = () => api.request({url: `/customer_cloud_insurance/${insuranceId}`});
  return (
    <Main>
      <Navbar />
      <Content>
        <Helmet title="Försäkring (Cloud)" />
        <PlaceholderCard
          queryKey={queryKey}
          queryFn={queryFn}
        />
      </Content>
    </Main>
  );
});
export default CustomerCloudInsurancePage;

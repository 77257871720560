import React from 'react';
import {
  FormikFormGroupSelect,
  FormikFormGroupControl,
} from 'src/form/FormikFormControls';
import * as insuranceFilters from 'shared/filter/customer_jaycom_insurance';
import * as JaycomInsuranceFormatters from 'src/jaycomInsurance/JaycomInsuranceFormatters';
import constants from 'shared/constants';

export function Status () {
  return (
    <FormikFormGroupSelect
      label="Status"
      name="status"
      required
    >
      {constants.customerJaycomInsurance.statusTypes.map(status => (
        <option value={status} key={status}>{insuranceFilters.status(status)}</option>
      ))}
    </FormikFormGroupSelect>
  );
}

export function FormInsuredAmout () {
  return (
    <FormikFormGroupSelect
      label="Försäkrat månadsbelopp"
      name="insured_amount"
      required
    >
      <option value="">Ange önskad summa</option>
      {constants.customerJaycomInsurance.insuredAmounts.map((amount, index) => (
        <option value={amount} key={amount}><JaycomInsuranceFormatters.InsuredAmount value={amount} /></option>
      ))}
    </FormikFormGroupSelect>
  );
}

export function FormPaymentMethod () {
  return (
    <FormikFormGroupSelect
      label="Betalningsmetod"
      name="payment_method"
      required
    >
      <option value="autogiro">Autogiro</option>
      <option value="invoice">Faktura</option>
    </FormikFormGroupSelect>
  );
}

export function FormClearingNo () {
  return (
    <FormikFormGroupControl
      label="Clearingnummer"
      minLength={4}
      maxLength={4}
      name="clearing_no"
      pattern="^\d{4}$"
      required
    />
  );
}

export function FormAccountNo () {
  return (
    <FormikFormGroupControl
      label="Kontonummer"
      minLength={1}
      maxLength={100}
      name="account_no"
      pattern="^\d+$"
      required
    />
  );
}

/**
 * WARNING: This file is used clientside
 */

const getter = require('shared/get');

exports.indexColumnName = function (columnKey) {
  switch (columnKey) {
    default: return columnKey;
    case 'id': return 'ID';
    case 'customer_id': return 'Kund';
    case 'subject': return 'Ämne';
    case 'method': return 'Metod';
    case 'user_id': return 'Användare';
    case 'created_at': return 'Skapad';
    case 'created_at_relative': return 'Skapad';
    case 'expires_at': return 'Utgår';
    case 'expires_at_relative': return 'Utgår';
    case 'actions': return 'Åtgärder';
  }
};

exports.indexField = function (customerJaycomInsurance, columnKey) {
  const get = (...args) => getter(customerJaycomInsurance, ...args);
  switch (columnKey) {
    default: return get(columnKey);
    case 'customer_id': return get('Customer.name') || get('Customer.id') || get('customer_id') || '';
    case 'user_id': return get('User.name') || get('User.id') || get('user_id') || '';
    case 'method': return exports.method(get(columnKey));
  }
};

exports.method = function (method) {
  switch (method) {
    default: return 'Okänd: ' . method;
    case 'customer_other': return 'Kund (övrig)';
    case 'customer_bankid_auth': return 'BankID-autentisering av kund';
    case 'customer_bankid_sign': return 'BankID-signering av kund';
    case 'user_bankid_phone_auth': return 'BankID-telefonautentisering med användare';
    case 'user_bankid_phone_sign': return 'BankID-telefonsignering med användare';
    case 'customer_web': return 'Kund via webben';
    case 'user_phone': return 'Telefonsamtal med kunden';
    case 'user_other': return 'Användare (övrig)';
  }
};

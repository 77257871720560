import React from 'react';
import sortBy from 'lodash/sortBy';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import classNames from 'classnames';
import { Plus } from 'react-feather';
import { Move, Trash } from 'react-feather';
import { Button, ButtonGroup, Form } from 'react-bootstrap';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorAlert from 'src/alerts/ErrorAlert';
import {
  WidgetGroups,
  WidgetList as WidgetListType,
  WidgetListState,
  WidgetListStateAction,
} from 'src/widgets/types';

interface WidgetListProps {
  widgetGroups?: WidgetGroups;
  allWidgets: WidgetListType;
  widgetProps?: Record<string, any>;
  state: WidgetListState;
  dispatch: React.Dispatch<WidgetListStateAction>;
}

export default function WidgetList (props: WidgetListProps) {
  const {
    widgetGroups = [],
    allWidgets,
    widgetProps = {},
    dispatch,
    state,
  }  = props;

  const handleDragEnd = result => {
    if (!result.destination) return;
    const { destination, source } = result;
    dispatch({type: 'swap', from: source.index, to: destination.index});
  };

  const availableWidgets = Object.keys(allWidgets).filter(id => {
    return !(state.current || []).find(item => item.id === id);
  }).map(id => ({id, ...allWidgets[id]}));

  const itemsIndexed = state.current.map((item, index) => ({
    ...item,
    index,
  })).filter(item => allWidgets[item.id]);

  return (
    <>
      <ControlPanel
        layout={state.active}
        widgetGroups={widgetGroups}
        availableWidgets={availableWidgets}
        onChangeLayout={index => dispatch({type: 'activate', index})}
        onSubmit={id => dispatch({type: 'add', id})}
      />

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {itemsIndexed.map(item => (
                <Draggable
                  key={item.id}
                  draggableId={item.id}
                  index={item.index}
                >
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.draggableProps}>
                      <WidgetContainer
                        Component={allWidgets[item.id].Component}
                        title={allWidgets[item.id].title}
                        dragHandleProps={provided.dragHandleProps}
                        isDragging={snapshot.isDragging}
                        onRemove={() => dispatch({type: 'remove', id: item.id})}
                        widgetProps={widgetProps}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}

function ControlPanel (props) {
  const { availableWidgets, widgetGroups, onSubmit, layout, onChangeLayout } = props;

  const groupedWidgets = widgetGroups.map(group => {
    const widgets = Object.keys(availableWidgets).filter(id => {
      return availableWidgets[id].group === group.id;
    }).map(id => availableWidgets[id]);
    return {...group, widgets: sortBy(widgets, 'title')};
  });

  const [choice, setChoice] = React.useState('');

  const handleSubmit = ev => {
    ev.preventDefault();
    setChoice('');
    onSubmit(choice);
  };

  return (
    <Form onSubmit={handleSubmit} className="mx-3 mt-1 mb-3 d-flex justify-content-between">
      <ButtonGroup size="sm" className="rounded">
        {[0, 1, 2].map(value => (
          <FormControlWidgetLayoutButton
            key={value}
            value={value}
            layout={layout}
            onChangeLayout={onChangeLayout}
            disabled={layout === value}
          />
        ))}
      </ButtonGroup>
      <Form.Group className="d-flex flex-fill ms-2">
        <Form.Select
          size="sm"
          name="id"
          disabled={!availableWidgets.length}
          onChange={ev => setChoice(ev.target.value)}
          value={choice}
          required
        >
          <option value="">
            {!availableWidgets.length ? 'Inga fler rutor att lägga till' : 'Välj en ruta att lägga till'}
          </option>
          {groupedWidgets.map(group => (
            <optgroup label={group.title} key={group.id}>
              {group.widgets.map(({id, title}) => (
                <option key={id} value={id}>{title}</option>
              ))}
            </optgroup>
          ))}
        </Form.Select>
        <Button
          type="submit"
          variant="secondary"
          size="sm"
          className="ms-2 rounded"
          disabled={!availableWidgets.find(w => w.id === choice)}
        >
          <Plus /> 
        </Button>
      </Form.Group>
    </Form>
  );
}

function WidgetContainer (props) {
  const {
    widgetProps,
    dragHandleProps,
    onRemove,
    isDragging,
    Component,
    title,
  } = props;

  const className = classNames('m-0 border', {'border-primary': isDragging});
  return (
    <div className="pb-3 pt-0 px-3">
      <ErrorBoundary FallbackComponent={ErrorAlert}>
        <Component
          {...widgetProps}
          title={title}
          className={className}
          controls={(
            <>
              <Button variant="" size="sm" className="btn-icon p-0" {...dragHandleProps} title="Flytta">
                <Move size={16} />
              </Button>
              <Button variant="" size="sm" className="btn-icon p-0" onClick={onRemove} title="Ta bort">
                <Trash size={16} />
              </Button>
            </>
          )}
        />
      </ErrorBoundary>
    </div>
  );
}

function FormControlWidgetLayoutButton (props) {
  const { value, layout, onChangeLayout, ...otherProps } = props;
  const variant = value === layout ? 'secondary' : 'outline-secondary';
  return (
    <Button
      variant={variant}
      size="sm"
      onClick={() => onChangeLayout(value)}
      {...otherProps}
    >
      {value + 1}
    </Button>
  );
}

import React from 'react';
import ModalAnchor from 'src/anchors/ModalAnchor';
import PepQueryModalBody from 'src/pepQuery/PepQueryModalBody';
import { Name } from 'src/pepQuery/PepQueryFormatters';

export default function PepQueryAnchor (props) {
  const { pepQuery, ...restOfProps } = props;
  return (
    <ModalAnchor
      {...restOfProps}
      title={<Name value={pepQuery} />}
      name="PEP-fråga"
    >
      <PepQueryModalBody id={pepQuery.id} />
    </ModalAnchor>
  );
}


import React from 'react';

interface TaskRowModalBody {
  id: string;
}

export default function TaskModalBody (props: TaskRowModalBody) {
  return (
    <div>task modal body here</div>
  );
}


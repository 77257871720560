import React from 'react';

import { THEME, TTheme } from 'src/constants';
import useSettingsState from 'src/hooks/useSettingsState';

interface IThemeContext {
  theme: TTheme;
  setTheme: (newTheme: TTheme) => void;
}

const initialState: IThemeContext = {
  theme: THEME.LIGHT,
  setTheme: () => {},
};

const ThemeContext = React.createContext<IThemeContext>(initialState);

function ThemeProvider ({ children }) {
  const [theme, setTheme] = useSettingsState('theme', THEME.LIGHT);
  const value: IThemeContext = {theme, setTheme};
  return (
    <ThemeContext.Provider value={value}>
      {children}
    </ThemeContext.Provider>
  );
}

export { ThemeProvider, ThemeContext };

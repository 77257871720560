import React from 'react';
import { WidgetListCard } from 'src/cards/CardHelpers';
import { WidgetGroups, WidgetList } from 'src/widgets/types';
import { useQuery } from '@tanstack/react-query';
import { WidgetLoader, WidgetTimeline } from 'src/widgets/CommonWidgets';
import UserAnchor from 'src/user/UserAnchor';
import { BankProcessRow } from 'shared/types/bank_process';
import { BankProcessLogRowWithRelations } from 'shared/types/bank_process_log';
import * as bankProcessLogFilters from 'shared/filter/bank_process_log';
import { Link } from 'react-router-dom';

interface BankProcessWidgetProps {
  bankProcess: BankProcessRow;
}

export const widgetGroups: WidgetGroups = [
  {id: 'properties', title: 'Egenskaper'},
];

export const allWidgets: WidgetList = {
  log: {
    id: 'log',
    Component: Log,
    title: 'Logg',
    group: 'properties',
  },
};

interface BankProcessLogResponse {
  logs: BankProcessLogRowWithRelations[];
  bankProcess: BankProcessRow;
}

export function Log (props: BankProcessWidgetProps) {
  const { bankProcess } = props;

  const query = useQuery<BankProcessLogResponse, Error>({
    queryKey: [`/bank_process/${bankProcess.id}/log`, {params: {limit: 5}}],
  });

  return (
    <WidgetListCard {...props}>
      <WidgetLoader isLoading={query.isLoading} error={query.error}>
        {query.isSuccess && (
          <WidgetTimeline
            className="m-3"
            list={query.data.logs}
            description={bankProcessLogFilters.description}
            by={item => item.user_id ? <UserAnchor user={item.User} /> : 'System'}
          />
        )}
        <div className="d-flex justify-content-center mb-2 pt-2 border-top">
          <Link className="btn btn-outline-secondary btn-sm" to={`/bank_process/${bankProcess.id}/log`}>
            Visa hela loggen
          </Link>
        </div>
      </WidgetLoader>
    </WidgetListCard>
  );
}

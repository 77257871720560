import React from 'react';
import classNames from 'classnames';
import { Button, ButtonProps, Spinner } from 'react-bootstrap';

// TODO move into src/buttons

export interface ButtonSpinnerProps extends ButtonProps {
  className?: string;
  isLoading?: boolean;
  spinnerSize?: 'sm';
}

export default function ButtonSpinner (props: ButtonSpinnerProps) {
  const {
    isLoading,
    children,
    spinnerSize = 'sm',
    className,
    ...buttonProps
  } = props;
  const classes = classNames(className, 'd-inline-flex align-items-center');
  return (
    <Button {...buttonProps} className={classes}>
      {isLoading && (
        <Spinner className="me-2" animation="border" size={spinnerSize} variant="default" />
      )}
      {children}
    </Button>
  );
}

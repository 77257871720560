import React from 'react';
import * as api from 'src/api';
import Main from 'src/ui/Main';
import Navbar from 'src/navbar/Navbar';
import Content from 'src/ui/Content';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import PlaceholderCard from 'src/cards/PlaceholderCard';

// TODO finish

interface IComplaintPage {
}

const ComplaintPage: React.FC<IComplaintPage> = React.memo(function ComplaintPage (props: IComplaintPage) {
  const { complaintId } = useParams();
  const queryKey = ['complaint', {complaintId}];
  const queryFn = () => api.request({url: `/complaint/${complaintId}`});
  return (
    <Main>
      <Navbar />
      <Content>
        <Helmet title="Klagomål" />
        <PlaceholderCard
          queryKey={queryKey}
          queryFn={queryFn}
        />
      </Content>
    </Main>
  );
});
export default ComplaintPage;

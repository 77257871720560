import React from 'react';
import * as api from 'src/api';
import Main from 'src/ui/Main';
import Navbar from 'src/navbar/Navbar';
import Content from 'src/ui/Content';
import { Helmet } from 'react-helmet-async';
import { useParams, NavLink, useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import BlockSpinner from 'src/spinners/BlockSpinner';
import { BankRow } from 'shared/types/bank';
import { ErrorAlertCardBody, CardTitleIconStatus  } from 'src/cards/CardHelpers';
import { Card, Tab, Nav } from 'react-bootstrap';
import { Activity, DollarSign, Edit2, Type } from 'react-feather';
import * as BankFormatters from 'src/bank/BankFormatters';
import * as IconButtons from 'src/buttons/IconButtons';
import Breadcrumbs from 'src/components/Breadcrumbs';
import BankEditForm, { BankEditFormValues, bankToFormValues, formValuesToUpdate } from 'src/bank/BankEditForm';
import * as formUtils from 'src/utils/form';
import BankLogCard from 'src/cards/BankLogCard';
import useNotyf from 'src/hooks/useNotyf';
import BankTextFragmentCard from 'src/cards/BankTextFragmentCard';
import BankCommissionRateCards from 'src/cards/BankCommissionRateCards';

interface BankPageParams {
  bankId: string;
  tab?: string;
}

export default function BankPage () {
  const { bankId, tab = 'form' } = useParams() as unknown as BankPageParams;

  const queryClient = useQueryClient();
  const notyf = useNotyf();
  const navigate = useNavigate();

  const readQuery = useQuery<BankRow, Error>({
    queryKey: [`/bank/${bankId}`],
  });

  const bank = readQuery.data;

  const updateMutation = useMutation<BankRow, Error, Partial<BankRow>>({
    mutationFn: vars => api.request({
      url: `/bank/${bankId}`,
      method: 'PATCH',
      data: vars,
    }),
    onSuccess: result => {
      queryClient.setQueryData([`/bank/${bankId}`], result);
      notyf.success({message: 'Långivaren uppdaterades'});
    },
  });

  const deleteMutation = useMutation<void, Error>({
    mutationFn: () => api.request({
      url: `/bank/${bankId}`,
      method: 'DELETE',
    }),
    onSuccess: () => {
      navigate('/banks');
      notyf.success({message: 'Långivaren raderades'});
    },
  });

  const initialValues = bank ? bankToFormValues(bank) : {};

  const formCycleHelpers = formUtils.getFormikFormCycleHelpers<BankRow, BankEditFormValues, Partial<BankRow>>({
    queryDataToFormValues: bankToFormValues,
    formValuesToMutationVars: values => formValuesToUpdate(initialValues, values),
    mutateAsync: updateMutation.mutateAsync,
  });

  const onClickDelete = () => {
    return deleteMutation.mutateAsync();
  };

  return (
    <Main>
      <Navbar />
      <Content>
        <Helmet title="Långivare" />
        <h1>Redigera långivare</h1>
        <Breadcrumbs
          crumbs={[
            ['/banks', 'Långivare'],
            [`/bank/${bankId}/form`, bank ? <BankFormatters.Name value={bank} /> : bankId],
          ]}
        />
        <BlockSpinner isLoading={readQuery.isLoading} />
        <ErrorAlertCardBody error={readQuery.error} className="mx-0 mt-0 mb-3" />
        {bank && (
          <Card className="border">
            <Card.Header className="border-bottom bg-light">
              <CardTitleIconStatus
                as="h2"
                title={<BankFormatters.Name value={bank} />}
                Status={<BankFormatters.StatusLabel value={bank.is_active} className="px-3" />}
                Icon={<DollarSign size={16} />}
                spinning={readQuery.isRefetching}
              >
                <IconButtons.ConfirmModalDeleteButton
                  onConfirm={onClickDelete}
                  message="Är du säker på att du vill radera den här banken?"
                />
                <IconButtons.CopyObjectIdButton id={bank.id} />
                <IconButtons.CopyCurrentUrlButton />
                <IconButtons.RefreshObjectButton
                  disabled={readQuery.isRefetching}
                  onClick={() => readQuery.refetch()}
                />
              </CardTitleIconStatus>
            </Card.Header>
            <Tab.Container
              activeKey={tab}
              transition={false}
              unmountOnExit={true}
              mountOnEnter={true}
            >
              <Nav className="nav-tabs pt-3 px-3">
                <Nav.Item>
                  <NavLink className="nav-link" to={`/bank/${bankId}/form`}>
                    <span className="d-inline-flex gap-1 align-items-center">
                      <Edit2 size={12} />{' '}
                      Formulär
                    </span>
                  </NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink className="nav-link" to={`/bank/${bankId}/commission`}>
                    <span className="d-inline-flex gap-1 align-items-center">
                      <DollarSign size={12} />{' '}
                      Ersättning
                    </span>
                  </NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink className="nav-link" to={`/bank/${bankId}/texts`}>
                    <span className="d-inline-flex gap-1 align-items-center">
                      <Type size={12} />{' '}
                      Texter
                    </span>
                  </NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink className="nav-link" to={`/bank/${bankId}/log`}>
                    <span className="d-inline-flex gap-1 align-items-center">
                      <Activity size={12} />{' '}
                      Logg
                    </span>
                  </NavLink>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="form">
                  <BankEditForm
                    initialValues={bankToFormValues(bank)}
                    onSubmit={formCycleHelpers.onSubmit}
                    submitError={updateMutation.error}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="commission">
                  <Card.Body>
                    <BankCommissionRateCards
                      bankId={bankId}
                    />
                  </Card.Body>
                </Tab.Pane>
                <Tab.Pane eventKey="texts">
                  <Card.Body className="pb-2">
                    <BankTextFragmentCard bankId={bankId} />
                  </Card.Body>
                </Tab.Pane>
                <Tab.Pane eventKey="log">
                  <Card.Body>
                    <BankLogCard bankId={bankId} />
                  </Card.Body>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Card>
        )}
      </Content>
    </Main>
  );
}

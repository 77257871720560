import React from 'react';
import useWebsocketModel, { TModelIds } from 'src/hooks/useWebsocketModel';

type TUpdateCallback<T> = (id: string, update: T) => void;

export default function useWebsocketModelUpdates<T> (modelName: string, onUpdateReceived: TUpdateCallback<T>) {
  const { websocket, ids, setIds } = useWebsocketModel(modelName);

  React.useEffect(() => {
    websocket.methodEmitter.on('modelUpdate', data => {
      if (!isApplicableUpdate(modelName, ids, data)) return;
      const { id, update } = data;
      onUpdateReceived(id, update as T);
    });
    return () => {
      websocket.methodEmitter.off('modelUpdate');
    };
  }, [websocket.methodEmitter, ids, modelName, onUpdateReceived]);

  return {
    setIds: (models: TModelIds) => {
      setIds(models);
    },
  };
}

interface IWebsocketModelUpdate {
  id: string;
  modelName: string;
  update: object;
}

function isApplicableUpdate (modelName: string, modelIds: string[], update: IWebsocketModelUpdate) {
  if (modelName !== update.modelName) return false;
  return modelName === update.modelName && modelIds.includes(update.id);
}

import React from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import { Spinner } from 'react-bootstrap';

interface AuthLoaderGuardProps extends React.PropsWithChildren {

}

export default function AuthLoaderGuard (props: AuthLoaderGuardProps) {
  const { children } = props;

  const { isAuthenticated, isInitialized } = useAuth();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (isInitialized && !isAuthenticated) {
      navigate('/login/password');
    }
  }, [isAuthenticated, isInitialized, navigate]);

  if (!isInitialized) {
    return (
      <div className="container p-4 d-flex justify-content-center">
        <Spinner animation="border" variant="default" />
      </div>
    );
  }

  if (!isAuthenticated) {
    return null;
  }

  return (
    <>
      {children}
    </>
  );
}

// same as AuthLoaderGuard except blocks if authed and shows a fallback
export function UnauthedLoaderGuard (props: AuthLoaderGuardProps) {
  const { children } = props;

  const { isAuthenticated, isInitialized } = useAuth();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (isInitialized && isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated, isInitialized, navigate]);

  if (!isInitialized) {
    return (
      <div className="container p-4 d-flex justify-content-center">
        <Spinner animation="border" variant="default" />
      </div>
    );
  }

  if (isAuthenticated) {
    return null;
  }

  return (
    <>
      {children}
    </>
  );
}

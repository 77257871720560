import React from 'react';
import isObjectLike from 'lodash/isObjectLike';
import * as complaintFilter from 'likvidum/shared/filter/complaint';
import StatusBaseLabel, {StatusBaseLabelProps} from 'src/formatters/StatusBaseLabel';
import { ComplaintRow } from 'shared/types/complaint';

interface NameProps {
  value: Pick<ComplaintRow, 'id'>;
}

export function Name (props: NameProps) {
  const { value:complaint } = props;
  if (!isObjectLike(complaint)) return null;
  const { id } = complaint;
  return (
    <>{id}</>
  );
}

interface StatusProps {
  value: ComplaintRow['status'];
}

export function Status (props: StatusProps) {
  const { value } = props;
  return complaintFilter.status(value);
}

interface StatusLabelProps extends Omit<StatusBaseLabelProps, 'status'> {
  value: ComplaintRow['status'];
}

export function StatusLabel (props: StatusLabelProps) {
  const { value, ...restOfProps } = props;
  return (
    <StatusBaseLabel
      {...restOfProps}
      value={value}
      formatter={complaintFilter.status}
    />
  );
}

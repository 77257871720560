import React from 'react';
import { Form } from 'react-bootstrap';

interface JSONObjectInputProps {
  id?: string;
  name: string;
  value: object;
  onChange: React.ChangeEventHandler;
}

const JSONObjectInput: React.FC<JSONObjectInputProps> = React.memo(function JSONObjectInput (props: JSONObjectInputProps) {
  const { id, name, value, onChange:onChangeOuter } = props;

  const [error, setError] = React.useState<boolean>(false);
  const [formatted, setFormatted] = React.useState<string>('{}');

  React.useEffect(() => {
    let str: string;
    try {
      str = JSON.stringify(value, null, 2);
    } catch (err) {
      setFormatted('');
      setError(true);
      return;
    }
    setError(false);
    setFormatted(str);
  }, [value]);

  const onChange = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newFormatted = ev.target.value;
    setFormatted(newFormatted);
    try {
      JSON.parse(newFormatted);
      setError(false);
    } catch (err) {
      setError(true);
    }
  };

  const onBlur = (ev: React.FocusEvent) => {
    let newValue: object;
    try {
      newValue = JSON.parse(formatted);
      setError(false);
      setFormatted(JSON.stringify(newValue, null, 2));
    } catch (err) {
      setError(true);
      return;
    }

    const clonedEvent = {
      ...ev,
      target: {...ev.target, name, value: newValue},
      persist: () => {}, // Add a no-op function for persist
    };
    onChangeOuter(clonedEvent);
  };

  return (
    <Form.Control
      id={id}
      as="textarea"
      name={name}
      value={formatted}
      onChange={onChange}
      onBlur={onBlur}
      isInvalid={error}
      rows={Math.max(3, formatted.split(/\n/g).length + 1)}
      style={{fontFamily: 'monospace'}}
    />
  );
});
export default JSONObjectInput;

import React from 'react';
import qs from 'qs';

const OLD_CRM_URL = process.env.REACT_APP_OLD_CRM_URL ?? '';

export function ucFirst (str: string): string {
  if (!str) return str;
  return str.slice(0, 1).toUpperCase() + str.slice(1);
}

export function metabaseApiUrlToBaseUrl (apiUrl: string): string {
  return apiUrl.replace(/\/api\/?/, '');
}

export function addQueryToUrl (uri: string, query = {}) {
  const url = new URL(uri);
  url.search = qs.stringify({
    ...qs.parse(url.search, {
      ignoreQueryPrefix: true,
      strictNullHandling: true,
    }),
    ...query,
  }, {addQueryPrefix: false});
  return url.toString();
}

type TProps = {[key: string]: any};

export function debugIsEqual (prevProps: TProps, nextProps: TProps) {
  const prevPropsKeys = Object.keys(prevProps);
  const nextPropsKeys = Object.keys(nextProps);

  if (prevPropsKeys.length !== nextPropsKeys.length) {
    console.log('Number of props changed'); // eslint-disable-line
    return false;
  }

  for (const key of prevPropsKeys) {
    if (prevProps[key] !== nextProps[key]) {
      console.log(`Prop '${key}' changed:`, prevProps[key], '->', nextProps[key]); // eslint-disable-line
      return false;
    }
  }

  return true;
}

export function preventDefaultSubmit (ev: React.FormEvent<HTMLFormElement>) {
  ev.preventDefault();
}

export function formToData<T> (form: HTMLFormElement): T {
  const data = {};
  for (const element of Array.from(form.elements)) {
    if (
      element instanceof HTMLInputElement
      || element instanceof HTMLSelectElement
      || element instanceof HTMLTextAreaElement
    ) {
      const control:(HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement) = element;
      data[control.name] = control.value;
    }
  }
  return data;
}

export function oldCrmUrl (suffix: string = ''): string {
  return OLD_CRM_URL + suffix;
}

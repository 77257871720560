import React, {ElementType} from 'react';
import classNames from 'classnames';
import ErrorAlert from 'src/alerts/ErrorAlert';
import { Card, Accordion } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';

interface ICardTitleIcon extends React.PropsWithChildren {
  as?: ElementType<any>;
  Icon?: React.ReactNode;
  title: React.ReactNode;
  spinning?: boolean;
}

export function CardTitleIcon (props: ICardTitleIcon) {
  const { as = 'h5', Icon, title, spinning, children } = props;
  return (
    <Card.Title as={as} className="mb-0 d-flex justify-content-between align-items-center">
      <div className="d-flex align-items-center gap-1">
        {Icon}
        <span>{title}</span>
        {spinning && (
          <Spinner
            className="ms-2"
            animation="border"
            size="sm"
            variant="default"
            style={{opacity: 0.5}}
          />
        )}
      </div>
      {children && (
        <div>{children}</div>
      )}
    </Card.Title>
  );
}

interface CardTitleIconStatusProps extends ICardTitleIcon {
  Status: React.ReactNode;
}

export function CardTitleIconStatus (props: CardTitleIconStatusProps) {
  const { as = 'h5', Status, Icon, title, spinning, children } = props;
  return (
    <Card.Title as={as} className="mb-0 d-flex justify-content-between align-items-center">
      <div className="d-flex align-items-center gap-2">
        {Icon}
        <span>{title}</span>
        {Status}
        {spinning && (
          <Spinner
            className="ms-2"
            animation="border"
            size="sm"
            variant="default"
            style={{opacity: 0.5}}
          />
        )}
      </div>
      {children && (
        <div className="align-items-end d-flex gap-1">{children}</div>
      )}
    </Card.Title>
  );
}

interface AccordionCardProps extends React.PropsWithChildren {
  className?: string;
  controls?: React.ReactNode;
  title?: React.ReactNode;
  onToggleCallback?: () => void;
  defaultOpen?: boolean;
}

export function AccordionCard (props: AccordionCardProps) {
  const {
    className,
    children,
    title,
    onToggleCallback,
    defaultOpen = false,
  } = props;
  const eventKey = React.useId();
  return (
    <Accordion
      defaultActiveKey={defaultOpen ? eventKey : null}
      className={classNames(className, 'accordion-card')}
    >
      <Accordion.Item eventKey={eventKey} className="bg-white">
        <div className="accordion-header">
          <Accordion.Button className="py-1 px-2" onClick={onToggleCallback}>
            <span style={{fontWeight: 500}}>
              {title}
            </span>
          </Accordion.Button>
        </div>
        <Accordion.Body className="p-0">
          {children}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

interface WidgetListCardProps extends React.PropsWithChildren {
  className?: string;
  controls?: React.ReactNode;
  title?: React.ReactNode;
}

export function WidgetListCard (props: WidgetListCardProps) {
  const {
    className,
    children,
    controls,
    title,
  } = props;
  return (
    <Card className={classNames(className)}>
      <Card.Header className="py-1 px-2 d-flex justify-content-between bg-light border-bottom align-items-baseline">
        <div style={{fontWeight: 500}}>
          {title}
        </div>
        {controls && (
          <div className="d-flex">
            {controls}
          </div>
        )}
      </Card.Header>
      <Card.Body className="p-0">
        {children}
      </Card.Body>
    </Card>
  );
}

interface ErrorAlertCardBodyProps {
  error?: null | string | Error;
  className?: string;
}

export function ErrorAlertCardBody (props: ErrorAlertCardBodyProps) {
  const { error, className } = props;
  if (!error) return null;
  return (
    <Card.Body className={className}>
      <ErrorAlert error={error} className="m-0" />
    </Card.Body>
  );
}

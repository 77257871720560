import React from 'react';
import classNames from 'classnames';
import SidebarNavList from 'src/sidebar/SidebarNavList';
import { SidebarNavListItemProps } from 'src/sidebar/SidebarNavListItem';
import { anyChildHasPermission } from 'src/sidebar/sidebarNavItems';
import useAuth from 'src/hooks/useAuth';

export interface SidebarNavSectionProps {
  title?: string;
  className?: string;
  pages: SidebarNavListItemProps[];
}

const SidebarNavSection: React.FC<SidebarNavSectionProps> = React.memo(function SidebarNavSection (props: SidebarNavSectionProps) {
  const { title, pages, className, ...rest } = props;
  const auth = useAuth();

  if (!anyChildHasPermission(auth, pages)) {
    return null;
  }

  return (
    <React.Fragment {...rest}>
      {title && <li className={classNames(className, 'sidebar-header')}>{title}</li>}
      <SidebarNavList pages={pages} depth={0} />
    </React.Fragment>
  );
});

export default SidebarNavSection;

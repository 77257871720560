import React from 'react';
import Anchor from  'src/anchors/Anchor';
import QuicklookAnchor from 'src/anchors/QuicklookAnchor';
import ComplaintQuicklook from 'src/complaint/ComplaintQuicklook';
import { Name } from 'src/complaint/ComplaintFormatters';
import {ComplaintRow} from 'shared/types/complaint';

interface ComplaintAnchorProps {
  complaint: ComplaintRow;
}

export default function ComplaintAnchor (props: ComplaintAnchorProps) {
  const { complaint, ...restOfProps } = props;
  return (
    <QuicklookAnchor
      {...restOfProps}
      url={`/complaint/${complaint.id}`}
      title={complaint.id}
      name="Klagomål"
      objectId={complaint.id}
    >
      <ComplaintQuicklook id={complaint.id} />
    </QuicklookAnchor>
  );
}

export function ComplaintPlainAnchor (props: ComplaintAnchorProps) {
  const { complaint, ...restOfProps } = props;
  return (
    <Anchor
      {...restOfProps}
      url={`/complaint/${complaint.id}`}
      name="Klagomålet"
    >
      <Name value={complaint} />
    </Anchor>
  );
}

import React from 'react';

interface IEmailModalBody {
  id: string;
}

export default function EmailModalBody (props: IEmailModalBody) {
  return (
    <div>email modal body here</div>
  );
}

import React from 'react';
import { Helmet } from 'react-helmet-async';
import useAuth from 'src/hooks/useAuth';
import Content from 'src/ui/Content';
import Main from 'src/ui/Main';
import Navbar from 'src/navbar/Navbar';
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import { GuardFunction, GuardPermission } from 'src/guards/AuthGuards';
import ErroredApplicationsCard from 'src/cards/ErroredApplicationsCard';
import LatestComplaintsCard from 'src/cards/LatestComplaintsCard';
import FavoritesCard from 'src/cards/FavoritesCard';
import AccountNotesCard from 'src/cards/AccountNotesCard';
import AccountLogCard from 'src/cards/AccountLogCard';
import FilesCard from 'src/cards/FilesCard';
import DashboardStatusCounterCard from 'src/cards/DashboardStatusCounterCard';

export default function DashboardPage () {
  return (
    <Main>
      <Navbar />
      <Content>
        <Helmet title="Översikt" />
        <Container fluid className="p-0">
          <Header />
          <GuardPermission permission="dashboard_status">
            <DashboardStatusCounterCard />
          </GuardPermission>
          <Row>
            <GuardFunction fn="errors">
              <Col xl="6">
                <ErroredApplicationsCard />
              </Col>
            </GuardFunction>
            <Col xl="6">
              <FavoritesCard />
            </Col>
          </Row>
          <Row>
            <Col lg="6" className="d-flex">
              <AccountLogCard />
            </Col>
            <GuardFunction fn="klagomalsansvarig">
              <Col xl="6">
                <LatestComplaintsCard />
              </Col>
            </GuardFunction>
          </Row>
          <Row>
            <Col lg="6" className="d-flex">
              <FilesCard />
            </Col>
            <GuardPermission permission="account_notes">
              <Col lg="6" className="d-flex">
                <AccountNotesCard />
              </Col>
            </GuardPermission>
          </Row>
        </Container>
      </Content>
    </Main>
  );
}

function Header () {
  const { user } = useAuth();
  return (
    <Row className="mb-2 mb-xl-3">
      <Col xs="auto" className="d-none d-sm-block">
        <h3>Välkommen, {user?.name}!</h3>
      </Col>
    </Row>
  );
}

import React from 'react';
import { Helmet } from 'react-helmet-async';
import Content from 'src/ui/Content';
import Main from 'src/ui/Main';
import Navbar from 'src/navbar/Navbar';
import { RefreshObjectButton } from 'src/buttons/IconButtons';
import Pluralizer from 'src/formatters/Pluralizer';
import * as api from 'src/api';
import { Link } from 'react-router-dom';
import * as commonFilterDefs from 'src/tables/commonFilterDefinitions';
import { PieChart } from 'react-feather';
import { Container, Card } from 'react-bootstrap';
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { StatisticSalesmanRow } from 'shared/types/statistic_user';
import { IColumnDefinition, IFilterDefinition } from 'src/tables/Table';
import { getLinkToFilteredTable } from 'src/tables/helpers';
import { IStateOrder, THeaderGroups, TStateFilterMap, TableRow } from 'src/tables/types';
import * as TableCells from 'src/tables/TableCells';
import * as commonColumnDefs from 'src/tables/commonColumnDefinitions';
import CardBodyTable from 'src/tables/CardBodyTable';
import { ErrorAlertCardBody, CardTitleIcon  } from 'src/cards/CardHelpers';
import statisticUserFilter from 'shared/filter/statistic_user';
import moment from 'moment';
import useLocalStorage from 'src/hooks/useLocalStorage';
import * as SetFilterButtons from 'src/buttons/SetFilterButton';

interface IStatisticsSalesmanFilter extends TStateFilterMap {
  date?: {
    field: 'closed_at' | 'created_at';
    from?: string;
    to?: string;
  };
  created_by_user_groups?: string;
  portal_is_from?: boolean;
}

export default function StatisticsSalesmanPage () {
  const [filter, setFilter] = useLocalStorage<TStateFilterMap>('StatisticsSalesmanPageFilter', defaultFilter);
  const [order, setOrder] = useLocalStorage<IStateOrder>('StatisticsSalesmanPageOrder', {id: 'asc'});

  const query = useQuery<StatisticSalesmanRow, Error>({
    queryKey: ['StatisticsSalesmanPage', filter, order],
    placeholderData: keepPreviousData,
    queryFn: props => {
      const params = {...(props.queryKey[1] as IStatisticsSalesmanFilter), order};
      return api.request({
        method: 'POST',
        url:  '/statistic/user/filter/salesman',
        data: {...params},
      });
    },
  });

  const rows: TableRow[] = React.useMemo(() => {
    if (!query.data?.rows) return [];
    return query.data.rows;
  }, [query.data]);

  const footerRows: TableRow[] = React.useMemo(() => {
    if (!query.data?.sum) return [];
    return [query.data.sum];
  }, [query.data]);

  return (
    <Main>
      <Navbar />
      <Content>
        <Helmet title="Handläggarstatistik" />
        <Container fluid className="p-0">
          <Card className="border mb-1">
            <Card.Header className="pb-2">
              <CardTitleIcon
                title="Handläggarstatistik"
                Icon={<PieChart size={16} />}
                spinning={query.isLoading || query.isRefetching}
              >
                <RefreshObjectButton
                  disabled={query.isRefetching}
                  onClick={() => query.refetch()}
                />
              </CardTitleIcon>
              <Card.Subtitle className="mt-1 small d-flex align-items-baseline">
                <Pluralizer
                  count={query.data?.rows?.length ?? 0}
                  zero="Inga handläggare"
                  one="Visar 1 handläggare"
                  otherwise="Visar %% handläggare"
                />
                <SetFilterButtons.ThisYear filter={filter} setFilter={setFilter} />
                <SetFilterButtons.LastYear filter={filter} setFilter={setFilter} />
                <SetFilterButtons.ThisMonth filter={filter} setFilter={setFilter} />
                <SetFilterButtons.LastMonth filter={filter} setFilter={setFilter} />
                <SetFilterButtons.ThisWeek filter={filter} setFilter={setFilter} />
                <SetFilterButtons.LastWeek filter={filter} setFilter={setFilter} />
                <SetFilterButtons.Today filter={filter} setFilter={setFilter} />
                <SetFilterButtons.Yesterday filter={filter} setFilter={setFilter} />
              </Card.Subtitle>
            </Card.Header>
            <ErrorAlertCardBody error={query.error} className="border-top p-3" />
            <CardBodyTable
              size="sm"
              filter={filter}
              order={order}
              filterDefinitions={filterDefinitions}
              columnDefinitions={columnDefinitions}
              headerGroups={headerGroups}
              setFilter={setFilter}
              setOrder={setOrder}
              rows={rows}
              footerRows={footerRows}
              isFetched={query.isFetched}
              params={{filter}}
            />
          </Card>
        </Container>
      </Content>
    </Main>
  );
}

const defaultFilter = {
  date: {
    field: 'created_at',
    from: moment().startOf('month').format('YYYY-MM-DD'),
    to: moment().endOf('month').format('YYYY-MM-DD'),
  },
};

const filterDefinitions: IFilterDefinition[] = [
  commonFilterDefs.dateRangeOnField({id: 'date'}, [
    {value: 'created_at', label: 'Skapad'},
    {value: 'closed_at', label: 'Stängd'},
    {value: 'accepted_at', label: 'Accepterad'},
  ]),
  commonFilterDefs.boolean({
    id: 'portal_is_from',
  }),
  commonFilterDefs.groupId({
    id: 'created_by_user_groups',
    permission: 'group_select_options',
  }),
].map(def => ({
  ...def,
  title: statisticUserFilter.filterName(def.id),
}));

const columnDefinitions: IColumnDefinition[] = [
  commonColumnDefs.basic({
    id: 'id',
    title: 'Handläggare',
    Cell: MaybeUserTableCell,
    cellProps: {rowAsObject: true},
    sortable: true,
  }),
  commonColumnDefs.basic({
    id: 'values.tot',
    title: 'Totalt',
    Cell: CountLinkedCell,
    cellProps: {rowFilter: []},
    sortable: true,
  }),
  commonColumnDefs.basic({
    id: 'values.tot_has_co_applicant',
    title: 'Meds',
    Cell: TableCells.Count,
    sortable: true,
  }),
  commonColumnDefs.basic({
    id: 'values.tot_new_customer',
    title: 'Ny kund',
    Cell: CountLinkedCell,
    cellProps: {rowFilter: [{key: 'ordinal', value: {value: 1, op: 'eq'}}]},
    sortable: true,
  }),
  commonColumnDefs.basic({
    id: 'values.tot_ever_waiting',
    title: 'Bev',
    Cell: CountLinkedCell,
    cellProps: {rowFilter: [{key: 'flag_status_ever_waiting', value: true}]},
    sortable: true,
  }),

  commonColumnDefs.basic({
    id: 'values.tot_accepted',
    title: 'Totalt',
    Cell: CountLinkedCell,
    cellProps: {rowFilter: [{key: 'flag_status_ever_accepted', value: true}]},
    sortable: true,
  }),
  commonColumnDefs.basic({
    id: 'values.sum_ever_accepted',
    title: 'Summa',
    Cell: TableCells.Currency,
    sortable: true,
  }),
  commonColumnDefs.basic({
    id: 'values.pct_accepted_over_tot',
    title: 'Acpgrad',
    Cell: TableCells.Percentage,
    sortable: true,
  }),
  commonColumnDefs.basic({
    id: 'values.pct_application_rejected_over_tot',
    title: 'Avsgrad',
    Cell: TableCells.Percentage,
    sortable: true,
  }),

  commonColumnDefs.basic({
    id: 'values.tot_disbursed',
    title: 'Totalt',
    Cell: CountLinkedCell,
    cellProps: {rowFilter: [{key: 'status', value: 'disbursed'}]},
    sortable: true,
  }),
  commonColumnDefs.basic({
    id: 'values.pct_disbursed_over_tot',
    title: 'Utbgrad',
    Cell: TableCells.Percentage,
    sortable: true,
  }),
  commonColumnDefs.basic({
    id: 'values.sum_disbursed',
    title: 'Summa',
    Cell: TableCells.Currency,
    sortable: true,
  }),
  commonColumnDefs.basic({
    id: 'values.sum_disbursed_new',
    title: 'Summa',
    Cell: TableCells.Currency,
    sortable: true,
  }),
  commonColumnDefs.basic({
    id: 'values.sum_disbursed_old_customer_not_prev_disbursed',
    title: 'Utan utb',
    Cell: TableCells.Currency,
    sortable: true,
  }),
  commonColumnDefs.basic({
    id: 'values.sum_disbursed_customer_prev_disbursed',
    title: 'Med utb',
    Cell: TableCells.Currency,
    sortable: true,
  }),
  commonColumnDefs.basic({
    id: 'values.sum_disbursed_old',
    title: 'Summa',
    Cell: TableCells.Currency,
    sortable: true,
  }),
];

const headerGroups: THeaderGroups = [
  {
    id: 'id',
    title: '',
    columns: ['id'],
  },
  {
    id: 'applications',
    title: 'Ansökningar',
    columns: [
      'values.tot',
      'values.tot_has_co_applicant',
      'values.tot_new_customer',
      'values.tot_ever_waiting',
    ],
  },
  {
    id: 'accepted',
    title: 'Accepter',
    columns: [
      'values.tot_accepted',
      'values.sum_ever_accepted',
      'values.pct_accepted_over_tot',
      'values.pct_application_rejected_over_tot',
    ],
  },
  {
    id: 'disbursed',
    title: 'Utbetalningar',
    columns: [
      'values.tot_disbursed',
      'values.pct_disbursed_over_tot',
      'values.sum_disbursed',
    ],
  },
  {
    id: 'disbursed_new',
    title: 'Utb. nykund',
    columns: [
      'values.sum_disbursed_new',
    ],
  },
  {
    id: 'disbursed_old',
    title: 'Utb. ej nykund',
    columns: [
      'values.sum_disbursed_old_customer_not_prev_disbursed',
      'values.sum_disbursed_customer_prev_disbursed',
      'values.sum_disbursed_old',
    ],
  },
];

function MaybeUserTableCell (props) {
  const { row } = props;
  if (row.id !== 'sum') {
    if (row.id === null) return '-';
    return <TableCells.UserTableCell {...props} />;
  } 
  return (
    <strong>Summa</strong>
  );
}

function CountLinkedCell (props) {
  const { value, row, params, rowFilter } = props;
  const { filter } = params;
  const to = getLinkToFilteredTable('/applications/all', filter, [
    ...rowFilter,
    row.id === 'sum' || row.id === null ? null : {key: 'created_by_user_id', value: row.id},
  ].filter(v => v));
  return (
    <Link to={to}>
      {value} st
    </Link>
  );
}

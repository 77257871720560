import { v4 as uuid } from 'uuid';

export const primus = {

  wrap: (data: {}) => ({
    plugin: 'primus-responder',
    requestId: uuid(),
    data,
  }),

  callMethod: (method: string, data: any) => primus.wrap({
    method,
    data,
  }),

  hello: (sessionId: string) => primus.wrap({
    method: 'hello',
    data: {session_id: sessionId},
  }),

  pong: (ts: string): string => `"primus::pong::${ts}"`,

};

export const methods = {

  subscribeToModel: (modelName: string, ids: string[]) => ({
    modelName,
    ids,
  }),

  unsubscribeToModel: (modelName: string, ids: string[]) => ({
    modelName,
    ids,
  }),
};

// returns true if a websocket message received from the server is a methodName call
export function isMessageForMethod (methodName: string, lastJsonMessage:(any | null)): boolean {
  return lastJsonMessage?.type === 0 && lastJsonMessage?.data?.[0] === methodName;
}

import React from 'react';
import isObjectLike from 'lodash/isObjectLike';
import { SmsTemplateRow } from 'shared/types/sms_template';

interface NameProps {
  value: SmsTemplateRow;
}

export function Name (props: NameProps) {
  const { value:smsTemplate } = props;
  if (!isObjectLike(smsTemplate)) return null;
  return <>{smsTemplate.name}</>;
}


import React from 'react';

import { LAYOUT, TLayout } from 'src/constants';
import useSettingsState from 'src/hooks/useSettingsState';

interface ILayoutContext {
  layout: TLayout;
  setLayout: (newLayout: TLayout) => void;
}

const initialState: ILayoutContext = {
  layout: LAYOUT.FLUID,
  setLayout: () => {},
};

const LayoutContext = React.createContext<ILayoutContext>(initialState);

function LayoutProvider ({ children }) {
  const [layout, setLayout] = useSettingsState('layout', LAYOUT.FLUID);
  const value: ILayoutContext = {layout, setLayout};
  return (
    <LayoutContext.Provider value={value}>
      {children}
    </LayoutContext.Provider>
  );
}

export { LayoutProvider, LayoutContext };

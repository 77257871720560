import React from 'react';

interface ILetterModalBody {
  id: string;
}

export default function LetterModalBody (props: ILetterModalBody) {
  return (
    <div>letter modal body here</div>
  );
}

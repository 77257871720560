import React from 'react';
import { Alert } from 'react-bootstrap';
import { UserSession } from 'shared/types/user';
import ButtonSpinner from 'src/spinners/ButtonSpinner';
import ErrorAlert from 'src/alerts/ErrorAlert';
import useAuth from 'src/hooks/useAuth';
import * as api from 'src/api';
import { useMutation } from '@tanstack/react-query';
import { QRCode } from 'react-qr-svg';
import classNames from 'classnames';

interface StartResponse {
  orderRef: string;
  autoStartToken: string;
  qrStartToken: string;
  qrCodeString: string;
}

interface CollectVars {
  orderRef: string;
}

interface BankIdState {
  lastCollectResponse: null | WrappedCollectResponse;
  polling: boolean;
  orderRef: null | string;
  qrCode: null | string;
}

const emptyState = (): BankIdState => ({
  lastCollectResponse: null,
  polling: false,
  orderRef: null,
  qrCode: null,
});

interface LoginBankIdProps {
  local?: boolean;
}

export default function LoginBankId (props: LoginBankIdProps) {
  const { local = false } = props;
  const auth = useAuth();

  const [state, setState] = React.useState<BankIdState>(emptyState());
  const [loginPromise, setLoginPromise] = React.useState<Promise<any> | null>(null);

  const startMutation = useMutation<StartResponse, Error>({
    mutationFn: () => api.request({
      url: '/auth/bankid',
      timeout: 15000,
      method: 'POST',
      data: {},
    }).then(response => response.bankid),
    onSuccess: response => {
      const { autoStartToken, qrCodeString, orderRef } = response;

      setState({
        lastCollectResponse: {bankid: {status: 'started'}},
        polling: true,
        orderRef,
        qrCode: qrCodeString,
      });

      if (local) {
        const redirectUrl = new URL('bankid:///');
        const params = new URLSearchParams({
          autostarttoken: autoStartToken,
          redirect: 'null',
        });
        redirectUrl.search = params.toString();

        // const redirectUrl = `bankid:///?autostarttoken=${autoStartToken}&redirect=null`;
        window.location.href = redirectUrl.toString();
      }

    },
  });

  const collectMutation = useMutation<WrappedCollectResponse, Error, CollectVars>({
    mutationFn: data => api.request({
      url: `/auth/bankid/${data.orderRef}`,
      method: 'GET',
    }),
    onError: () => {
      setState(emptyState());
    },
    onSuccess: lastCollectResponse => {
      setState(prevState => ({
        ...prevState,
        lastCollectResponse,
      }));

      if ('status' in (lastCollectResponse?.bankid ?? {})) {
        if (lastCollectResponse.bankid?.status === 'pending') {
          const { qrCodeString } = lastCollectResponse.bankid;
          setState(prevState => ({...prevState, qrCode: qrCodeString}));
        } else {
          setState(prevState => ({
            ...prevState,
            polling: false,
            orderRef: null,
            qrCode: null,
          }));
          if (lastCollectResponse.bankid.status === 'complete' && 'user' in lastCollectResponse) {
            const promise = auth.login(lastCollectResponse.user).finally(() => {
              setLoginPromise(null);
            });
            setLoginPromise(promise);
          }
        }
      }
    },
  });

  const onClickStart = () => {
    setState(emptyState());
    startMutation.mutateAsync();
  };

  React.useEffect(() => {
    if (!state.polling || !state.orderRef) return;

    const intervalId = setInterval(() => {
      if (collectMutation.isPending) return;
      collectMutation.mutateAsync({orderRef: state.orderRef as string});
    }, 2000);

    return () => {
      clearInterval(intervalId);
    };
  }, [state, collectMutation]);

  const startDisabled = startMutation.isPending || state.polling || loginPromise !== null;

  return (
    <div>
      <ErrorAlert error={startMutation.error} />
      <div className="pt-3">
        <div className="d-flex flex-column align-items-center">
          <ButtonSpinner
            className="d-flex align-items-center gap-1 flex-wrap justify-content-center"
            onClick={onClickStart}
            disabled={startDisabled}
            size="lg"
            isLoading={startDisabled}
          >
            {local ? (
              <>Påbörja BankID-signeringen</>
            ) : (
              <>Skapa QR-kod för att påbörja signeringen med BankID</>
            )}
          </ButtonSpinner>
          {!local && state.qrCode && (
            <BankIdQrCode value={state.qrCode} />
          )}
        </div>
      </div>
      <ErrorAlert error={collectMutation.error} className="mt-3 mb-1" />
      {state.lastCollectResponse && (
        <>
          {'error' in state.lastCollectResponse ? (
            <Alert variant="danger" className="text-danger mt-3 mb-0 p-3 justify-content-center">
              {state.lastCollectResponse.error}
            </Alert>
          ) : (
            <BankIdStatusAlert response={state.lastCollectResponse.bankid} local={local} />
          )}
        </>
      )}
    </div>
  );
}

interface BankIdQrCodeProps {
  value: string;
}
 
function BankIdQrCode (props: BankIdQrCodeProps) {
  const { value } = props;
  const style = {width: 150, height: 150};
  return (
    <div className="d-flex mt-3">
      <div className="border rounded p-2">
        <QRCode style={style} value={value} />
      </div>
    </div>
  );
}

type WrappedCollectResponse = {
  localStatus?: null | number;
  bankid: CollectResponse;
  user?: UserSession;
} | {
  localStatus: 422;
  bankid: CollectResponse;
  error: string;
}

type CollectResponse = {
  // started is not a real CollectResponse but something we put just after starting
  // this is so that we can immediately show a message to the user without waiting
  // for the result of the first poll to return
  status: 'started';
} | {
  status: 'pending';
  orderRef: string;
  hintCode: string;
  qrCodeString: string;
} | {
  status: 'complete';
} | {
  status: 'failed';
  orderRef: string;
  hintCode: string;
} | {
  errorCode: string;
  details: string;
}

interface BankIdStatusAlertProps {
  response: CollectResponse;
  local: boolean;
}

export function BankIdStatusAlert (props: BankIdStatusAlertProps) {
  const { response, local } = props;
  if ('status' in response && response.status === 'complete') return null;

  const hintCode = 'hintCode' in response ? response.hintCode : null;
  const message = getMessage(local, response, hintCode);
  const variant = getVariant(response);
  const textColorClassName = `text-${variant}`;
  return (
    <Alert variant={variant} className={classNames('mt-3 mb-0 p-3 justify-content-center', textColorClassName)}>
      <span>{message}</span>
    </Alert>
  );
}

function getVariant (response: CollectResponse): string {
  if ('errorCode' in response) return 'danger';
  switch (response.status) {
    default: return '';
    case 'started': return 'info';
    case 'pending': return 'info';
    case 'failed': return 'danger';
  }
}

function getMessage (local: boolean, response: CollectResponse, hintCode?: null | string): string {
  if ('errorCode' in response) {
    const { errorCode, details } = response;
    return `Ett fel inträffade: ${errorCode} - ${details}`;
  }
  const { status } = response;
  if (status === 'started') {
    return local ? 'Försöker starta BankID-appen.' : 'Starta BankID-appen och skanna QR-koden.';
  } else if (status === 'pending') {
    switch (hintCode) {
      default: return `Signering pågår. (${hintCode})`;
      case 'noClient':
      case 'outstandingTransaction':
        return local ? 'Försöker starta BankID-appen.' : 'Starta BankID-appen och skanna QR-koden.';
      case 'started':
        return 'Söker efter BankID, det kan ta en liten stund…';
      case 'userSign': return 'Skriv in din säkerhetskod i BankID-appen och välj Identifiera.';
    }
  } else if (status === 'failed') {
    switch (hintCode) {
      default: return `Okänt fel. Försök igen. (${hintCode})`;
      case 'expiredTransaction': return 'BankID-appen svarar inte. Kontrollera att den är startad och att du har internetanslutning. Försök sedan igen.';
      case 'certificateErr': return 'Det BankID du försöker använda är för gammalt eller spärrat. Använd ett annat BankID eller hämta ett nytt hos din internetbank.';
      case 'userCancel': return 'Åtgärden avbruten.';
      case 'cancelled': return 'Åtgärden avbruten. Försök igen.';
      case 'startFailed':
        return local ? 'BankID-appen verkar inte finnas i din dator eller telefon.' : 'Misslyckades att läsa av QR koden. Starta BankID-appen och skanna QR koden.';
      case 'notSupportedByUserApp': return 'Anropet stöds inte av din BankID-app. Var god uppdatera appen.';
    }
  }
  return `${status}: ${hintCode}`;
}

import React from 'react';
import DateTime from 'src/formatters/DateTime';
import OverlaySpinner from 'src/spinners/OverlaySpinner';
import ErrorAlert from 'src/alerts/ErrorAlert';
import { CardTitleIcon } from 'src/cards/CardHelpers';
import {RefreshObjectButton} from 'src/buttons/IconButtons';
import { useQuery } from '@tanstack/react-query';
import { BankLogRowWithRelations } from 'shared/types/bank_log';
import UserAnchor from 'src/user/UserAnchor';
import * as bankLogFilters from 'shared/filter/bank_log';
import { Card, Table } from 'react-bootstrap';
import { Activity } from 'react-feather';
import InspectObjectModalButton from 'src/buttons/InspectObjectModalButton';

interface BankLogCardProps {
  bankId: string;
}

export default function BankLogCard (props: BankLogCardProps) {
  const { bankId } = props;

  const query = useQuery<BankLogRowWithRelations[], Error>({
    queryKey: [`/bank/${bankId}/log`],
  });

  const data = query.data ?? [];

  return (
    <Card className="flex-fill w-100 border mb-1">
      <Card.Header className="border-bottom">
        <CardTitleIcon
          title="Långivarlogg"
          Icon={<Activity size={18} />}
          spinning={query.isRefetching}
        >
          <RefreshObjectButton
            disabled={query.isRefetching}
            onClick={() => query.refetch()}
          />
        </CardTitleIcon>
      </Card.Header>
      <div className="position-relative table-responsive">
        <ErrorAlert error={query.error} className="mx-3 mt-3 mb-2" />
        <OverlaySpinner isLoading={query.isLoading} />
        <Table striped className="my-0 border-bottom">
          <thead>
            <tr>
              <th>Datum</th>
              <th>Utförare</th>
              <th>Beskrivning</th>
            </tr>
          </thead>
          <tbody>
            {query.isSuccess && data.map(item => (
              <tr key={item.id}>
                <td>
                  <DateTime value={item.date} />
                </td>
                <td>
                  {!item.user_id ? 'System' : (
                    <UserAnchor user={item.User} />
                  )}
                </td>
                <td>
                  {bankLogFilters.description(item)}
                </td>
                <td>
                  <div className="d-flex justify-content-end">
                    <InspectObjectModalButton
                      size="sm"
                      object={item}
                    />
                  </div>
                </td>
              </tr>
            ))}
            {!query.data?.length && (
              <tr>
                <td colSpan={3} className="text-center">Det finns inga logginlägg</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </Card>
  );
}

import { useEffect } from 'react';
import usePalette from 'src/hooks/usePalette';
import ChartJSPluginAnnotation from 'chartjs-plugin-annotation';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  PointElement,
  Title,
  CategoryScale,
  BarElement,
} from 'chart.js';

const ChartJsDefaults = () => {
  const palette = usePalette();

  useEffect(() => {
    ChartJS.register(ChartJSPluginAnnotation, ArcElement, Title, Tooltip, Legend, LinearScale, PointElement, CategoryScale, BarElement);
    ChartJS.defaults.plugins.tooltip.animation = false;
    ChartJS.defaults.plugins.tooltip.boxPadding = 5;
    ChartJS.defaults.plugins.legend.display = false;
    ChartJS.defaults.locale = 'sv-SE';
    ChartJS.defaults.color = palette['gray-600'];
    ChartJS.defaults.font.family = 'Poppins, \'Helvetica Neue\', Helvetica, Arial, sans-serif';
  }, [palette]);

  return null;
};

export default ChartJsDefaults;

import React from 'react';
import { Button, Modal } from 'react-bootstrap';

interface SimpleModalOpeningButtonProps {
  className?: string;
  label: React.ReactNode;
  modalTitle?: React.ReactNode;
  children: React.ReactNode;
  variant?: string;
}

const SimpleModalOpeningButton: React.FC<SimpleModalOpeningButtonProps> = React.memo(function ModalOpeningButton (props: SimpleModalOpeningButtonProps) {
  const {
    children,
    label,
    modalTitle,
    ...buttonProps
  } = props;

  const [showModal, setShowModal] = React.useState(false);

  const onHide = () => {
    setShowModal(false);
  };

  return (
    <>
      <Button {...buttonProps} onClick={() => setShowModal(true)}>
        {label}
      </Button>
      <Modal show={showModal} onHide={onHide}>
        {modalTitle && (
          <Modal.Header>
            <Modal.Title>
              {modalTitle}
            </Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body className="m-2">
          {children}
        </Modal.Body>
      </Modal>
    </>
  );
});
export default SimpleModalOpeningButton;

import React from 'react';
import {useQuery} from '@tanstack/react-query';
import OverlaySpinner from 'src/spinners/OverlaySpinner';
import ErrorAlert from 'src/alerts/ErrorAlert';
import * as IconButtons from 'src/buttons/IconButtons';
import { CardTitleIcon } from 'src/cards/CardHelpers';
import { Smartphone, Trash2 } from 'react-feather';
import { SmsRow } from 'shared/types/sms';
import {Card, Table} from 'react-bootstrap';
import DateTime from 'src/formatters/DateTime';
import * as SmsFormatters from 'src/sms/SmsFormatters';
import TemplateAnchor from 'src/template/TemplateAnchor';
import InspectObjectModalButton from 'src/buttons/InspectObjectModalButton';
import TemplateQuickCreateForm from 'src/template/TemplateQuickCreateForm';
import {GuardPermission} from 'src/guards/AuthGuards';
import {PermissionDefinition} from 'src/contexts/AuthContext';
import ExecuteButton from 'src/buttons/ExecuteButton';
import ConfirmActionModalExecuteButton from 'src/buttons/ConfirmActionModalExecuteButton';

interface SmsContactControlCardProps {
  createPermission?: PermissionDefinition;
  searchParams: Record<string, any>;
  searchTemplateParams: Record<string, any>;
  objectType: string;
  objectId: string;
}

export default function SmsContactControlCard (props: SmsContactControlCardProps) {
  const { searchParams, searchTemplateParams, createPermission, objectId, objectType } = props;

  const readList = useQuery<SmsRow[], Error>({
    queryKey: ['/sms/search', {params: searchParams}],
  });

  const reload = () => {
    readList.refetch();
  };

  const data = readList.data ?? [];

  return (
    <Card className="flex-fill w-100 border mb-1">
      <Card.Header className="border-bottom bg-light">
        <CardTitleIcon
          title="SMS"
          Icon={<Smartphone size={18} />}
          spinning={readList.isRefetching}
        >
          <IconButtons.RefreshObjectButton
            disabled={readList.isRefetching}
            onClick={reload}
          />
        </CardTitleIcon>
      </Card.Header>
      <div className="position-relative table-responsive">
        <ErrorAlert error={readList.error} className="mx-3 mt-3 mb-2" />
        <OverlaySpinner isLoading={readList.isLoading || readList.isRefetching} />
        <Table className="my-0 border-bottom" size="sm">
          <thead>
            <tr>
              <th>Mall</th>
              <th>GSM</th>
              <th>Status</th>
              <th>Statustid</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {readList.isSuccess && data.map(item => (
              <tr key={item.id}>
                <td><TemplateAnchor template={{id: item.template_id}} /></td>
                <td>{item.gsm}</td>
                <td>
                  <SmsFormatters.StatusLabel value={item.status} short />
                </td>
                <td>
                  <DateTime value={item.status_at} />
                </td>
                <td>
                  <div className="d-flex justify-content-end gap-1">
                    {item.status === 'new' && (
                      <ExecuteButton
                        url={`/sms/${item.id}/send`}
                        onSuccess={reload}
                        size="sm"
                      >
                        Skicka
                      </ExecuteButton>
                    )}
                    <GuardPermission permission="sms_delete">
                      {item.status !== 'sent' && (
                        <ConfirmActionModalExecuteButton
                          url={`/sms/${item.id}`}
                          className="px-1"
                          method="DELETE"
                          onSuccess={reload}
                          size="sm"
                          variant="danger"
                          message="Är du säker på att du vill ta bort SMS:et?"
                        >
                          <Trash2 size={14 }/>
                        </ConfirmActionModalExecuteButton>
                      )}
                    </GuardPermission>
                    <InspectObjectModalButton
                      size="sm"
                      object={item}
                    />
                  </div>
                </td>
              </tr>
            ))}
            {!readList.data?.length && (
              <tr>
                <td colSpan={4} className="text-center">Det finns inga SMS</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      {createPermission && (
        <GuardPermission permission={createPermission}>
          <Card.Footer className="position-relative">
            <TemplateQuickCreateForm
              objectType={objectType}
              objectId={objectId}
              category="sms"
              searchParams={searchTemplateParams}
              onCreated={reload}
            />
          </Card.Footer>
        </GuardPermission>
      )}
    </Card>
  );
}

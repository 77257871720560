import React from 'react';
import consentFilter from 'shared/filter/customer_consent';
import * as commonFilterDefs from 'src/tables/commonFilterDefinitions';
import * as commonColumnDefs from 'src/tables/commonColumnDefinitions';
import * as TableCellActions from 'src/tables/TableCellActions';
import { IColumnDefinition, IFilterDefinition } from 'src/tables/Table';
import TablePage from 'src/tables/TablePage';
import LinkButton from 'src/buttons/LinkButton';
import { Printer } from 'react-feather';

export default function CustomerConsentTablePage () {
  return (
    <TablePage
      title="Samtycken"
      pageTitle="Samtyckestabell"
      columnDefinitions={columnDefinitions}
      filterDefinitions={filterDefinitions}
      routeUrl="/customer_consent"
      baseQueryUrl="/customer_consent/table"
      baseQueryKey="CustomerConsentTable"
    />
  );
}

const filterDefinitions: IFilterDefinition[] = [
  commonFilterDefs.dateRange({id: 'created_at'}),
  commonFilterDefs.dateRelative({id: 'created_at_relative'}),
  commonFilterDefs.dateRange({id: 'expires_at'}),
  commonFilterDefs.dateRelative({id: 'expires_at_relative'}),
  commonFilterDefs.string({id: 'subject'}),
  commonFilterDefs.customerConsentMethod({id: 'method'}),
  commonFilterDefs.uuid({id: 'customer_id'}),
  commonFilterDefs.userId({
    id: 'user_id',
    permission: 'customer_consent_table:read_others',
  }),
].map(filterDefinition => ({
  title: consentFilter.indexColumnName(filterDefinition.id),
  ...filterDefinition,
}));

const columnDefinitions: IColumnDefinition[] = [
  commonColumnDefs.select(),
  ...([
    commonColumnDefs.customerConsentId({id: 'id', cellProps: {rowAsObject: true}}),
    commonColumnDefs.customerId({
      id: 'customer_id',
      cellProps: {objKey: 'Customer', idKey: 'customer_id'},
    }),
    commonColumnDefs.cell('method'),
    commonColumnDefs.cell('subject'),
    commonColumnDefs.userId({
      id: 'user_id',
      cellProps: {objKey: 'User', idKey: 'user_id'},
    }),
    commonColumnDefs.datetime({
      id: 'created_at',
      sortable: true,
    }),
    commonColumnDefs.datetime({
      id: 'expires_at',
      sortable: true,
    }),
  ].map(columnDefinition => {
    const { id } = columnDefinition;
    const header = consentFilter.indexColumnName(id);
    const cellValue = consent => consentFilter.indexField(consent, id);
    return {
      Cell: ({value}) => <>{value}</>,
      ...columnDefinition,
      cellValue,
      Header: ({value}) => <>{value}</>,
      title: header,
    };
  })),
  commonColumnDefs.actions({}, [
    props => (
      <LinkButton
        to={`/customer_consent/${props.row.id}/print`}
        size="sm"
        variant="outline-primary"
        className="px-1"
        target="_blank"
        title="Öppna utskriftsvänlig sida"
      >
        <Printer size={14} />
      </LinkButton>
    ),
    TableCellActions.InspectRowTableCellAction,
  ]),
];

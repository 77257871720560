import React from 'react';
import classNames from 'classnames';

export default function Main (props) {
  const { className, children, flush } = props;
  const style = flush ? {height: '100vh'} : {};
  return (
    <div className={classNames('main', className)} style={style}>
      {children}
    </div>
  );
}
